import common from './ch/common.json'
import loginPage from './ch/loginPage.json'
import recoveryPassPage from './ch/recoveryPassPage.json'
import generalIndustriesPage from './ch/generalIndustriesPage.json'
import generalPanelPage from './ch/generalPanelPage.json'
import breadcrumb from './ch/breadcrumb.json'
import generalUserPage from './ch/generalUserPage.json'
import filters from './ch/filters.json'
import traceabilityRetailPage from './ch/traceabilityRetailPage.json'
import traceabilityProviderPage from './ch/traceabilityProviderPage.json'
import traceabilityWeavingPage from './ch/traceabilityWeavingPage.json'
import traceabilityWiringPage from './ch/traceabilityWiringPage.json'
import propertiesPage from './ch/propertiesPage.json'
import tableComponent from './ch/tableComponent.json'
import biomesSelectComponent from './ch/biomesSelectComponent.json'
import productionChainPage from './ch/productionChainPage.json'
import ordersManagementPage from './ch/ordersManagementPage.json'
import traceabilityPanelPage from './ch/traceabilityPanelPage.json'
import newIndustry from './ch/newIndustry.json'
import generalIndustryGroup from './ch/generalIndustryGroup.json'
import newIndustryGroup from './ch/newIndustryGroup.json'
import newUser from './ch/newUser.json'
import orderDetails from './ch/orderDetails.json'
import providerDetailsPage from './ch/providerDetailsPage.json'
import notFoundPage from './ch/notFoundPage.json'
import accordionDetailsContent from './ch/accordionDetailsContent.json'
import leadTimePage from './ch/leadTimePage.json'
import fabricsPage from './ch/fabricsPage.json'
import downloadReport from './ch/downloadReport.json'

export default {
  biomesSelectComponent,
  breadcrumb,
  common,
  filters,
  generalIndustriesPage,
  generalIndustryGroup,
  generalPanelPage,
  loginPage,
  newIndustry,
  newIndustryGroup,
  newUser,
  productionChainPage,
  propertiesPage,
  providerDetailsPage,
  recoveryPassPage,
  tableComponent,
  traceabilityPanelPage,
  traceabilityProviderPage,
  traceabilityRetailPage,
  traceabilityWeavingPage,
  traceabilityWiringPage,
  generalUserPage,
  orderDetails,
  ordersManagementPage,
  notFoundPage,
  accordionDetailsContent,
  leadTimePage,
  fabricsPage,
  downloadReport
}
