/**
 * Forma o numero de telefone
 * @param value value de telefone que vai ser formatado
 * @returns value do telefone formatado
 */
export const phoneMask = (value: string) => {
  if (!value) return value
  let valMask = value.replace(/\D/g, '')
  if (valMask.length > 10) {
    valMask = valMask.replace(/\D/g, '')
    valMask = valMask.replace(/(\d{2})(\d)/, '$1 $2')
    valMask = valMask.replace(/(\d{5})(\d)/, '$1-$2')
    valMask = valMask.replace(/(\d{4})\d+?$/, '$1')
  } else {
    valMask = valMask.replace(/\D/g, '')
    valMask = valMask.replace(/(\d{2})(\d)/, '$1 $2')
    valMask = valMask.replace(/(\d{4})(\d)/, ' $1-$2')
    valMask = valMask.replace(/(\d{4})\d+?$/, '$1')
  }
  return valMask
}
