import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import * as Yup from 'yup'

import { toasts } from 'utils/toasts'
import { submitOnEnter } from 'utils/submitOnEnter'

import {
  INITIAL_STATE,
  ERRORS_INITIAL_STATE,
  INITIAL_STATE_CONSTANT_ORDER_SITUATION,
  INITIAL_STATE_CONSTANT_STATUS_ORDER
} from '../../leadTime.constants'

import { Button, Input, InputSelect } from 'components'

import * as S from '../../styled'

Yup.addMethod(Yup.object, 'atLeastOneOf', function (list) {
  return this.test({
    name: 'atLeastOneOf',
    message: 'Preencha o formulário corretamente',
    exclusive: true,
    params: { keys: list.join(', ') },
    test: value => value == null || list.some(f => !!value[f])
  })
})

const schema = Yup.object()
  .shape({
    supplierAndOutsourcedName: Yup.string(),
    activity: Yup.string(),
    orderStatus: Yup.string(),
    orderSituation: Yup.string(),
    column: Yup.string(),
    direction: Yup.string()
  })
  .atLeastOneOf([
    'supplierAndOutsourcedName',
    'activity',
    'orderStatus',
    'orderSituation'
  ])

type FilterProps = {
  onSubmit: (_form: any) => void
}

export function FilterForm({ onSubmit }: FilterProps) {
  const { t } = useTranslation()

  const [form, setForm] = useState({
    ...INITIAL_STATE
  })
  const [errors, setErrors] = useState({ ...ERRORS_INITIAL_STATE })

  const [orderStatus] = useState(INITIAL_STATE_CONSTANT_STATUS_ORDER)
  const [orderStatusSelected, setOrderStatusSelected] = useState([])

  const [orderSituation] = useState(INITIAL_STATE_CONSTANT_ORDER_SITUATION)
  const [orderSituationSelected, setOrderSituationSelected] = useState([])

  async function onFilter() {
    try {
      const filter = await schema.validate(form, {
        abortEarly: false,
        stripUnknown: true
      })

      const params = { ...filter }
      delete params.orderNumber

      onSubmit(params)
    } catch (ex) {
      console.log(ex.inner)

      if (ex.inner && ex.inner.length) {
        const pairs = ex.inner?.map(({ path, message }) => [
          path,
          message.replace(`${path} `, '')
        ])
        toasts.invalidForm()
        setErrors(Object.fromEntries(pairs) as typeof ERRORS_INITIAL_STATE)
      }
    }
  }

  const clearFilter = useCallback(() => {
    setForm({ ...INITIAL_STATE })
    setErrors({ ...ERRORS_INITIAL_STATE })
    onFilter()
  }, [])

  function handleInput(value: string, name: string) {
    setErrors({ ...errors, [name]: '' })
    setForm({ ...form, [name]: value })
  }

  function handleSelectOrderStatus(field: string, value: any) {
    setErrors({ ...errors, [field]: '' })
    setForm({ ...form, [field]: value.id })
    setOrderStatusSelected(value)
  }

  function handleSelectOrderSituation(field: string, value: any) {
    setErrors({ ...errors, [field]: '' })
    setForm({ ...form, [field]: value.id })
    setOrderSituationSelected(value)
  }

  useEffect(() => {
    onFilter()
  }, [])

  return (
    <S.Wrapper container>
      <S.FullGrid item xs={12}>
        <S.GridFilter container>
          <S.GridInput item xs={12} sm={6} md={3}>
            <Input
              error={Boolean(errors.supplierAndOutsourcedName)}
              fullWidth
              helperText={errors.supplierAndOutsourcedName}
              label={t('leadTimePage:filters:supplierAndOutsourcedName')}
              onInput={value => handleInput(value, 'supplierAndOutsourcedName')}
              onKeyPress={submitOnEnter(onFilter)}
              value={form.supplierAndOutsourcedName}
            />
          </S.GridInput>

          <S.GridInput item xs={12} sm={6} md={3}>
            <Input
              error={Boolean(errors.activity)}
              fullWidth
              helperText={errors.activity}
              label={t('leadTimePage:filters:activity')}
              onInput={value => handleInput(value, 'activity')}
              onKeyPress={submitOnEnter(onFilter)}
              value={form.activity}
            />
          </S.GridInput>

          <S.GridInput item xs={12} sm={6} md={3}>
            <InputSelect
              error={Boolean(errors.orderStatus)}
              fullWidth
              helperText={errors.orderStatus}
              defaultValue={orderStatusSelected}
              label={t('ordersManagementPage:filtersOrders:orderStatus')}
              onSelected={val => handleSelectOrderStatus('orderStatus', val)}
              optionLabel="label"
              optionValue="id"
              options={orderStatus || []}
              value={form.orderStatus}
              loadingText="Aguarde..."
            />
          </S.GridInput>

          <S.GridInput item xs={12} sm={6} md={3}>
            <InputSelect
              error={Boolean(errors.orderSituation)}
              fullWidth
              helperText={errors.orderSituation}
              defaultValue={orderSituationSelected}
              label={t('ordersManagementPage:filtersOrders:ordersSituation')}
              onSelected={val =>
                handleSelectOrderSituation('orderSituation', val)
              }
              optionLabel="label"
              optionValue="id"
              options={orderSituation || []}
              value={form.orderSituation}
              loadingText="Aguarde..."
            />
          </S.GridInput>
        </S.GridFilter>

        <S.GridFilter>
          <S.GridButtons item xs={12} sm={12} md={12}>
            <Button variant="gray" onClick={clearFilter} size="medium">
              <p>{t('ordersManagementPage:filtersOrders:clean')}</p>
            </Button>
            <Button variant="blue" onClick={() => onFilter()} size="medium">
              <p>{t('ordersManagementPage:filtersOrders:filter')}</p>
            </Button>
          </S.GridButtons>
        </S.GridFilter>
      </S.FullGrid>
    </S.Wrapper>
  )
}
