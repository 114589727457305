export const INITIAL_ORDERS_DATA = [
  {
    name: 'orders',
    color: '#315ca7',
    data: [2, 4, 8, 3, 1]
  }
]

export const INITIAL_VOLUMES_DATA = [
  {
    name: 'volumes',
    color: '#3fab36',
    data: [2, 4, 3, 6, 8, 7]
  }
]

export const INITIAL_TOTALIZER_COUNTERS = [
  {
    totalOrders: 0,
    totalVolumes: 0,
    ordersDeliveried: 0,
    volumesDeliveried: 0,
    ordersOpen: 0,
    volumesOpen: 0,
    orderDelayed: 0,
    volumeDelayed: 0,
    ordersAbrCertified: 0,
    volumesAbrCertified: 0
  }
]

export const INITIAL_RANKING_STATE = [
  {
    name: '',
    y: 0
  }
]

export const INITIAL_SUMMARY = {
  providers: [],
  subProviders: [],
  weaving: [],
  wiring: []
}

export const tabOptions = [
  {
    tag: 'wiring',
    label: 'wiring'
  }
]
