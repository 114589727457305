import { useState } from 'react'
import * as S from './styled'

import { Icon } from '../Icon'
import { Button } from '../Button'

export type ModalProps = {
  cancel?: string
  cancelButton?: boolean
  width?: number
  content: JSX.Element
  button?: JSX.Element
  isActionsEnabled?: boolean
  title?: string
  titleIcon?: string
  centerTitle?: boolean
  onExit?: () => void
}

const Modal = ({
  cancel = 'Cancelar',
  cancelButton = true,
  width = 60,
  content,
  button,
  isActionsEnabled = true,
  title,
  titleIcon,
  centerTitle = false,
  onExit
}: ModalProps) => {
  const [isOpen, setIsOpen] = useState(true)

  function setModalClose() {
    setIsOpen(false)
    !!onExit && onExit()
  }

  let actions: JSX.Element
  if (isActionsEnabled) {
    actions = (
      <S.ActionButtons>
        <div>
          {cancelButton && (
            <Button variant="gray" onClick={setModalClose} size="medium">
              <p>{cancel}</p>
            </Button>
          )}
          {button}
        </div>
      </S.ActionButtons>
    )
  } else {
    actions = <S.ActionButtons data-testid="no-actions"></S.ActionButtons>
  }

  return (
    <S.Background isOpen={isOpen}>
      <S.Wrapper width={width}>
        <S.Modal
          data-testid="modal"
          isOpen={isOpen}
          width={width}
          aria-label="modal"
          aria-hidden={!isOpen}
        >
          <S.Header hasTitle={!!title}>
            {title && (
              <S.TitleContainer centerTitle={centerTitle}>
                {titleIcon && <Icon icon={titleIcon} height={16} width={16} />}
                <h4>{title}</h4>
              </S.TitleContainer>
            )}
            <S.Close
              data-testid="close-modal"
              aria-label="close-modal"
              onClick={setModalClose}
            >
              <Icon icon="modal-exit" height={16} width={16} cursorPointer />
            </S.Close>
          </S.Header>
          <S.Template>
            <S.Content width={width}>{content}</S.Content>
            {isActionsEnabled && (
              <S.Actions className={'actions'}>
                <S.Line />
                {actions}
              </S.Actions>
            )}
          </S.Template>
        </S.Modal>
      </S.Wrapper>
    </S.Background>
  )
}

export default Modal
