import { Base } from 'layouts'

import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import * as S from './styled'

import { CardItems } from './CardItems'

export function SearchPanel() {
  const { t } = useTranslation()
  return (
    <Base>
      <S.GridContainer data-testid="cards-searchpanel" container spacing={4}>
        {CardItems().map(({ link, title, description, icon }, index) => (
          <S.GridIconCard key={index} item xs={12} md={4}>
            <Link to={link}>
              <S.IconCardColor
                title={t(`traceabilityPanelPage:${title}`)}
                description={t(`traceabilityPanelPage:${description}`)}
                icon={icon}
              />
            </Link>
          </S.GridIconCard>
        ))}
      </S.GridContainer>
    </Base>
  )
}
