import { Grid } from '@material-ui/core'
import styled from 'styled-components'

export const GridFields = styled(Grid)`
  display: flex;
  margin: -16px;
  width: calc(100% + 24px);
  flex-direction: row;
`

export const GridInput = styled(Grid)``
