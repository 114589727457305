import styled from 'styled-components'
import Radio from '@material-ui/core/Radio'
import { FormControlLabel, FormLabel } from '@material-ui/core'

export const Wrapper = styled.div`
  margin: 0.4rem 0.8rem;
`

export const FLabel = styled(FormLabel).attrs(() => {})`
  font-size: 1rem;
`

export const RButton = styled(Radio).attrs(() => {})``

export const FControlLabel = styled(FormControlLabel).attrs(() => {})`
  .MuiTypography-body1 {
    font-size: 1.4rem;
    line-height: 2.4rem;
    color: ${({ theme, disabled }) =>
      disabled ? theme.colors.lightGray : theme.colors.darkGray};
  }

  padding-left: ${({ theme }) => theme.spacings.xsmall};
  padding-top: ${({ theme }) => theme.spacings.xxxsmall};
`

export const AlertIcon = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.error};

  position: relative;
  top: 0.8rem;
  height: 0;
  img {
    height: 1rem;
  }

  .MuiFormHelperText-root {
    color: ${({ theme }) => theme.colors.error};
    font-size: 1rem;
    font-weight: 600;
    padding-left: ${({ theme }) => theme.spacings.xxsmall};
    top: -0.5rem;
    position: relative;
  }
`
