import { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { t } from 'i18next'
import { useTranslation } from 'react-i18next'

import { cnpjMask } from 'utils/CnpjMask'
import { formaDateStringLocale } from 'utils/formaDateStringLocale'

import { ItemOrderDetails } from '../ItemOrdersTable/ItemOrderDetails'
import { GeneralOrderDetails } from '../GeneralOrderDetails/GeneralOrderDetails'

import { Icon } from 'components'
import { ColumnsProps } from 'components/Table/Table'
import { TooltipComponent } from 'components/Tooltip'
import { TableAccordion } from 'components/TableAccordion'
import { ModalTable } from 'components/ModalOpenTable/ModalTable'
import { TraceabilityRetailFiltersContext } from 'contexts/traceabilityRetailTableFilters/traceabilityRetailTableFilters.context'

import * as S from './styles'

type OrderItemType = {
  orderID: string
  orderNumber: string
  supplierName: string
  supplierCnpj: string
  orderDate: string
  invoiceDeliveryDate: string
  orderStatus: 'A' | 'W'
  orderSituation: string
  totalOrderItems: string
  qtyTotal: number
  totalDelivered: string
  supplierOrigin: string | null
  haveFabricData: 'N' | 'Y'
  scheduleEndDate?: string
}

const columns = (): ColumnsProps[] => [
  {
    fixed: true,
    align: 'center',
    name: 'orderNumber',
    title: t('traceabilityRetailPage:tables:orders:orderNumber')
  },
  {
    fixed: false,
    name: 'supplierName',
    title: t('traceabilityRetailPage:tables:orders:supplierName')
  },
  {
    fixed: false,
    name: 'supplierCnpj',
    title: t('traceabilityRetailPage:tables:orders:CNPJprovider')
  },
  {
    fixed: false,
    align: 'center',
    name: 'orderDate',
    title: t('traceabilityRetailPage:tables:orders:orderDate')
  },
  {
    fixed: false,
    align: 'center',
    name: 'invoiceDeliveryDate',
    title: t('traceabilityRetailPage:tables:orders:invoiceDeliveryDate')
  },
  {
    fixed: false,
    align: 'center',
    name: 'scheduleEndDate',
    title: t('traceabilityRetailPage:tables:orders:scheduleEndDate')
  },
  {
    align: 'center',
    fixed: false,
    name: 'orderStatus',
    title: t('traceabilityRetailPage:tables:orders:orderStatus')
  },
  {
    fixed: false,
    align: 'center',
    name: 'orderSituation',
    title: t('traceabilityRetailPage:tables:orders:orderSituation')
  },
  {
    fixed: false,
    align: 'center',
    name: 'orderOriginType',
    title: t('traceabilityRetailPage:tables:orders:orderOriginType')
  },
  {
    fixed: false,
    align: 'center',
    name: 'totalOrderItems',
    title: t('traceabilityRetailPage:tables:orders:totalOrderItems')
  },
  {
    fixed: false,
    align: 'center',
    name: 'qtyTotal',
    title: t('traceabilityRetailPage:tables:orders:quantityItemsOrdered')
  },
  {
    fixed: false,
    align: 'center',
    name: 'totalDelivered',
    title: t('traceabilityRetailPage:tables:orders:totalDelivered')
  },
  {
    fixed: false,
    align: 'center',
    name: 'supplierOrigin',
    title: t('traceabilityRetailPage:tables:orders:originSupplier')
  },
  {
    fixed: false,
    align: 'center',
    name: 'orderProgress',
    title: t('traceabilityRetailPage:tables:orders:progress')
  }
]
export type OrderItensTableProps = {
  rows: OrderItemType[]
}

export function OrdersTable({ rows }: OrderItensTableProps) {
  const { setTraceabilityRetailsFilters, traceabilityRetailsFilters } =
    useContext(TraceabilityRetailFiltersContext)
  const { t, i18n } = useTranslation()

  const language = i18n.language === '中文 - ZH - CN' ? 'zh-CN' : i18n.language

  const [columnSorted, setColumnSorted] = useState(
    traceabilityRetailsFilters?.column || 'orderDate'
  )
  const [sortDirection, setSortDirection] = useState<'DESC' | 'ASC'>(
    traceabilityRetailsFilters?.direction || 'DESC'
  )

  const [tableHeight, setTableHeight] = useState(0)
  const [orderId, setOrderId] = useState(null)
  const [idModal, setIdModal] = useState(null)
  const [openModal, setOpenModal] = useState(false)

  function actionTable(orderItemParam?: OrderItemType) {
    return (
      <S.Nav>
        <TooltipComponent
          text={t('traceabilityRetailPage:tables:orders:orderItems')}
        >
          <S.Link
            onClick={() => {
              setIdModal(orderItemParam.orderID)
              setOpenModal(true)
            }}
          >
            <Icon
              icon="details"
              height={24}
              width={24}
              fill="success"
              cursorPointer
            />
          </S.Link>
        </TooltipComponent>
        <Link
          to={{
            pathname: `/gestao-pedidos/detalhes-pedido/${orderItemParam.orderNumber}`,
            state: { fromRetail: true }
          }}
        >
          <TooltipComponent
            text={t(
              'ordersManagementPage:tables:ordersTable:actions:manageOrder'
            )}
          >
            <S.Link>
              <Icon
                icon="breadcrumbs-order-summary"
                height={24}
                width={24}
                fill="success"
                cursorPointer
              />
            </S.Link>
          </TooltipComponent>
        </Link>
        <Link to={`/gestao-pedidos/lead-time/${orderItemParam.orderNumber}`}>
          <TooltipComponent
            text={t('ordersManagementPage:tables:ordersTable:actions:leadTime')}
          >
            <S.Link>
              <Icon
                icon="hourglass"
                height={24}
                width={24}
                fill="success"
                cursorPointer
              />
            </S.Link>
          </TooltipComponent>
        </Link>
        {orderItemParam.haveFabricData !== 'N' ? (
          <Link to={`/rastreabilidade/tecidos/${orderItemParam.orderNumber}`}>
            <TooltipComponent text={t('fabricsPage:tables:fabrics:fabrics')}>
              <S.Link>
                <Icon
                  icon="fabrics"
                  height={24}
                  width={24}
                  fill="success"
                  cursorPointer
                />
              </S.Link>
            </TooltipComponent>
          </Link>
        ) : (
          <TooltipComponent text={t('fabricsPage:tables:fabrics:fabrics')}>
            <S.Link>
              <Icon
                icon="fabricsDesabilited"
                height={24}
                width={24}
                fill="primary"
              />
            </S.Link>
          </TooltipComponent>
        )}

        {/*
          Inativando esse link por decisão de negócio que no momento, não será necessário usar a tela de cadeia produtiva
          Mantendo código para futuras necessidades e revisões
          <Link
            to={'/cadeia-produtiva'}
            onClick={() => setFilterForProductiveChain(orderItemParam)}
          >
            <S.Link>
              <Icon
                icon="productive-chain-action"
                height={16}
                width={16}
              ></Icon>
              <span>
                {t('traceabilityRetailPage:tables:orders:productiveChain')}
              </span>
            </S.Link>
          </Link> */}
      </S.Nav>
    )
  }

  function handleSortData(column: string, direction: 'ASC' | 'DESC') {
    setColumnSorted(column)
    setSortDirection(direction)
    setTraceabilityRetailsFilters({
      ...traceabilityRetailsFilters,
      column,
      direction
    })
  }

  function handleSortColumn(column: string) {
    setColumnSorted(column)
    handleSortData(column, sortDirection)
    if (column !== columnSorted) return
    const direction = sortDirection === 'ASC' ? 'DESC' : 'ASC'
    handleSortData(column, direction)
  }

  function handleGetIdRow(orderItemParam?: OrderItemType) {
    setOrderId(orderItemParam.orderID)
  }

  function handleTableHeight(height: number) {
    setTableHeight(height)
  }

  const formattedOrders = rows.map(item => ({
    ...item,
    supplierCnpj: item.supplierCnpj ? cnpjMask(item.supplierCnpj) : null,
    orderDate: item.orderDate ? formaDateStringLocale(item.orderDate) : null,
    invoiceDeliveryDate: item.invoiceDeliveryDate
      ? formaDateStringLocale(item.invoiceDeliveryDate)
      : null,
    key: JSON.stringify({ id: item.orderID, number: item.orderNumber }),
    totalOrderItems: Number(item.totalOrderItems).toLocaleString(language),
    qtyTotal: Number(item.qtyTotal).toLocaleString(language),
    totalDelivered: Number(item.totalDelivered).toLocaleString(language),
    scheduleEndDate: item.scheduleEndDate
      ? formaDateStringLocale(item.scheduleEndDate)
      : null
  }))

  return (
    <>
      {openModal ? (
        <ModalTable
          content={<ItemOrderDetails id={idModal} />}
          titleTable={t('orderDetails:titleItems')}
          onExit={() => setOpenModal(false)}
        />
      ) : null}
      <TableAccordion
        rows={formattedOrders}
        columns={columns()}
        handleGetId={handleGetIdRow}
        sortColumn={{ column: columnSorted, sort: sortDirection }}
        onSortColumn={handleSortColumn}
        actionTable={actionTable}
        titleTable={t('traceabilityRetailPage:tables:orders:ordersDetails')}
        statusBoxColumnName="orderStatus"
        situationBoxColumnName="orderSituation"
        height={tableHeight}
      >
        <GeneralOrderDetails id={orderId} parentHeight={handleTableHeight} />
      </TableAccordion>
    </>
  )
}
