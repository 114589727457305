import styled from 'styled-components'
import SVG from 'react-inlinesvg'

interface fillType {
  fill: 'primary' | 'secondary' | 'red' | 'success' | 'white'
}

export const Icon = styled(SVG)<fillType>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ height }) => `${height}px`};
  width: ${({ width }) => `${width}px`};
  fill: ${({ theme, fill }) => theme.colors[fill]};
`
