import { Icon } from 'components'
import { useTranslation } from 'react-i18next'
import * as S from './styled'

export function UserPermissions({
  handleChange,
  values,
  userRoleSelected,
  userRoleLogged,
  canEdit
}) {
  const { t } = useTranslation()
  const traceable = [
    {
      icon: 'varejo',
      name: 'retail',
      tag: 'retail',
      disable:
        userRoleLogged === 'admind' || !canEdit || userRoleSelected === 'admind'
    },

    // {
    //   icon: 'propriedade',
    //   name: 'property',
    //   tag: 'traceabilityProperty'
    // },
    {
      icon: 'confeccao',
      name: 'provider',
      tag: 'provider',
      disable:
        (userRoleSelected === 'visual' && userRoleLogged === 'admind') ||
        !canEdit
    },
    // Comentado por motivos de não estar em uso no momento, mas pode ser usado no futuro
    // {
    //   icon: 'historico',
    //   name: 'blockchainHistory',
    //   tag: 'blockchainHistory'
    // },
    // {
    //   icon: 'breadcrumbs-spinning',
    //   name: 'wiring',
    //   tag: 'wiring'
    // },
    // {
    //   icon: 'tecelagem',
    //   name: 'weaving',
    //   tag: 'weaving'
    // },
    {
      icon: 'list',
      name: 'ordersManagement',
      tag: 'ordersManagement',
      disable: !canEdit
    }
    // Comentado por motivos de não estar em uso no momento, mas pode ser usado no futuro
    // {
    //   icon: 'cadeia-produtiva',
    //   name: 'productiveChain',
    //   tag: 'productiveChain'
    // },

    // {
    //   icon: 'hourglass',
    //   name: 'leadTime',
    //   tag: 'leadTime'
    // }
  ]

  const registrable = [
    {
      icon: 'usuario',
      name: 'user',
      tag: 'users',
      disable: userRoleSelected === 'visual' || !canEdit
    },
    {
      icon: 'industria',
      name: 'industries',
      tag: 'industries',
      disable: userRoleSelected === 'visual' || !canEdit
    },
    {
      icon: 'industria',
      name: 'industriesGroup',
      tag: 'industriesGroup',
      disable:
        userRoleSelected === 'visual' ||
        !canEdit ||
        userRoleSelected === 'admind'
    }
    // {
    //   icon: 'propriedade',
    //   name: 'property',
    //   tag: 'propertyRegister'
    // }
    // Comentado por motivos de não estar em uso no momento, mas pode ser usado no futuro
    // {
    //   icon: 'phone',
    //   name: 'productionLaunchApp',
    //   tag: 'appProduction'
    // },
    // {
    //   icon: 'two-sided-arrow',
    //   name: 'productionAllocation',
    //   tag: 'productionAllocation'
    // }
  ]

  return (
    <S.FullGrid item container sm={12} md={12}>
      <div style={{ marginBottom: 16 }}>
        <S.GridHeader>
          <S.GridTitle item container xs={12}>
            <S.BoxTitle>
              {t('newUser:permissions:traceability.traceability')}
            </S.BoxTitle>
          </S.GridTitle>
        </S.GridHeader>
        <S.GridFilter container>
          {traceable.length &&
            values &&
            traceable.map(({ name, icon, tag, disable }, key) => (
              <S.PermissionOption key={key}>
                <div>
                  <Icon icon={icon} />
                  <span>{t(`newUser:permissions:traceability:${name}`)}</span>
                </div>
                <S.SwitchButton
                  disabled={disable}
                  key={key}
                  onChange={event => handleChange(tag, event.target.checked)}
                  checked={values[tag]}
                />
              </S.PermissionOption>
            ))}
        </S.GridFilter>
      </div>
      <S.DividerPermissions />
      <div>
        <S.GridHeader>
          <S.GridTitle item container xs={12}>
            <S.BoxTitle>
              {t('newUser:permissions:registration:registration')}
            </S.BoxTitle>
          </S.GridTitle>
        </S.GridHeader>
        <S.GridFilter container>
          {registrable.length &&
            values &&
            registrable.map(({ icon, name, tag, disable }, key) => (
              <S.PermissionOption key={key}>
                <div>
                  <Icon icon={icon} />
                  <span>{t(`newUser:permissions:registration:${name}`)}</span>
                </div>
                <S.SwitchButton
                  disabled={disable}
                  key={key}
                  onChange={event => handleChange(tag, event.target.checked)}
                  checked={values[tag]}
                />
              </S.PermissionOption>
            ))}
        </S.GridFilter>
      </div>
    </S.FullGrid>
  )
}
