import { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import * as Yup from 'yup'
import { addDays, format, subDays, subMonths } from 'date-fns'

import { SupplierFiltersContext } from 'contexts/supplierFilters/supplierFilters.context'

import { toasts } from 'utils/toasts'
import { YupCNPJ } from 'utils/YupCNPJ'
import { submitOnEnter } from 'utils/submitOnEnter'

import { providerRequestPayload } from 'pages/Provider/constants'

import {
  InputDate,
  RadioButton,
  Button,
  Input,
  InputSelect,
  InputCpfCnpj
} from 'components'

import * as S from './styled'

const getDateLastMonth = subMonths(new Date(), 1)
const formattedDate = new Date(
  getDateLastMonth.getFullYear(),
  getDateLastMonth.getMonth(),
  1,
  getDateLastMonth.getHours(),
  getDateLastMonth.getMinutes(),
  getDateLastMonth.getSeconds()
)

const INITIAL_STATE = {
  isSupplierPage: true,
  startDate: formattedDate,
  endDate: new Date(),
  dateType: 'order',
  orderNumber: '',
  invoiceNumber: '',
  orderStatus: '',
  certificationABR: '',
  productCode: '',
  providerCnpj: '',
  providerName: '',
  outsourcedName: '',
  outsourcedCnpj: '',
  column: 'orderDate',
  direction: 'DESC'
}

const ERRORS_INITIAL_STATE = {
  startDate: null,
  endDate: null,
  dateType: 'order',
  orderNumber: '',
  invoiceNumber: '',
  productCode: '',
  orderStatus: '',
  certificationABR: '',
  providerCnpj: '',
  providerName: '',
  outsourcedName: '',
  outsourcedCnpj: ''
}

Yup.addMethod(Yup.object, 'atLeastOneOf', function (list) {
  return this.test({
    name: 'atLeastOneOf',
    message: 'Preencha o formulário corretamente',
    exclusive: true,
    params: { keys: list.join(', ') },
    test: value => value == null || list.some(f => !!value[f])
  })
})

const schema = Yup.object()
  .shape({
    isSupplierPage: Yup.boolean().default(true),
    startDate: Yup.date().nullable(),
    endDate: Yup.date().nullable(),
    cnpj: Yup.lazy(data => {
      if (data) return YupCNPJ.verify()
      return Yup.string()
    }),
    name: Yup.string(),
    orderNumber: Yup.string(),
    invoiceNumber: Yup.string(),
    productCode: Yup.string(),
    dateType: Yup.string(),
    orderStatus: Yup.string(),
    providerCnpj: Yup.string(),
    providerName: Yup.string(),
    outsourcedCnpj: Yup.string(),
    outsourcedName: Yup.string(),
    column: Yup.string(),
    direction: Yup.string()
  })
  .atLeastOneOf([
    'startDate',
    'endDate',
    'cnpj',
    'name',
    'orderNumber',
    'invoiceNumber'
  ])

type FilterProps = {
  onSubmit: (_form: any) => void
}

export function FilterForm({ onSubmit }: FilterProps) {
  const { t } = useTranslation()
  const orderStatusOptions = () => [
    {
      id: '0',
      label: t('traceabilityRetailPage:filtersRetail.orderStatusDropdown.T'),
      tag: 'T'
    },
    {
      id: '1',
      label: t('traceabilityRetailPage:filtersRetail.orderStatusDropdown.A'),
      tag: 'A'
    },
    {
      id: '2',
      label: t('traceabilityRetailPage:filtersRetail.orderStatusDropdown.P'),
      tag: 'P'
    },
    {
      id: '3',
      label: t('traceabilityRetailPage:filtersRetail.orderStatusDropdown.F'),
      tag: 'F'
    }
  ]

  const { clearSupplierFilters, setSupplierFilters, supplierFilters } =
    useContext(SupplierFiltersContext)

  function parseFilterOptions() {
    const startDateKey =
      providerRequestPayload[supplierFilters.dateType].startDate
    const endDateKey = providerRequestPayload[supplierFilters.dateType].endDate
    return {
      startDate: supplierFilters[startDateKey],
      endDate: supplierFilters[endDateKey]
    }
  }

  const _INITIAL_STATE = {
    ...INITIAL_STATE,
    ...supplierFilters,
    ...parseFilterOptions()
  }

  const [form, setForm] = useState({ ..._INITIAL_STATE })
  const [orderStatusSelected, setOrderStatusSelected] = useState(() => ({
    id: '0',
    label: t('traceabilityRetailPage:filtersRetail.orderStatusDropdown.T'),
    tag: 'T'
  }))
  const [errors, setErrors] = useState({ ...ERRORS_INITIAL_STATE })

  async function onFilter(isFirstLoadPage?: boolean) {
    try {
      const filter = await schema.validate(form, {
        abortEarly: false,
        stripUnknown: true
      })

      const params = {
        ...filter,
        orderNumber: filter.orderNumber,
        invoiceNumber: filter.invoiceNumber,
        [providerRequestPayload[form.dateType].startDate]: filter.startDate,
        [providerRequestPayload[form.dateType].endDate]: filter.endDate,
        providerCnpj: filter.providerCnpj.replace(/[^\w\s]/gi, '') || '',
        outsourcedCnpj: filter.outsourcedCnpj.replace(/[^\w\s]/gi, '') || ''
      }

      const _params = {
        ...params,
        startDate: format(new Date(params.startDate), 'yyyy-MM-dd'),
        endDate: format(new Date(params.endDate), 'yyyy-MM-dd')
      }

      delete _params.dateType
      delete _params.startDate
      delete _params.endDate

      if (!isFirstLoadPage) setSupplierFilters(params)
      onSubmit({ ..._params })
    } catch (ex) {
      console.log(ex.inner)

      const pairs = ex.inner.map(({ path, message }) => [
        path,
        message.replace(`${path} `, '')
      ])
      toasts.invalidForm()
      setErrors(Object.fromEntries(pairs) as typeof ERRORS_INITIAL_STATE)
    }
  }

  const clearFilter = useCallback(() => {
    clearSupplierFilters()
    setForm({ ...INITIAL_STATE })
    setErrors({ ...ERRORS_INITIAL_STATE })
    onFilter(true)
  }, [])

  function handleInput(value: string, name: string) {
    setErrors({ ...errors, [name]: '' })
    setForm({ ...form, [name]: value })
  }

  function handleInputData(value: string | Date, name: string) {
    setForm({ ...form, [name]: value })
  }

  function handleSelect(value: any, name: string) {
    if (value.value) {
      setForm({ ...form, [name]: value.value })
    } else {
      setForm({ ...form, [name]: value })
    }
  }

  function handleSelectOrderStatus(field: string, value: any) {
    setErrors({ ...errors, [field]: '' })
    setForm({ ...form, [field]: value.tag })
    setOrderStatusSelected(value)
  }

  useEffect(() => {
    onFilter(true)
  }, [])

  const dateType = [
    {
      title: t('traceabilityProviderPage:filtersProvider:order'),
      value: 'order'
    },
    {
      title: t('traceabilityProviderPage:filtersProvider:invoice'),
      value: 'invoice'
    },
    {
      title: t('traceabilityProviderPage:filtersProvider:production'),
      value: 'production'
    },
    {
      title: t('traceabilityProviderPage:filtersProvider:deliveryForecast'),
      value: 'prediction'
    }
  ]

  return (
    <S.Wrapper container>
      <S.FullGrid item xs={12}>
        <S.GridHeader>
          <S.GridTitle item container xs={12}>
            <S.BoxTitle>
              {t('traceabilityProviderPage:filtersProvider:filterData')}
            </S.BoxTitle>
            <S.BoxLine />
          </S.GridTitle>
        </S.GridHeader>
        <S.GridFilter container>
          <S.GridInput item xs={12} sm={6} md={2}>
            <InputDate
              fullWidth
              label={t('traceabilityProviderPage:filtersProvider:initialDate')}
              value={form.startDate}
              error={Boolean(errors.startDate)}
              helperText={errors.startDate}
              onInput={value => handleInputData(value, 'startDate')}
              maxDate={form.endDate && subDays(form.endDate, 1)}
            />
          </S.GridInput>
          <S.GridInput item xs={12} sm={6} md={2}>
            <InputDate
              fullWidth
              label={t('traceabilityProviderPage:filtersProvider:finalDate')}
              value={form.endDate}
              error={Boolean(errors.endDate)}
              helperText={errors.endDate}
              onInput={value => handleInputData(value, 'endDate')}
              minDate={form.startDate && addDays(form.startDate, 1)}
            />
          </S.GridInput>
          <S.GridInput item xs={12} sm={12} md={6}>
            <RadioButton
              label=""
              options={dateType}
              key="dateType"
              value={form.dateType}
              onSelected={value => handleSelect(value, 'dateType')}
            />
          </S.GridInput>
        </S.GridFilter>
        <S.GridFilter container>
          <S.GridInput item xs={12} sm={6} md={4}>
            <Input
              error={Boolean(errors.orderNumber)}
              fullWidth
              helperText={errors.orderNumber}
              label={t('traceabilityProviderPage:filtersProvider:orderNumber')}
              onInput={value => handleInput(value, 'orderNumber')}
              onKeyPress={submitOnEnter(onFilter)}
              value={form.orderNumber}
            />
          </S.GridInput>
          <S.GridInput item xs={12} sm={6} md={4}>
            <Input
              error={Boolean(errors.invoiceNumber)}
              fullWidth
              helperText={errors.invoiceNumber}
              label={t('traceabilityProviderPage:filtersProvider:invoice')}
              onInput={value => handleInput(value, 'invoiceNumber')}
              onKeyPress={submitOnEnter(onFilter)}
              value={form.invoiceNumber}
            />
          </S.GridInput>
          <S.GridInput item xs={12} sm={6} md={4}>
            <Input
              error={Boolean(errors.productCode)}
              fullWidth
              helperText={errors.productCode}
              label={t('traceabilityProviderPage:filtersProvider:productCode')}
              onInput={value => handleInput(value, 'productCode')}
              onKeyPress={submitOnEnter(onFilter)}
              value={form.productCode}
            />
          </S.GridInput>
          <S.GridInput item xs={12} sm={6} md={4}>
            <InputSelect
              error={Boolean(errors.orderStatus)}
              fullWidth
              helperText={errors.orderStatus}
              defaultValue={orderStatusSelected}
              label={t('traceabilityProviderPage:filtersProvider:orderStatus')}
              onSelected={val => handleSelectOrderStatus('orderStatus', val)}
              value={form.orderStatus}
              optionLabel="label"
              optionValue="tag"
              options={orderStatusOptions() || []}
              loadingText="Aguarde..."
            />
          </S.GridInput>
          <S.GridInput item xs={12} sm={6} md={4}>
            <InputCpfCnpj
              error={Boolean(errors.providerCnpj)}
              fullWidth
              helperText={errors.providerCnpj}
              label={t('traceabilityProviderPage:filtersProvider:CNPJprovider')}
              onInput={value => handleInput(value, 'providerCnpj')}
              onKeyPress={submitOnEnter(onFilter)}
              value={form.providerCnpj}
              type="cnpj"
            />
          </S.GridInput>
          <S.GridInput item xs={12} sm={6} md={4}>
            <Input
              error={Boolean(errors.providerName)}
              fullWidth
              helperText={errors.providerName}
              label={t(
                'traceabilityProviderPage:filtersProvider:supplierCorporateName'
              )}
              onInput={value => handleInput(value, 'providerName')}
              onKeyPress={submitOnEnter(onFilter)}
              value={form.providerName}
            />
          </S.GridInput>
          {/* REMOVIDO POR HORA */}
          {/* <S.GridInput item xs={12} sm={6} md={4}>
            <InputSelect
              error={Boolean(errors.certificationABR)}
              fullWidth
              helperText={errors.certificationABR}
              label={t(
                'traceabilityProviderPage:filtersProvider:certificationABR'
              )}
              onSelected={() => {}}
              optionLabel="nome"
              optionValue="id.M49"
              options={[]}
              loadingText="Aguarde..."
            />
          </S.GridInput> */}
          <S.GridInput item xs={12} sm={6} md={4}>
            <InputCpfCnpj
              error={Boolean(errors.outsourcedCnpj)}
              fullWidth
              helperText={errors.outsourcedCnpj}
              label={t(
                'traceabilityProviderPage:filtersProvider:CNPJsubContractors'
              )}
              onInput={value => handleInput(value, 'outsourcedCnpj')}
              onKeyPress={submitOnEnter(onFilter)}
              value={form.outsourcedCnpj}
              type="cnpj"
            />
          </S.GridInput>
          <S.GridInput item xs={12} sm={6} md={4}>
            <Input
              error={Boolean(errors.outsourcedName)}
              fullWidth
              helperText={errors.outsourcedName}
              label={t(
                'traceabilityProviderPage:filtersProvider:subcontractedCorporateName'
              )}
              onInput={value => handleInput(value, 'outsourcedName')}
              onKeyPress={submitOnEnter(onFilter)}
              value={form.outsourcedName}
            />
          </S.GridInput>
        </S.GridFilter>
        <S.GridFilter>
          <S.GridButtons item xs={12} sm={12} md={12}>
            <Button variant="gray" onClick={clearFilter} size="medium">
              <p>{t('traceabilityProviderPage:filtersProvider:clean')}</p>
            </Button>
            <Button variant="blue" onClick={() => onFilter()} size="medium">
              <p>{t('traceabilityProviderPage:filtersProvider:filter')}</p>
            </Button>
          </S.GridButtons>
        </S.GridFilter>
      </S.FullGrid>
    </S.Wrapper>
  )
}
