import { HTTPB2B } from 'providers'
import { UserType, ChangePasswordType } from 'types/User.types'

export class UserService extends HTTPB2B {
  private endpoint = '/user'

  public changePassword({ payload }: { payload: ChangePasswordType }) {
    return this.patch({
      endpoint: `${this.endpoint}/update-passwd`,
      payload
    })
  }

  public create({ payload }: { payload: UserType }) {
    return this.post({ endpoint: this.endpoint, payload })
  }

  public deleteUser({ id }) {
    return this.delete({ endpoint: `${this.endpoint}/${id}` })
  }

  public edit(user: UserType) {
    const { id } = user
    return this.put({ endpoint: `${this.endpoint}/${id}`, payload: user })
  }

  public fetchAll(filters) {
    return this.get({ endpoint: this.endpoint, params: filters })
  }

  public fetchOne(id) {
    return this.get({ endpoint: `${this.endpoint}/${id}` })
  }

  public requestPwdReset(email: string) {
    return this.post({ endpoint: '/pwd-recovery', payload: { email } })
  }

  public update(id: string, payload: Record<string, any>) {
    return this.put({ endpoint: `${this.endpoint}/${id}`, payload })
  }

  public updateProfile(id: string, payload: Record<string, any>) {
    return this.put({
      endpoint: `${this.endpoint}/update-profile/${id}`,
      payload
    })
  }

  public uploadAvatar(id: string, file: File) {
    const multipartForm = new FormData()
    multipartForm.set('file', file)
    return this.post({
      endpoint: `${this.endpoint}/upload-avatar/${id}`,
      payload: multipartForm
    })
  }

  public resetPwd(payload: {
    confirmPassword: string
    email: string
    password: string
    token: string
  }) {
    return this.patch({ endpoint: '/pwd-recovery', payload })
  }
}
