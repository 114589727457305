import styled from 'styled-components'

export const WraperImages = styled.div`
  cursor: default;
  align-self: center;
`

export const WraperImagesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 16px;
  align-items: center;
  justify-items: center;

  img {
    width: 12.8rem;
    height: 96px;
    object-fit: cover;
    border-radius: 0.4rem;
    background: #dfdada;
    border: 2px solid #fff;
    box-shadow: 0px 3px 6px #00000029;
  }
`

export const WraperImagesContainerLabel = styled.label`
  height: 88px;
  width: 11.7rem;
  background: #dfdada;
  border: 2px solid #fff;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 0.4rem;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
`
export const WraperImagesInput = styled.input`
  display: none;
`

export const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.primary};
  border: none;
  border-radius: 50%;
  height: 2.4rem;
  transform-origin: center;
  outline: none;
  width: 2.4rem;
  :hover {
    background-color: ${({ theme }) => theme.colors.primary.darken()};
  }

  p {
    color: ${({ theme }) => theme.colors.white};
    font-family: serif;
    font-size: 1.2rem;
  }
`

export const DivRemove = styled.div`
  position: relative;
`

export const RemoveImage = styled.label`
  position: absolute;
  z-index: 100;
  top: -1rem;
  right: 0;
`
