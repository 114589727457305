import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

import App from './App'
import reportWebVitals from './reportWebVitals'
import * as serviceWorker from './serviceWorkerRegistration'

import { app as config, API as apiConfig } from 'config'
import { makeServer } from 'mirage'

const envMode = process.env.NODE_ENV

if (envMode !== 'development') {
  Sentry.init({
    dsn: 'https://397a34c5b71f4182872ad6b2372a8c04@o517414.ingest.sentry.io/5876099',
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0
  })
}

ReactDOM.render(<App />, document.getElementById('root'))

if (config.env === 'test') {
  makeServer({ baseURL: apiConfig.baseURL, environment: config.env })
}

serviceWorker.register()
reportWebVitals()
