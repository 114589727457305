import { Grid } from '@material-ui/core'
import styled, { css } from 'styled-components'

export const Row = styled.div<{ marginBotton?: string }>`
  display: flex;
  ${props => props.marginBotton && css({ marginBottom: props.marginBotton })}
`

export const GridCard = styled(Grid)`
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 5px 25px #0000000d;
  border-radius: 1.2rem;
  padding: 1.6rem;
`

export const GridHeader = styled(Grid)`
  display: flex;
  padding-bottom: 0.8rem;
  align-items: baseline;
`

export const GridTitle = styled(Grid)`
  display: block;
  padding-top: 0.5rem;
  padding-bottom: 1.6rem;
`

export const GridStatus = styled(Grid)`
  margin-top: 1rem;
  display: block;
  text-align: end;
`

export const BoxTitle = styled.p`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1.4rem;
  font-weight: 600;
  padding-bottom: 1rem;
`
export const BoxLine = styled.p`
  width: 7.7rem;
  border-bottom: 4px solid ${({ theme }) => theme.colors.primary};
`

export const Line = styled.div`
  width: auto;
  height: 0.1rem;
  background-color: #70707052;
  opacity: 0.2;
`

export const GroupName = styled.p`
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: 1.6rem;
  font-weight: 600;
  padding-top: 1.6rem;
`
export const GroupTitle = styled.p`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1.4rem;
  font-weight: 600;
`

export const GroupTitleName = styled.p`
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: 1.4rem;
  padding-top: 1rem;
`
export const GroupTitleEmail = styled.p`
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: 1.4rem;
  padding-top: 1rem;
`
export const GridLinks = styled.div`
  margin-top: 3.4rem;
  display: flex;
  justify-content: end;
  gap: 2rem;
`

export const Link = styled.li`
  align-items: center;
  background: white;
  color: ${({ theme }) => theme.colors.primary};
  display: flex;
  width: fit-content;
  margin-bottom: 1.6rem;
  cursor: pointer;

  > span {
    font-size: 1.4rem;
    margin: 0 0.8rem;
  }
`

export const Card = styled(Grid)`
  display: flex;
  align-items: center;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.1);
  padding: 2rem 2.5rem;
  border-radius: 5px;
  margin: 0 10px;
`

export const ProfileGroupContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const ProfileGroup = styled.div`
  background-color: #f7f9fd;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1.4rem;
  border: 1.8px solid ${({ theme }) => theme.colors.lightGray};
  border-radius: 0.8rem;
  min-height: 3.5rem;
  min-width: 13rem;
  padding: 0.8rem;
  font-weight: bold;
  margin-top: 1rem;
  margin-right: 1rem;
  display: flex;
  justify-content: center;

  :first-child {
    margin: 1rem 1rem 0 0;
  }
`
