import { Grid } from '@material-ui/core'

import styled from 'styled-components'

export const GridHeader = styled(Grid)`
  display: inline-flexbox;
  padding: 0.5rem 0 0.7rem 0;
`

export const GridTitle = styled(Grid)`
  display: block;
  padding: 0.5rem 0 1rem 0;
`

export const BoxTitle = styled.p`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1.4rem;
  font-weight: 600;
  padding-bottom: 1rem;
`

export const BoxLine = styled.p`
  width: 6.6rem;
  border-bottom: 4px solid ${({ theme }) => theme.colors.primary};
`
