import { Tooltip } from '@material-ui/core'

import * as S from './styled'

type TooltipProps = {
  text: string
  children: any
}

export function TooltipComponent({ text, children }: TooltipProps) {
  return (
    <Tooltip
      arrow
      title={
        <S.TooltipWrapper>
          <S.TooltipText>{text}</S.TooltipText>
        </S.TooltipWrapper>
      }
    >
      {children}
    </Tooltip>
  )
}
