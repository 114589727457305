import { ChangeEvent, ReactNode } from 'react'

import { Icon } from 'components/Icon'

import * as S from './styled'

type AccordionProps = {
  isActive?: boolean
  title?: string
  icon: string
  counter?: number
  expanded?: boolean
  children?: ReactNode
  onChange?: (_event: ChangeEvent<{}>, _isExpanded: boolean) => void
  isTraceabilityPage?: boolean
  color?: string
}

export function Accordion({
  isActive,
  title,
  icon,
  counter,
  expanded,
  children,
  onChange,
  color,
  isTraceabilityPage = false
}: AccordionProps) {
  return (
    <>
      <S.Container
        hasCounter={counter}
        expanded={expanded}
        onChange={onChange}
        isTraceabilityPage={isTraceabilityPage}
      >
        <S.Summary
          expanded={expanded}
          isActive={isActive}
          expandIcon={
            isActive ? <Icon icon="chevron-down" width={16} height={16} /> : ''
          }
          color={color}
          hasCounter={counter}
          isTraceabilityPage={isTraceabilityPage}
        >
          <S.AccordionIcon icon={icon} />
          <S.Box>
            <S.BoxTitle>{title}</S.BoxTitle>
            {isActive && expanded && <S.BoxLine />}
          </S.Box>
        </S.Summary>
        {!isTraceabilityPage && (
          <S.Details isTraceabilityPage={isTraceabilityPage}>
            {children}
          </S.Details>
        )}
        {isTraceabilityPage && expanded && children}
      </S.Container>
    </>
  )
}
