import { Grid } from '@material-ui/core'
import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-bottom: 5.4rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};
`

export const DivTitle = styled.div`
  display: flex;
  flex-direction: column;
  white-space: normal;
`

export const GridHeader = styled(Grid)`
  display: inline-flexbox;
  margin-bottom: 2.4rem;
`

export const GridTitle = styled(Grid)`
  display: flex;
  align-self: center;
  align-items: center;
`

export const BoxTitle = styled.p`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 1.8rem;
  font-weight: 600;
  margin-left: 1.6rem;
`

export const WrapperMap = styled.div`
  width: auto;
  height: 300px;
  margin: 0.8rem -2.4rem -3.2rem !important;

  position: relative;
  display: flex;

  .leaflet-container {
    z-index: 5;
  }

  .map-popup .leaflet-popup-content-wrapper {
    background: rgba(255, 255, 255, 0.8);
    border-radius: 20px;
    box-shadow: none;
    border: 2px solid #15c3d6;
  }

  .map-popup .leaflet-popup-content {
    color: #0089a5;
    font-size: 20px;
    font-weight: bold;
    margin: 8px 12px;

    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .map-popup .leaflet-popup-content a {
    width: 33px;
    height: 33px;
    background: #15c3d6;
    box-shadow: 17.2868px 27.6589px 41.4884px rgba(23, 142, 166, 0.16);
    border-radius: 12px;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .map-popup .leaflet-popup-tip-container {
    display: none;
  }
`
