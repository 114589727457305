import env from 'env-var'

const geoMapBiomes = Object.freeze({
  geoBR: env.get('REACT_APP_GEO_BIOMES_BR').asUrlString()
})

const geoMapCities = Object.freeze({
  geoRO: env.get('REACT_APP_GEO_CITIES_RO').asUrlString(),
  geoAC: env.get('REACT_APP_GEO_CITIES_AC').asUrlString(),
  geoAM: env.get('REACT_APP_GEO_CITIES_AM').asUrlString(),
  geoRR: env.get('REACT_APP_GEO_CITIES_RR').asUrlString(),
  geoPA: env.get('REACT_APP_GEO_CITIES_PA').asUrlString(),
  geoAP: env.get('REACT_APP_GEO_CITIES_AP').asUrlString(),
  geoTO: env.get('REACT_APP_GEO_CITIES_TO').asUrlString(),
  geoMA: env.get('REACT_APP_GEO_CITIES_MA').asUrlString(),
  geoPI: env.get('REACT_APP_GEO_CITIES_PI').asUrlString(),
  geoCE: env.get('REACT_APP_GEO_CITIES_CE').asUrlString(),
  geoRN: env.get('REACT_APP_GEO_CITIES_RN').asUrlString(),
  geoPB: env.get('REACT_APP_GEO_CITIES_PB').asUrlString(),
  geoPE: env.get('REACT_APP_GEO_CITIES_PE').asUrlString(),
  geoAL: env.get('REACT_APP_GEO_CITIES_AL').asUrlString(),
  geoSE: env.get('REACT_APP_GEO_CITIES_SE').asUrlString(),
  geoBA: env.get('REACT_APP_GEO_CITIES_BA').asUrlString(),
  geoMG: env.get('REACT_APP_GEO_CITIES_MG').asUrlString(),
  geoES: env.get('REACT_APP_GEO_CITIES_ES').asUrlString(),
  geoRJ: env.get('REACT_APP_GEO_CITIES_RJ').asUrlString(),
  geoSP: env.get('REACT_APP_GEO_CITIES_SP').asUrlString(),
  geoPR: env.get('REACT_APP_GEO_CITIES_PR').asUrlString(),
  geoSC: env.get('REACT_APP_GEO_CITIES_SC').asUrlString(),
  geoRS: env.get('REACT_APP_GEO_CITIES_RS').asUrlString(),
  geoMS: env.get('REACT_APP_GEO_CITIES_MS').asUrlString(),
  geoMT: env.get('REACT_APP_GEO_CITIES_MT').asUrlString(),
  geoGO: env.get('REACT_APP_GEO_CITIES_GO').asUrlString(),
  geoDF: env.get('REACT_APP_GEO_CITIES_DF').asUrlString()
})
const geoMapBR = Object.freeze({
  geoBR: env.get('REACT_APP_GEO_STATES_BR').asUrlString()
})

export { geoMapBiomes, geoMapCities, geoMapBR }
