import styled from 'styled-components'

export const IconWrapper = styled.div`
  align-self: center;
  color: white;
  cursor: pointer;
  margin-left: 2.4rem;
`

export const MenuGroup = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
`

export const Langdropdown = styled.div`
  align-items: center;
  text-align: center;
  display: flex;
  justify-content: flex-end;
  margin-right: 2rem;
  position: relative;
`
