import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  height: 800px;

  position: relative;
  display: flex;
`

export const LeafletContainerMap = styled.div`
  width: 100%;
  height: 800px;

  position: absolute;
  display: flex;

  .leaflet-container {
    z-index: 5;
    border-top-left-radius: 1.2rem;
    border-bottom-left-radius: 1.2rem;
  }

  .map-popup .leaflet-popup-content-wrapper {
    background: rgba(255, 255, 255, 0.8);
    border-radius: 20px;
    box-shadow: none;
    border: 2px solid #15c3d6;
  }

  .map-popup .leaflet-popup-content {
    color: #0089a5;
    font-size: 20px;
    font-weight: bold;
    margin: 8px 12px;

    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .map-popup .leaflet-popup-content a {
    width: 33px;
    height: 33px;
    background: #15c3d6;
    box-shadow: 17.2868px 27.6589px 41.4884px rgba(23, 142, 166, 0.16);
    border-radius: 12px;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .map-popup .leaflet-popup-tip-container {
    display: none;
  }

  .leaflet-control {
    display: none;
  }
`
