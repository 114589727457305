import { HTTP } from 'providers'

interface SupplierServiceParams {
  confectionId?: string
  orderNumber?: string
  fabricOriginId?: string
  outsourcedName?: string
  outsourcedCnpj?: string
  column: string
  direction: string
  page?: number
  limit?: number
}
export class SupplierService extends HTTP {
  private endpoint = '/confection'

  private getParamsContainValue(params: SupplierServiceParams) {
    return Object.fromEntries(
      Object.entries(params).filter(
        ([_, value]) => value !== '' && value !== undefined
      )
    )
  }

  public getSubcontractedProductionData(params: SupplierServiceParams) {
    const validParams = this.getParamsContainValue(params)
    return this.get({
      endpoint: `${this.endpoint}/details/subcontractors`,
      params: validParams
    })
  }
}
