export const INITIAL_STATE = {
  companies: [],
  companiesGroups: [],
  confirmPassword: '',
  CPF: '',
  department: '',
  email: '',
  emailRecovery: '',
  firstName: '',
  languageId: '81e58ad5-b2c0-43ea-a54f-a0a564615b66',
  lastName: '',
  password: '',
  phone: '',
  phoneRecovery: '',
  retails: [],
  roleId: '',
  permissions: {
    retail: false,
    provider: false,
    weaving: false,
    wiring: false,
    productiveChain: false,
    traceabilityProperty: false,
    blockchainHistory: false,
    users: false,
    industries: false,
    industriesGroup: false,
    propertyRegister: false,
    ordersManagement: false,
    leadTime: false,
    productionLaunchApp: false,
    productionAllocation: false
  }
}

export const ERRORS_INITIAL_STATE = {
  CPF: '',
  confirmPassword: '',
  companiesGroups: '',
  department: '',
  email: '',
  emailRecovery: '',
  firstName: '',
  languageId: '',
  lastName: '',
  password: '',
  phone: '',
  phoneRecovery: '',
  roleId: '',
  permissions: {
    retail: false,
    provider: false,
    weaving: false,
    wiring: false,
    productiveChain: false,
    traceabilityProperty: false,
    blockchainHistory: false,
    users: false,
    industries: false,
    industriesGroup: false,
    propertyRegister: false,
    ordersManagement: false,
    leadTime: false,
    productionLaunchApp: false,
    productionAllocation: false
  }
}

export const PASSWORD_INITIAL_STATE = {
  currentPassword: '',
  password: '',
  confirmPassword: ''
}
