import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'

import * as Yup from 'yup'
import { isValid as validateCPF } from 'cpf'
import { toasts } from 'utils/toasts'
import { phoneValidator } from 'utils/phoneValidator'

import { all as allLanguages } from 'store/Language/language.selector'

import { ERRORS_INITIAL_STATE, INITIAL_STATE } from 'constants/user.constants'

import {
  IndustryGroupService,
  UserRoleService,
  UserService,
  UserStorageService
} from 'services'
import { YupValidator } from 'services/yupValidator.service'

import { UserPermissions } from '../UserPermissions'

import {
  Button,
  Input,
  InputCpfCnpj,
  InputPhone,
  InputSelect,
  InputAutocomplete
} from 'components'

import * as S from './styled'

const EditUserFormSchema = Yup.object().shape({
  CPF: Yup.string()
    .required()
    .test('cpf', 'CPF inválido', value => {
      if (value) return validateCPF(value)
      return false
    })
    .label('CPF'),
  department: Yup.string().required().label('Departamento'),
  email: Yup.string().required().email().label('E-mail'),
  emailRecovery: Yup.string()
    .notOneOf(
      [Yup.ref('email')],
      'E-mail de recuperação de senha não pode ser igual campo E-mail'
    )
    .nullable()
    .email()
    .label('E-mail de recuperação de senha'),
  firstName: Yup.string().required().label('Primeiro nome'),
  lastName: Yup.string().required().label('Sobrenome'),
  languageId: Yup.string().required().label('Idioma'),
  companiesGroups: Yup.array(),
  confirmPassword: Yup.mixed().test(
    'confirmPassword',
    'As senhas devem ser iguais',
    (value, props) => {
      if (props.parent.password) {
        const sc = Yup.string().required().oneOf([props.parent.password])
        return sc.isValidSync(value)
      }
      return true
    }
  ),
  phone: Yup.lazy(data => {
    if (!data) return Yup.string().nullable(true)
    phoneValidator.nullable(true).label('Celular')
  }),
  password: Yup.string().test('password', 'Senha é obrigatório', value => {
    if (value) {
      const sc = Yup.string().required().min(6).max(32).label('Senha')
      return sc.isValidSync(value)
    }
    return true
  }),
  phoneRecovery: Yup.lazy(data => {
    if (!data) return Yup.string().nullable()
    return phoneValidator
      .nullable(true)
      .notOneOf(
        [Yup.ref('phone')],
        'Esse celular deve ser diferente do principal!'
      )
      .label('Celular')
  }),
  roleId: Yup.string().required().label('Tipo de usuário')
})

const CreateUserFormSchema = Yup.object().shape({
  CPF: Yup.string()
    .required()
    .test('cpf', 'CPF inválido', value => {
      if (value) return validateCPF(value)
      return false
    })
    .label('CPF'),
  confirmPassword: Yup.string()
    .required()
    .oneOf([Yup.ref('password'), null], 'As senhas devem ser iguais')
    .label('Confirmação de senha'),
  department: Yup.string().required().label('Departamento'),
  email: Yup.string().required().email().label('E-mail'),
  emailRecovery: Yup.string()
    .notOneOf(
      [Yup.ref('email')],
      'E-mail de recuperação de senha precisa ser diferente do campo E-mail'
    )
    .nullable(true)
    .email()
    .label('E-mail de recuperação de senha'),
  firstName: Yup.string().required().label('Primeiro nome'),
  lastName: Yup.string().required().label('Sobrenome'),
  languageId: Yup.string().required().label('Idioma'),
  companiesGroups: Yup.array(),
  password: Yup.string().required().min(6).max(32).label('Senha'),
  phone: Yup.lazy(data => {
    if (!data) return Yup.string().nullable(true)
    phoneValidator.nullable(true).label('Celular')
  }),
  phoneRecovery: Yup.lazy(data => {
    if (!data) return Yup.string().nullable(true)
    return phoneValidator
      .nullable(true)
      .notOneOf(
        [Yup.ref('phone')],
        'Esse celular deve ser diferênte do principal!'
      )
      .label('Celular')
  }),
  roleId: Yup.string().required().label('Tipo de usuário')
})

const INITIAL_STATE_USER_ROLE = [
  {
    id: '',
    label: '',
    tag: ''
  }
]

interface ParamTypes {
  id: string
}

export function UserForm() {
  const { t } = useTranslation()

  const { id } = useParams<ParamTypes>()

  const userService = new UserService()
  const userRoleService = new UserRoleService()

  const [form, setForm] = useState({ ...INITIAL_STATE })
  const [errors, setErrors] = useState({ ...ERRORS_INITIAL_STATE })
  const [userRole, setUserRole] = useState(INITIAL_STATE_USER_ROLE)
  const [companyGroupBlocked, setCompanyGroupBlocked] = useState(null)
  const [companiesGroupsOptions, setCompaniesGroupsOptions] = useState([])
  const [companiesGroupsSelected, setCompaniesGroupsSelected] = useState([])

  const { languages } = useSelector(allLanguages)
  const { user } = UserStorageService.getUserData()

  const fetchUserRole = async () => {
    try {
      const { data } = await userRoleService.fetchAll()
      setUserRole(data as any)
    } catch (error) {
      console.log('fetchUserRole-ERROR', error)
    }
  }

  useEffect(() => {
    fetchUserRole()
  }, [])

  const userRolesSelected = () => {
    if (userRole.length && form.roleId) {
      return userRole.find(item => item.id === form.roleId).tag
    }
  }

  const isSameUser = user.userId === id
  const isSameUserRole = user.role === userRolesSelected()

  const canEdit = !id || !isSameUser || user.role === 'admeco'

  const { goBack } = useHistory()

  const _userRole = userRole.map(item => ({
    ...item,
    label: t(`newUser:extraInformation:userType:${item.tag}`)
  }))

  const rolesForAdminRetail = _userRole.filter(item => item.tag !== 'admeco')
  const rolesForAdminInd = _userRole.filter(
    item => item.tag !== 'admvarejo' && item.tag !== 'admeco'
  )

  const userRolesFilter = {
    admind: rolesForAdminInd,
    admvarejo: rolesForAdminRetail,
    admeco: _userRole
  }

  const formattedLabelUserRole = userRolesFilter[user.role]

  useEffect(() => {
    if (!userRole.length) fetchUserRole()
  }, [userRole])

  useEffect(() => {
    if (id && !Object.values(companiesGroupsSelected).length) {
      const getData = async () => {
        const userService = new UserService()
        const { data } = await userService.fetchOne(id)
        const _form = data as typeof INITIAL_STATE
        setErrors({ ...ERRORS_INITIAL_STATE })
        setForm({ ..._form })
      }
      getData()
    }
  }, [])

  const fetchCompaniesGroupsOptions = useCallback(async () => {
    const { data, success } =
      await new IndustryGroupService().fetchAllWithAccessRelease()

    if (data.code === 'NOT_FOUND') {
      return setCompaniesGroupsOptions([])
    }
    if (!success && data.code !== 'NOT_FOUND') toasts.generalFail()
    setCompaniesGroupsOptions(data.items)

    const groupsPreSelected = []
    if (form.companiesGroups.length) {
      form.companiesGroups.forEach(groupForm => {
        const groupMatched = data.items.find(
          groupOption => groupForm.id === groupOption.id
        )
        groupsPreSelected.push(groupMatched)
      })
      setCompaniesGroupsSelected(groupsPreSelected)
    }
  }, [])

  const manipulatePermissions = () => {
    const _userRolesSelected = userRolesSelected()
    if (_userRolesSelected === 'admind') {
      form.permissions = {
        ...form.permissions,
        industriesGroup: false,
        retail: false
      }
    }
    if (_userRolesSelected === 'visual' && !id) {
      form.permissions = {
        ...form.permissions,
        industries: false,
        industriesGroup: false,
        provider: false,
        users: false,
        retail: false
      }
      if (user.role === 'admind') form.permissions.provider = true
      if (user.role === 'admvarejo') form.permissions.retail = true
    }
    setForm({ ...form })
  }

  const manipulateCompaniesGroups = () => {
    const _userRolesSelected = userRolesSelected()
    if (
      (_userRolesSelected === 'admvarejo' && isSameUserRole) ||
      (_userRolesSelected === 'admind' && isSameUserRole)
    ) {
      const userCompanyGroupTag = {
        admvarejo: 'var',
        admind: 'for'
      }[_userRolesSelected]
      companiesGroupsOptions?.forEach(group => {
        user.companiesGroups.forEach(userCompanyGroup => {
          const groupTags = userCompanyGroup.companyProfiles
            .filter(item => item.tag === userCompanyGroupTag)
            .map(item => item.tag)
          if (
            group.id === userCompanyGroup.id &&
            groupTags.includes(userCompanyGroupTag)
          ) {
            const _group = { ...group, blocked: user.role !== 'admeco' }
            setCompanyGroupBlocked(_group)
            setForm({ ...form, companiesGroups: [_group] })
            return setCompaniesGroupsSelected([_group])
          }
        })
      })
      return
    }
    if (
      user.role !== 'admeco' &&
      (_userRolesSelected === 'visual' || isSameUserRole)
    ) {
      companiesGroupsOptions?.forEach(group => {
        user.companiesGroups.forEach(userCompanyGroup => {
          if (group.id === userCompanyGroup.id) {
            const _group = { ...group, blocked: user.role !== 'admeco' }
            setCompanyGroupBlocked(_group)
            setForm({ ...form, companiesGroups: [_group] })
            return setCompaniesGroupsSelected([_group])
          }
        })
      })
      return
    }
    setCompaniesGroupsSelected(form.companiesGroups)
  }

  useEffect(() => {
    if (!Object.values(companiesGroupsSelected).length) {
      fetchCompaniesGroupsOptions()
    }
  }, [fetchCompaniesGroupsOptions, companiesGroupsSelected])

  useEffect(() => {
    if (userRole.length && form.roleId) {
      manipulatePermissions()
      manipulateCompaniesGroups()
    }
  }, [form.roleId])

  function handleInput(value: string, name: string) {
    setErrors({ ...errors, [name]: '' })
    setForm({ ...form, [name]: value })
  }

  function handleSelected(name: string, value: string) {
    setErrors({ ...errors, [name]: '' })
    setForm({ ...form, [name]: value })
  }

  function handleAutocomplete(field: string, value: any) {
    setErrors({ ...errors, [field]: '' })
    setForm({ ...form, [field]: value })
    setCompaniesGroupsSelected(value)
  }

  const handleSwitchChange = (name: string, value: boolean) => {
    form.permissions = { ...form.permissions, [name]: value }
    setForm({ ...form })
  }

  async function submit() {
    setErrors({ ...ERRORS_INITIAL_STATE })
    const [isValid, validationError] = await new YupValidator(
      id ? EditUserFormSchema : CreateUserFormSchema
    ).validate(form)

    if (!isValid) {
      return setErrors(validationError as typeof ERRORS_INITIAL_STATE)
    }

    if (!form.password) {
      delete form.password
      delete form.confirmPassword
    }
    if (!form.phoneRecovery) delete form.phoneRecovery
    if (!form.phone) delete form.phone
    if (!form.emailRecovery) delete form.emailRecovery

    const payload = {
      ...form,
      companiesGroups: form.companiesGroups.length
        ? form.companiesGroups.map(company => ({ id: company.id }))
        : []
    }

    if (id) {
      const { success, data } = await userService.edit({ ...payload, id })

      if (success) {
        toasts.edited()
        goBack()
      } else {
        if (data.code === 'DUPLICATED' && data.details === 'email') {
          return toasts.duplicatedEmail()
        }
        if (data.code === 'INSUFFICIENT_PERMISSIONS') {
          return toasts.insufficientPermissions()
        }
        if (data.code === 'DUPLICATED' && data.details === 'CPF') {
          return toasts.duplicatedCPF()
        }
        toasts.generalFail()
      }
    } else {
      const { success, data } = await userService.create({ payload })

      if (success) {
        toasts.created()
        goBack()
      } else {
        if (data.code === 'DUPLICATED' && data.details === 'email') {
          return toasts.duplicatedEmail()
        }
        if (data.code === 'INSUFFICIENT_PERMISSIONS') {
          return toasts.insufficientPermissions()
        }
        if (data.code === 'DUPLICATED' && data.details === 'CPF') {
          return toasts.duplicatedCPF()
        }
        toasts.generalFail()
      }
    }
  }

  const onExit = useCallback(() => {
    goBack()
    setForm({ ...INITIAL_STATE })
  }, [])

  return (
    <S.Wrapper container>
      <S.FullGrid item xs={12}>
        <div style={{ marginBottom: 16 }}>
          <S.GridHeader>
            <S.GridTitle item container xs={12}>
              <S.BoxTitle>
                {t('newUser:basicInformation:basicInformation')}
              </S.BoxTitle>
            </S.GridTitle>
          </S.GridHeader>
          <S.GridFilter container spacing={2}>
            <S.GridInput item sm={12} md={4}>
              <Input
                disabled={!canEdit}
                error={Boolean(errors.firstName)}
                fullWidth
                helperText={errors.firstName}
                label={t('newUser:basicInformation:firstName')}
                onInput={value => handleInput(value, 'firstName')}
                value={form.firstName}
              />
            </S.GridInput>
            <S.GridInput item sm={12} md={4}>
              <Input
                disabled={!canEdit}
                error={Boolean(errors.lastName)}
                fullWidth
                helperText={errors.lastName}
                label={t('newUser:basicInformation:lastName')}
                onInput={value => handleInput(value, 'lastName')}
                value={form.lastName}
              />
            </S.GridInput>
            <S.GridInput item sm={12} md={4}>
              <InputCpfCnpj
                disabled={!canEdit}
                error={Boolean(errors.CPF)}
                fullWidth
                helperText={errors.CPF}
                label={t('newUser:basicInformation:CPF')}
                onInput={value => handleInput(value, 'CPF')}
                type="cpf"
                value={form.CPF}
              />
            </S.GridInput>
            <S.GridInput item sm={12} md={4}>
              <Input
                disabled={!canEdit}
                error={Boolean(errors.email)}
                fullWidth
                helperText={errors.email}
                label={t('newUser:basicInformation:email')}
                onInput={value => handleInput(value, 'email')}
                value={form.email}
              />
            </S.GridInput>
            <S.GridInput item sm={12} md={4}>
              <Input
                disabled={!canEdit}
                error={Boolean(errors.password)}
                fullWidth
                helperText={errors.password}
                label={t('newUser:basicInformation:password')}
                onInput={value => handleInput(value, 'password')}
                type="password"
                value={form.password}
              />
            </S.GridInput>
            <S.GridInput item sm={12} md={4}>
              <Input
                disabled={!canEdit}
                error={Boolean(errors.confirmPassword)}
                fullWidth
                helperText={errors.confirmPassword}
                label={t('newUser:basicInformation:confirmPassword')}
                onInput={value => handleInput(value, 'confirmPassword')}
                type="password"
                value={form.confirmPassword}
              />
            </S.GridInput>
          </S.GridFilter>
        </div>
        <div style={{ marginBottom: 16 }}>
          <S.GridHeader>
            <S.GridTitle item container xs={12}>
              <S.BoxTitle>
                {t('newUser:extraInformation:extraInformation')}
              </S.BoxTitle>
            </S.GridTitle>
          </S.GridHeader>
          <S.GridFilter container spacing={2}>
            <S.GridInput item sm={12} md={4}>
              <Input
                disabled={!canEdit}
                error={Boolean(errors.emailRecovery)}
                fullWidth
                helperText={errors.emailRecovery}
                label={t('newUser:extraInformation:passwordRecoveryEmail')}
                onInput={value => handleInput(value, 'emailRecovery')}
                value={form.emailRecovery}
              />
            </S.GridInput>
            <S.GridInput item sm={12} md={4}>
              <InputPhone
                disabled={!canEdit}
                error={Boolean(errors.phone)}
                fullWidth
                helperText={errors.phone}
                label={t('newUser:extraInformation:contactCellphone')}
                onInput={value => handleInput(value, 'phone')}
                value={form.phone}
              />
            </S.GridInput>
            <S.GridInput item sm={12} md={4}>
              <InputPhone
                disabled={!canEdit}
                error={Boolean(errors.phoneRecovery)}
                fullWidth
                helperText={errors.phoneRecovery}
                label={t('newUser:extraInformation:contactPhone')}
                onInput={value => handleInput(value, 'phoneRecovery')}
                value={form.phoneRecovery}
              />
            </S.GridInput>
            <S.GridInput item sm={12} md={4}>
              <InputSelect
                disabled={!canEdit}
                error={Boolean(errors.languageId)}
                fullWidth
                helperText={errors.languageId}
                label={t('newUser:extraInformation:language')}
                onSelected={({ id }) => handleSelected('languageId', id)}
                optionLabel="label"
                options={languages}
                optionValue="id"
                value={form.languageId}
              />
            </S.GridInput>
            <S.GridInput item sm={12} md={4}>
              <Input
                disabled={!canEdit}
                error={Boolean(errors.department)}
                fullWidth
                helperText={errors.department}
                label={t('newUser:extraInformation:departament')}
                onInput={value => handleInput(value, 'department')}
                value={form.department}
              />
            </S.GridInput>
            <S.GridInput item sm={12} md={4}>
              {userRole.length ? (
                <InputSelect
                  disabled={!canEdit}
                  error={Boolean(errors.roleId)}
                  fullWidth
                  helperText={errors.roleId}
                  label={t('newUser:extraInformation:userType:userType')}
                  onSelected={({ id }) => handleSelected('roleId', id)}
                  optionLabel="label"
                  options={formattedLabelUserRole}
                  optionValue="id"
                  value={form.roleId}
                />
              ) : null}
            </S.GridInput>
          </S.GridFilter>
        </div>
        <div style={{ marginBottom: 16 }}>
          <S.GridHeader>
            <S.GridTitle item container xs={12}>
              <S.BoxTitle>
                {t(
                  'newUser:industryGroupThisUserHasAccess:industryGroupThisUserHasAccess'
                )}
              </S.BoxTitle>
            </S.GridTitle>
          </S.GridHeader>
          <S.GridFilter container spacing={2}>
            <S.GridInput item sm={12} md={12}>
              <InputAutocomplete
                fullWidth
                disabled={!canEdit}
                error={Boolean(errors.companiesGroups)}
                helperText={errors.companiesGroups}
                label={t('newUser:industryGroupThisUserHasAccess:selectGroup')}
                options={companiesGroupsOptions || []}
                optionLabel="name"
                defaultValue={companiesGroupsSelected}
                onSelected={val => handleAutocomplete('companiesGroups', val)}
                loading={true}
                loadingText={
                  !companiesGroupsOptions.length
                    ? t('common:dataNotFound')
                    : t('common:pleaseWait')
                }
                userRoleSelected={userRolesSelected()}
                userRoleLogged={user.role}
                companyGroupBlocked={companyGroupBlocked}
              />
            </S.GridInput>
          </S.GridFilter>
        </div>
      </S.FullGrid>
      <UserPermissions
        values={form.permissions}
        handleChange={handleSwitchChange}
        userRoleSelected={userRolesSelected()}
        userRoleLogged={user.role}
        canEdit={canEdit}
      />
      <S.GridButtons item xs={12}>
        <Button variant="gray" onClick={onExit} size="medium">
          <p>{t('common:Cancel')}</p>
        </Button>
        <Button variant="green" onClick={submit} size="medium">
          <p>{t('common:Save')}</p>
        </Button>
      </S.GridButtons>
    </S.Wrapper>
  )
}
