/* eslint-disable no-unused-vars */
import { useCallback, useEffect, useState } from 'react'

import { OrderService } from 'services'

import { onFilterPaginate } from 'utils/OnFilterPaginate'

import {
  INITIAL_STATE_ORDER_ITEMS,
  orderItems
} from 'pages/AllOrdersManagement/allOrdersManagement.constants'
import { OrderItemsProps } from 'pages/AllOrdersManagement/allOrdersManagement.types'

import { Pagination, Table } from 'components'

type SupplierProps = {
  orderNumber: string
}

export function ModalTableOrderItems({ orderNumber }: SupplierProps) {
  const [orderItemsTable, setOrderItemsTable] = useState<OrderItemsProps>({
    ...INITIAL_STATE_ORDER_ITEMS
  })

  const getOrderItemsFromApiWithID = useCallback(
    async (page?: number, limit?: number) => {
      const orderService = new OrderService()
      await orderService
        .getOrderItens({ orderNumber, page, limit })
        .then(response => {
          const { data } = response
          setOrderItemsTable(data as OrderItemsProps)
        })
    },
    [orderNumber]
  )

  function handlePaginationFilter({ limit, page }) {
    const filters = onFilterPaginate(orderItemsTable.pagination, limit, page)

    getOrderItemsFromApiWithID(filters.page, filters.limit)
  }

  useEffect(() => {
    if (orderNumber && !orderItemsTable.items.length) {
      getOrderItemsFromApiWithID()
    }
  }, [getOrderItemsFromApiWithID, orderNumber, orderItemsTable])

  return (
    <>
      <Table rows={orderItemsTable.items} columns={orderItems()} />
      <Pagination
        totalItems={orderItemsTable.pagination.totalItems}
        limit={orderItemsTable.pagination.limit}
        page={orderItemsTable.pagination.page}
        setFilter={handlePaginationFilter}
      />
    </>
  )
}
