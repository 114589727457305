import { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import * as Yup from 'yup'
import { addDays, format, subDays, subMonths } from 'date-fns'

import { IndustryGroupService, OrderTypeOriginService } from 'services'

import { RetailTableContext } from 'contexts/retailTable/retailTable.context'
import { TraceabilityRetailFiltersContext } from 'contexts/traceabilityRetailTableFilters/traceabilityRetailTableFilters.context'

import { toasts } from 'utils/toasts'
import { YupCNPJ } from 'utils/YupCNPJ'
import { submitOnEnter } from 'utils/submitOnEnter'

import { Location } from './Location'

import { retailRequestPayload } from 'constants/retailFilterForm.constants'

import {
  InputAutocomplete,
  InputDate,
  RadioButton,
  Button,
  InputCpfCnpj,
  Input,
  InputSelect
} from 'components'

import * as S from './styled'

const getDateLastMonth = subMonths(new Date(), 1)
const formattedDate = new Date(
  getDateLastMonth.getFullYear(),
  getDateLastMonth.getMonth(),
  1,
  getDateLastMonth.getHours(),
  getDateLastMonth.getMinutes(),
  getDateLastMonth.getSeconds()
)

const INITIAL_STATE = {
  startDate: formattedDate,
  endDate: new Date(),
  dateType: 'order',
  cnpj: '',
  name: '',
  searchNameAndCnpjIn: 'retail',
  companyGroups: [],
  orderNumber: '',
  invoiceNumber: '',
  productCode: '',
  orderStatus: 'T',
  souABR: '',
  providerCountry: '',
  providerCity: '',
  providerMicroregion: '',
  providerState: '',
  orderTypeOrigin: 'T'
}

const ERRORS_INITIAL_STATE = {
  startDate: null,
  endDate: null,
  dateType: 'order',
  cnpj: '',
  name: '',
  searchNameAndCnpjIn: 'retail',
  companyGroups: null,
  orderNumber: '',
  invoiceNumber: '',
  productCode: '',
  orderStatus: '',
  souABR: '',
  providerCountry: null,
  providerCity: null,
  providerMicroregion: '',
  providerState: null,
  orderTypeOrigin: null
}

Yup.addMethod(Yup.object, 'atLeastOneOf', function (list) {
  return this.test({
    name: 'atLeastOneOf',
    message: 'Preencha o formulário corretamente',
    exclusive: true,
    params: { keys: list.join(', ') },
    test: value => value == null || list.some(f => !!value[f])
  })
})

const schema = Yup.object()
  .shape({
    startDate: Yup.date(),
    endDate: Yup.date(),
    cnpj: Yup.lazy((data, { parent }) => {
      if (data && parent.searchNameAndCnpjIn !== 'property') {
        return YupCNPJ.verify()
      }
      return Yup.string()
    }),
    companyGroups: Yup.array()
      .of(
        Yup.object()
          .shape({
            id: Yup.string().nullable()
          })
          .nullable()
      )
      .nullable(),
    name: Yup.string(),
    orderNumber: Yup.string(),
    invoiceNumber: Yup.string(),
    searchNameAndCnpjIn: Yup.string(),
    dateType: Yup.string(),
    orderStatus: Yup.string(),
    providerCountry: Yup.string(),
    providerCity: Yup.string(),
    providerMicroregion: Yup.string(),
    providerState: Yup.string(),
    productCode: Yup.string(),
    orderTypeOrigin: Yup.string()
  })
  .atLeastOneOf([
    'startDate',
    'endDate',
    'cnpj',
    'name',
    'orderNumber',
    'invoiceNumber'
  ])

type FilterProps = {
  onSubmit: (_form: any) => void
}

export function FilterForm({ onSubmit }: FilterProps) {
  const { t, i18n } = useTranslation()
  const { clearOrderKeySelected } = useContext(RetailTableContext)
  const {
    traceabilityRetailsFilters,
    setTraceabilityRetailsFilters,
    clearTraceabilityRetailsFilters
  } = useContext(TraceabilityRetailFiltersContext)

  function parseFilterOptions() {
    const nameKey =
      retailRequestPayload[traceabilityRetailsFilters.searchNameAndCnpjIn].name
    const cnpjKey =
      retailRequestPayload[traceabilityRetailsFilters.searchNameAndCnpjIn].cnpj
    const startDateKey =
      retailRequestPayload[traceabilityRetailsFilters.dateType].startDate
    const endDateKey =
      retailRequestPayload[traceabilityRetailsFilters.dateType].endDate
    return {
      name: traceabilityRetailsFilters[nameKey],
      cnpj: traceabilityRetailsFilters[cnpjKey],
      startDate: traceabilityRetailsFilters[startDateKey],
      endDate: traceabilityRetailsFilters[endDateKey]
    }
  }

  const _INITIAL_STATE = {
    ...INITIAL_STATE,
    ...traceabilityRetailsFilters,
    ...parseFilterOptions()
  }

  const [form, setForm] = useState({
    ..._INITIAL_STATE
  })
  const [groupProfile, setGroupProfile] = useState([])
  const [errors, setErrors] = useState({ ...ERRORS_INITIAL_STATE })
  const [orderTypeOrigin, setOrderTypeOrigin] = useState([])

  const orderStatusOptions = () => [
    {
      id: '0',
      label: t('traceabilityRetailPage:filtersRetail.orderStatusDropdown.T'),
      tag: 'T'
    },
    {
      id: '1',
      label: t('traceabilityRetailPage:filtersRetail.orderStatusDropdown.A'),
      tag: 'A'
    },
    {
      id: '2',
      label: t('traceabilityRetailPage:filtersRetail.orderStatusDropdown.P'),
      tag: 'P'
    },
    {
      id: '3',
      label: t('traceabilityRetailPage:filtersRetail.orderStatusDropdown.F'),
      tag: 'F'
    }
  ]

  const [orderStatusSelected, setOrderStatusSelected] = useState(() => ({
    id: '0',
    label: t('traceabilityRetailPage:filtersRetail.orderStatusDropdown.T'),
    tag: 'T'
  }))

  const [orderTypeOriginSelected, setOrderTypeOriginSelected] = useState(
    () => ({
      description: t(
        'traceabilityRetailPage:filtersRetail.orderTypeOriginDropdown.T'
      ),
      tag: 'T'
    })
  )

  const fetchGroupProfileOptions = useCallback(async () => {
    delete form.column
    delete form.direction

    const { data, success } = await new IndustryGroupService().fetchAll(form)
    if (data.code === 'NOT_FOUND') {
      return setGroupProfile([])
    }

    if (!success && data.code !== 'NOT_FOUND') toasts.generalFail()
    const formatNameAndTranslateGroupProfile = data.items.map(item => ({
      ...item,
      label: item.name
    }))
    setGroupProfile(formatNameAndTranslateGroupProfile)
  }, [])

  const fetchOrderTypeOrigin = useCallback(async () => {
    const { data, success } = await new OrderTypeOriginService().fetchAll()
    const dataTranslated = data.map(item => ({
      ...item,
      description: t(
        `traceabilityRetailPage:filtersRetail.orderTypeOriginDropdown.${item.tag}`
      )
    }))

    if (!success) toasts.generalFail()
    setOrderTypeOrigin(dataTranslated as any[])
  }, [])

  useEffect(() => {
    fetchGroupProfileOptions()
    fetchOrderTypeOrigin()
  }, [fetchGroupProfileOptions])

  useEffect(() => {
    const orderTypeOriginTranslated = orderTypeOrigin.map(item => ({
      ...item,
      description: t(
        `traceabilityRetailPage:filtersRetail.orderTypeOriginDropdown.${item.tag}`
      )
    }))
    setOrderTypeOrigin(orderTypeOriginTranslated)
  }, [i18n.language])

  async function onFilter(isFirstLoadPage?: boolean) {
    try {
      const filter = await schema.validate(form, {
        abortEarly: false,
        stripUnknown: true
      })

      const params = {
        ...filter,
        orderNumber: filter.orderNumber,
        invoiceNumber: filter.invoiceNumber,
        [retailRequestPayload[form.dateType].startDate]: filter.startDate,
        [retailRequestPayload[form.dateType].endDate]: filter.endDate,
        [retailRequestPayload[form.searchNameAndCnpjIn].name]: filter.name,
        [retailRequestPayload[form.searchNameAndCnpjIn].cnpj]:
          filter.cnpj.replace(/[./-]/g, ''),
        companyGroups: form.companyGroups
      }

      const _params = {
        ...params,
        startDate: format(new Date(params.startDate), 'yyyy-MM-dd'),
        endDate: format(new Date(params.endDate), 'yyyy-MM-dd')
      }

      delete _params.startDate
      delete _params.endDate
      delete _params.dateType
      delete _params.searchNameAndCnpjIn
      delete _params.cnpj
      delete _params.name
      delete _params.companyGroupProfiles

      if (!isFirstLoadPage) {
        setTraceabilityRetailsFilters({
          ...params,
          column: form.column,
          direction: form.direction
        })
      }
      clearOrderKeySelected()
      onSubmit({
        ..._params,
        companyGroups: form.companyGroups.length
          ? JSON.stringify(form.companyGroups.map(item => item.id))
          : []
      })
    } catch (ex) {
      if (ex.inner && ex.inner.length) {
        const pairs = ex.inner?.map(({ path, message }) => [
          path,
          message.replace(`${path} `, '')
        ])
        toasts.invalidForm()
        setErrors(Object.fromEntries(pairs) as typeof ERRORS_INITIAL_STATE)
      }
    }
  }

  function clearFilter() {
    clearTraceabilityRetailsFilters()
    setForm({ ...INITIAL_STATE })
    setErrors({ ...ERRORS_INITIAL_STATE })
  }

  function handleInput(value: string, name: string) {
    setErrors({ ...errors, [name]: '' })
    setForm({ ...form, [name]: value })
  }

  function handleInputData(value: string | Date, name: string) {
    setForm({ ...form, [name]: value })
  }

  function handleSelect(value: any, name: string) {
    if (value.value) {
      setForm({ ...form, [name]: value.value })
    }
    if (name === 'searchNameAndCnpjIn' && value === 'property') {
      errors.cnpj = null
      const cnpj = form.cnpj.replace(/[./-]/g, '')
      form.cnpj = cnpj
      setErrors({ ...errors })
    }
    setForm({ ...form, [name]: value })
  }

  function handleSelectedIBGEState(name: string, value: any) {
    setErrors({ ...errors, [name]: '' })
    form.providerState = value
    setForm({ ...form })
  }

  const handleSelectedIBGECity = (name: string, value: any) => {
    setErrors({ ...errors, [name]: '' })
    form.providerCity = value
    setForm({ ...form })
  }

  function handleSelectedMicroregion(name: string, value: any) {
    setErrors({ ...errors, [name]: '' })
    form.providerMicroregion = value
    setForm({ ...form })
  }

  function handleSelectedCountry(name: string, value: any) {
    setErrors({ ...errors, [name]: '' })
    form.providerCountry = value
    setForm({ ...form })
  }

  function handleSelectOrderStatus(field: string, value: any) {
    setErrors({ ...errors, [field]: '' })
    setForm({ ...form, [field]: value.tag })
    setOrderStatusSelected({
      ...value,
      label: t(
        `traceabilityRetailPage:filtersRetail.orderStatusDropdown.${value.tag}`
      )
    })
  }

  function handleSelectOrderOriginType(field: string, value: any) {
    setErrors({ ...errors, [field]: '' })
    setForm({ ...form, [field]: value.tag })
    setOrderTypeOriginSelected({
      ...value,
      description: t(
        `traceabilityRetailPage:filtersRetail.orderTypeOriginDropdown.${value.tag}`
      )
    })
  }

  function handleAutocomplete(field: string, value: any) {
    setErrors({ ...errors, [field]: '' })
    setForm({ ...form, [field]: value })
  }

  const dateType = [
    { title: t('traceabilityRetailPage:filtersRetail:order'), value: 'order' },
    {
      title: t('traceabilityRetailPage:filtersRetail:invoice'),
      value: 'invoice'
    },
    {
      title: t('traceabilityRetailPage:filtersRetail:invoiceDelivery'),
      value: 'invoiceDelivery'
    },
    {
      title: t('traceabilityRetailPage:filtersRetail:production'),
      value: 'production'
    },
    {
      title: t('traceabilityRetailPage:filtersRetail:deliveryForecast'),
      value: 'prediction'
    }
  ]

  const searchNameAndCnpjIn = [
    {
      title: t('traceabilityRetailPage:filtersRetail:retail'),
      value: 'retail'
    },
    {
      title: t('traceabilityRetailPage:filtersRetail:provider'),
      value: 'provider'
    },
    {
      title: t('traceabilityRetailPage:filtersRetail:outsourced'),
      value: 'outsourced'
    },
    {
      title: t('traceabilityRetailPage:filtersRetail:weaving'),
      value: 'weaving'
    },
    {
      title: t('traceabilityRetailPage:filtersRetail:wiring'),
      value: 'wiring'
    },
    {
      title: t('traceabilityRetailPage:filtersRetail:property'),
      value: 'property'
    }
  ]

  useEffect(() => {
    onFilter(true)
  }, [])

  return (
    <S.Wrapper container>
      <S.FullGrid item xs={12}>
        <S.GridHeader>
          <S.GridTitle item container xs={12}>
            <S.BoxTitle>
              {t('traceabilityRetailPage:filtersRetail:filterData')}
            </S.BoxTitle>
            <S.BoxLine />
          </S.GridTitle>
        </S.GridHeader>
        <S.GridFilter container>
          <S.GridInput item xs={12} sm={6} md={3}>
            <InputDate
              fullWidth
              label={t('traceabilityRetailPage:filtersRetail:initialDate')}
              value={form.startDate}
              error={Boolean(errors.startDate)}
              helperText={errors.startDate}
              onInput={value => handleInputData(value, 'startDate')}
              maxDate={form.endDate && subDays(form.endDate, 1)}
            />
          </S.GridInput>
          <S.GridInput item xs={12} sm={6} md={3}>
            <InputDate
              fullWidth
              label={t('traceabilityRetailPage:filtersRetail:finalDate')}
              value={form.endDate}
              error={Boolean(errors.endDate)}
              helperText={errors.endDate}
              onInput={value => handleInputData(value, 'endDate')}
              minDate={form.startDate && addDays(form.startDate, 1)}
            />
          </S.GridInput>
          <S.GridInput item xs={12} sm={12} md={6}>
            <RadioButton
              label=""
              options={dateType}
              key="dateType"
              value={form.dateType}
              onSelected={value => handleSelect(value, 'dateType')}
            />
          </S.GridInput>
        </S.GridFilter>
        <S.GridFilter container>
          <S.GridInput item xs={12} sm={6} md={3}>
            {form.searchNameAndCnpjIn !== 'property' ? (
              <InputCpfCnpj
                error={Boolean(errors.cnpj)}
                fullWidth
                helperText={errors.cnpj}
                label={t(
                  'traceabilityRetailPage:filtersRetail:nationalRegistryOfLegalEntities'
                )}
                onInput={value => handleInput(value, 'cnpj')}
                onKeyPress={submitOnEnter(onFilter)}
                type="cnpj"
                value={form.cnpj}
              />
            ) : (
              <Input
                error={Boolean(errors.cnpj)}
                fullWidth
                helperText={errors.cnpj}
                label={'FAZ CAR'}
                onInput={value => handleInput(value, 'cnpj')}
                onKeyPress={submitOnEnter(onFilter)}
                value={form.cnpj}
              />
            )}
          </S.GridInput>
          <S.GridInput item xs={12} sm={6} md={3}>
            <Input
              error={Boolean(errors.name)}
              fullWidth
              helperText={errors.name}
              label={t('traceabilityRetailPage:filtersRetail:corporateName')}
              onInput={value => handleInput(value, 'name')}
              onKeyPress={submitOnEnter(onFilter)}
              value={form.name}
            />
          </S.GridInput>
          <S.GridInput item xs={12} sm={12} md={6}>
            <RadioButton
              label=""
              options={searchNameAndCnpjIn}
              key="searchNameAndCnpjIn"
              value={form.searchNameAndCnpjIn}
              onSelected={e => handleSelect(e, 'searchNameAndCnpjIn')}
            />
          </S.GridInput>
        </S.GridFilter>
        <S.GridFilter container>
          <S.GridInput item xs={12}>
            <InputAutocomplete
              fullWidth
              error={Boolean(errors.companyGroups)}
              helperText={errors.companyGroups}
              label={t(
                'traceabilityRetailPage:filtersRetail:industryGroup:industryGroupInput'
              )}
              options={groupProfile || []}
              optionLabel="label"
              defaultValue={form.companyGroups}
              loading={true}
              loadingText={
                !groupProfile.length
                  ? t('common:dataNotFound')
                  : t('common:pleaseWait')
              }
              onSelected={val => handleAutocomplete('companyGroups', val)}
            />
          </S.GridInput>
        </S.GridFilter>
        <S.GridFilter container>
          <S.GridInput item xs={12} sm={6} md={4}>
            <Input
              error={Boolean(errors.orderNumber)}
              fullWidth
              helperText={errors.orderNumber}
              label={t('traceabilityRetailPage:filtersRetail:orderNumber')}
              onInput={value => handleInput(value, 'orderNumber')}
              onKeyPress={submitOnEnter(onFilter)}
              value={form.orderNumber}
            />
          </S.GridInput>
          <S.GridInput item xs={12} sm={6} md={4}>
            <Input
              error={Boolean(errors.invoiceNumber)}
              fullWidth
              helperText={errors.invoiceNumber}
              label={t('traceabilityRetailPage:filtersRetail:invoice')}
              onInput={value => handleInput(value, 'invoiceNumber')}
              onKeyPress={submitOnEnter(onFilter)}
              value={form.invoiceNumber}
            />
          </S.GridInput>

          <S.GridInput item xs={12} sm={6} md={4}>
            <Input
              error={Boolean(errors.productCode)}
              fullWidth
              helperText={errors.productCode}
              label={t('traceabilityRetailPage:filtersRetail:productCode')}
              onInput={value => handleInput(value, 'productCode')}
              onKeyPress={submitOnEnter(onFilter)}
              value={form.productCode}
            />
          </S.GridInput>
          <S.GridInput item xs={12} sm={6} md={4}>
            <InputSelect
              error={Boolean(errors.orderStatus)}
              fullWidth
              helperText={errors.orderStatus}
              defaultValue={orderStatusSelected}
              label={t('traceabilityProviderPage:filtersProvider:orderStatus')}
              onSelected={val => handleSelectOrderStatus('orderStatus', val)}
              optionLabel="label"
              optionValue="tag"
              options={orderStatusOptions() || []}
              value={form.orderStatus}
              loadingText="Aguarde..."
            />
          </S.GridInput>
          <S.GridInput item xs={12} sm={6} md={4}>
            <InputSelect
              error={Boolean(errors.orderStatus)}
              fullWidth
              helperText={errors.orderStatus}
              defaultValue={orderTypeOriginSelected}
              label={t('traceabilityRetailPage:filtersRetail:orderTypeOrigin')}
              onSelected={val =>
                handleSelectOrderOriginType('orderTypeOrigin', val)
              }
              optionLabel="description"
              optionValue="tag"
              options={orderTypeOrigin || []}
              value={form.orderTypeOrigin}
              loadingText="Aguarde..."
            />
          </S.GridInput>
          {/* REMOVIDO POR HORA */}
          {/* <S.GridInput item xs={12} sm={6} md={4}>
            <InputSelect
              error={Boolean(errors.souABR)}
              fullWidth
              helperText={errors.souABR}
              label={t('traceabilityRetailPage:filtersRetail:amAbr')}
              onSelected={() => {}}
              optionLabel="nome"
              optionValue="id.M49"
              options={[]}
              loadingText="Aguarde..."
            />
          </S.GridInput> */}
        </S.GridFilter>

        <S.GridHeader style={{ marginTop: '1.6rem' }}>
          <S.GridTitle item container xs={12}>
            <S.BoxTitle>
              {t('traceabilityRetailPage:filtersRetail:supplierOrigin')}
            </S.BoxTitle>
          </S.GridTitle>
        </S.GridHeader>
        <Location
          errors={errors}
          payload={form}
          optionValueCity="title"
          handleSelectedIBGEState={handleSelectedIBGEState}
          handleSelectedIBGECity={handleSelectedIBGECity}
          handleSelectedMicroregion={handleSelectedMicroregion}
          handleSelectedCountry={handleSelectedCountry}
        />

        <S.GridFilter>
          <S.GridButtons item xs={12} sm={12} md={12}>
            <Button variant="gray" onClick={clearFilter} size="medium">
              <p>{t('traceabilityRetailPage:filtersRetail:clean')}</p>
            </Button>
            <Button variant="blue" onClick={() => onFilter()} size="medium">
              <p>{t('traceabilityRetailPage:filtersRetail:filter')}</p>
            </Button>
          </S.GridButtons>
        </S.GridFilter>
      </S.FullGrid>
    </S.Wrapper>
  )
}
