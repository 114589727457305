import { HTTP } from 'providers'
import { ClientType } from 'types/Client.types'

export class ClientService extends HTTP {
  private endpoint = '/retail'
  private config: any = {
    showLoading: true
  }

  public fetchAll({ params = {} } = {}) {
    return this.get({ endpoint: this.endpoint, params })
  }

  public searchByGroup({ groupId, params }) {
    return this.get({
      endpoint: `${this.endpoint}/by-group/${groupId}`,
      params
    })
  }

  public removeMany({ ids }: { ids: string[] }) {
    return this.delete({ endpoint: `${this.endpoint}/many`, data: { ids } })
  }

  public create({ payload }: { payload: ClientType }) {
    return this.post({
      endpoint: this.endpoint,
      payload,
      config: this.config
    })
  }

  public fetchOne(id) {
    return this.get({
      endpoint: `${this.endpoint}/${id}`
    })
  }

  public fetchUnrelatedWithGroup({ groupId, params = {} }) {
    return this.get({
      endpoint: `${this.endpoint}/unrelated-with-group/${groupId}`,
      params
    })
  }

  public edit(client: ClientType) {
    const { id } = client
    return this.put({
      endpoint: `${this.endpoint}/${id}`,
      payload: client,
      config: this.config
    })
  }

  public deleteClient({ id }) {
    return this.delete({
      endpoint: `${this.endpoint}/${id}`
    })
  }
}
