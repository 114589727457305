import { ColumnsProps } from 'components/Table/Table'
import { t } from 'i18next'

export const itemOrderDetails = (): ColumnsProps[] => [
  {
    fixed: true,
    align: 'center',
    name: 'itemCode',
    title: t('orderDetails:items.itemCode')
  },
  {
    fixed: false,
    align: 'center',
    name: 'itemDescription',
    title: t('orderDetails:items.itemDescription')
  },
  {
    fixed: false,
    align: 'center',
    name: 'qtyTotal',
    title: t('orderDetails:items.qtdTotalItems')
  },
  {
    fixed: false,
    align: 'center',
    name: 'orderStatus',
    title: t('orderDetails:items.status')
  },
  {
    fixed: false,
    align: 'center',
    name: 'orderSituation',
    title: t('orderDetails:items.situation')
  },

  {
    fixed: false,
    align: 'center',
    name: 'orderProgress',
    title: t('orderDetails:items.progress')
  },
  {
    fixed: false,
    align: 'center',
    name: 'invoiceQty',
    title: t('orderDetails:items.totalDelivered')
  },
  {
    fixed: false,
    align: 'center',
    name: 'previousDeliveryDate',
    title: t('orderDetails:items.previousDeliveryDate')
  },
  {
    fixed: false,
    align: 'center',
    name: 'deliveryDate',
    title: t('orderDetails:items.deliveryDate')
  }
]
