import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { FabricsService } from 'services/fabrics.service'

import { cnpjMask } from 'utils/CnpjMask'
import { formaDateStringLocale } from 'utils/formaDateStringLocale'

import {
  generalDataTable,
  INITIAL_STATE_GENERAL_DATA
} from '../../../fabrics.constants'
import { GeneralData, IQueryParameters } from '../../../fabrics.types'

import { Table } from 'components'

import * as S from './styled'

export function GeneralDataTable() {
  const { t } = useTranslation()

  const { id: orderNumber } = useParams<IQueryParameters>()

  const [generalData, setGeneralData] = useState<GeneralData>(
    INITIAL_STATE_GENERAL_DATA
  )

  const getGeneralDataFromApiWithID = useCallback(async () => {
    const fabricsService = new FabricsService()
    await fabricsService.getOrderData({ orderNumber }).then(response => {
      const { data } = response
      setGeneralData(data.items[0] as GeneralData)
    })
  }, [orderNumber])

  const formattedGeneralData = {
    ...generalData,
    orderDate: generalData.orderDate
      ? formaDateStringLocale(generalData.orderDate)
      : null,
    supplierCnpj: generalData.supplierCnpj
      ? cnpjMask(generalData.supplierCnpj)
      : null,
    deliveryDate: generalData.deliveryDate
      ? formaDateStringLocale(generalData.deliveryDate)
      : null,
    deliveryForecastDate: generalData.deliveryForecastDate
      ? formaDateStringLocale(generalData.deliveryForecastDate)
      : null
  }

  useEffect(() => {
    if (orderNumber && Object.values(generalData).every(value => !value)) {
      getGeneralDataFromApiWithID()
    }
  }, [getGeneralDataFromApiWithID, orderNumber, generalData])

  return (
    <S.Container>
      <S.Title>
        {t('fabricsPage:tables:generalData:generalData')}
        <S.Line />
      </S.Title>
      <S.Wrapper>
        <Table
          rows={[formattedGeneralData]}
          columns={generalDataTable()}
          statusBoxColumnName="orderStatus"
          situationBoxColumnName="orderSituation"
        />
      </S.Wrapper>
    </S.Container>
  )
}
