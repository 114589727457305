import styled from 'styled-components'

export const SimpleCard = styled.div`
  border-radius: 1.2rem;
  border: none;
  color: white;
  display: grid;
  height: 13.4rem;
  padding: 2.4rem 2.4rem 1.6px 2.4rem;
  transform-origin: left;
  transition: box-shadow 400ms, color 400ms, transform 400ms;
  will-change: background-color, color, transform;
  box-shadow: 0px 0px 0px #00000021;
  &:hover {
    box-shadow: 0 10px 16px -6px #00000041;
    transform: scale(1.005);
  }

  &.dark {
    background: transparent linear-gradient(180deg, #4ba8df 0%, #1f3968 100%) 0%
      0% no-repeat padding-box;
  }

  &.darker {
    background: transparent linear-gradient(180deg, #315ca7 0%, #192e54 100%) 0%
      0% no-repeat padding-box;
  }

  &.medium {
    background: transparent linear-gradient(180deg, #49aadd 0%, #7edec1 100%) 0%
      0% no-repeat padding-box;
  }

  &.light {
    background: transparent linear-gradient(180deg, #70c3f7 0%, #b7f6fa 100%) 0%
      0% no-repeat padding-box;
  }
`
export const Title = styled.p`
  font-size: 1.6rem;
  font-weight: 600;
`

export const Description = styled.p`
  font-size: 4rem;
  font-weight: 600;
`
