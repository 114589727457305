import styled from 'styled-components'

export const HeaderGrid = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`

export const ButtonContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const ButtonText = styled.span`
  margin-left: 12px;
`
