import { useHistory } from 'react-router-dom'

import * as S from './styled'

export function SuccessResetMessage() {
  const history = useHistory()

  return (
    <S.Wrapper container>
      <S.FullGrid item xs={12}>
        <S.TitleMargin />

        <S.DescriptionBox item sm={12}>
          <S.DescriptionText>
            Senha alterada com sucesso! Faça login
          </S.DescriptionText>
        </S.DescriptionBox>

        <S.GoBackLoginButton
          variant="green"
          onClick={() => history.push('/login')}
        >
          Voltar para o login
        </S.GoBackLoginButton>
      </S.FullGrid>
    </S.Wrapper>
  )
}
