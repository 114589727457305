import { useEffect, useState } from 'react'

import { IndustryType } from 'types/Industry.types'

import { Checkbox, Icon } from 'components'
import { MiniSpinner } from 'components/MiniSpinner'
import { TableNotFoundHint } from 'components/TableNotFoundHint'

import * as S from './styled'

export type IndustryTableAddProps = {
  industries: IndustryType[]
  loading: boolean
  setIndustries: (_val) => void
}

export function IndustryTableAdd({
  industries,
  loading,
  setIndustries
}: IndustryTableAddProps) {
  const [allCheckboxes, setAllCheckboxes] = useState(false)
  const [checkboxes, setCheckboxes] = useState([])

  useEffect(() => {
    if (industries.length) {
      if (checkboxes.length === industries.length) setAllCheckboxes(true)
      else setAllCheckboxes(false)

      setIndustries(checkboxes)
    }
  }, [checkboxes, industries, setIndustries])

  function cleanSelectedIndustries() {
    setCheckboxes([])
  }

  function changeAll() {
    setAllCheckboxes(!allCheckboxes)

    if (!allCheckboxes) setCheckboxes(industries.map(({ id }) => id))
    else cleanSelectedIndustries()
  }

  function handleCheckbox(id: string) {
    return function handle(val) {
      if (val) setCheckboxes([...checkboxes, id])
      else {
        const newCheckboxes = new Set(checkboxes.filter(each => each !== id))
        setCheckboxes(Array.from(newCheckboxes))
      }
    }
  }

  function descriptionIndustrySelect() {
    const plural = checkboxes.length > 1 ? 's' : ''
    return `${checkboxes.length} indústria${plural} selecionada${plural}`
  }

  return (
    <S.Wrapper data-testid="IndustryTableAdd">
      {Boolean(industries.length && checkboxes.length) && (
        <S.CheckboxInfoGuide>
          <S.SelectedCheckboxInfo>
            <S.RemoveIndustryIcon
              title="Limpar"
              onClick={cleanSelectedIndustries}
            >
              <Icon icon="input-exit" height={12} width={12} />
            </S.RemoveIndustryIcon>
            <span>{descriptionIndustrySelect()}</span>
          </S.SelectedCheckboxInfo>
        </S.CheckboxInfoGuide>
      )}
      <S.TableGroup>
        <S.ScrollWrapper>
          <S.Table id="table-fixed">
            <tbody>
              <S.Line id="header">
                <S.Header>
                  <S.CheckboxLine>
                    <Checkbox
                      labelFor=""
                      label=""
                      value="all"
                      disabled={!industries.length}
                      checked={allCheckboxes}
                      onCheck={changeAll}
                    />
                    Razão Social
                  </S.CheckboxLine>
                </S.Header>
                <S.Header>CNPJ</S.Header>
              </S.Line>
              {industries.map((each, key) => (
                <S.Line id="values" key={key}>
                  <S.Column>
                    <S.CheckboxLine>
                      <Checkbox
                        labelFor=""
                        label=""
                        value={each.id}
                        isChecked={
                          !!checkboxes.filter(f => f.includes(each.id)).length
                        }
                        onCheck={handleCheckbox(each.id)}
                      />
                      {each.name}
                    </S.CheckboxLine>
                  </S.Column>
                  <S.Column>{each.cnpj}</S.Column>
                </S.Line>
              ))}
            </tbody>
          </S.Table>
        </S.ScrollWrapper>
      </S.TableGroup>
      {loading && <MiniSpinner />}
      {!loading && !industries.length && (
        <TableNotFoundHint to="/industria/novo" />
      )}
    </S.Wrapper>
  )
}
