import common from './en/common.json'
import loginPage from './en/loginPage.json'
import recoveryPassPage from './en/recoveryPassPage.json'
import generalIndustriesPage from './en/generalIndustriesPage.json'
import generalPanelPage from './en/generalPanelPage.json'
import breadcrumb from './en/breadcrumb.json'
import generalUserPage from './en/generalUserPage.json'
import filters from './en/filters.json'
import traceabilityRetailPage from './en/traceabilityRetailPage.json'
import traceabilityProviderPage from './en/traceabilityProviderPage.json'
import traceabilityWeavingPage from './en/traceabilityWeavingPage.json'
import traceabilityWiringPage from './en/traceabilityWiringPage.json'
import propertiesPage from './en/propertiesPage.json'
import tableComponent from './en/tableComponent.json'
import biomesSelectComponent from './en/biomesSelectComponent.json'
import productionChainPage from './en/productionChainPage.json'
import ordersManagementPage from './en/ordersManagementPage.json'
import traceabilityPanelPage from './en/traceabilityPanelPage.json'
import newIndustry from './en/newIndustry.json'
import generalIndustryGroup from './en/generalIndustryGroup.json'
import newIndustryGroup from './en/newIndustryGroup.json'
import newUser from './en/newUser.json'
import orderDetails from './en/orderDetails.json'
import providerDetailsPage from './en/providerDetailsPage.json'
import notFoundPage from './en/notFoundPage.json'
import accordionDetailsContent from './en/accordionDetailsContent.json'
import leadTimePage from './en/leadTimePage.json'
import fabricsPage from './en/fabricsPage.json'
import downloadReport from './en/downloadReport.json'

export default {
  biomesSelectComponent,
  productionChainPage,
  ordersManagementPage,
  breadcrumb,
  common,
  filters,
  generalIndustriesPage,
  generalIndustryGroup,
  generalPanelPage,
  loginPage,
  newIndustry,
  newIndustryGroup,
  newUser,
  propertiesPage,
  providerDetailsPage,
  recoveryPassPage,
  tableComponent,
  traceabilityPanelPage,
  traceabilityProviderPage,
  traceabilityRetailPage,
  traceabilityWeavingPage,
  traceabilityWiringPage,
  generalUserPage,
  orderDetails,
  notFoundPage,
  accordionDetailsContent,
  leadTimePage,
  fabricsPage,
  downloadReport
}
