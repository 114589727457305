import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import * as Yup from 'yup'

import { YupValidator } from 'services/yupValidator.service'
import { UserService } from 'services/user.service'

import { RequestResetPwdForm } from './RequestForm'
import { ResetPwdForm } from './ResetForm'
import { SuccessRequestMessage } from './SuccessRequestMessage'
import { SuccessResetMessage } from './SuccessResetMessage'

import { Signin } from 'components'

import * as S from './styled'

const RecoverySchema = Yup.object().shape({
  email: Yup.string().required().email().label('E-mail')
})

const INITIAL_STATE = { email: '' }
const VISIBLE_SECTION = {
  requestForm: 0,
  requestMessage: 1,
  resetForm: 2,
  resetMessage: 3
}

export function RecoveryPwd() {
  const { t } = useTranslation()
  const location = useLocation()
  const [form, setForm] = useState(INITIAL_STATE)
  const [visibleSec, setVisibleSection] = useState(VISIBLE_SECTION.requestForm)
  const [errors, setErrors] = useState(INITIAL_STATE)
  const [query, setQuery] = useState({})

  function handleInput(name: string, value: string) {
    setErrors({ ...errors, [name]: '' })
    setForm({ ...form, [name]: value })
  }

  async function handleClick() {
    setErrors({ ...INITIAL_STATE })

    const [isValid, validationError] = await new YupValidator(
      RecoverySchema
    ).validate(form)

    if (!isValid) return setErrors(validationError as any)

    const response = await new UserService().requestPwdReset(form.email)
    if (response.data.code === 'NOT_FOUND') {
      setErrors({ email: t('recoveryPassPage:emailNotFound') })
      return
    }
    setVisibleSection(VISIBLE_SECTION.requestMessage)
  }

  async function onResetSubmit(payload) {
    await new UserService().resetPwd({ ...payload, ...query })
    setVisibleSection(VISIBLE_SECTION.resetMessage)
  }

  useEffect(() => {
    const raw = new URLSearchParams(window.location.search.substring(1))
    const email = raw.get('email')
    const token = raw.get('token')

    const _query = {}
    if (email) Object.assign(_query, { email })
    if (token) Object.assign(_query, { token })

    if (!Object.keys(query).length && Object.keys(_query).length) {
      setQuery(_query)
      setVisibleSection(VISIBLE_SECTION.resetForm)
    }
  }, [query, location.search])

  return (
    <Signin>
      <S.SideBarCorner>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="138.11"
          height="395.5"
          viewBox="0 0 138.11 395.5"
          className="decoration"
        >
          <path
            fill="#3fab36"
            className="decorator_form"
            d="M138.11,212.143c0-56.625-22.787-78.15-36.5-107.314C92.441,85.312,93.41,0,93.41,0H0V395.5H93.41s1.552-61.884,8.206-76.042C115.324,290.293,138.11,268.769,138.11,212.143Z"
          />
        </svg>
        <p className="chevron">›</p>
      </S.SideBarCorner>
      <S.FormContainer>
        <S.Logo />
        <S.Title>{t('recoveryPassPage:pageTitle')}</S.Title>

        {visibleSec === VISIBLE_SECTION.requestMessage && (
          <SuccessRequestMessage email={form.email} />
        )}
        {visibleSec === VISIBLE_SECTION.requestForm && (
          <RequestResetPwdForm
            onInput={handleInput}
            onSubmit={handleClick}
            error={errors.email}
            value={form.email}
          />
        )}
        {visibleSec === VISIBLE_SECTION.resetForm && (
          <ResetPwdForm onSubmit={onResetSubmit} />
        )}
        {visibleSec === VISIBLE_SECTION.resetMessage && <SuccessResetMessage />}

        {visibleSec !== VISIBLE_SECTION.resetMessage && (
          <S.ForgetPassword to={'/login'}>
            {t('recoveryPassPage:backToLogin')}
          </S.ForgetPassword>
        )}
      </S.FormContainer>
    </Signin>
  )
}
