import { CardsGeneral } from 'components'
import { Base } from 'layouts'
import { UserStorageService } from 'services'
import { Redirect } from 'react-router-dom'

export function General() {
  const { user } = UserStorageService.getUserData()

  return (
    <Base>
      {user.role !== 'visual' ? (
        <CardsGeneral />
      ) : (
        <Redirect to={{ pathname: '/404' }} />
      )}
    </Base>
  )
}
