import { Button } from 'components/Button'
import { Icon } from 'components/Icon'
import { Modal } from 'components/Modal'

import * as S from './styled'

export type ModalDeleteGroupIndustryProps = {
  groupName: string
  companies: number
  onExit?: () => void
  onRemove?: () => void
}

export function ModalDeleteGroupIndustry({
  groupName,
  companies,
  onExit,
  onRemove
}: ModalDeleteGroupIndustryProps) {
  const button = (
    <Button variant="green" onClick={onRemove} size="medium">
      <p>Sim</p>
    </Button>
  )

  const content = (
    <S.Wrapper>
      <S.Icon>
        <Icon icon="dropdown-delete" width={16} height={16} />
      </S.Icon>
      <S.Title>Excluir grupo</S.Title>
      <S.Description>
        Tem certeza que deseja excluir o <span>Grupo {groupName}</span> ?
        {companies > 0 && (
          <>
            <br /> Esse grupo tem <span>{companies}</span> indústria(s)
            relacionada(s) a ele.
          </>
        )}
      </S.Description>
    </S.Wrapper>
  )

  return (
    <Modal
      button={button}
      content={content}
      isActionsEnabled={true}
      width={60}
      onExit={onExit}
    />
  )
}
