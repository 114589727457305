import { createContext, ReactNode, useState } from 'react'

interface RetailTableProviderProps {
  children: ReactNode
}

interface IOrdersKeysSelected {
  id: string
  number: string
}

interface IRetailTableDispatch {
  setOrderKeySelected: (_key: IOrdersKeysSelected[]) => void
}

interface IRetailTable {
  orderKeySelected: IOrdersKeysSelected[]
  setOrderKeySelected?: (_key: string) => void
  clearOrderKeySelected?: () => void
}

export const RetailTableContext = createContext<IRetailTable>(null)
export const RetailTableDispatchContext =
  createContext<IRetailTableDispatch>(null)

export default function RetailTableProvider({
  children
}: RetailTableProviderProps) {
  const [orderKeySelected, _setOrderKeySelected] = useState<
    IOrdersKeysSelected[]
  >([])
  function clearOrderKeySelected() {
    _setOrderKeySelected([])
    window.localStorage.removeItem('orderKeySelected')
  }

  function persistInLocalStorage(data: IOrdersKeysSelected[]) {
    window.localStorage.setItem('orderKeySelected', JSON.stringify(data))
  }

  function setOrderKeySelected(key: string) {
    const ordersKeysParsed = JSON.parse(key) as IOrdersKeysSelected

    const alreadExistsOrdersKey = orderKeySelected.find(
      item => item.id === ordersKeysParsed.id
    )

    if (!alreadExistsOrdersKey) {
      const _orderKeySelected = orderKeySelected.map(key => key)
      _orderKeySelected.push(ordersKeysParsed)
      _setOrderKeySelected(_orderKeySelected)
      persistInLocalStorage(_orderKeySelected)
      return
    }

    const _orderKeySelected = orderKeySelected.filter(
      item => item.id !== ordersKeysParsed.id
    )
    _setOrderKeySelected(_orderKeySelected)
    persistInLocalStorage(_orderKeySelected)
  }

  return (
    <RetailTableContext.Provider
      value={{ orderKeySelected, setOrderKeySelected, clearOrderKeySelected }}
    >
      {children}
    </RetailTableContext.Provider>
  )
}
