import styled from 'styled-components'
import { Button } from 'components/Button'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  span {
    font-weight: bold;
  }
`
export const UlComponent = styled.ul`
  padding-left: 4rem;
  margin: 2rem 0 4rem;
`

export const FileList = styled(UlComponent)`
  display: flex;
  width: 60%;
  justify-content: space-between;
`

export const ReportList = styled(UlComponent)`
  li + li {
    margin-top: 2rem;
  }
`

export const ButtonComponent = styled(Button)`
  svg {
    fill: ${({ theme }) => theme.colors.white};
  }
`

export const Line = styled.div`
  width: auto;
  height: 0.2rem;
  background-color: #f2f3f7;
`
export const Icon = styled.div`
  background-color: #f2f3f7;
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2.4rem;
`

export const Title = styled.p`
  color: ${({ theme }) => theme.colors.darkGrey};
  font-size: 1.8rem;
  font-weight: 600;
  height: 2.3rem;
  margin-bottom: 1.6rem;
`

export const Description = styled.p`
  color: #545557;
  font-size: 1.6rem;
  height: 2rem;
  padding: 0 7rem;

  span {
    font-weight: 600;
  }
`
