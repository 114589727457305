import { HTTPB2B } from 'providers'

export class LeadTimeService extends HTTPB2B {
  private endpoint = '/lead-time'

  public async getGaneralData(filters) {
    return this.get({
      endpoint: this.endpoint,
      params: filters
    })
  }

  public async getOrderSummary(filters) {
    return this.get({
      endpoint: `${this.endpoint}/order-summary`,
      params: filters
    })
  }
}
