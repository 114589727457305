import { useTranslation } from 'react-i18next'

import { Totalizer } from 'components'

import * as S from '../../styled'
interface TotalizersProps {
  totalizersData: any
}

export function Totalizers({ totalizersData }: TotalizersProps) {
  const { t, i18n } = useTranslation()
  const language = i18n.language === '中文 - ZH - CN' ? 'zh-CN' : i18n.language

  return (
    <S.TotalizersContainer>
      <Totalizer
        primaryText={t('traceabilityProviderPage:totalizersProvider:orders')}
        primaryValue={totalizersData.totalOrders.toLocaleString(language) || 0}
      />
      <Totalizer
        primaryText={t(
          'traceabilityProviderPage:totalizersProvider:ordersDelivered'
        )}
        primaryValue={
          totalizersData.ordersDeliveried.toLocaleString(language) || 0
        }
      />
      <Totalizer
        primaryText={t(
          'traceabilityProviderPage:totalizersProvider:openOrders'
        )}
        primaryValue={totalizersData.ordersOpen.toLocaleString(language) || 0}
      />
      <Totalizer
        primaryText={t(
          'traceabilityProviderPage:totalizersProvider:lateOrders'
        )}
        primaryValue={totalizersData.orderDelayed.toLocaleString(language) || 0}
      />
    </S.TotalizersContainer>
  )
}
