import { t } from 'i18next'
import { ColumnsProps } from 'components/Table/Table'
import {
  GeneralOrderData,
  HeaderItemProps,
  LaunchProductionProps,
  OrderItemDetailsProps
} from './orderManagementDetails.type'

export const INITIAL_STATE_GENERAL_DETAILS: GeneralOrderData = {
  orderNumber: null,
  orderStatus: null,
  orderSituation: null,
  orderDate: null,
  deliveryForecastDate: null,
  qtyTotal: null,
  qtyDelivery: null,
  qtyTotalDelivered: null
}

export const INITIAL_STATE_SUMMARY = {
  providers: [],
  subProviders: []
}

export const INITIAL_STATE_ORDER_ITEM_DETAILS: OrderItemDetailsProps = {
  items: [],
  pagination: {
    limit: 0,
    page: 0,
    totalItems: 0,
    totalPages: 0
  }
}

export const INITIAL_STATE_HEADER_ITEMS: HeaderItemProps = {
  items: [],
  pagination: {
    limit: 0,
    page: 0,
    totalItems: 0,
    totalPages: 0
  }
}

export const INITIAL_STATE_LAUNCH_PRODUCTION: LaunchProductionProps = {
  items: [],
  pagination: {
    limit: 0,
    page: 0,
    totalItems: 0,
    totalPages: 0
  }
}

export const accordionItems = [
  {
    tag: 'providers',
    icon: 'confeccao',
    title: 'ownProduction',
    hasActionButton: true,
    accordionDetailsButtonText: 'subProviders'
  },
  {
    tag: 'subProviders',
    icon: 'subproviders',
    title: 'subProviders',
    hasActionButton: true,
    accordionDetailsButtonText: 'ownProduction'
  }
]

export const generalOrderData = (): ColumnsProps[] => [
  {
    fixed: true,
    align: 'center',
    name: 'orderNumber',
    title: t('ordersManagementPage:tables:generalDataTable:orderNumber')
  },
  {
    fixed: false,
    align: 'center',
    name: 'orderStatus',
    title: t('ordersManagementPage:tables:generalDataTable:orderStatus')
  },

  {
    fixed: false,
    align: 'center',
    name: 'orderSituation',
    title: t('ordersManagementPage:tables:generalDataTable:orderSituation')
  },
  {
    fixed: false,
    align: 'center',
    name: 'orderDate',
    title: t('ordersManagementPage:tables:generalDataTable:orderDate')
  },

  {
    fixed: false,
    align: 'center',
    name: 'deliveryForecastDate',
    title: t(
      'ordersManagementPage:tables:generalDataTable:deliveryForecastDate'
    )
  },
  {
    fixed: false,
    align: 'center',
    name: 'qtyTotal',
    title: t('ordersManagementPage:tables:generalDataTable:qtyTotal')
  },
  {
    fixed: false,
    align: 'center',
    name: 'qtyTotalDelivered',
    title: t('ordersManagementPage:tables:generalDataTable:qtyDelivery')
  }
]

export const orderItemDetails = (): ColumnsProps[] => [
  {
    fixed: true,
    align: 'center',
    name: 'activity',
    title: t('ordersManagementPage:tables:orderItemDetailsTable:activity')
  },
  {
    fixed: false,
    align: 'center',
    name: 'qtyTotal',
    title: t('ordersManagementPage:tables:orderItemDetailsTable:qtyTotal')
  },

  {
    fixed: false,
    align: 'center',
    name: 'qtyDelivered',
    title: t('ordersManagementPage:tables:orderItemDetailsTable:qtyProduced')
  },
  {
    fixed: false,
    align: 'center',
    name: 'unity',
    title: t('ordersManagementPage:tables:orderItemDetailsTable:unitMeasure')
  },

  {
    fixed: false,
    align: 'center',
    name: 'requestDate',
    title: t('ordersManagementPage:tables:orderItemDetailsTable:requestDate')
  },
  {
    fixed: false,
    align: 'center',
    name: 'deliveryForecast',
    title: t(
      'ordersManagementPage:tables:orderItemDetailsTable:deliveryForecast'
    )
  }
]

export const launchProduction = (): ColumnsProps[] => [
  {
    fixed: true,
    align: 'center',
    name: 'invoice',
    title: t('ordersManagementPage:tables:launchProductionTable:invoice')
  },
  {
    fixed: false,
    align: 'center',
    name: 'requestDate',
    title: t('ordersManagementPage:tables:launchProductionTable:releaseDate')
  },

  {
    fixed: false,
    align: 'center',
    name: 'activity',
    title: t('ordersManagementPage:tables:launchProductionTable:activity')
  },
  {
    fixed: false,
    align: 'center',
    name: 'qtyDelivered',
    title: t('ordersManagementPage:tables:launchProductionTable:qtyProduced')
  }
]
