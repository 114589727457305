import { RetailTableContext } from 'contexts/retailTable/retailTable.context'
import { ChangeEvent, ReactNode, useContext, useState } from 'react'
import * as S from './styled'

type TabsProps = {
  tabOptions?: {
    tag: string
    label: string
    renderComponent: ReactNode
  }[]
  blockForCheckOnly?: boolean
  unlockWhenHasCheckOne?: boolean
}

interface TabPanelProps {
  children?: ReactNode
  index: string | number
  value: string | number
}

function TabPanel({ children, value, index }: TabPanelProps) {
  return (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && <S.Container>{children}</S.Container>}
    </div>
  )
}

export function Tabs({
  tabOptions,
  blockForCheckOnly = false,
  unlockWhenHasCheckOne = false
}: TabsProps) {
  const [value, setValue] = useState(tabOptions && tabOptions[0].tag)

  const { orderKeySelected } = useContext(RetailTableContext)

  const handleChange = (_event: ChangeEvent<{}>, newValue: string) => {
    if (blockForCheckOnly && unlockWhenHasCheckOne) setValue(newValue)
    if (!blockForCheckOnly) setValue(newValue)
  }

  if (!orderKeySelected.length && value !== tabOptions[0].tag) {
    setValue(tabOptions[0].tag)
  }

  return (
    <>
      <S.TabsContainer
        value={value}
        onChange={handleChange}
        TabIndicatorProps={{
          style: { display: 'none' }
        }}
      >
        {tabOptions &&
          tabOptions.map((tab, index) => (
            <S.TabItem
              key={index}
              label={tab.label}
              value={tab.tag}
              hasOnlyOneItem={tabOptions.length > 1}
              isActive={value === tab.tag}
              changeBackgroundForWhite={
                blockForCheckOnly && unlockWhenHasCheckOne
              }
              changeCursorPointerToBlock={
                blockForCheckOnly && unlockWhenHasCheckOne
              }
            />
          ))}
      </S.TabsContainer>
      {tabOptions &&
        tabOptions.map((tab, index) => {
          return (
            <TabPanel key={index} value={value} index={tab.tag}>
              {tab.renderComponent}
            </TabPanel>
          )
        })}
    </>
  )
}
