import { Button } from 'components/Button'
import { Icon } from 'components/Icon'
import { Modal } from 'components/Modal'

import * as S from './styled'

export type ModalCreateProps = {
  cnpj: string | string[]
  groupName: string
  onExit?: () => void
  onRemove?: () => void
}

function formatCNPJ(cnpj) {
  if (Array.isArray(cnpj)) return cnpj.join(', ')
  return cnpj
}

export function ModalDelete({
  cnpj,
  groupName,
  onExit,
  onRemove
}: ModalCreateProps) {
  const button = (
    <Button variant="green" onClick={onRemove} size="medium">
      <p>Sim</p>
    </Button>
  )

  const content = (
    <S.Wrapper>
      <S.Icon>
        <Icon icon="dropdown-delete" width={16} height={16} />
      </S.Icon>
      <S.Title>Remover indústria</S.Title>
      <S.Description>
        Tem certeza que deseja excluir o <span>CNPJ(s) {formatCNPJ(cnpj)}</span>{' '}
        do <span>Grupo {groupName}</span> ?
      </S.Description>
    </S.Wrapper>
  )

  return (
    <Modal
      button={button}
      content={content}
      isActionsEnabled={true}
      width={60}
      onExit={onExit}
    />
  )
}
