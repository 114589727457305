import { toast } from 'react-toastify'

export const toasts = {
  addProducer() {
    return toast.success('Produtor adicionado com sucesso.', {
      autoClose: 5 * 1000
    })
  },
  addGroupSuccess() {
    return toast.success('Grupo adicionado com sucesso.', {
      autoClose: 5 * 1000
    })
  },
  blockStatusProducer() {
    return toast.success('Situação alterada com sucesso.', {
      autoClose: 5 * 1000
    })
  },
  citiesFail() {
    return toast.error(
      'Erro ao buscar as cidades, tente novamente em instantes',
      { autoClose: 5 * 1000 }
    )
  },
  created() {
    return toast.success('Dados cadastrados com sucesso.', {
      autoClose: 5 * 1000
    })
  },
  deleted() {
    return toast.success('Dados excluídos com sucesso.', {
      autoClose: 5 * 1000
    })
  },
  discarded() {
    return toast.success('Dados descartados com sucesso.', {
      autoClose: 5 * 1000
    })
  },
  duplicated() {
    return toast.warn(
      'O documento informado já está cadastrado em nossa base de dados',
      { autoClose: 5 * 1000 }
    )
  },
  duplicatedCPFCNPJIE() {
    return toast.warn(
      'CPF/CNPJ e IE duplicados ou já cadastrado em nossa base de dados',
      { autoClose: 5 * 1000 }
    )
  },
  duplicatedCNPJ() {
    return toast.warn(
      'O CNPJ informado já está cadastrado em nossa base de dados',
      { autoClose: 5 * 1000 }
    )
  },
  duplicatedCPF() {
    return toast.warn(
      'O CPF informado já está cadastrado em nossa base de dados',
      { autoClose: 5 * 1000 }
    )
  },
  duplicatedEmail() {
    return toast.warn(
      'O e-mail informado já está cadastrado em nossa base de dados',
      { autoClose: 5 * 1000 }
    )
  },
  duplicatedPhone() {
    return toast.warn(
      'O telefone informado já está cadastrado em nossa base de dados',
      { autoClose: 5 * 1000 }
    )
  },
  insufficientPermissions() {
    return toast.error('Informe ao menos uma permissão', {
      autoClose: 5 * 1000
    })
  },
  withoutPermission() {
    return toast.warn('Ao menos uma permissão deve ser informada', {
      autoClose: 5 * 1000
    })
  },
  edited() {
    return toast.success('Dados editados com sucesso.', { autoClose: 5 * 1000 })
  },
  generalFail({ code = 'UNKNOWN_ERROR' } = {}) {
    return toast.error(
      'Ocorreu uma falha inesperada. Por favor, aguarde alguns minutos de tente novamente.',
      { autoClose: 5 * 1000, toastId: code }
    )
  },
  import() {
    return toast.success('Dados importados com sucesso.', {
      autoClose: 5 * 1000
    })
  },
  updatePwd() {
    return toast.success('Senha alterada com sucesso.', {
      autoClose: 5 * 1000
    })
  },
  updatePwdError() {
    return toast.error(
      'Erro ao alterar senha, verifique os dados informados.',
      {
        autoClose: 5 * 1000
      }
    )
  },
  updatePwdOldError() {
    return toast.error('Erro ao alterar senha, senha atual incorreta.', {
      autoClose: 5 * 1000
    })
  },
  invalidForm({ code = 'UNKNOWN_ERROR' } = {}) {
    return toast.error('Preencha o formulário corretamente.', {
      autoClose: 5 * 1000,
      toastId: code
    })
  }
}
