import { useState, useEffect, useCallback } from 'react'

import { InputSelect } from 'components'

import {
  INITIAL_LOCATION_STATE,
  ERRORS_LOCATION_STATE
} from 'constants/retailFilterForm.constants'

import { IBGEService } from 'services'

import * as S from './styled'
import { useTranslation } from 'react-i18next'
import { toasts } from 'utils/toasts'
import { country } from 'components/Location/types'

type PropTypes = {
  errors: typeof ERRORS_LOCATION_STATE
  optionValueCity?: string
  payload: typeof INITIAL_LOCATION_STATE
  handleSelectedIBGEState?: (_value, _name) => void
  handleSelectedIBGECity?: (_value, _name) => void
  handleSelectedMicroregion?: (_value, _name) => void
  handleSelectedCountry?: (_value, _name) => void
}

export function Location({
  errors,
  payload,
  handleSelectedIBGEState,
  handleSelectedIBGECity,
  handleSelectedMicroregion,
  handleSelectedCountry
}: PropTypes) {
  const [states, setStates] = useState([])
  const [_cities, setCities] = useState([])
  const [country, setCountry] = useState([])
  const [microregion, setMicroregion] = useState([])
  const { t } = useTranslation()

  const fetchCountry = useCallback(async () => {
    const initialAllOption = {
      id: { M49: '', 'ISO-ALPHA-2': '--', 'ISO-ALPHA-3': '---' },
      nome: 'Todos',
      'regiao-intermediaria': null,
      'sub-regiao': {
        id: { M49: '' },
        nome: 'Todos',
        regiao: { id: { M49: '' }, nome: 'Todos' }
      }
    }
    if (!country.length) {
      const { data, success } = await new IBGEService().fetchAllCountry()
      if (!success) toasts.generalFail()
      data.unshift(initialAllOption)
      setCountry(data as any)
    }
  }, [country.length])

  const fetchStates = useCallback(async () => {
    const initialAllOption = {
      id: '',
      sigla: '',
      nome: 'Todos',
      regiao: { id: '', sigla: '', nome: '' }
    }
    const { data, success } = await new IBGEService().fetchAll()
    if (!success) toasts.generalFail()
    data.unshift(initialAllOption)
    setStates(data as any)
  }, [])

  const fetchCities = useCallback(async () => {
    if (
      _cities.length > 1 &&
      payload.providerState !== _cities[1].microrregiao.mesorregiao.UF.id
    ) {
      handleSelectedIBGECity('city', '')
      handleSelectedMicroregion('microregion', '')
    }
    const initialAllOption = {
      id: '',
      nome: 'Todas',
      microrregiao: {
        id: '',
        nome: '',
        mesorregiao: {
          id: '',
          nome: '',
          UF: {
            id: '',
            sigla: '',
            nome: '',
            regiao: { id: '', sigla: '', nome: '' }
          }
        }
      },
      'regiao-imediata': {
        id: '',
        nome: '',
        'regiao-intermediaria': {
          id: '',
          nome: '',
          UF: {
            id: '',
            sigla: '',
            nome: '',
            regiao: { id: '', sigla: '', nome: '' }
          }
        }
      }
    }
    const { data, success } = await new IBGEService().fetchCitiesByStateId(
      payload.providerState
    )
    if (!success) toasts.generalFail()
    data.unshift(initialAllOption)
    setCities(data as any)
  }, [payload.providerState])

  const fetchMicroregions = useCallback(async () => {
    if (
      microregion.length > 1 &&
      payload.providerState !== microregion[1].mesorregiao.UF.id
    ) {
      handleSelectedMicroregion('microregion', '')
    }
    const initialAllOption = {
      id: '',
      nome: 'Todas',
      mesorregiao: {
        id: '',
        nome: '',
        UF: {
          id: '',
          sigla: '',
          nome: '',
          regiao: { id: '', sigla: '', nome: '' }
        }
      }
    }
    const { data, success } = await new IBGEService().fecthMicroregionByStateId(
      payload.providerState
    )
    if (!success) toasts.generalFail()
    data.unshift(initialAllOption)
    setMicroregion(data as any)
  }, [payload.providerState])

  useEffect(() => {
    fetchStates()
    fetchCountry()
    fetchCities()
    fetchMicroregions()
  }, [fetchCountry, fetchStates, fetchCities, fetchMicroregions])

  function handleCountryChange(val: country) {
    handleSelectedCountry('country', String(val.id.M49))
  }

  function handleStateChange(val) {
    handleSelectedIBGEState('state', val.id)
  }

  function handleCityChange(val) {
    handleSelectedIBGECity('city', val.id)
  }

  function handlMicroregionChange(val) {
    handleSelectedMicroregion('microregion', val.id)
  }

  useEffect(() => {
    if (payload.providerCountry !== '76') {
      handleSelectedIBGEState('state', '')
      handleSelectedIBGECity('city', '')
      handleSelectedMicroregion('microregion', '')
    }
  }, [payload.providerCountry])

  return (
    <S.GridFilter container spacing={2}>
      <S.GridInput item xs={12} sm={6} md={4}>
        <InputSelect
          error={Boolean(errors.providerCountry)}
          fullWidth
          helperText={errors.providerCountry}
          label={t('propertiesPage:CountryInput')}
          onSelected={(val: country) => handleCountryChange(val)}
          optionLabel="nome"
          optionValue="id.M49"
          options={country || []}
          value={Number(payload.providerCountry) || ''}
          loadingText="Aguarde..."
        />
      </S.GridInput>
      <S.GridInput item xs={12} sm={6} md={4}>
        <InputSelect
          error={Boolean(errors.providerState)}
          disabled={payload.providerCountry !== '76'}
          fullWidth
          helperText={errors.providerState}
          label={t('propertiesPage:StateInput')}
          onSelected={val => handleStateChange(val)}
          optionLabel="nome"
          optionValue="id"
          options={states || []}
          value={Number(payload.providerState) || ''}
          loadingText="Aguarde..."
        />
      </S.GridInput>
      <S.GridInput item xs={12} sm={6} md={4}>
        <InputSelect
          error={Boolean(errors.providerCity)}
          disabled={payload.providerCountry !== '76'}
          fullWidth
          helperText={errors.providerCity}
          label={t('propertiesPage:CityInput')}
          onSelected={val => handleCityChange(val)}
          optionLabel="nome"
          options={_cities || []}
          value={Number(payload.providerCity) || ''}
          loading={true}
          loadingText="Aguarde.."
          optionValue="id"
        />
      </S.GridInput>
      <S.GridInput item xs={12} sm={6} md={4}>
        <InputSelect
          error={Boolean(errors.providerMicroregion)}
          disabled={payload.providerCountry !== '76'}
          fullWidth
          helperText={errors.providerMicroregion}
          label={t('propertiesPage:MicroregionInput')}
          onSelected={val => handlMicroregionChange(val)}
          optionLabel="nome"
          options={microregion || []}
          value={Number(payload.providerMicroregion) || ''}
          loading={true}
          loadingText="Aguarde.."
          optionValue="id"
        />
      </S.GridInput>
    </S.GridFilter>
  )
}
