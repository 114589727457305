import common from './es/common.json'
import loginPage from './es/loginPage.json'
import recoveryPassPage from './es/recoveryPassPage.json'
import generalIndustriesPage from './es/generalIndustriesPage.json'
import generalPanelPage from './es/generalPanelPage.json'
import breadcrumb from './es/breadcrumb.json'
import generalUserPage from './es/generalUserPage.json'
import filters from './es/filters.json'
import traceabilityRetailPage from './es/traceabilityRetailPage.json'
import traceabilityProviderPage from './es/traceabilityProviderPage.json'
import traceabilityWeavingPage from './es/traceabilityWeavingPage.json'
import traceabilityWiringPage from './es/traceabilityWiringPage.json'
import propertiesPage from './es/propertiesPage.json'
import tableComponent from './es/tableComponent.json'
import biomesSelectComponent from './es/biomesSelectComponent.json'
import productionChainPage from './es/productionChainPage.json'
import ordersManagementPage from './es/ordersManagementPage.json'
import traceabilityPanelPage from './es/traceabilityPanelPage.json'
import newIndustry from './es/newIndustry.json'
import generalIndustryGroup from './es/generalIndustryGroup.json'
import newIndustryGroup from './es/newIndustryGroup.json'
import newUser from './es/newUser.json'
import orderDetails from './es/orderDetails.json'
import providerDetailsPage from './es/providerDetailsPage.json'
import notFoundPage from './es/notFoundPage.json'
import accordionDetailsContent from './es/accordionDetailsContent.json'
import leadTimePage from './es/leadTimePage.json'
import fabricsPage from './es/fabricsPage.json'
import downloadReport from './es/downloadReport.json'

export default {
  biomesSelectComponent,
  productionChainPage,
  ordersManagementPage,
  breadcrumb,
  common,
  filters,
  generalIndustriesPage,
  generalIndustryGroup,
  generalPanelPage,
  loginPage,
  newIndustry,
  newIndustryGroup,
  newUser,
  propertiesPage,
  providerDetailsPage,
  recoveryPassPage,
  tableComponent,
  traceabilityPanelPage,
  traceabilityProviderPage,
  traceabilityRetailPage,
  traceabilityWeavingPage,
  traceabilityWiringPage,
  generalUserPage,
  orderDetails,
  notFoundPage,
  accordionDetailsContent,
  leadTimePage,
  fabricsPage,
  downloadReport
}
