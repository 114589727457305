import { DrawerOpen } from '../../types/Sidebar.types'

export const types = Object.freeze({
  SET_DRAWER: '@sidebar/setDrawer',
  SET_PERSISTENT: '@sidebar/setPersistent'
})

export function setDrawer({ drawerOpen }: Pick<DrawerOpen, 'drawerOpen'>) {
  return {
    type: types.SET_DRAWER,
    payload: { drawerOpen }
  }
}

export function setPersistent({ persistent }: { persistent: boolean }) {
  return {
    type: types.SET_PERSISTENT,
    payload: { persistent }
  }
}
