import { Grid } from '@material-ui/core'
import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -0.5rem;
`

export const GridFields = styled(Grid)`
  display: flex;
  margin: -16px;
  width: calc(100% + 24px);
  flex-direction: row;
  margin-bottom: 0;
`

export const GridInput = styled(Grid)``

export const Remove = styled.div`
  align-self: flex-end;
  margin-right: 0.8rem;
  margin-bottom: 0.4rem;
  height: 2.8rem;
`

export const Removed = styled.label`
  display: flex;
  align-items: center;

  p {
    font-size: 1.4rem;
    color: ${({ theme }) => theme.colors.error};
    cursor: pointer;
  }
`
