import * as S from './styled'

export type Props = {
  icon: string
  height?: number
  width?: number
  fill?: 'primary' | 'secondary' | 'red' | 'success' | 'white'
  className?: string
  cursorPointer?: boolean
}

export function Icon({
  icon,
  className = '',
  fill = 'primary',
  height = 48,
  width = 48,
  cursorPointer = false
}: Props) {
  return (
    <S.Icon
      cursor={cursorPointer ? 'pointer' : 'default'}
      fill={fill}
      className={className}
      height={height}
      preserveAspectRatio="xMidYMid meet"
      src={`/icons/${icon}.svg`}
      width={width}
    />
  )
}
