import { UserStorageService } from 'services'

function checkCardPermissions() {
  // Comentando esses componentes por decisão de negócio que no momento, não será necessário
  // Mantendo código para futuras necessidades e revisões
  const cardItems = () => [
    /*   {
    description: 'Verifique se o fardo possui certificação',
    icon: 'breadcrumbs-bale',
    link: 'consulta/fardo',
    title: 'Fardo'
  }, */
    // {
    //   description: 'consultProductionData',
    //   icon: 'breadcrumbs-spinning',
    //   link: 'rastreabilidade/fiacao',
    //   title: 'wiring'
    // },
    // {
    //   description: 'consultProductionData',
    //   icon: 'breadcrumbs-weaving',
    //   link: 'rastreabilidade/tecelagem',
    //   title: 'weaving'
    // },
    {
      description: 'consultProductionData',
      icon: 'breadcrumbs-confection',
      link: 'rastreabilidade/fornecedor',
      title: 'provider'
    },
    {
      description: 'filterAndAnalyzeTraceability',
      icon: 'breadcrumbs-retail',
      link: 'rastreabilidade/varejo',
      title: 'retail'
    }
  ]

  const isAuthenticated = UserStorageService.isAuthenticated()
  const _cardItems = []

  if (isAuthenticated) {
    const {
      profile: { permissions }
    } = UserStorageService.getUserData()

    if (permissions.provider) {
      _cardItems.push(cardItems().find(item => item.title === 'provider'))
    }
    if (permissions.retail) {
      _cardItems.push(cardItems().find(item => item.title === 'retail'))
    }

    return _cardItems
  }
}

export const CardItems = () => checkCardPermissions()
