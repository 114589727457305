import { useTranslation } from 'react-i18next'

import { omit } from 'lodash'

import { IconButton } from '@material-ui/core'
import { Icon, Input, InputCpfCnpj, InputDate, InputPhone } from 'components'

import * as S from './styled'

type CertificationType = {
  id?: string
  name?: string
  acronym?: string
  number?: string
  issuanceDate?: Date | null
  expirationDate?: Date | null
  cnpjCert?: string
  corporateName?: string
  certifierEmail?: string
  certifierPhone?: string
}

export type CertificationFormProps = {
  errors: any
  index: number
  payload: CertificationType
  removeButton: boolean
  onFill: (_key, _val, _index) => void
  onRemove: (_index) => void
}

// function handleInputData(value: string | Date, name: string) {
//   setErrors({ ...errors, [name]: '' })
//   setFilter({ ...filter, [name]: value })
// }

export function CertificationForm({
  index,
  errors,
  payload,
  removeButton,
  onFill,
  onRemove
}: CertificationFormProps) {
  const { t } = useTranslation()

  function err(name: string) {
    const everyFieldIsEmpty = Object.values(omit(payload, 'id')).every(
      value => !value
    )

    if (!everyFieldIsEmpty) {
      const { certifications } = errors
      return certifications
        ? certifications[index][name]
        : errors[`certifications[${index}].${name}`]
    }
  }

  return (
    <S.Wrapper>
      <S.Remove>
        {removeButton && (
          <S.Removed onClick={onRemove}>
            <p>{t('common:Delete')}</p>
            <IconButton
              style={{ backgroundColor: '#ffffff' }}
              aria-label="delete picture"
              component="span"
            >
              <Icon icon="delete-image" height={16} width={16} fill="red" />
            </IconButton>
          </S.Removed>
        )}
      </S.Remove>
      <S.GridFields container spacing={2}>
        <S.GridInput item sm={12} md={4}>
          <Input
            fullWidth
            label={t('newIndustry:certifications:certificationName')}
            value={payload.name}
            error={Boolean(err('name'))}
            helperText={err('name')}
            onInput={value => onFill('name', value, index)}
          />
        </S.GridInput>
        <S.GridInput item sm={12} md={4}>
          <Input
            fullWidth
            label={t('newIndustry:certifications:acronymCertification')}
            value={payload.acronym}
            error={Boolean(err('acronym'))}
            helperText={err('acronym')}
            onInput={value => onFill('acronym', value, index)}
          />
        </S.GridInput>
        <S.GridInput item sm={12} md={4}>
          <Input
            fullWidth
            label={t('newIndustry:certifications:certificationNumber')}
            value={payload.number}
            error={Boolean(err('number'))}
            helperText={err('number')}
            onInput={value => onFill('number', value, index)}
          />
        </S.GridInput>
        <S.GridInput item sm={12} md={4}>
          <InputDate
            fullWidth
            label={t('newIndustry:certifications:issuanceDate')}
            value={payload.issuanceDate}
            error={Boolean(err('issuanceDate'))}
            helperText={err('issuanceDate')}
            onInput={value => onFill('issuanceDate', value, index)}
          />
        </S.GridInput>
        <S.GridInput item sm={12} md={4}>
          <InputDate
            fullWidth
            label={t('newIndustry:certifications:expirationDate')}
            value={payload.expirationDate}
            error={Boolean(err('expirationDate'))}
            helperText={err('expirationDate')}
            onInput={value => onFill('expirationDate', value, index)}
          />
        </S.GridInput>
        <S.GridInput item sm={12} md={4}>
          <InputCpfCnpj
            fullWidth
            label={t('newIndustry:certifications:certifyingDocument')}
            value={payload.cnpjCert}
            error={Boolean(err('cnpjCert'))}
            helperText={err('cnpjCert')}
            type="cnpj"
            onInput={value => onFill('cnpjCert', value, index)}
          />
        </S.GridInput>
        <S.GridInput item sm={12} md={4}>
          <Input
            fullWidth
            label={t('newIndustry:certifications:certifyingCompanyName')}
            value={payload.corporateName}
            error={Boolean(err('corporateName'))}
            helperText={err('corporateName')}
            onInput={value => onFill('corporateName', value, index)}
          />
        </S.GridInput>
        <S.GridInput item sm={12} md={4}>
          <Input
            fullWidth
            label={t('newIndustry:certifications:certifierEmail')}
            value={payload.certifierEmail}
            error={Boolean(err('certifierEmail'))}
            helperText={err('certifierEmail')}
            onInput={value => onFill('certifierEmail', value, index)}
          />
        </S.GridInput>
        <S.GridInput item sm={12} md={4}>
          <InputPhone
            fullWidth
            label={t('newIndustry:certifications:certifierPhone')}
            value={payload.certifierPhone}
            error={Boolean(err('certifierPhone'))}
            helperText={err('certifierPhone')}
            onInput={value => onFill('certifierPhone', value, index)}
          />
        </S.GridInput>
      </S.GridFields>
    </S.Wrapper>
  )
}
