import { Grid, Switch } from '@material-ui/core'
import styled from 'styled-components'

export const FullGrid = styled(Grid)`
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 5px 25px #0000000d;
  border-radius: 0 1.2rem 1.2rem;
  padding: 2.4rem 2rem 2.4rem 2rem;
  margin-top: 4.8rem;
  position: relative;
  flex-wrap: nowrap;
  column-gap: 2rem;

  &::before {
    content: 'Permissões';
    font-size: 1.4rem;
    width: 170px;
    height: 27px;
    position: absolute;
    top: -2.7rem;
    left: 0;
    background-color: ${({ theme }) => theme.colors.primary};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1.2rem 1.2rem 0 0;
    color: ${({ theme }) => theme.colors.white};
  }

  .MuiGrid-spacing-xs-2 > .MuiGrid-item {
    padding: 0.4rem;
  }

  @media (max-width: 600px) {
    flex-wrap: wrap;
  }
`

export const GridHeader = styled(Grid)`
  display: inline-flexbox;
  padding: 0.5rem 0 0.7rem 0;
`

export const GridFilter = styled(Grid)`
  display: flex;
  margin: 0;
  width: 100%;
  flex-direction: row;
  column-gap: 8rem;
`

export const GridTitle = styled(Grid)`
  display: block;
  padding: 0.5rem 0 1rem 0;
`

export const BoxTitle = styled.p`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1.4rem;
  font-weight: 600;
  padding-bottom: 1rem;
`

export const PermissionOption = styled(Grid)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2.4rem 0;
  font-weight: bold;
  font-size: 1.4rem;

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.rowTable};
  }

  > div {
    display: flex;
    align-items: center;
  }

  svg {
    width: 3.2rem;
    height: 3.2rem;
    margin-right: 1.6rem;
    fill: ${({ theme }) => theme.colors.secondary};
    color: ${({ theme }) => theme.colors.secondary};
  }

  @media (min-width: 960px) {
    &:nth-child(7n-1) {
      border-bottom: none;
    }
  }
`

export const SwitchButton = styled(Switch).attrs(() => ({
  classes: {
    root: 'root',
    switchBase: 'switchBase',
    thumb: 'thumb',
    track: 'track',
    checked: 'checked',
    focusVisible: 'focusVisible'
  },
  disableRipple: true,
  focusVisibleClassName: 'focusVisible'
}))`
  &.root {
    width: 3.8rem;
    height: 2.2rem;
    padding: 0;
    margin: 0.8rem;
  }

  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  .switchBase {
    padding: 0.1rem;

    &.checked {
      transform: translateX(1.6rem);
      color: white;
      & + .track {
        background-color: ${({ theme }) => theme.colors.secondary};
        opacity: 1;
        border: none;
      }
    }

    &.focusVisible &.thumb {
      color: ${({ theme }) => theme.colors.secondary};
      border: 6px solid #fff;
    }
  }

  .thumb {
    width: 2rem;
    height: 2rem;
  }

  & .track {
    border-radius: 13px;
    border: 1px solid #bdbdbd;
    background-color: ${({ theme }) => theme.colors.lightGray};
    opacity: 1;
    transition: background-color 300ms ease-in-out;
  }

  .checked {
  }
  .focusVisible {
  }
`

export const DividerPermissions = styled.div`
  width: 2px;
  margin: 0 2.4rem !important;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.rowTable};
  margin: 2.4rem 0;
`
