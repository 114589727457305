import styled from 'styled-components'
import { Grid } from '@material-ui/core'
import { IconCard } from 'components/IconCard'

export const GridContainer = styled(Grid)`
  margin-bottom: 4rem;
`
export const GridIconCard = styled(Grid)``

export const IconCardColor = styled(IconCard)``
