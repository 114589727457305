import { AccordionDetailsContentInfo } from 'components/AccordionDetailsContentInfo/AccordionDetailsContentInfo'

import * as S from './styled'

type AccordionDetailsContentProps = {
  tag: string
  data: any
}
export function AccordionDetailsContent({
  tag,
  data
}: AccordionDetailsContentProps) {
  const dataSuppliers = {
    cnpj: data.supplierCnpj,
    country: data.supplierCountry,
    orders: data.orders,
    corporateName: data.supplierName,
    city: data.supplierCity,
    state: data.supplierState,
    volumes: data.volumes
  }

  const dataSubcontractors = {
    cnpj: data.outsourcedCnpj,
    country: data.outsourcedCountry,
    orders: data.orders,
    corporateName: data.outsourcedName,
    city: data.outsourcedCity,
    state: data.outsourcedState,
    volumes: data.volumes
  }

  function contentVariation() {
    const AccordionDetails = {
      providers: () => <AccordionDetailsContentInfo data={dataSuppliers} />,
      subProviders: () => (
        <AccordionDetailsContentInfo data={dataSubcontractors} />
      ),
      default: () => null
    }
    const Component = AccordionDetails[tag]
    return <Component />
  }

  return <S.Container>{contentVariation()}</S.Container>
}
