import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Base } from 'layouts'

import { CardItems } from './CardItems'

import * as S from './styled'

export function OrderManagement() {
  const { t } = useTranslation()

  return (
    <Base>
      <S.GridContainer data-testid="cards-searchpanel" container spacing={4}>
        {CardItems.map((item, index) => (
          <S.GridIconCard key={index} item xs={12} md={6}>
            <Link to={item.link}>
              <S.IconCardColor
                title={t(`ordersManagementPage:cards:${item.title}`)}
                description={t(
                  `ordersManagementPage:cards:${item.description}`
                )}
                icon={item.icon}
              />
            </Link>
          </S.GridIconCard>
        ))}
      </S.GridContainer>
    </Base>
  )
}
