import { useState, useEffect, useContext } from 'react'
import { TraceabilityRetailFiltersContext } from 'contexts/traceabilityRetailTableFilters/traceabilityRetailTableFilters.context'
import { TraceabilityService } from 'services'
import { Table } from '../Tables'
import * as S from './styled'
import { Loading, ReportButton } from 'components'
import { ReportModal } from 'components/Traceability/Orders/ReportModal'
import { useTranslation } from 'react-i18next'

export function TabOrdersComponent({ loading }) {
  const [loadingTable, setLoadingTable] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const { i18n } = useTranslation()
  const [ordersRows, setOrdersRows] = useState({
    items: [],
    pagination: {
      page: 0,
      totalItems: 0,
      limit: 0,
      totalPages: 0
    }
  })

  const { traceabilityRetailsFilters } = useContext(
    TraceabilityRetailFiltersContext
  )

  const getOrdersTableFromApi = async (isExport = false) => {
    if (loadingTable) return
    setLoadingTable(true)
    const service = new TraceabilityService()
    const _filters = { ...traceabilityRetailsFilters }
    delete _filters.startDate
    delete _filters.endDate
    delete _filters.dateType
    delete _filters.searchNameAndCnpjIn
    delete _filters.cnpj
    delete _filters.name

    if (
      traceabilityRetailsFilters.companyGroups &&
      traceabilityRetailsFilters.companyGroups.length
    ) {
      _filters.companyGroups = JSON.stringify(
        traceabilityRetailsFilters.companyGroups.map(item => item.id)
      )
    }

    if (isExport) {
      await service.exportRetailReportData({
        ..._filters,
        language: i18n.language
      })
      setLoadingTable(false)
      return
    }

    await service.getOrdersTable({ ..._filters }).then(({ data }: any) => {
      setOrdersRows(data)
    })
    setLoadingTable(false)
  }

  useEffect(() => {
    if (!loadingTable) getOrdersTableFromApi()
  }, [traceabilityRetailsFilters])

  async function handleExportReport() {
    setIsOpen(true)
    await getOrdersTableFromApi(true)
  }

  function onExit() {
    setIsOpen(false)
  }

  return (
    <S.Container loading={loading}>
      <S.Box>
        <S.BoxTitle>{'Pedidos'}</S.BoxTitle>
        <S.BoxLine />
      </S.Box>

      <ReportButton onClick={handleExportReport} />
      {isOpen && <ReportModal onClickExport={onExit} />}
      {loadingTable ? (
        <Loading />
      ) : (
        <Table rows={ordersRows} hasActions={false} variation={'orders'} />
      )}
    </S.Container>
  )
}
