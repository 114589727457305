import { InputHTMLAttributes, useEffect, useState } from 'react'
import * as S from './styled'

export type CheckboxProps = {
  isChecked?: boolean
  label: string
  labelFor: string
  value?: string | ReadonlyArray<string> | number
  onCheck?: (_status: boolean) => void
} & InputHTMLAttributes<HTMLInputElement>

export function Checkbox({
  isChecked = false,
  label,
  labelFor,
  value,
  onCheck,
  ...props
}: CheckboxProps) {
  const [checked, setChecked] = useState(isChecked)

  const onChange = () => {
    const status = !checked
    setChecked(status)

    !!onCheck && onCheck(status)
  }

  useEffect(() => {
    setChecked(isChecked)
  }, [isChecked])

  return (
    <S.Wrapper>
      <S.Input
        id={labelFor}
        type="checkbox"
        onChange={onChange}
        checked={checked}
        value={value}
        {...props}
      />
      <S.Label htmlFor={labelFor}>{label}</S.Label>
    </S.Wrapper>
  )
}
