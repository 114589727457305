import { AccordionDetails } from 'components'
import styled from 'styled-components'

export const SummaryWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
  margin-bottom: 3rem;

  @media screen and (min-width: 768px) {
    margin-top: -3rem;
  }
`

export const SummaryContainer = styled.div`
  width: 100%;
  height: fit-content;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(40rem, 1fr));
  align-items: baseline;
  gap: 2rem;
  margin-bottom: 2.5rem;

  @media (max-width: 1795px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 875px) {
    grid-template-columns: 1fr;
    margin-top: 0rem;
  }
`

export const SummaryDetails = styled.div`
  margin-top: 4rem;
`

export const AccordionDetailsComponent = styled(AccordionDetails)`
  height: 10rem;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 1.2rem;
  background-color: ${({ theme }) => theme.colors.white};
  margin-bottom: 1.4rem !important;
  width: 100%;
`
