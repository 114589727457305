import { call, put } from 'redux-saga/effects'

import { toasts } from 'utils/toasts'

import { LanguageService } from 'services'

import * as actions from './language.actions'

export function* getAllLanguages() {
  yield put(actions.setLanguage({ languages: [] }))

  const $city = new LanguageService()
  const {
    success,
    status,
    data: languages
  } = yield call($city.getAllLang.bind($city))

  if (status === 404) {
    return yield put(
      actions.setLanguage({
        languages: []
      })
    )
  } else if (success) {
    return yield put(actions.setLanguage({ languages }))
  } else toasts.generalFail()
}
