import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const CounterItem = styled.div<{ isClickAction: boolean }>`
  background-color: #315ca7;
  border-radius: 50%;
  color: white;
  font-size: 1.2rem;
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;

  ${({ isClickAction }) => isClickAction && 'cursor: pointer;'}
`
