import { Tabs, Tab, Grid } from '@material-ui/core'
import styled, { css } from 'styled-components'

type TabProps = {
  isActive: boolean
  hasOnlyOneItem: boolean
  changeBackgroundForWhite: boolean
  changeCursorPointerToBlock: boolean
}

export const Container = styled(Grid)`
  background-color: ${({ theme }) => theme.colors.white};
  height: 100%;
  width: 100%;
  font-size: 1.6rem;
  box-shadow: 0px 5px 25px #0000000d;
  border-radius: 0 1.2rem 1.2rem;
  padding: 2.4rem 2rem 2.4rem 2rem;

  .MuiGrid-spacing-xs-2 > .MuiGrid-item {
    padding: 0.4rem;
  }

  @media (max-width: 600px) {
    flex-wrap: wrap;
  }
`

export const TabsContainer = styled(Tabs)`
  .MuiTabs-flexContainer {
    height: 3rem !important;
  }

  &.MuiTabs-root {
    min-height: 2.7rem;
  }
`

export const TabItem = styled(Tab)<TabProps>`
  background-color: ${({ theme, changeBackgroundForWhite }) =>
    changeBackgroundForWhite ? theme.colors.white : theme.colors.footer};
  width: 170px;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.lightGray};
  text-transform: capitalize;
  cursor: ${({ changeCursorPointerToBlock }) =>
    changeCursorPointerToBlock ? 'pointer' : 'not-allowed'};

  &:first-child {
    border-radius: 1.2rem 0 0 0;
  }
  &:last-child {
    border-radius: 0 1.2rem 0 0;
  }

  &.MuiButtonBase-root.MuiTab-root {
    min-height: 3rem;
  }

  &.MuiTab-textColorInherit {
    opacity: 1;
  }
  .MuiTabs-indicator {
    display: none !important;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      &.MuiButtonBase-root {
        background: ${({ theme }) => theme.colors.primary};
        color: ${({ theme }) => theme.colors.white};
      }
    `}

  ${({ hasOnlyOneItem }) =>
    !hasOnlyOneItem &&
    css`
      &:first-child {
        border-radius: 1.2rem 1.2rem 0 0;
      }
    `}
`
