import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding-bottom: 0.05rem;
  display: flow-root;
  width: 100%;
  margin-top: 1.8rem;

  #table-scrolled {
    td,
    tr,
    th {
      cursor: text;
    }
    td:first-child,
    th:first-child {
      border-left: 0px;
    }

    td:last-child,
    th:last-child {
      border-right: 0px;
    }
  }
`

export const ScrollWrapper = styled.div`
  display: grid;
  overflow-x: auto;
  width: 100%;

  ::-webkit-scrollbar {
    height: 0.4rem;
    width: 1.2rem;
  }
  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.background};
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.gray.luminance(0.5)};
    border-radius: 2rem;
  }
`

export const Table = styled.table`
  border-collapse: collapse;
  font-family: Arial, Helvetica, sans-serif;
  table-layout: auto;
  margin-bottom: auto;
`

export const Header = styled.th<{
  textAlign?: 'left' | 'center'
}>`
  border: 1px solid #ddd;
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: 1.4rem;
  font-weight: 600;
  padding: 1.1rem 1.6rem;
  white-space: nowrap;
  text-align: ${props => props.textAlign || 'left'};
  vertical-align: middle;
  line-height: 1.6rem;

  &.center {
    text-align: center;
  }

  &.right {
    text-align: right;
  }
`

export const AllItens = styled.button`
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.white};
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 100%;
  font-size: 1.3rem;
  background-color: ${({ theme }) => theme.colors.primary};
  margin-left: 1rem;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.2);
  }
`

export const Line = styled.tr`
  height: 4rem;
  :nth-child(even) {
    background-color: ${({ theme }) => theme.colors.rowTable};
  }
`

export const Column = styled.td<{
  textAlign?: 'left' | 'center'
  minWidth?: number | string
}>`
  border: 1px solid #ddd;
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: 1.4rem;
  padding: 0 1.6rem;
  white-space: nowrap;
  text-align: ${props => props.textAlign || 'left'};
  vertical-align: middle;
  line-height: 1.6rem;
  ${props => props.minWidth && css({ minWidth: props.minWidth })}

  &.center {
    text-align: center;
    margin: 0 auto;
  }

  &.right {
    text-align: right;
  }

  &#orderSummary div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &#actions-column {
    padding: 0.6rem 0 0.6rem 0;
    div {
      margin: auto;
    }
    button {
      display: block;
      margin: auto;
    }
  }
`
