import { Input } from 'components/Input'
import { InputCpfCnpj } from 'components/InputCpfCnpj'
import { InputPhone } from 'components/InputPhone'

import * as S from './styled'

export type BasicInformationProps = {
  form?: any
  editableForm?: any
  onChange?: (_value: string, _name: string) => void
}

export function BasicInformation({ form, onChange }: BasicInformationProps) {
  return (
    <S.GridFields container spacing={2}>
      <S.GridInput item sm={12} md={6}>
        <Input
          fullWidth
          label="Primeiro nome"
          value={form.firstName}
          onInput={value => onChange(value, 'firstName')}
        />
      </S.GridInput>
      <S.GridInput item sm={12} md={6}>
        <Input
          fullWidth
          label="Sobrenome"
          value={form.lastName}
          onInput={value => onChange(value, 'lastName')}
        />
      </S.GridInput>
      <S.GridInput item sm={12} md={6}>
        <Input fullWidth disabled label="E-mail" value={form.email} />
      </S.GridInput>
      <S.GridInput item sm={12} md={6}>
        <InputCpfCnpj
          fullWidth
          disabled
          label="CPF"
          value={form.CPF}
          type="cpf"
        />
      </S.GridInput>
      <S.GridInput item sm={12} md={6}>
        <Input
          fullWidth
          label="E-mail de recuperação de senha"
          value={form.emailRecovery}
          onInput={value => onChange(value, 'emailRecovery')}
        />
      </S.GridInput>
      <S.GridInput item sm={12} md={6}>
        <InputPhone
          fullWidth
          label="Celular"
          value={form.phone}
          onInput={value => onChange(value, 'phone')}
        />
      </S.GridInput>
      <S.GridInput item sm={12} md={6}>
        <InputPhone
          fullWidth
          label="Telefone"
          value={form.phoneRecovery}
          onInput={value => onChange(value, 'phoneRecovery')}
        />
      </S.GridInput>
      <S.GridInput item sm={12} md={6}>
        <Input
          fullWidth
          disabled
          label="Departamento"
          value={form.department}
        />
      </S.GridInput>
      <S.GridInput item sm={12} md={6}>
        <Input fullWidth disabled label="Tipo de usuário" value={form.role} />
      </S.GridInput>
    </S.GridFields>
  )
}
