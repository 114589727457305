import styled, { keyframes } from 'styled-components'

const fade = keyframes`
  from {
    opacity: 0.7;
  }

  to {
    opacity: 0.15;
  }
`

export const ChartContainer = styled.div`
  position: relative;
  box-shadow: 4px 10px 20px #00000029;
  background: #fff;
  border-radius: 1.2rem;
`

export const Radio = styled.div`
  position: absolute;
  right: 0.5rem;
  bottom: 1.2rem;

  > div {
    display: flex;
    flex-direction: column;
  }
`

export const Legend = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 600;
  padding-bottom: 2rem;
  margin-left: 5rem;
`

export const ColorLegend = styled.span`
  display: block;
  width: 1.2rem;
  height: 1.2rem;
  background-color: ${({ color }) => `${color}`};
  margin-right: 1rem;
`

export const BarChartLoadingContainer = styled.div`
  width: 100%;
  position: absolute;
  height: 275px;
  display: flex;
  justify-content: center;
  align-content: center;
  margin-top: -275px;
`

export const LoadingTextContainer = styled.div<{ isLoading?: boolean }>`
  width: 100%;
  height: 275px;
  background-color: #fff;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  animation: 1.5s ${fade} alternate infinite;
`

export const LoadingText = styled.span`
  font-size: 2rem;
  font-weight: 900;
  text-align: center;
  height: auto;
  width: 100%;
`
