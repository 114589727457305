import { UserStorageService } from 'services'

export const CardItems = [
  {
    description: 'ordersDescription',
    icon: 'card-api',
    link: 'gestao-pedidos/pedidos',
    title: 'orders'
  }
  // {
  //   description: 'leadTimeDescription',
  //   icon: 'hourglass',
  //   link: 'gestao-pedidos/lead-time',
  //   title: 'leadTime'
  // }
]

const isAuthenticated = UserStorageService.isAuthenticated()

if (isAuthenticated) {
  const {
    profile: { permissions }
  } = UserStorageService.getUserData()

  if (permissions.ordersManagement) {
    if (permissions.leadTime) {
      CardItems.splice(2, 2)
    } else {
      CardItems.splice(1, 1)
    }
  }
}
