import { useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { format } from 'date-fns'

import { cnpjMask } from 'utils/CnpjMask'

import { ordersTable } from '../../../allOrdersManagement.constants'
import { ModalTableOrderItems } from '../ModalTableOrderItems'
import { OrderTable, OrderTableProps } from '../../../allOrdersManagement.types'

import { Icon, Table } from 'components'
import { TooltipComponent } from 'components/Tooltip'
import { ModalTable } from 'components/ModalOpenTable/ModalTable'

import * as S from './styles'

export function OrdersTable({ rows }: OrderTableProps) {
  const { t } = useTranslation()

  const [orderNumber, setOrderNumber] = useState('')
  const [openModal, setOpenModal] = useState(false)

  function actionTable(orderItemParam?: OrderTable) {
    return (
      <S.Nav>
        <TooltipComponent
          text={t(
            'ordersManagementPage:tables:ordersTable:actions:orderDetails'
          )}
        >
          <S.Link
            onClick={() => {
              setOrderNumber(orderItemParam.orderNumber)
              setOpenModal(true)
            }}
          >
            <Icon
              icon="details"
              height={24}
              width={24}
              fill="success"
              cursorPointer
            />
          </S.Link>
        </TooltipComponent>
        <Link
          to={`/gestao-pedidos/detalhes-pedido/${orderItemParam.orderNumber}`}
        >
          <TooltipComponent
            text={t(
              'ordersManagementPage:tables:ordersTable:actions:manageOrder'
            )}
          >
            <S.Link>
              <Icon
                icon="breadcrumbs-order-summary"
                height={24}
                width={24}
                fill="success"
                cursorPointer
              />
            </S.Link>
          </TooltipComponent>
        </Link>
        <Link to={`/gestao-pedidos/lead-time/${orderItemParam.orderNumber}`}>
          <TooltipComponent
            text={t('ordersManagementPage:tables:ordersTable:actions:leadTime')}
          >
            <S.Link>
              <Icon
                icon="hourglass"
                height={24}
                width={24}
                fill="success"
                cursorPointer
              />
            </S.Link>
          </TooltipComponent>
        </Link>
      </S.Nav>
    )
  }
  return (
    <>
      {openModal ? (
        <ModalTable
          content={<ModalTableOrderItems orderNumber={orderNumber} />}
          titleTable={'Itens do Pedido'}
          onExit={() => setOpenModal(false)}
        />
      ) : null}
      <Table
        rows={rows.map(item => ({
          ...item,
          key: JSON.stringify({ id: item.orderID, number: item.orderNumber }),
          orderDate: format(new Date(item.orderDate), 'dd/MM/yyyy'),
          previousDeliveryDate: format(
            new Date(item.previousDeliveryDate),
            'dd/MM/yyyy'
          ),
          supplierAndOutsourcedCnpj: item.supplierAndOutsourcedCnpj
            ? cnpjMask(item.supplierAndOutsourcedCnpj)
            : null
        }))}
        columns={ordersTable()}
        actionTable={actionTable}
        statusBoxColumnName="orderStatus"
        situationBoxColumnName="orderSituation"
      />
    </>
  )
}
