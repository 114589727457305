import { Base } from 'layouts'

import { GeneralDataTable } from './Components/Tables/GeneralDataTable'
import { FabricsTable } from './Components/Tables/FabricsTable/FabricsTable'

export function Fabrics() {
  return (
    <Base>
      <GeneralDataTable />
      <FabricsTable />
    </Base>
  )
}
