import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { UserStorageService } from 'services'

import { IconCard } from 'components/IconCard'

import * as S from './styled'

const links = {
  consulta: '/consulta',
  geral: '/geral',
  rastreabilidade: '/rastreabilidade',
  cadeiaProdutiva: '/cadeia-produtiva',
  gestaoPedidos: '/gestao-pedidos'
}

export function Cards() {
  const { t } = useTranslation()
  const {
    user,
    profile: { permissions }
  } = UserStorageService.getUserData()

  // Inativando essa função por decisão de negócio que no momento, não será necessário
  // Mantendo código para futuras necessidades e revisões
  function _Permission() {
    const {
      profile: { permissions }
    } = UserStorageService.getUserData()

    if (permissions.productiveChain) {
      return (
        <S.GridIconCard item xs={12} md={6}>
          <Link to={links.cadeiaProdutiva}>
            <IconCard
              title={t('generalPanelPage:productionChain')}
              description={t('generalPanelPage:productionChainDescription')}
              icon="breadcrumbs-production-chain"
            />
          </Link>
        </S.GridIconCard>
      )
    } else {
      return null
    }
  }

  function OrdersManagementPermission() {
    if (permissions.ordersManagement || permissions.leadTime) {
      return (
        <S.GridIconCard item xs={12} md={6}>
          <Link to={links.gestaoPedidos}>
            <IconCard
              title={t('generalPanelPage:orderManagement')}
              description={t('generalPanelPage:orderManagementDescription')}
              icon="breadcrumbs-order-summary"
            />
          </Link>
        </S.GridIconCard>
      )
    } else {
      return null
    }
  }

  return (
    <S.GridContainer data-testid="cards" container spacing={4}>
      {user.role !== 'visual' && (
        <S.GridIconCard item xs={12} md={6}>
          <Link to={links.geral}>
            <IconCard
              title={t('generalPanelPage:generalPanelCard')}
              description={t('generalPanelPage:generalPanelCardDescription')}
              icon="card-panel"
            />
          </Link>
        </S.GridIconCard>
      )}
      <S.GridIconCard item xs={12} md={6}>
        <Link to={links.rastreabilidade}>
          <IconCard
            title={t('generalPanelPage:traceabilityPanelCard')}
            description={t('generalPanelPage:traceabilityPanelCardDescription')}
            icon="card-tracing"
          />
        </Link>
      </S.GridIconCard>

      {/* <Permission /> */}

      {<OrdersManagementPermission />}

      {/* <S.GridIconCard item xs={12} md={6}>
        <Link to={links.consulta}>
          <IconCard
            title="Painel de Consulta"
            description="Consultar certificação"
            icon="card-cotton"
          />
        </Link>
      </S.GridIconCard> */}
    </S.GridContainer>
  )
}
