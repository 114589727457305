import styled from 'styled-components'

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 2.4rem;
  width: 100%;
`

export const Spinner = styled.div`
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }

  animation: spin infinite linear 1s;
  border: 0.64rem solid ${({ theme }) => theme.colors.primary.alpha(0.16)};
  border-bottom-color: ${({ theme }) => theme.colors.primary};
  border-radius: 50%;
  height: 4rem;
  width: 4rem;
`
