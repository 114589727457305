import styled from 'styled-components'
import { Grid } from '@material-ui/core'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  white-space: normal;
`

export const GridHeader = styled(Grid)`
  display: inline-flexbox;
  margin-bottom: 2.4rem;
`

export const GridTitle = styled(Grid)`
  display: block;
`

export const BoxTitle = styled.p`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1.8rem;
  font-weight: 600;
`

export const Line = styled.div`
  width: auto;
  height: 0.2rem;
  background-color: #f2f3f7;
`

export const Title = styled.div`
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: 1.6rem;
  margin-bottom: 1rem;
  margin-top: 2.4rem;
`

export const GridFilter = styled(Grid)`
  display: flex;
  margin: 0 -0.8rem;
  width: calc(100% + 16px);
  flex-direction: row;
`
export const GridInput = styled(Grid)``

export const GridButtons = styled(Grid)`
  align-self: center;
  text-align: end;
  button + button {
    margin-left: 0.8rem;
  }
`

export const GridTable = styled.div`
  margin-top: 1.6rem;
  max-height: 25rem;
  overflow-y: auto;

  ::-webkit-scrollbar {
    height: 1.2rem;
    width: 0.4rem;
  }
  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.background};
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.gray.luminance(0.5)};
    border-radius: 2rem;
  }

  @media screen and (max-height: 750px) {
    max-height: 15rem;
  }
`
