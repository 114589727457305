import { useContext } from 'react'

import { ProvidersTable } from './ProvidersTable/Provider'
import { SupplierFiltersContext } from 'contexts/supplierFilters/supplierFilters.context'
import ProductiveChainTraceabilityFilter from 'contexts/productiveChain/productiveChainTraceabilityFilter.context'

import { onFilterPaginate } from 'utils/OnFilterPaginate'

import { Pagination } from 'components'

import * as S from './styled'

export type TableProps = {
  rows: {
    items: any[]
    pagination: {
      totalItems: number
      page: number
      limit: number
    }
  }
  variation: string
  hasActions?: boolean
}

export function Table({ rows, variation }: TableProps) {
  const { supplierFilters, setSupplierFilters } = useContext(
    SupplierFiltersContext
  )

  function handleTableBody() {
    const fixedTable = {
      providers: () => <ProvidersTable rows={rows.items} />,
      default: () => null
    }

    const Component = fixedTable[variation] || fixedTable.default
    return <Component />
  }

  function handlePaginationFilter({ limit, page }) {
    setSupplierFilters({
      ...supplierFilters,
      ...onFilterPaginate(supplierFilters, limit, page)
    })
  }

  return (
    <>
      <ProductiveChainTraceabilityFilter>
        <S.Wrapper>{handleTableBody()}</S.Wrapper>
        <Pagination
          totalItems={rows.pagination.totalItems}
          page={rows.pagination.page}
          limit={rows.pagination.limit}
          setFilter={handlePaginationFilter}
        />
      </ProductiveChainTraceabilityFilter>
    </>
  )
}
