import { subMonths } from 'date-fns'
import { ordersFilterRequestPayload } from 'pages/AllOrdersManagement/allOrdersManagement.constants'
import { createContext, ReactNode, useState } from 'react'

interface IOrdersTableProvider {
  children: ReactNode
}

export interface IOrdersFiltersContext {
  startDate: Date
  endDate: Date
  orderEndDate?: Date | string
  orderStartDate?: Date | string
  predictionStartDate?: Date | string
  predictionEndDate?: Date | string
  dateType: string
  orderNumber: string
  orderStatus: string
  orderSituation: string
  supplierAndOutsourcedCnpj: string
  supplierAndOutsourcedName: string
  activity: string
  expireIn: Date | string
}

interface IOrdersFiltersProps {
  ordersManagementFilters?: Record<string, any>
  setOrdersManagementFilters?: (_filters: Record<string, any>) => void
  clearOrdersManagementFilters?: () => void
}

export const OrdersManagementTableContext =
  createContext<IOrdersFiltersProps>(undefined)

export default function OrdersTableProvider({
  children
}: IOrdersTableProvider) {
  const localStorageFilters = () =>
    localStorage.getItem('ordersManagementFilters')

  const localStorageFiltersParsed = () =>
    localStorageFilters() ? JSON.parse(localStorageFilters()) : null

  const getDateLastMonth = subMonths(new Date(), 1)
  const formattedDate = new Date(
    getDateLastMonth.getFullYear(),
    getDateLastMonth.getMonth(),
    1
  )

  function parseFilterOptions() {
    if (localStorageFiltersParsed()) {
      const startDateKey =
        ordersFilterRequestPayload[localStorageFiltersParsed().dateType]
          .startDate
      const endDateKey =
        ordersFilterRequestPayload[localStorageFiltersParsed().dateType].endDate
      return {
        [startDateKey]: localStorageFiltersParsed()[startDateKey],
        [endDateKey]: localStorageFiltersParsed()[endDateKey]
      }
    }
    return {
      orderStartDate: formattedDate,
      orderEndDate: new Date(),
      startDate: formattedDate,
      endDate: new Date()
    }
  }

  const INITIAL_FILTERS_STATE = () => ({
    supplierAndOutsourcedName:
      localStorageFiltersParsed()?.supplierAndOutsourcedName || '',
    supplierAndOutsourcedCnpj:
      localStorageFiltersParsed()?.supplierAndOutsourcedCnpj || '',
    orderSituation: localStorageFiltersParsed()?.orderSituation || '',
    activity: localStorageFiltersParsed()?.activity || '',
    orderStatus: localStorageFiltersParsed()?.orderStatus || '',
    orderNumber: localStorageFiltersParsed()?.orderNumber || '',
    dateType: localStorageFiltersParsed()?.dateType || 'order',
    endDate: localStorageFiltersParsed()?.orderEndDate || new Date(),
    startDate: localStorageFiltersParsed()?.orderStartDate || formattedDate,
    expireIn: localStorageFiltersParsed()?.expireIn || new Date(),
    ...parseFilterOptions()
  })

  const [ordersManagementFilters, setContextState] =
    useState<IOrdersFiltersContext>(INITIAL_FILTERS_STATE())

  function clearOrdersManagementFilters() {
    localStorage.removeItem('ordersManagementFilters')
    setContextState(INITIAL_FILTERS_STATE())
  }

  function setOrdersManagementFilters(filters: IOrdersFiltersContext) {
    setContextState(filters)
    localStorage.setItem(
      'ordersManagementFilters',
      JSON.stringify({ ...filters, expireIn: new Date() })
    )
  }
  return (
    <OrdersManagementTableContext.Provider
      value={{
        clearOrdersManagementFilters,
        setOrdersManagementFilters,
        ordersManagementFilters
      }}
    >
      {children}
    </OrdersManagementTableContext.Provider>
  )
}
