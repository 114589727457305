import { useState, useEffect } from 'react'
import { useHistory, Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import clsx from 'clsx'
import { debounce } from 'lodash'
import { useTranslation } from 'react-i18next'

import List from '@material-ui/core/List'
import AppBar from '@material-ui/core/AppBar'
import Popover from '@material-ui/core/Popover'
import ListItem from '@material-ui/core/ListItem'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'

import { Icon } from 'components/Icon'
import { Topbar } from 'components/Topbar'
import { ModalChangePassword } from 'components/ModalChangePassword'

import { all } from 'store/Sidebar/Sidebar.selector'
import { setDrawer, setPersistent } from 'store/Sidebar/Sidebar.actions'

import * as S from './styled'

const drawerWidth = 262

const useStyles = makeStyles(theme => ({
  root: { display: 'flex' },
  appBar: {
    width: `calc(100% - ${theme.spacing(9) + 1}px)`,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    boxShadow: '0px 3px 60px #00000017'
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  logoButton: {
    marginRight: 36
  },
  hide: {
    display: 'none'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap'
  },
  drawerOpen: {
    width: drawerWidth,
    background: '#ffffff',
    borderRight: '1px solid #f5f6fa',
    transition: theme.transitions.create('all', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    background: '#ffffff',
    borderRight: '1px solid #f5f6fa',
    transition: theme.transitions.create('all', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1
    }
  },
  toolbar: {
    backgroundColor: '#ffffff',
    boxShadow: '0px 3px 60px #00000017',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: theme.spacing(0, 3),
    ...theme.mixins.toolbar
  },
  content: {
    paddingTop: 100,
    paddingBottom: 0,
    paddingRight: 24,
    background: '#f5f6fa',
    flexGrow: 1,
    minHeight: `calc(100vh - ${4}rem)`,
    overflow: 'hidden'
  },
  contentPermanent: {
    paddingLeft: 22
  },
  contentTemporary: {
    paddingLeft: theme.spacing(7) + 1 + 38
  },
  popover: { pointerEvents: 'none' },
  paper: { padding: theme.spacing(1) }
}))

type ItemProps = {
  id: string
  labelMenu: string
  icon: string
  link: string
  sidebar: boolean
}

export type SidebarProps = {
  pathname: string
  itens: ItemProps[]
}

export function Sidebar({ pathname, itens, children }) {
  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const { drawerOpen, persistent } = useSelector(all)

  const [menu, setMenu] = useState([])
  const [popoverAnchor, setPopoverAnchor] = useState(null)
  const [changePassword, setChangePassword] = useState(false)
  const [variant, setVariant] = useState<'permanent' | 'temporary'>('permanent')

  const debounceMouseEnter = debounce(onMouseEnterDrawer, 400)

  function onExit() {
    setChangePassword(false)
  }

  function setPersistentDrawer() {
    dispatch(setPersistent({ persistent: !persistent }))
    handleDrawer(!persistent)
  }

  function handleDrawer(stateOverwrite: boolean | null = null) {
    const curr = stateOverwrite !== null ? stateOverwrite : !drawerOpen
    dispatch(setDrawer({ drawerOpen: curr }))
  }

  function isActive(value: string) {
    return pathname === value ? 'item-active' : ''
  }

  function handlerLink(link: string) {
    history?.push({ pathname: link })
  }

  function onMouseEnterItem(e) {
    e.stopPropagation()
    const { target } = e
    setPopoverAnchor(target)
  }

  function onMouseLeavesItem(e) {
    e.stopPropagation()
    setPopoverAnchor(null)
  }

  function onMouseEnterDrawer(e) {
    e.stopPropagation()
    if (persistent) return
    handleDrawer(true)
    setVariant('temporary')
  }

  function onMouseLeaveDrawer(e) {
    e.stopPropagation()
    debounceMouseEnter.cancel()
    if (persistent) return
    handleDrawer(false)
    setVariant('permanent')
  }

  function DrawerButtons() {
    return (
      <S.Menu>
        <IconButton
          data-testid="menu"
          color="inherit"
          aria-label="open drawer"
          onClick={setPersistentDrawer}
          edge="start"
        >
          <Icon icon="hamburguer" width={14} height={14} aria-label="menu" />
        </IconButton>
        <S.MenuTitle onClick={setPersistentDrawer}>
          {t('common:menu')}
        </S.MenuTitle>
        <S.LogoWrapper className={drawerOpen ? 'more-small-close' : ''}>
          <Link to="/">
            <S.Title>Tracecotton</S.Title>
          </Link>
        </S.LogoWrapper>
      </S.Menu>
    )
  }

  function IconLogo() {
    if (drawerOpen) {
      return <Icon icon="panel-logo-ecotrace" width={100} />
    } else {
      return (
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={() => handleDrawer()}
          edge="start"
          className={clsx(classes.logoButton, {
            [classes.hide]: drawerOpen
          })}
        >
          <Icon icon="panel-simbolo-ecotrace" width={24} height={24} />
        </IconButton>
      )
    }
  }

  useEffect(() => {
    const menuItems = itens().filter(item => item.sidebar)
    setMenu(menuItems)
  }, [itens])

  return (
    <div data-testid="sidebar" className={classes.root}>
      {changePassword && <ModalChangePassword onExit={onExit} />}
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: drawerOpen
        })}
      >
        <Topbar
          button={<DrawerButtons />}
          setChangePassword={() => setChangePassword(true)}
        />
      </AppBar>
      <S.MenuDrawer
        variant={variant}
        open={Boolean(drawerOpen)}
        anchor="left"
        onMouseEnter={debounceMouseEnter}
        onMouseLeave={onMouseLeaveDrawer}
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: drawerOpen,
          [classes.drawerClose]: !drawerOpen
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: drawerOpen,
            [classes.drawerClose]: !drawerOpen
          })
        }}
      >
        <div className={classes.toolbar}>
          <IconLogo />
        </div>
        <List style={{ paddingTop: 16, paddingBottom: 16 }}>
          {menu.map(item => (
            <div key={item.id} data-testid="redirect">
              <ListItem
                button
                onClick={() => handlerLink(item.link)}
                onMouseEnter={debounce(onMouseEnterItem, 600)}
                onMouseLeave={debounce(onMouseLeavesItem, 1200)}
                style={{ margin: '11px 0', padding: '8px 16px' }}
              >
                <S.ListItemI>
                  <S.IconWrapper
                    data-testid="item-active"
                    className={isActive(item.link)}
                  >
                    <Icon icon={item.icon} width={16} height={16} />
                  </S.IconWrapper>
                </S.ListItemI>
                <S.ListItemT primary={item.labelMenu} />
              </ListItem>
              <S.Line />
            </div>
          ))}
        </List>
      </S.MenuDrawer>
      {Boolean(popoverAnchor) && (
        <Popover
          anchorEl={popoverAnchor}
          anchorOrigin={{ horizontal: 'right', vertical: 'center' }}
          classes={{ paper: classes.paper }}
          className={classes.popover}
          transformOrigin={{ horizontal: 'left', vertical: 'center' }}
          open
          disableRestoreFocus
        >
          {popoverAnchor.innerText}
        </Popover>
      )}
      <main
        className={clsx(classes.content, {
          [classes.contentPermanent]: variant === 'permanent',
          [classes.contentTemporary]: variant === 'temporary'
        })}
      >
        {children}
      </main>
    </div>
  )
}
