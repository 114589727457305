import { HTTPIbge } from 'providers'

export class IBGEService extends HTTPIbge {
  private endpoint = '/localidades'

  public async fetchAllCountry() {
    return this.get({
      endpoint: `${this.endpoint}/paises`,
      params: {
        orderBy: 'nome'
      }
    })
  }

  public async fetchAll() {
    const states = await this.get({
      endpoint: `${this.endpoint}/estados`,
      params: {
        orderBy: 'nome'
      }
    })
    return states
  }

  public async fetchCities() {
    const states = await this.get({
      endpoint: `${this.endpoint}/municipios`,
      params: {
        orderBy: 'nome'
      }
    })
    return states
  }

  public async fetchCitiesByStateId(stateId: string) {
    const states = await this.get({
      endpoint: `${this.endpoint}/estados/${stateId}/municipios`,
      params: {
        orderBy: 'nome'
      }
    })
    return states
  }

  public async fetchStateInfoByStateId(stateId: string) {
    const states = await this.get({
      endpoint: `${this.endpoint}/estados/${stateId}`,
      params: {
        orderBy: 'nome'
      }
    })
    return states
  }

  public async fecthMicroregionByStateId(stateId: string) {
    return this.get({
      endpoint: `${this.endpoint}/estados/${stateId}/microrregioes`,
      params: {
        orderBy: 'nome'
      }
    })
  }

  public async fetchMicroregionInfoById(microregionId: string) {
    return this.get({
      endpoint: `${this.endpoint}/microrregioes/${microregionId}`,
      params: {
        orderBy: 'nome'
      }
    })
  }

  public async fetchMicroregions() {
    return this.get({
      endpoint: `${this.endpoint}/microrregioes`,
      params: {
        orderBy: 'nome'
      }
    })
  }

  public async fetchCityInfoByCityId(cityId: string) {
    const city = await this.get({
      endpoint: `${this.endpoint}/municipios/${cityId}`
    })
    return city
  }
}
