import styled from 'styled-components'
import { OutlinedInput, TextareaAutosize } from '@material-ui/core'

export const LoginContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Logo = styled.img.attrs({
  alt: 'Lupa com uma folha com Ecotrace escrito ao lado',
  src: '/images/logo.png'
})`
  height: auto;
  object-fit: contain;
  object-position: center;
  margin-top: 50px;
  width: 32rem;
`

export const Title = styled.h1`
  font-weight: 600;
  text-align: center;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 3rem;
  line-height: 36px;
`
export const Description = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 2rem;
  line-height: 28px;

  text-align: center;

  color: ${({ theme }) => theme.colors.black2};

  margin-top: 30px;
  margin-bottom: 24px;
`

export const SideBarCornerImage = styled.img.attrs({
  alt: 'Curved corner sidebar',
  src: './images/loginBorderCurved.png'
})`
  height: 60vh;
  justify-self: center;
  align-self: center;
`
export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const FormInput = styled(OutlinedInput)`
  width: 351px;
  margin-bottom: 24px;
  color: ${({ theme }) => theme.colors.gray1};
`

export const FormTextArea = styled(TextareaAutosize)`
  width: 351px;
  margin-bottom: 24px;
  margin-top: 24px;
  background-color: ${({ theme }) => theme.colors.white2};
  border: 1px solid ${({ theme }) => theme.colors.gray3};
  padding: 15px;
`

export const InputError = styled.span`
  display: flex;
  flex-direction: row;
  color: ${({ theme }) => theme.colors.red};
  font-size: 1.1rem;
  margin-top: -5px;
  height: 30px;
`
