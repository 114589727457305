import { get } from 'lodash'

import { types } from './UserProfileDropdown.actions'

export type UserProfileDropdown = {
  userName: string | null
  avatar?: string | null
}

const INITIAL_STATE = { userName: null, avatar: null }

function setState(state: UserProfileDropdown) {
  return function _handle(payload: Record<string, any>) {
    return { ...state, ...payload }
  }
}

export default function _reducer(state = INITIAL_STATE, { type, payload }) {
  const _handlers = {
    [types.SET]: setState(state),
    _default: () => state
  }

  return get(_handlers, type, _handlers._default)(payload)
}
