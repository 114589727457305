import { Picture } from 'components/Picture'

type ProfileImageType = {
  payload: { src?: string }
  onLoadImage: (_key) => void
  onRemoveImage: (_key) => void
}

export function ProfileImage({
  payload,
  onLoadImage,
  onRemoveImage
}: ProfileImageType) {
  return (
    <Picture
      name="photo"
      imgUrl={payload.src || ''}
      onLoadImage={onLoadImage}
      onRemoveImage={onRemoveImage}
    />
  )
}
