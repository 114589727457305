import styled from 'styled-components'

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding-bottom: 0.05rem;
  display: flow-root;
  width: 100%;
  margin-top: 1.8rem;
`

export const Nav = styled.nav`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
`

export const Link = styled.li`
  align-items: center;
  cursor: pointer;
  background: transparent;
  color: ${({ theme }) => theme.colors.gray};
  display: flex;
`
