import { ColumnsProps } from 'components/Table/Table'
import { OrderDetails } from './orderDetails.types'

export const INITIAL_STATE_GENERAL_DETAILS: OrderDetails = {
  supplierName: null,
  supplierCnpj: null,
  invoiceSaleNumber: null,
  invoiceNatureOrigin: null,
  invoiceIssueDate: null,
  invoiceDeliveryDate: null,
  quantityProducts: null,
  quantityItems: null,
  unitMeasure: null,
  city: null,
  state: null,
  country: null,
  orderDateUpdated: null,
  orderNumber: null,
  totalCnpj: null,
  qtyTotalDelivered: null
}

export const generalOrderDetails = (totalCnpj: string, t): ColumnsProps[] => [
  {
    align: 'center',
    fixed: true,
    name: 'supplierName',
    title: `${t(
      'traceabilityRetailPage:tables.orderDetails.supplierName'
    )} [${totalCnpj}]`
  },
  {
    fixed: false,
    align: 'center',
    name: 'supplierCnpj',
    title: t('traceabilityRetailPage:tables.orderDetails.supplierCnpj')
  },
  {
    fixed: false,
    align: 'center',
    name: 'invoiceSaleNumber',
    title: t('traceabilityRetailPage:tables.orderDetails.invoiceSaleNumber')
  },
  {
    fixed: false,
    align: 'center',
    name: 'invoiceNatureOrigin',
    title: t('traceabilityRetailPage:tables.orderDetails.invoiceNatureOrigin')
  },
  {
    fixed: false,
    align: 'center',
    name: 'invoiceIssueDate',
    title: t('traceabilityRetailPage:tables.orderDetails.invoiceIssueDate')
  },
  {
    fixed: false,
    align: 'center',
    name: 'invoiceDeliveryDate',
    title: t('traceabilityRetailPage:tables.orderDetails.invoiceDeliveryDate')
  },
  {
    fixed: false,
    align: 'center',
    name: 'qtyTotalDelivered',
    title: t('traceabilityRetailPage:tables.orderDetails.qtyTotalDelivered')
  },
  {
    fixed: false,
    align: 'center',
    name: 'quantityItems',
    title: t('traceabilityRetailPage:tables.orderDetails.quantityItems')
  },
  {
    fixed: false,
    align: 'center',
    name: 'unitMeasure',
    title: t('traceabilityRetailPage:tables.orderDetails.unitMeasure')
  },
  {
    fixed: false,
    align: 'center',
    name: 'city',
    title: t('traceabilityRetailPage:tables.orderDetails.city')
  },
  {
    fixed: false,
    align: 'center',
    name: 'state',
    title: t('traceabilityRetailPage:tables.orderDetails.state')
  },
  {
    fixed: false,
    align: 'center',
    name: 'country',
    title: t('traceabilityRetailPage:tables.orderDetails.country')
  },
  {
    fixed: false,
    align: 'center',
    name: 'orderDateUpdated',
    title: t('traceabilityRetailPage:tables.orderDetails.orderDateUpdated')
  }
]
