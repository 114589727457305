import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { LeadTimeService } from 'services/leadTime.service'

import {
  INITIAL_STATE_GENERAL_DATA,
  generalData
} from '../../leadTime.constants'
import { GeneralDataTableProps, IQueryParameters } from '../../leadTime.types'

import { Loading, Table } from 'components'

import * as S from '../../styled'
import { formaDateStringLocale } from 'utils/formaDateStringLocale'
import { cnpjMask } from 'utils/CnpjMask'

export function GeneralDataTable() {
  const { t } = useTranslation()
  const { id: orderNumber }: IQueryParameters = useParams()

  const [loading, setLoading] = useState(false)
  const [generalDataTable, setGeneralDataTable] =
    useState<GeneralDataTableProps>(INITIAL_STATE_GENERAL_DATA)

  const getGeneralDataFromApiWithID = useCallback(async () => {
    setLoading(true)
    try {
      const leadTimeService = new LeadTimeService()
      await leadTimeService.getGaneralData({ orderNumber }).then(response => {
        const { data } = response
        setGeneralDataTable(data as GeneralDataTableProps)
      })
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }, [])

  const formattedGeneralDataTable = generalDataTable.items.map(item => ({
    ...item,
    orderDate: item.orderDate ? formaDateStringLocale(item.orderDate) : null,
    deliveryForecast: item.deliveryForecast
      ? formaDateStringLocale(item.deliveryForecast)
      : null,
    endDate: item.endDate ? formaDateStringLocale(item.endDate) : null,
    supplierAndOutsourcedCnpj: item.supplierAndOutsourcedCnpj
      ? cnpjMask(item.supplierAndOutsourcedCnpj)
      : null,
    leadTime: `${item.leadTime} ${t('leadTimePage:tables:generalData:days')}`
  }))

  useEffect(() => {
    getGeneralDataFromApiWithID()
  }, [])

  return (
    <S.Container>
      {loading ? (
        <Loading height="4rem" />
      ) : (
        <>
          <S.Title>
            {t('ordersManagementPage:tables:generalDataTable:generalData')}
            <S.Line />
          </S.Title>
          <S.Wrapper>
            <Table
              rows={formattedGeneralDataTable}
              columns={generalData()}
              statusBoxColumnName="orderStatus"
              situationBoxColumnName="orderSituation"
            />
          </S.Wrapper>
        </>
      )}
    </S.Container>
  )
}
