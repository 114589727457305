import { useCallback, useEffect, useState } from 'react'
import i18n from 'i18n'

import { SupplierService } from 'services'

import { cnpjMask } from 'utils/CnpjMask'
import { onFilterPaginate } from 'utils/OnFilterPaginate'
import { formaDateStringLocale } from 'utils/formaDateStringLocale'

import {
  INITIAL_STATE_SUBCONTRACTED_PRODUCTION_DETAILS,
  subcontractedProductionDetails
} from './ProductionDetailsTable.constants'
import { SubcontractedProductionDetailsProps } from './ProductionDetailsTable.type'

import { Pagination, Table } from 'components'

type ProductionProps = {
  orderNumber: string
  outsourcedName?: string
  outsourcedCnpj?: string
  parentHeight?(_value: number): void
}

export function ProductionDetailsTable({
  orderNumber,
  outsourcedName,
  outsourcedCnpj,
  parentHeight
}: ProductionProps) {
  const language = i18n.language === '中文 - ZH - CN' ? 'zh-CN' : i18n.language

  const [subcontractedProductionData, setSubcontractedProductionData] =
    useState<SubcontractedProductionDetailsProps>({
      ...INITIAL_STATE_SUBCONTRACTED_PRODUCTION_DETAILS
    })

  const [columnSorted, setColumnSorted] = useState('invoiceDate')
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC'>('DESC')

  const [totalCnpj, setTotalCnpj] = useState('0')
  const formattedSubcontractedDate = subcontractedProductionData.items.map(
    item => ({
      ...item,
      cnpj: item.cnpj ? cnpjMask(item.cnpj) : null,
      expectedEndDate: item.expectedEndDate
        ? formaDateStringLocale(item.expectedEndDate)
        : null,
      requestDate: item.requestDate
        ? formaDateStringLocale(item.requestDate)
        : null,
      invoiceDate: item.invoiceDate
        ? formaDateStringLocale(item.invoiceDate)
        : null,
      orderUpdateDate: item.orderUpdateDate
        ? formaDateStringLocale(item.orderUpdateDate)
        : null,
      qtyDelivered: Number(item.qtyDelivered).toLocaleString(language)
    })
  )

  function handlePaginationSubcontractedProductionFilter({ limit, page }) {
    const filters = onFilterPaginate(
      subcontractedProductionData.pagination,
      limit,
      page
    )

    getSubcontractedProductionFromApiWithID(false, filters.page, filters.limit)
  }

  const calcParentHeight = () => {
    // "+1" pra considerar a linha do header da tabela
    const totalRows = subcontractedProductionData.items.length + 1
    // 40px é a altura da linha da tabela
    const rowHeight = 40

    parentHeight(totalRows * rowHeight)
  }

  const getTotalCnpj = () => {
    subcontractedProductionData.items.map(item => {
      return setTotalCnpj(item.totalCnpj)
    })
  }

  function handleSortData(column: string, direction: 'ASC' | 'DESC') {
    setColumnSorted(column)
    setSortDirection(direction)
  }

  function handleSortColumn(column: string) {
    setColumnSorted(column)
    handleSortData(column, sortDirection)
    if (column !== columnSorted) return
    const direction = sortDirection === 'ASC' ? 'DESC' : 'ASC'
    handleSortData(column, direction)
  }

  const getSubcontractedProductionFromApiWithID = useCallback(
    async (isFirstRequest: boolean, page?: number, limit?: number) => {
      const supplierService = new SupplierService()
      if (
        (isFirstRequest && !subcontractedProductionData.items.length) ||
        !isFirstRequest
      ) {
        await supplierService
          .getSubcontractedProductionData({
            orderNumber,
            outsourcedName,
            outsourcedCnpj,
            page: page || subcontractedProductionData.pagination.page,
            limit: limit || subcontractedProductionData.pagination.limit,
            column: columnSorted,
            direction: sortDirection
          })
          .then(response => {
            const { data } = response
            setSubcontractedProductionData(
              data as SubcontractedProductionDetailsProps
            )
          })
      }
    },
    [sortDirection, columnSorted]
  )

  useEffect(() => {
    if (orderNumber) {
      getSubcontractedProductionFromApiWithID(true)
    }
    getTotalCnpj()
    return () => parentHeight(0)
  }, [])

  useEffect(() => {
    calcParentHeight()
  }, [subcontractedProductionData.items.length])

  useEffect(() => {
    getSubcontractedProductionFromApiWithID(false)
  }, [columnSorted, sortDirection])

  return (
    <>
      <Table
        rows={formattedSubcontractedDate}
        columns={subcontractedProductionDetails(totalCnpj)}
        sortColumn={{ column: columnSorted, sort: sortDirection }}
        onSortColumn={handleSortColumn}
      />
      <Pagination
        totalItems={subcontractedProductionData.pagination.totalItems}
        limit={subcontractedProductionData.pagination.limit}
        page={subcontractedProductionData.pagination.page}
        setFilter={handlePaginationSubcontractedProductionFilter}
      />
    </>
  )
}
