import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import { Checkbox } from 'components/Checkbox'
import { TableNotFoundHint } from 'components/TableNotFoundHint'
import { useTranslation } from 'react-i18next'

import * as S from './styled'

export type ColumnsProps = {
  align?: 'right' | 'left' | 'center'
  fixed?: boolean
  name: string
  title?: string
  useStatusBoxComponent?: boolean
}

export type TableProps = {
  columns: ColumnsProps[]
  linkTableNotFound?: string
  rows: any[]
  sortColumn?: {
    column: string
    sort: string
  }
  actionTable?: (_row) => JSX.Element
  changeColumn?: (_props) => JSX.Element
  onSortColumn?: (_column) => void
  actionColumnLabel?: string
  isLoading?: boolean
  checkOrders?: boolean
  selectOrdersKeys?: (_key: string, _selectAll: boolean) => void
  checkedKeys?: string[]
  selectedAll?: boolean
  disableSelectOrder?: boolean
  disableSelectAllOrder?: boolean
  statusBoxColumnName?: string
  statusOrderCustomText?: boolean
  situationBoxColumnName?: string
  situationOrderCustomText?: boolean
}

export function Table({
  columns = [],
  linkTableNotFound = '',
  rows = [],
  sortColumn,
  actionTable,
  changeColumn,
  onSortColumn,
  actionColumnLabel,
  isLoading,
  checkOrders,
  selectOrdersKeys,
  checkedKeys = [],
  disableSelectOrder,
  statusBoxColumnName,
  statusOrderCustomText = false,
  situationBoxColumnName,
  situationOrderCustomText = false
}: TableProps) {
  const { t } = useTranslation()
  const columnFixed = columns.findIndex(f => f.fixed) > -1
  const columnScroll = columns.findIndex(f => !f.fixed) > -1

  const loadingTable = () => {
    if (!rows.length && isLoading) {
      return (
        <S.TableLoadingFistTime>
          Aguarde, carregando dados...
        </S.TableLoadingFistTime>
      )
    }
    if (rows.length && isLoading) {
      return <S.TableLoading>Aguarde, carregando dados...</S.TableLoading>
    }
    if (!rows.length && !isLoading) {
      return <TableNotFoundHint to={linkTableNotFound} />
    }
  }

  function IconHeader({ column = '' }) {
    return (
      <S.ButtonIcon aria-label="Chevron Right">
        {sortColumn.column !== column || sortColumn.sort === 'DESC' ? (
          <ArrowDownwardIcon
            style={{
              color: sortColumn.column === column ? '#757575' : '#b1b1b1'
            }}
          />
        ) : (
          <ArrowUpwardIcon
            style={{
              color: sortColumn.column === column ? '#757575' : '#b1b1b1'
            }}
          />
        )}
      </S.ButtonIcon>
    )
  }

  function renderStatusAndSituationBox(
    column: string,
    rowValue: string,
    text: string
  ) {
    if (column === statusBoxColumnName) {
      // Colors based from status defined on i18n file above
      // A = "Aberto", P = "Parcial", F = "Finalizado"
      // It's only when don't have a custom status text
      return (
        <S.BoxContainer>
          {statusOrderCustomText ? text : t(`common:orderStatus:${rowValue}`)}
        </S.BoxContainer>
      )
    }

    if (column === situationBoxColumnName) {
      // Colors based from status defined on i18n file above
      // A = "Aberto", P = "Parcial", F = "Finalizado"
      // It's only when don't have a custom status text
      return (
        <S.BoxContainer>
          {situationOrderCustomText
            ? text
            : t(`common:orderSituation:${rowValue}`)}
        </S.BoxContainer>
      )
    }
    return rowValue
  }

  function handleChangeColumn(column, row) {
    return changeColumn
      ? changeColumn({ column, row })
      : renderStatusAndSituationBox(
          column.name,
          row[column.name],
          row.customText
        )
  }

  return (
    <S.Wrapper
      data-testid="Table"
      columnFixed={columnFixed}
      columnScroll={columnScroll}
      actionTable={actionTable}
    >
      <S.Container isLoading={isLoading}>
        {checkOrders && (
          <S.Table id="table-actions">
            <tbody>
              <S.Line id="header">
                <S.Header>
                  <S.SelectAllHeaderContainer>
                    {t('traceabilityRetailPage:tables:orders:select')} &nbsp;
                  </S.SelectAllHeaderContainer>
                </S.Header>
              </S.Line>
              {rows.map((row, index) => (
                <S.Line key={index}>
                  <S.Column>
                    <S.CheckboxColumnContainer>
                      <Checkbox
                        label=""
                        disabled={
                          !checkedKeys.includes(row.key) && disableSelectOrder
                        }
                        labelFor="order-key"
                        isChecked={checkedKeys.includes(row.key)}
                        onClick={() => selectOrdersKeys(row.key, false)}
                      />
                    </S.CheckboxColumnContainer>
                  </S.Column>
                </S.Line>
              ))}
            </tbody>
          </S.Table>
        )}
        <S.Table id="table-fixed">
          <tbody>
            <S.Line id="header">
              {columns.map(
                (column, index) =>
                  column.fixed && (
                    <S.Header
                      key={index}
                      buttonOpacity={
                        sortColumn ? sortColumn.column === column.name : 0
                      }
                      className={column.align}
                      onClick={() =>
                        onSortColumn ? onSortColumn(column.name) : false
                      }
                    >
                      {onSortColumn && column.align === 'right' && (
                        <IconHeader column={column.name} />
                      )}
                      {column.title || column.name}
                      {onSortColumn && column.align !== 'right' && (
                        <IconHeader column={column.name} />
                      )}
                    </S.Header>
                  )
              )}
            </S.Line>
            {rows.map((row, index) => {
              return (
                <S.Line id="values" key={index}>
                  {columns.map(
                    (column, i) =>
                      column.fixed && (
                        <S.Column key={i} className={column.align}>
                          {row[column.name]
                            ? handleChangeColumn(column, row)
                            : '--'}
                        </S.Column>
                      )
                  )}
                </S.Line>
              )
            })}
          </tbody>
        </S.Table>

        {columnScroll && (
          <S.ScrollWrapper>
            <S.Table id="table-scrolled">
              <tbody>
                <S.Line id="header">
                  {columns.map(
                    (column, index) =>
                      !column.fixed && (
                        <S.Header
                          key={index}
                          buttonOpacity={
                            sortColumn ? sortColumn.column === column.name : 0
                          }
                          className={column.align}
                          onClick={() =>
                            onSortColumn ? onSortColumn(column.name) : false
                          }
                        >
                          {onSortColumn && column.align === 'right' && (
                            <IconHeader column={column.name} />
                          )}
                          {column.title || column.name}
                          {onSortColumn && column.align !== 'right' && (
                            <IconHeader column={column.name} />
                          )}
                        </S.Header>
                      )
                  )}
                </S.Line>
                {rows.map((row, index) => (
                  <S.Line id="values" key={index}>
                    {columns.map(
                      (column, i) =>
                        !column.fixed && (
                          <S.Column key={i} className={column.align}>
                            {row[column.name]
                              ? handleChangeColumn(column, row)
                              : '--'}
                          </S.Column>
                        )
                    )}
                  </S.Line>
                ))}
              </tbody>
            </S.Table>
          </S.ScrollWrapper>
        )}
        {actionTable && (
          <S.Table id="table-actions">
            <tbody>
              <S.Line id="header">
                <S.Header>{actionColumnLabel || t('Actions')}</S.Header>
              </S.Line>
              {rows.map((row, index) => (
                <S.Line key={index}>
                  <S.Column id="actions-column">
                    {actionTable && actionTable(row)}
                  </S.Column>
                </S.Line>
              ))}
            </tbody>
          </S.Table>
        )}
      </S.Container>

      {loadingTable()}
    </S.Wrapper>
  )
}
