import { useCallback, useContext, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import i18n from 'i18n'

import { OrdersManagementService } from 'services/ordersManagement.service'

import { OrdersManagementTableContext } from 'contexts/ordersTable/ordersTable.context'
import { SupplierFiltersContext } from 'contexts/supplierFilters/supplierFilters.context'
import { TraceabilityRetailFiltersContext } from 'contexts/traceabilityRetailTableFilters/traceabilityRetailTableFilters.context'

import { onFilterPaginate } from 'utils/OnFilterPaginate'
import { formaDateStringLocale } from 'utils/formaDateStringLocale'

import {
  orderItemDetails,
  INITIAL_STATE_ORDER_ITEM_DETAILS
} from 'pages/OrderManagementDetails/orderManagementDetails.constants'
import { OrderItemDetailsProps } from 'pages/OrderManagementDetails/orderManagementDetails.type'

import { Pagination } from 'components'

import * as S from '../../styles'

type ParamsProps = {
  id?: string
}

type LocationProps = {
  fromRetail: boolean
  fromSupplier: boolean
}

export function ItemsTable({ headerItem }) {
  const { state } = useLocation<LocationProps>()
  const { id: orderNumber } = useParams<ParamsProps>()

  const language = i18n.language === '中文 - ZH - CN' ? 'zh-CN' : i18n.language

  const { ordersManagementFilters } = useContext(OrdersManagementTableContext)
  const { traceabilityRetailsFilters } = useContext(
    TraceabilityRetailFiltersContext
  )
  const { supplierFilters } = useContext(SupplierFiltersContext)

  const [itemsDetails, setItemsDetails] = useState<OrderItemDetailsProps>(
    INITIAL_STATE_ORDER_ITEM_DETAILS
  )

  const getItemsFromApi = useCallback(async (page?: number, limit?: number) => {
    try {
      const service = new OrdersManagementService()
      let _filters
      switch (true) {
        case state?.fromRetail:
          _filters = { ...traceabilityRetailsFilters }
          break
        case state?.fromSupplier:
          _filters = { ...supplierFilters }
          break
        default:
          _filters = { ...ordersManagementFilters }
      }
      delete _filters.startDate
      delete _filters.endDate
      delete _filters.dateType
      delete _filters.expireIn

      await service
        .getItemsDetails({
          ..._filters,
          orderNumber,
          supplierAndOutsourcedCnpj: headerItem.supplierAndOutsourcedCnpj,
          supplierAndOutsourcedName: headerItem.supplierAndOutsourcedName,
          itemCode: headerItem.itemCode,
          direction: 'DESC',
          page,
          limit
        })
        .then(({ data }: any) => {
          setItemsDetails(data)
        })
    } catch (error) {
      console.log(error)
    }
  }, [])

  const formattedItemsDetails = itemsDetails.items.map(item => ({
    ...item,
    deliveryForecast: formaDateStringLocale(item.deliveryForecast),
    requestDate: formaDateStringLocale(item.requestDate),
    qtyTotal: Number(item.qtyTotal).toLocaleString(language),
    qtyDelivered: Number(item.qtyDelivered).toLocaleString(language)
  }))

  function handlePaginationFilter({ limit, page }) {
    const filters = onFilterPaginate(ordersManagementFilters, limit, page)

    getItemsFromApi(filters.page, filters.limit)
  }

  useEffect(() => {
    getItemsFromApi()
  }, [ordersManagementFilters])

  return (
    <>
      <S.TableComponent
        rows={formattedItemsDetails}
        columns={orderItemDetails()}
      />
      <Pagination
        totalItems={itemsDetails?.pagination.totalItems}
        page={itemsDetails?.pagination.page}
        limit={itemsDetails?.pagination.limit}
        setFilter={handlePaginationFilter}
      />
    </>
  )
}
