import { Modal } from 'components/Modal'

import * as S from './styled'

export type ModalTableProps = {
  onExit?: () => void
  titleTable: string
  content: any
}

export function ModalTable({ onExit, titleTable, content }: ModalTableProps) {
  const wrapperTable = (
    <S.Wrapper>
      <S.Box>
        <S.BoxTitle>{titleTable}</S.BoxTitle>
        <S.BoxLine />
      </S.Box>
      <S.GridTable>
        <S.ContentWrapper>{content}</S.ContentWrapper>
      </S.GridTable>
    </S.Wrapper>
  )
  return (
    <Modal
      content={wrapperTable}
      isActionsEnabled={true}
      width={150}
      cancelButton={false}
      onExit={onExit}
    />
  )
}
