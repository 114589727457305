import { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'

import { SupplierFiltersContext } from 'contexts/supplierFilters/supplierFilters.context'
import { TraceabilityRetailFiltersContext } from 'contexts/traceabilityRetailTableFilters/traceabilityRetailTableFilters.context'
import { OrdersManagementTableContext } from 'contexts/ordersTable/ordersTable.context'

import { OrdersManagementService } from 'services/ordersManagement.service'

import { INITIAL_STATE_HEADER_ITEMS } from 'pages/OrderManagementDetails/orderManagementDetails.constants'
import { HeaderItemProps } from 'pages/OrderManagementDetails/orderManagementDetails.type'

import { ItemsTable } from './Tables/ItemsTable'
import { InvoiceTable } from './Tables/InvoiceTable'

import { ModalDelete } from 'components/ModalDelete/ModalDelete'

// import { ActionTable } from './ActionTable/ActionTable'

import * as S from './styles'

type ParamsProps = {
  id?: string
}

type LocationProps = {
  fromRetail: boolean
  fromSupplier: boolean
}

export function ItemsDetailsTable({ openSummaryData }) {
  const { t } = useTranslation()

  const { state } = useLocation<LocationProps>()
  const { id: orderNumber } = useParams<ParamsProps>()

  const { ordersManagementFilters } = useContext(OrdersManagementTableContext)
  const { traceabilityRetailsFilters } = useContext(
    TraceabilityRetailFiltersContext
  )
  const { supplierFilters } = useContext(SupplierFiltersContext)

  const [openModal, setOpenModal] = useState(false)
  const [deleteActivity, setDeleteActivity] = useState({ id: null })
  const [headerItems, setHeaderItems] = useState<HeaderItemProps>(
    INITIAL_STATE_HEADER_ITEMS
  )

  const [expandedDetailsTable, setExpandedDetailsTable] = useState(null)
  const [getDetailsTableData, setGetDetailsTableData] = useState(null)

  function onExit() {
    setDeleteActivity({ id: null })
    setOpenModal(false)
  }

  function onDelete(id) {
    setDeleteActivity(id)
    onExit()
  }

  const handleDetailsTableClick = (index, row) => {
    if (expandedDetailsTable === index) {
      setExpandedDetailsTable(null)
    } else {
      setGetDetailsTableData(row)
      setExpandedDetailsTable(index)
    }
  }

  const getItemsFromApi = useCallback(async () => {
    try {
      const service = new OrdersManagementService()
      let _filters
      switch (true) {
        case state?.fromRetail:
          _filters = { ...traceabilityRetailsFilters }
          break
        case state?.fromSupplier:
          _filters = { ...supplierFilters }
          break
        default:
          _filters = { ...ordersManagementFilters }
      }
      delete _filters.startDate
      delete _filters.endDate
      delete _filters.dateType
      delete _filters.expireIn

      await service
        .getHeaderDetails({
          ..._filters,
          orderNumber,
          supplierAndOutsourcedName: openSummaryData.supplierAndOutsourcedName,
          supplierAndOutsourcedCnpj: openSummaryData.supplierAndOutsourcedCnpj,
          direction: 'DESC'
        })
        .then(({ data }: any) => {
          setHeaderItems(data)
        })
    } catch (error) {
      console.log(error)
    }
  }, [])

  useEffect(() => {
    getItemsFromApi()
  }, [ordersManagementFilters])

  // ESTE CODIGO SERA INTRODUZIDO NA V2 ==== NÃO REMOVER

  // function actionTable() {
  //   return (
  //     <ActionTable
  //       textLine2={t('common:Delete')}
  //       iconTypeLine2="dropdown-delete"
  //       setOpen={() => setOpenModal(true)}
  //     />
  //   )
  // }

  return (
    <S.Container>
      {openModal ? (
        <ModalDelete
          title={t('ordersManagementPage:modalDelete:title')}
          descriptionOne={t('ordersManagementPage:modalDelete:descriptionOne')}
          descriptionTwo={`${t(
            'ordersManagementPage:modalDelete:descriptionTwo'
          )} ${''}`}
          onExit={onExit}
          onRemove={() => onDelete(deleteActivity.id)}
        />
      ) : null}

      {headerItems?.items?.map((item, index) => (
        <>
          <S.Title>
            {t('ordersManagementPage:tables:orderedItem')}
            <S.SubTitle>{`${item.itemCode} - ${item.itemDescription}`}</S.SubTitle>
          </S.Title>
          <ItemsTable headerItem={item} />
          <S.WrapperAccordion
            isActive={true}
            icon="details"
            title={t('ordersManagementPage:tables:details')}
            onChange={() => {
              handleDetailsTableClick(index, item)
            }}
          >
            <S.Wrapper>
              <S.Title>
                {t('ordersManagementPage:tables:launchProduction')}
              </S.Title>

              {expandedDetailsTable === index && (
                <InvoiceTable getDetailsTableData={getDetailsTableData} />
              )}
            </S.Wrapper>
          </S.WrapperAccordion>
        </>
      ))}
    </S.Container>
  )
}
