export const types = Object.freeze({
  SET: '@spinner/set',
  TOGGLE: '@spinner/toggle'
})

export function toggle() {
  return { type: types.TOGGLE }
}

export function setVisibility(state: boolean) {
  return { type: types.SET, payload: { visible: state } }
}
