import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import * as Yup from 'yup'

import { IndustryGroupService, IndustryService } from 'services'

import validaCNPJ from 'utils/validaCNPJ'
import { submitOnEnter } from 'utils/submitOnEnter'
import { onFilterPaginate } from 'utils/OnFilterPaginate'

import { AddButton } from 'components'
import { Input } from 'components/Input'
import { Button } from 'components/Button'
import { Pagination } from 'components/Pagination'
import { InputCpfCnpj } from 'components/InputCpfCnpj'
import { IndustryGroupFilterTable } from './IndustryGroupFilterTable'

import * as S from './styled'

const FilterClientSchema = Yup.object().shape({
  name: Yup.string(),
  fancyName: Yup.string(),
  cnpj: Yup.string()
    .test('cnpj', 'CNPJ inválido', value => {
      if (value) {
        const isValid = validaCNPJ(value)
        return isValid
      }
      return true
    })
    .label('CNPJ')
})

export type IndustryGroupFilterProps = {
  onNewClick?: (_value: unknown) => void
  attFilterTable: boolean
}

interface ParamTypes {
  id: string
}

const INITIAL_STATE_INDUSTRY_GROUP = {
  adminEmail: '',
  adminName: '',
  cnpj: '',
  companies: [],
  companiesPagination: { limit: 10, page: 0, totalItems: 0, totalPages: 0 },
  companyGroupProfiles: [],
  id: '',
  name: '',
  visible: null
}

const INITIAL_STATE_INDUSTRY_FILTER_TABLE = {
  companies: [],
  companiesPagination: { limit: 10, page: 0, totalItems: 0, totalPages: 0 }
}

const GROUP_FILTER_INITIAL_STATE = {
  name: '',
  fancyName: '',
  cnpj: '',
  limit: 10,
  page: 0
}

const ERRORS_INITIAL_STATE = {
  name: '',
  fancyName: '',
  cnpj: ''
}

export function IndustryGroupFilter({
  onNewClick,
  attFilterTable
}: IndustryGroupFilterProps) {
  const { id } = useParams<ParamTypes>()

  const industryService = new IndustryService()
  const industryGroupService = new IndustryGroupService()

  const [loading, setLoading] = useState(false)
  const [attTable, setAttTable] = useState(false)

  const [errors, setErrors] = useState({ ...ERRORS_INITIAL_STATE })
  const [industry, setIndustry] = useState({ ...INITIAL_STATE_INDUSTRY_GROUP })
  const [filter, setFilter] = useState({
    ...GROUP_FILTER_INITIAL_STATE
  })
  const [industryFilterTable, setIndustryFilterTable] = useState({
    ...INITIAL_STATE_INDUSTRY_FILTER_TABLE
  })

  async function fetchIndustry() {
    try {
      const { data } = await industryGroupService.fetchOne(id)
      setIndustry(data as any)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchIndustry()
  }, [])

  function onInput(name: string) {
    return function _handle(val) {
      setErrors({ ...errors, [name]: '' })
      setFilter({ ...filter, [name]: val })
    }
  }

  async function submitFilter() {
    setLoading(true)
    try {
      await FilterClientSchema.validate(filter, {
        abortEarly: false,
        stripUnknown: true
      })
      const formatedFilter = {
        name: filter.name,
        fancyName: filter.fancyName,
        cnpj: filter.cnpj.replace(/[^\w\s]/gi, '') || ''
      }
      const { data } = await industryService.searchByGroup({
        groupId: id,
        params: formatedFilter
      })
      setIndustryFilterTable(data as any)
    } catch (ex) {
      const _errors = Object.fromEntries(
        ex.inner.map(({ path, message }) => {
          return [path, message.replace(`${message} `, '')]
        })
      )
      setErrors({ ...GROUP_FILTER_INITIAL_STATE, ..._errors })
    } finally {
      setLoading(false)
    }
  }

  async function onFilterPaginateIndustryTable({ limit, page }) {
    const filters = onFilterPaginate(
      industryFilterTable.companiesPagination,
      limit,
      page
    )
    const { data } = await industryService.searchByGroup({
      groupId: id,
      params: filters
    })
    setIndustryFilterTable(data as any)
  }

  const clearFilters = useCallback(() => {
    setFilter(GROUP_FILTER_INITIAL_STATE)
    setErrors({ ...GROUP_FILTER_INITIAL_STATE })
    submitFilter()
  }, [])

  useEffect(() => {
    submitFilter()
  }, [attTable, attFilterTable])

  return (
    <S.GridCard>
      <S.GridHeader>
        <S.GridTitle item container xs={12} md={12} lg={12}>
          <S.BoxTitle>Filtrar indústrias</S.BoxTitle>
          <S.BoxLine />
        </S.GridTitle>
        <S.ButtonAdd onClick={() => onNewClick(true)}>
          <AddButton />
        </S.ButtonAdd>
      </S.GridHeader>

      <S.GridFilter container>
        <S.GridInput item md={12} lg={4}>
          <Input
            fullWidth
            label="Razão social "
            value={filter.name}
            error={Boolean(errors.name)}
            helperText={errors.name}
            onKeyPress={submitOnEnter(submitFilter)}
            onInput={onInput('name')}
          />
        </S.GridInput>
        <S.GridInput item md={12} lg={4}>
          <Input
            fullWidth
            label="Nome fantasia"
            value={filter.fancyName}
            error={Boolean(errors.fancyName)}
            helperText={errors.fancyName}
            onKeyPress={submitOnEnter(submitFilter)}
            onInput={onInput('fancyName')}
          />
        </S.GridInput>
        <S.GridInput item md={12} lg={4}>
          <InputCpfCnpj
            fullWidth
            type="cnpj"
            value={filter.cnpj}
            error={Boolean(errors.cnpj)}
            helperText={errors.cnpj}
            onKeyPress={submitOnEnter(submitFilter)}
            onInput={onInput('cnpj')}
          />
        </S.GridInput>
        <S.GridButtons item xs={12}>
          <Button variant="gray" onClick={clearFilters} size="medium">
            <p>Limpar</p>
          </Button>
          <Button variant="blue" onClick={submitFilter} size="medium">
            <p>Filtrar</p>
          </Button>
        </S.GridButtons>
      </S.GridFilter>

      {!loading && (
        <S.GridTable>
          <IndustryGroupFilterTable
            isDeleted={setAttTable}
            groupId={industry.id}
            groupName={industry.name}
            items={industryFilterTable.companies}
            onNewClick={onNewClick}
          />
          {industryFilterTable.companiesPagination?.totalItems > 0 && (
            <Pagination
              limit={industryFilterTable.companiesPagination.limit}
              page={industryFilterTable.companiesPagination.page}
              totalItems={industryFilterTable.companiesPagination.totalItems}
              setFilter={onFilterPaginateIndustryTable}
            />
          )}
        </S.GridTable>
      )}
    </S.GridCard>
  )
}
