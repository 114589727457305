import { OrdersTable } from './OrdersTable'

import { TableProps } from '../../allOrdersManagement.types'

import { Pagination } from 'components'

import * as S from './styled'
import { useContext } from 'react'
import { OrdersManagementTableContext } from 'contexts/ordersTable/ordersTable.context'
import { onFilterPaginate } from 'utils/OnFilterPaginate'

export function Table({ rows, variation }: TableProps) {
  const { ordersManagementFilters, setOrdersManagementFilters } = useContext(
    OrdersManagementTableContext
  )

  function handleTableBody() {
    const fixedTable = {
      orders: () => <OrdersTable rows={rows?.items || []} />,
      default: () => null
    }
    const Component = fixedTable[variation] || fixedTable.default
    return <Component />
  }

  function handlePaginationFilter({ limit, page }) {
    setOrdersManagementFilters({
      ...ordersManagementFilters,
      ...onFilterPaginate(ordersManagementFilters, limit, page)
    })
  }

  return (
    <>
      <S.Wrapper>{handleTableBody()}</S.Wrapper>
      <Pagination
        totalItems={rows?.pagination.totalItems}
        page={rows?.pagination.page}
        limit={rows?.pagination.limit}
        setFilter={handlePaginationFilter}
      />
    </>
  )
}
