import { t } from 'i18next'

import { subMonths } from 'date-fns'

import { ColumnsProps } from 'components/Table/Table'
import { OrderItemsProps } from './allOrdersManagement.types'

const getDateLastMonth = subMonths(new Date(), 1)
const formattedDate = new Date(
  getDateLastMonth.getFullYear(),
  getDateLastMonth.getMonth(),
  1
)

// CONSTANTES FILTROS VVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV

export const ordersFilterRequestPayload = {
  order: {
    startDate: 'orderStartDate',
    endDate: 'orderEndDate'
  },
  prediction: {
    startDate: 'predictionStartDate',
    endDate: 'predictionEndDate'
  }
}

export const INITIAL_STATE = {
  startDate: formattedDate,
  endDate: new Date(),
  dateType: 'order',
  orderNumber: '',
  orderStatus: '',
  orderSituation: '',
  supplierAndOutsourcedCnpj: '',
  supplierAndOutsourcedName: '',
  expireIn: new Date()
}

export const ERRORS_INITIAL_STATE = {
  startDate: null,
  endDate: null,
  dateType: 'order',
  orderNumber: '',
  orderStatus: '',
  orderSituation: '',
  supplierAndOutsourcedCnpj: '',
  supplierAndOutsourcedName: ''
}

export const INITIAL_STATE_CONSTANT_ORDER_SITUATION = [
  {
    id: 'P',
    label: t('ordersManagementPage:constants:orderSituation:onTime'),
    tag: 'P'
  },
  {
    id: 'A',
    label: t('ordersManagementPage:constants:orderSituation:overdue'),
    tag: 'A'
  }
]

// CONSTANTES TABELA VVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV

export const ordersTable = (): ColumnsProps[] => [
  {
    fixed: true,
    align: 'center',
    name: 'orderNumber',
    title: t('ordersManagementPage:tables:ordersTable:orderNumber')
  },
  // ADICIONAR SOMENTE NA V2 ==== NÃO REMOVER
  // {
  //   fixed: true,
  //   align: 'center',
  //   name: 'purchaseOrderNumber',
  //   title: t('ordersManagementPage:tables:ordersTable:purchaseOrderNumber')
  // },
  {
    fixed: false,
    align: 'center',
    name: 'supplierAndOutsourcedName',
    title: t('ordersManagementPage:tables:ordersTable:corporateName')
  },
  {
    fixed: false,
    align: 'center',
    name: 'supplierAndOutsourcedCnpj',
    title: t('ordersManagementPage:tables:ordersTable:cnpjSupplier')
  },
  {
    fixed: false,
    align: 'center',
    name: 'orderStatus',
    title: t('ordersManagementPage:tables:ordersTable:orderStatus')
  },
  {
    fixed: false,
    align: 'center',
    name: 'orderSituation',
    title: t('ordersManagementPage:tables:ordersTable:orderSituation')
  },
  {
    fixed: false,
    align: 'center',
    name: 'orderDate',
    title: t('ordersManagementPage:tables:ordersTable:orderDate')
  },
  {
    fixed: false,
    align: 'center',
    name: 'previousDeliveryDate',
    title: t('ordersManagementPage:tables:ordersTable:previousDeliveryDate')
  },
  {
    fixed: false,
    align: 'center',
    name: 'qtyTotal',
    title: t('ordersManagementPage:tables:ordersTable:qtyTotal')
  },
  {
    fixed: false,
    align: 'center',
    name: 'qtyDelivery',
    title: t('ordersManagementPage:tables:ordersTable:qtyDelivery')
  },
  {
    fixed: false,
    align: 'center',
    name: 'qtySubcontractors',
    title: t('ordersManagementPage:tables:ordersTable:qtySubcontractors')
  }
]

export const orderItems = (): ColumnsProps[] => [
  {
    fixed: true,
    align: 'center',
    name: 'itemCode',
    title: t('orderDetails:items.itemCode')
  },
  {
    fixed: false,
    align: 'center',
    name: 'itemDescription',
    title: t('orderDetails:items.itemDescription')
  },
  {
    fixed: false,
    align: 'center',
    name: 'qtyTotal',
    title: t('orderDetails:items.qtdTotalItems')
  },
  {
    fixed: false,
    align: 'center',
    name: 'invoiceQty',
    title: t('orderDetails:items.totalDelivered')
  }
]

// DEMAIS CONSTANTES VVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV

export const INITIAL_STATE_ORDER_ITEMS: OrderItemsProps = {
  pagination: {
    totalItems: 0,
    totalPages: 0,
    page: 0,
    limit: 10
  },

  items: []
}
