import styled from 'styled-components'

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.footer};
  height: 4rem;
  display: flex;
  justify-content: flex-end;
  z-index: 101;
`

export const Title = styled.p`
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: 1.2rem;
  font-weight: 600;
  padding: 1.5rem;
`
