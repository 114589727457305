import axios, { AxiosInstance } from 'axios'
import { pick } from 'lodash'

import { APIIbge as config } from 'config'

import { toasts } from 'utils/toasts'

type Response = { success: boolean; status: number; data: Record<string, any> }
type GetParams = { endpoint: string; params?: Record<string, any> }

interface HTTPProvider {
  get(_settings: GetParams): Promise<Response>
}

export class HTTPIbge implements HTTPProvider {
  private $axios: AxiosInstance

  constructor() {
    this.$axios = axios.create(config)
  }

  private async handle(req): Promise<Response> {
    try {
      const res = await req
      return { success: true, status: res.status, data: res.data }
    } catch (ex) {
      if (!ex.response) {
        toasts.generalFail()
        return { success: false, status: -1, data: ex }
      }

      if (ex.response.status === 500) toasts.generalFail()
      return { ...pick(ex.response, 'data', 'status'), success: false }
    }
  }

  async get({ endpoint, params = {} }: GetParams): Promise<Response> {
    const _params = Object.fromEntries(
      Object.keys(params)
        .filter(key => params[key])
        .map(key => [key, params[key]])
    )
    const req = this.$axios.get(endpoint, { params: _params })
    return await this.handle(req)
  }
}
