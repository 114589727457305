import { retailRequestPayload } from 'constants/retailFilterForm.constants'
import { subMonths } from 'date-fns'
import { ReactNode, createContext, useState } from 'react'

interface RetailTableProviderProps {
  children: ReactNode
}

export interface IRetailFiltersContext {
  cnpj?: string
  name?: string
  invoiceNumber: string
  startDate: Date
  endDate: Date
  orderEndDate?: Date | string
  orderStartDate?: Date | string
  invoiceStartDate?: Date | string
  invoiceEndDate?: Date | string
  invoiceDeliveryStartDate?: Date | string
  invoiceDeliveryEndDate?: Date | string
  productionStartDate?: Date | string
  productionEndDate?: Date | string
  predictionStartDate?: Date | string
  predictionEndDate?: Date | string
  orderNumber: string
  retailCnpj?: string
  retailName?: string
  searchNameAndCnpjIn: string
  dateType: string
  orderStatus: string
  country?: string
  city?: string
  microregion?: string
  state?: string
  productCode?: string
  wiringName?: string
  wiringCnpj?: string
  providerName?: string
  providerCnpj?: string
  outsourcedName?: string
  outsourcedCnpj?: string
  weavingName?: string
  weavingCnpj?: string
  propertyName?: string
  propertyCar?: string
  providerCountry?: string
  providerCity?: string
  providerMicroregion?: string
  providerState?: string
  column?: string
  direction?: 'DESC' | 'ASC'
  companyGroups:
    | {
        id: string
        label?: string
        tag?: string
      }[]
    | any
}

interface ITraceabilityFiltersProps {
  traceabilityRetailsFilters?: IRetailFiltersContext
  setTraceabilityRetailsFilters?: (_filters: Record<string, any>) => void
  clearTraceabilityRetailsFilters?: () => void
}

export const TraceabilityRetailFiltersContext =
  createContext<ITraceabilityFiltersProps>(undefined)

export default function TraceabilityRetailFiltersProvider({
  children
}: RetailTableProviderProps) {
  const localStorageFilters = localStorage.getItem('traceabilityFilters')
  const localStorageFiltersParsed = localStorageFilters
    ? JSON.parse(localStorageFilters)
    : null

  const getDateLastMonth = subMonths(new Date(), 1)
  const formattedDate = new Date(
    getDateLastMonth.getFullYear(),
    getDateLastMonth.getMonth(),
    1,
    getDateLastMonth.getHours(),
    getDateLastMonth.getMinutes(),
    getDateLastMonth.getSeconds()
  )

  function parseFilterOptions() {
    if (localStorageFiltersParsed) {
      const nameKey =
        retailRequestPayload[localStorageFiltersParsed.searchNameAndCnpjIn].name
      const cnpjKey =
        retailRequestPayload[localStorageFiltersParsed.searchNameAndCnpjIn].cnpj
      const startDateKey =
        retailRequestPayload[localStorageFiltersParsed.dateType].startDate
      const endDateKey =
        retailRequestPayload[localStorageFiltersParsed.dateType].endDate
      return {
        [nameKey]: localStorageFiltersParsed[nameKey],
        [cnpjKey]: localStorageFiltersParsed[cnpjKey],
        [startDateKey]: localStorageFiltersParsed[startDateKey],
        [endDateKey]: localStorageFiltersParsed[endDateKey]
      }
    }

    return {
      retailName: '',
      retailCnpj: '',
      orderStartDate: formattedDate,
      orderEndDate: new Date()
    }
  }

  const INITIAL_FILTERS_STATE = {
    ...parseFilterOptions(),
    invoiceNumber: localStorageFiltersParsed?.invoiceNumber || '',
    orderNumber: localStorageFiltersParsed?.orderNumber || '',
    searchNameAndCnpjIn:
      localStorageFiltersParsed?.searchNameAndCnpjIn || 'retail',
    dateType: localStorageFiltersParsed?.dateType || 'order',
    orderStatus: localStorageFiltersParsed?.orderStatus || '',
    country: localStorageFiltersParsed?.country || '',
    city: localStorageFiltersParsed?.city || '',
    microregion: localStorageFiltersParsed?.microregion || '',
    state: localStorageFiltersParsed?.state || '',
    productCode: localStorageFiltersParsed?.productCode || '',
    propertyCar: localStorageFiltersParsed?.propertyCar || '',
    endDate: localStorageFiltersParsed?.orderEndDate || new Date(),
    startDate: localStorageFiltersParsed?.orderStartDate || formattedDate,
    companyGroups: localStorageFiltersParsed?.companyGroups || [],
    providerCountry: localStorageFiltersParsed?.providerCountry || '',
    providerCity: localStorageFiltersParsed?.providerCity || '',
    providerMicroregion: localStorageFiltersParsed?.providerMicroregion || '',
    providerState: localStorageFiltersParsed?.providerState || '',
    column: localStorageFiltersParsed?.column || 'orderDate',
    direction: localStorageFiltersParsed?.direction || 'DESC'
  }

  const [traceabilityRetailsFilters, setContextState] =
    useState<IRetailFiltersContext>(INITIAL_FILTERS_STATE)

  function clearTraceabilityRetailsFilters() {
    setContextState(INITIAL_FILTERS_STATE)
    localStorage.removeItem('traceabilityFilters')
  }

  function setTraceabilityRetailsFilters(filters: IRetailFiltersContext) {
    setContextState(filters)
    localStorage.setItem('traceabilityFilters', JSON.stringify(filters))
  }

  return (
    <TraceabilityRetailFiltersContext.Provider
      value={{
        traceabilityRetailsFilters,
        setTraceabilityRetailsFilters,
        clearTraceabilityRetailsFilters
      }}
    >
      {children}
    </TraceabilityRetailFiltersContext.Provider>
  )
}
