import { useTranslation } from 'react-i18next'
import { Icon } from '../Icon'
import * as S from './styled'

export type GoBackProps = {
  goBack: () => void
}

export function GoBack({ goBack }: GoBackProps) {
  const { t } = useTranslation()
  return (
    <S.Wrapper onClick={goBack}>
      <Icon icon="itens-interface-go-back" width={22} height={22} />
      <S.Title>{t('Back')}</S.Title>
    </S.Wrapper>
  )
}
