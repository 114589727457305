import { Divider, Grid } from '@material-ui/core'
import styled from 'styled-components'

export const GridFieldsPhoto = styled(Grid)`
  display: flex;
  margin: 0;
  width: calc(100% + 24px);
  flex-direction: row;
`
export const GridGalery = styled(Grid)`
  display: flex;
  margin: -16px;
  width: calc(100% + 24px);
  flex-direction: row;
`

export const CardPhotos = styled.div`
  background-color: #f7f9fd;
  border-radius: 0.8rem;
  width: 100%;
  margin: 1.6rem;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 0.8rem;
  display: grid;
`

export const ScrollWrapper = styled.div`
  cursor: pointer;
  display: flex;
  overflow-x: auto;
  height: 100%;
  margin: 1.6rem 4.3rem;
  padding: 1.6rem;

  ::-webkit-scrollbar {
    height: 0.4rem;
    width: 1.2rem;
  }
  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.background};
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.gray.luminance(0.5)};
    border-radius: 2rem;
  }
`

export const GridPhoto = styled(Grid)`
  display: flex;
  margin: 0 1.6rem;
  width: calc(100% + 24px);
  flex-direction: row;
`

export const DividerVertical = styled(Divider)`
  background-color: #f2f3f7;
`

export const DivPhotoMessage = styled.div`
  width: 100%;
  margin-right: 3.2rem;
`

export const CardPhoto = styled.div`
  background-color: #f7f9fd;
  border-radius: 0.8rem;
  width: 100%;
  margin: 0 1.6rem;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 0.8rem;
  height: 16.8rem;
`

export const AlertIcon = styled.div`
  display: flex;
  align-items: baseline;
  color: ${({ theme }) => theme.colors.error};

  position: relative;
  bottom: -0.4rem;
  left: 1.6rem;
  height: 0;

  img {
    height: 1rem;
  }

  p {
    color: #de4f4f;
    font-size: 1rem;
    font-weight: 600;
    padding-left: 0.4rem;

    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    letter-spacing: 0.03333em;
  }
`
