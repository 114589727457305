import { HTTPB2B } from 'providers'

export class ReportFileService extends HTTPB2B {
  public async getReportFile(fileName: string) {
    return this.get({
      endpoint: '/report-file',
      params: { fileName }
    })
  }
}
