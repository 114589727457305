import styled from 'styled-components'

import { Accordion, Table } from 'components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  grid-row-gap: 3rem;

  width: 100%;

  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 8px;

  padding: 2.4rem;
  margin-bottom: 5rem;
  margin-top: -5rem;
`

export const Title = styled.div`
  color: #315ca7;
  font-size: 16px;
  font-weight: 600;

  margin: 4rem 0;
`

export const SubTitle = styled.div`
  color: #000;
  font-size: 13px;
  font-weight: 600;

  margin-top: 1.5rem;
`
export const TableComponent = styled(Table)``

export const WrapperAccordion = styled(Accordion)``

export const Wrapper = styled.div`
  width: 100%;
`
