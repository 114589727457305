import { HTTPB2B } from 'providers'

interface OrderDetailsParams {
  orderNumber?: string
  column?: string
  direction?: string
  id?: string
  limit?: number
  page?: number
}

export class OrderService extends HTTPB2B {
  public async getOrderData(params: OrderDetailsParams) {
    return this.get({
      endpoint: '/traceability-order/details',
      params
    })
  }

  public async getOrderItens(params: OrderDetailsParams) {
    return this.get({
      endpoint: '/traceability-order/details/items',
      params
    })
  }
}
