import { calcOffsetForPagination } from './CalcOffsetForPagination'

export function onFilterPaginate(filters: any, limit, page) {
  const newFilter = {
    ...filters
  }

  newFilter.page = calcOffsetForPagination(
    filters.limit,
    filters.page,
    limit,
    page
  )
  newFilter.limit = limit

  return {
    ...filters,
    ...newFilter
  }
}
