import { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { IBGEService } from 'services'

import { toasts } from 'utils/toasts'

import { country } from './types'

import {
  INITIAL_LOCATION_STATE,
  ERRORS_LOCATION_STATE
} from 'constants/location.constants'

import {
  Button,
  Input,
  InputCep,
  InputSelect,
  ModalMapMarker
} from 'components'

import * as S from './styled'

type LatLngType = {
  lat: number
  lng: number
}

type CityType = {
  title: string
  value: string
  state: string
}

type PropTypes = {
  cities?: CityType[]
  errors: typeof ERRORS_LOCATION_STATE
  optionValueCity?: string
  payload: typeof INITIAL_LOCATION_STATE
  handleInput: (_value, _name) => void
  handleSelected: (_name: string) => (_value: any) => void
  handleSelectedIBGEState?: (_value, _name) => void
  handleSelectedIBGECity?: (_value, _name) => void
  handleSelectedMicroregion?: (_value, _name) => void
  handleSelectedCountry?: (_value, _name) => void
}

export function Location({
  errors,
  payload,
  handleInput,
  handleSelectedIBGEState,
  handleSelectedIBGECity,
  handleSelectedMicroregion,
  handleSelectedCountry
}: PropTypes) {
  const [hasError, setError] = useState(false)
  const [latLng, setLatLng] = useState<LatLngType>(null)
  const [states, setStates] = useState([])
  const [_cities, setCities] = useState([])
  const [country, setCountry] = useState([])
  const [microregion, setMicroregion] = useState('')
  const { t } = useTranslation()

  function viewInMap() {
    const lat = Number(payload.lat)
    const lng = Number(payload.lng)

    if (!lat || !lng) return setError(true)

    if (isNaN(parseFloat(lat.toString()))) return setError(true)
    if (isNaN(parseFloat(lng.toString()))) return setError(true)

    setError(false)
    setLatLng({ lat, lng })
  }

  function onExit() {
    setLatLng(null)
  }

  const fetchCountry = useCallback(async () => {
    if (!country.length) {
      const { data, success } = await new IBGEService().fetchAllCountry()

      if (!success) toasts.generalFail()

      setCountry(data as any)
    }
  }, [country.length])

  const fetchStates = useCallback(async () => {
    if (!states.length && payload.country) {
      const { data, success } = await new IBGEService().fetchAll()
      if (!success) toasts.generalFail()
      setStates(data as any)
    }
  }, [states.length, payload.country])

  const fetchCitiesByStateID = useCallback(async (id: string) => {
    const { data, success } = await new IBGEService().fetchCitiesByStateId(id)
    if (!success) toasts.generalFail()
    setCities(data as any)
  }, [])

  const fetchMicroregionById = useCallback(async (id: string) => {
    const { data, success } = await new IBGEService().fetchMicroregionInfoById(
      id
    )
    if (!success) toasts.generalFail()
    setMicroregion(data.nome)
  }, [])

  useEffect(() => {
    if (
      Number(payload.country) !== 76 &&
      payload.state &&
      payload.city &&
      payload.microregion
    ) {
      handleSelectedIBGEState('state', null)
      handleSelectedIBGECity('city', null)
      handleSelectedMicroregion('microregion', null)
      setMicroregion('')
      setStates([])
      setCities([])
    }
  }, [
    payload.country,
    payload.state,
    payload.city,
    payload.microregion,
    handleSelectedIBGEState,
    handleSelectedIBGECity,
    handleSelectedMicroregion
  ])

  useEffect(() => {
    fetchStates()
    fetchCountry()
    if (!_cities.length && payload.state) fetchCitiesByStateID(payload.state)
    if (payload.microregion && !microregion) {
      fetchMicroregionById(payload.microregion)
    }
  }, [
    fetchCountry,
    fetchStates,
    fetchCitiesByStateID,
    fetchMicroregionById,
    payload.state,
    payload.city,
    payload.microregion,
    microregion,
    _cities,
    states
  ])

  function handleCountryChange(val: country) {
    handleSelectedCountry('country', String(val.id.M49))
  }

  function handleStateChange(val) {
    handleSelectedIBGEState('state', val.id)
    fetchCitiesByStateID(val.id)
  }

  function handleCityChange(val) {
    handleSelectedIBGECity('city', val.id)
    handleSelectedMicroregion('microregion', val.microrregiao.id)
    setMicroregion(val.microrregiao.nome)
  }

  return (
    <>
      {latLng && <ModalMapMarker latLng={latLng} onExit={onExit} />}
      <S.GridFields container spacing={2}>
        {payload.country && (
          <S.GridInput item sm={12} md={4}>
            <InputSelect
              error={Boolean(errors.country)}
              fullWidth
              helperText={errors.country}
              label={t('newIndustry:localization:country')}
              onSelected={(val: country) => handleCountryChange(val)}
              optionLabel="nome"
              optionValue="id.M49"
              options={country || []}
              value={Number(payload.country)}
              loadingText="Aguarde..."
            />
          </S.GridInput>
        )}
        <S.GridInput item sm={12} md={4}>
          <InputSelect
            disabled={Number(payload.country) !== 76}
            error={Boolean(errors.state)}
            fullWidth
            helperText={errors.state}
            label={t('newIndustry:localization:state')}
            onSelected={val => handleStateChange(val)}
            optionLabel="nome"
            optionValue="id"
            options={states || []}
            value={Number(payload.state) || ''}
            loadingText="Aguarde..."
          />
        </S.GridInput>
        <S.GridInput item sm={12} md={4}>
          <InputSelect
            disabled={Number(payload.country) !== 76}
            error={Boolean(errors.city)}
            fullWidth
            helperText={errors.city}
            label={t('newIndustry:localization:city')}
            onSelected={val => handleCityChange(val)}
            optionLabel="nome"
            options={_cities || []}
            loading={true}
            loadingText="Aguarde.."
            optionValue="id"
            value={Number(payload.city) || ''}
          />
        </S.GridInput>
        <S.GridInput item sm={12} md={4}>
          <InputCep
            error={Boolean(errors.cep)}
            fullWidth
            helperText={errors.cep}
            label={t('newIndustry:localization:CEP')}
            onInput={value => handleInput(value, 'cep')}
            value={payload.cep}
          />
        </S.GridInput>
        <S.GridInput item sm={12} md={4}>
          <Input
            error={Boolean(errors.microregion)}
            fullWidth
            helperText={errors.microregion}
            label={t('newIndustry:localization:microRegion')}
            value={microregion || ''}
            disabled={true}
          />
        </S.GridInput>
        <S.GridInput item sm={12} md={4}>
          <Input
            error={Boolean(errors.address)}
            fullWidth
            helperText={errors.address}
            label={t('newIndustry:localization:address')}
            onInput={value => handleInput(value, 'address')}
            value={payload.address}
          />
        </S.GridInput>
        <S.GridInput item sm={12} md={4}>
          <S.InputCoordinates
            error={Boolean(errors.lat)}
            fullWidth
            helperText={errors.lat}
            label={t('newIndustry:localization:latitude')}
            onInput={value => handleInput(value, 'lat')}
            placeholder="-23.60916"
            value={payload.lat || ''}
          />
        </S.GridInput>
        <S.GridInput item sm={12} md={4}>
          <S.InputCoordinates
            error={Boolean(errors.lng)}
            fullWidth
            helperText={errors.lng}
            label={t('newIndustry:localization:longitude')}
            value={payload.lng || ''}
            placeholder="-51.66763"
            onInput={value => handleInput(value, 'lng')}
          />
        </S.GridInput>

        <S.GridButtonsMap item sm={12}>
          <Button variant="blue" onClick={viewInMap}>
            <p>{t('newIndustry:localization:viewMap')}</p>
          </Button>
          {hasError && (
            <S.ErrorMessage>
              {t('propertiesPage:HasErrorMessage')}
            </S.ErrorMessage>
          )}
        </S.GridButtonsMap>
      </S.GridFields>
    </>
  )
}
