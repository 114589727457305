import { useContext, useEffect, useState } from 'react'

import { SupplierFiltersContext } from 'contexts/supplierFilters/supplierFilters.context'
import { TraceabilityService } from 'services'
import { Table } from '../Tables'
import * as S from './styled'
import { useTranslation } from 'react-i18next'
import { ReportButton } from 'components'
import { ReportModal } from 'components/Traceability/Orders/ReportModal'

export function ProvidersTabContent({ loading }) {
  const { t, i18n } = useTranslation()

  const [isOpen, setIsOpen] = useState(false)
  const [rows, setRows] = useState({
    items: [],
    pagination: {
      page: 0,
      totalItems: 0,
      limit: 0,
      totalPages: 0
    }
  })

  const { supplierFilters } = useContext(SupplierFiltersContext)

  const getOrdersTableFromApi = async (isExport = false) => {
    const service = new TraceabilityService()
    const _filters = { ...supplierFilters }
    delete _filters.startDate
    delete _filters.endDate
    delete _filters.dateType

    if (isExport) {
      await service.exportSupplierReportData({
        ..._filters,
        language: i18n.language
      })
      return
    }

    await service.getProvidersTable({ ..._filters }).then(({ data }: any) => {
      setRows(data)
    })
  }

  useEffect(() => {
    getOrdersTableFromApi()
  }, [supplierFilters])

  async function handleExportReport() {
    setIsOpen(true)
    await getOrdersTableFromApi(true)
  }

  function onExit() {
    setIsOpen(false)
  }

  return (
    <S.Container loading={loading}>
      <S.Box>
        <S.BoxTitle>{t('traceabilityProviderPage:tables.title')}</S.BoxTitle>
        <S.BoxLine />
      </S.Box>
      <ReportButton onClick={handleExportReport} />
      {isOpen && <ReportModal onClickExport={onExit} />}
      <Table rows={rows} hasActions={false} variation={'providers'} />
    </S.Container>
  )
}
