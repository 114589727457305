import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { useHistory, useParams } from 'react-router-dom'

import * as Yup from 'yup'

import { omit } from 'lodash'

import { toasts } from 'utils/toasts'
import validaCNPJ from 'utils/validaCNPJ'
import { mapErrors } from 'utils/MapErrors'

import {
  IndustryGroupProfileService,
  IndustryService,
  UserStorageService
} from 'services'
import { YupValidator } from 'services/yupValidator.service'

import { isValid as validateCPF } from 'cpf'

import {
  CERTIFICATION_INITIAL_STATE,
  CONTACT_INITIAL_STATE,
  ERRORS_INITIAL_STATE,
  INITIAL_STATE
} from 'constants/industry.constants'

import { IndustryGroup } from './IndustryGroup/IndustryGroup'
import { IndustryPhotos } from './IndustryPhotos/IndustryPhotos'

import { BasicInformation } from './BasicInformation/BasicInformation'

import { Manager } from './Manager/Manager'
import { Technician } from './Technician/Technician'

import {
  Button,
  CertificationForm,
  ContactForm,
  InputAutocomplete,
  Location
} from 'components'

import * as S from './styled'

const contactsSchema = Yup.array()
  .of(
    Yup.object().shape({
      name: Yup.string().label('Nome'),
      email: Yup.string().email().label('Email'),
      phone: Yup.string().label('Telefone')
    })
  )
  .optional()

const technicianSchema = Yup.object().shape({
  bio: Yup.string().label('Mini Currículo'),
  doc: Yup.string().label('Número do Documento'),
  name: Yup.string().label('Nome')
})

const managerSchema = Yup.object()
  .shape({
    bio: Yup.string().label('Mini Currículo'),
    doc: Yup.string()
      .test('cpf', 'CPF inválido', value => {
        if (!value) return true
        if (value) {
          const valido = validateCPF(value)
          return valido
        }
        return false
      })
      .label('Número do Documento'),
    name: Yup.string().label('Nome')
  })
  .optional()

const certificationsSchema = Yup.array()
  .of(
    Yup.object().shape({
      name: Yup.string().required().label('Nome'),
      acronym: Yup.string().required().label('Sigla Certificação'),
      number: Yup.string().required().label('Número Certificação'),
      issuanceDate: Yup.string().required().label('Data Emissão'),
      expirationDate: Yup.string().required().label('Data Validade'),
      cnpjCert: Yup.string().required().label('CNPJ Certificadora'),
      corporateName: Yup.string()
        .required()
        .label('Razão Social Certificadora'),
      certifierEmail: Yup.string().email().label('Email').required().nullable(),
      certifierPhone: Yup.string().required().label('Telefone').nullable()
    })
  )
  .optional()

const IndustryFormSchema = Yup.object().shape({
  name: Yup.string().required().label('Razão Social'),
  fancyName: Yup.string().required().label('Nome Fantasia'),
  cnpj: Yup.string()
    .required()
    .test('cnpj', 'CNPJ inválido', value => {
      if (value) {
        const valido = validaCNPJ(value, false)
        return valido
      }
      return false
    })
    .label('CNPJ'),
  ie: Yup.string().required().label('Inscrição Estadual'),
  status: Yup.boolean().nullable(true).required().label('Status'),
  address: Yup.string().required().label('Endereço'),
  state: Yup.string().nullable(true).label('Estado'),
  city: Yup.string().nullable(true).label('Cidade'),
  cep: Yup.string().required().min(10, 'CEP Inválido').label('CEP'),
  lat: Yup.number()
    .nullable(true)
    .label('Latitude')
    .typeError('Latitude inválida, informe um número'),
  lng: Yup.number()
    .nullable(true)
    .label('Longitude')
    .typeError('Longitude inválida, informe um número'),
  companyProfiles: Yup.array().min(1).label('Tipo Indústria'),
  contacts: contactsSchema,
  technician: technicianSchema,
  manager: managerSchema,
  certifications: certificationsSchema
})

interface ParamTypes {
  id: string
}

export function IndustryForm() {
  const { t } = useTranslation()
  const { goBack } = useHistory()

  const { id } = useParams<ParamTypes>()

  const industryService = new IndustryService()

  const [form, setForm] = useState({ ...INITIAL_STATE })
  const [industry, setIndustry] = useState({ ...INITIAL_STATE })
  const [groupProfile, setGroupProfile] = useState(null)
  const [imgIndustry, setImgIndustry] = useState<File[]>([])
  const [errors, setErrors] = useState({ ...ERRORS_INITIAL_STATE })
  const [groupsProfileSelected, setGroupsProfileSelected] = useState([])
  const [deletedImgIndustry, setDeletedImgIndustry] = useState<string[]>([])
  const [toUploadImages, setUploadImages] = useState<Record<string, File>>({})

  const fetchIndustry = async () => {
    const { data } = await industryService.fetchOne(id)
    setIndustry(data as any)
  }

  function handleSelectImages(e: ChangeEvent<HTMLInputElement>) {
    if (!e.target.files) {
      return
    }

    const imgs = Array.from(e.target.files)

    const photos = []
    const images = []
    imgs.forEach((img, index) => {
      if (form.photos.length + (index + 1) <= 4) {
        images.push(img)
        photos.push({ filename: img.name, src: URL.createObjectURL(img) })
      }
    })

    setImgIndustry([...imgIndustry, ...images])
    setForm({ ...form, photos: [...form.photos, ...photos] })
  }

  const fetchGroupProfileOptions = useCallback(async () => {
    const { data, success } = await new IndustryGroupProfileService().fetchAll()
    if (!success) toasts.generalFail()

    const translateGroupOptions = data.map(item => ({
      ...item,
      label: t(`newIndustryGroup:groupProfile:${item.tag}`)
    }))

    setGroupProfile(translateGroupOptions)
  }, [])

  function onLoadImage({ name, field }: { name: string; field?: string }) {
    return function handleSelectImage(e: ChangeEvent<HTMLInputElement>) {
      if (!e.target.files) return

      const [rawImg] = Array.from(e.target.files)
      const img = { filename: rawImg.name, src: URL.createObjectURL(rawImg) }

      if (field) {
        setUploadImages(images => ({ ...images, [name]: rawImg }))
        setForm({ ...form, [name]: { ...form[name], [field]: img } })
        return
      }

      setUploadImages(images => ({ ...images, [name]: rawImg }))
      setForm({ ...form, [name]: img })
      setErrors({ ...errors, [name]: '' })
    }
  }

  function onRemoveImage({ name, field }: { name: string; field?: string }) {
    if (field) {
      setUploadImages(images => ({ ...images, [field]: null }))
      setForm({ ...form, [name]: { ...form[name], [field]: null } })
    } else {
      setUploadImages(images => ({ ...images, [name]: null }))
      setForm({ ...form, [name]: null })
    }
  }

  function handleRemoveItemImage(index) {
    const { photos } = form
    const { id } = photos.find((_, i) => i === index)

    if (id) {
      setDeletedImgIndustry([...deletedImgIndustry, id])
    }

    if (photos.length === 1) {
      setImgIndustry([])
      setForm({ ...form, photos: [] })
    } else {
      const imgs = [...photos]
      imgs.splice(index, 1)

      setImgIndustry([...imgs])
      setForm({ ...form, photos: [...imgs] })
    }
  }

  function handleInput(value: string, name: string) {
    setErrors({ ...errors, [name]: '' })
    setForm({ ...form, [name]: value })
  }

  function handleInputObject(value: string, name: string, field: string) {
    setErrors({ ...errors, [name]: { ...errors[name], [field]: '' } })
    setForm({ ...form, [name]: { ...form[name], [field]: value } })
  }

  function onFillContacts(key, val, index) {
    const updated = [...form.contacts]

    updated[index] = {
      ...updated[index],
      [key]: val
    }

    setForm({ ...form, contacts: updated })
  }

  function onFillCertifications(key, val, index) {
    const updated = [...form?.certifications]

    updated[index] = {
      ...updated[index],
      [key]: val
    }

    setForm({ ...form, certifications: updated })
  }

  function handleSelected(name: string) {
    return function handle(value) {
      setErrors({ ...errors, [name]: '' })
      setForm({ ...form, [name]: value.value })
    }
  }

  function handleSelectedIBGEState(name: string, value: any) {
    setErrors({ ...errors, [name]: '' })
    form.state = value
    setForm({ ...form })
  }

  const handleSelectedIBGECity = (name: string, value: any) => {
    setErrors({ ...errors, [name]: '' })
    form.city = value
    setForm({ ...form })
  }

  function handleSelectedMicroregion(name: string, value: any) {
    setErrors({ ...errors, [name]: '' })
    form.microregion = value
    setForm({ ...form })
  }

  function handleSelectedCountry(name: string, value: any) {
    setErrors({ ...errors, [name]: '' })
    form.country = value
    setForm({ ...form })
  }

  async function submit() {
    setErrors({
      ...ERRORS_INITIAL_STATE,
      contacts: form.contacts.map(_contact => CONTACT_INITIAL_STATE)
    })

    const certificationsVerify = form.certifications.filter(item => {
      const everyFieldIsEmpty = Object.values(omit(item, 'id')).every(
        value => !value
      )
      if (everyFieldIsEmpty) return null
      if (item.name) return form.certifications
      return (form.certifications = [])
    })

    const _form = {
      ...form,
      lat: form.lat || null,
      lng: form.lng || null
    }

    const checkIfValuesIsEmpty = value =>
      value && Object.values(value).every(value => !value || value === '')

    const managerIsEmpty = checkIfValuesIsEmpty(_form.manager)
    const contactsIsEmpty = _form.contacts.every(checkIfValuesIsEmpty)
    const technicianIsEmpty = checkIfValuesIsEmpty(_form.technician)

    if (managerIsEmpty) delete _form.manager
    if (contactsIsEmpty) delete _form.contacts
    if (technicianIsEmpty) delete _form.technician

    if (!certificationsVerify.length) delete _form.certifications
    const [isValid, validationError] = await new YupValidator(
      IndustryFormSchema
    ).validate(_form)
    if (!isValid) {
      toasts.invalidForm()
      return setErrors(validationError as typeof ERRORS_INITIAL_STATE)
    }

    fatchPhotos()

    const payload = {
      ..._form,
      toUploadImages,
      imgIndustry,
      deletedImgIndustry
    }

    const payloadCreate = {
      ..._form,
      toUploadImages,
      imgIndustry
    }

    if (id) {
      const { success, data } = await industryService.edit(payload)

      if (success) {
        toasts.edited()
        goBack()
      } else {
        if (data.code === 'DUPLICATED') {
          toast.error(data.message)
        }
        toasts.generalFail()
      }
    } else {
      const { success, data } = await industryService.create(payloadCreate)

      if (success) {
        toasts.created()
        goBack()
      } else {
        if (data.code === 'DUPLICATED') {
          toast.error(data.message)
        }
        toasts.generalFail()
      }
    }
  }

  async function fatchPhotos() {
    if (id && toUploadImages.sifPhoto) {
      const multipartForm = new FormData()
      multipartForm.set('file', toUploadImages.sifPhoto)

      await industryService.uploadSifPhoto({
        id,
        multipartForm
      })
    }

    if (id && toUploadImages.technician) {
      const multipartForm = new FormData()
      multipartForm.set('file', toUploadImages.technician)

      await industryService.uploadEmployeePhoto({
        id,
        multipartForm,
        name: 'technician'
      })
    }

    if (id && toUploadImages.manager) {
      const multipartForm = new FormData()
      multipartForm.set('file', toUploadImages.manager)

      await industryService.uploadEmployeePhoto({
        id,
        multipartForm,
        name: 'manager'
      })
    }

    if (id && imgIndustry) {
      const imgPost = []
      imgIndustry.map(async img => {
        const multipartForm = new FormData()

        multipartForm.set('file', img)
        const { photoId } = img as any

        if (!photoId) {
          imgPost.push(
            await industryService.uploadCompanyPhoto({
              id,
              multipartForm
            })
          )
        }
      })
    }

    if (id && deletedImgIndustry) {
      const imgDelete = []
      deletedImgIndustry.map(async del => {
        imgDelete.push(await industryService.removeCompanyPhoto({ id, del }))
      })
    }
  }

  function onAddItem() {
    const contacts =
      errors?.contacts?.length > 0
        ? errors?.contacts
        : mapErrors(errors, 'contacts')

    setErrors({
      ...errors,
      contacts: [...contacts, { ...CONTACT_INITIAL_STATE }]
    })

    setForm({
      ...form,
      contacts: [...form.contacts, { ...CONTACT_INITIAL_STATE }]
    })
  }

  function onAddCert() {
    const certifications =
      errors?.certifications?.length > 0
        ? errors?.certifications
        : mapErrors(errors, 'certifications')

    setErrors({
      ...errors,
      certifications: [...certifications, { ...CERTIFICATION_INITIAL_STATE }]
    })

    setForm({
      ...form,
      certifications: [
        ...form.certifications,
        { ...CERTIFICATION_INITIAL_STATE }
      ]
    })
  }

  function onRemoveItem(index) {
    const item = form.contacts

    const contacts =
      errors?.contacts?.length > 0
        ? errors?.contacts
        : mapErrors(errors, 'contacts')
    const itemError = contacts

    if (item.length === 1) {
      setForm({ ...form, contacts: [] })
      setErrors({ ...errors, contacts: [] })
      return
    }

    const updated = [...item]
    const updatedErrors = [...itemError]
    updated.splice(index, 1)
    updatedErrors.splice(index, 1)
    setForm({ ...form, contacts: updated })
    setErrors({ ...errors, contacts: updatedErrors })
  }

  function onRemoveCert(index) {
    const item = form.certifications

    const certifications =
      errors?.certifications?.length > 0
        ? errors?.certifications
        : mapErrors(errors, 'certifications')
    const itemError = certifications

    if (item.length === 1) {
      setForm({ ...form, certifications: [] })
      setErrors({ ...errors, certifications: [] })
      return
    }

    const updated = [...item]
    const updatedErrors = [...itemError]
    updated.splice(index, 1)
    updatedErrors.splice(index, 1)
    setForm({ ...form, certifications: updated })
    setErrors({ ...errors, certifications: updatedErrors })
  }

  function onExit() {
    goBack()
    setForm({ ...INITIAL_STATE })
  }

  function handleAutocomplete(field: string, value: any) {
    setErrors({ ...errors, [field]: '' })
    setForm({ ...form, [field]: value.map(item => ({ id: item.id })) })
    setGroupsProfileSelected(value)
  }

  const getPreSelectedProfiles = () => {
    const profilesListPreSelected = []
    industry.companyProfiles?.forEach(companyProfileId => {
      const profilesMatched = groupProfile?.find(
        profile => companyProfileId.id === profile.id
      )
      profilesListPreSelected.push(profilesMatched)
    })
    setGroupsProfileSelected(profilesListPreSelected)
  }

  useEffect(() => {
    if (id) {
      fetchIndustry()
    }
  }, [])

  useEffect(() => {
    if (!groupProfile) fetchGroupProfileOptions()
  }, [fetchGroupProfileOptions])

  useEffect(() => {
    if (id && industry.companyProfiles?.length) getPreSelectedProfiles()
  }, [industry])

  useEffect(() => {
    if (Object.keys(industry).length && industry.id === id) {
      const _form = {
        ...omit(industry, ['employees', 'photos'])
      } as typeof INITIAL_STATE
      _form.companyProfiles = industry.companyProfiles
      if (!_form.contacts?.length) {
        _form.contacts = [{ ...CONTACT_INITIAL_STATE }]
      }
      industry?.employees?.forEach(employee => {
        _form[employee.role] = {
          bio: employee.employee.bio,
          doc: employee.employee.doc,
          name: employee.employee.name,
          photo: employee.employee.photo
        }
      })
      if (
        // industry.certifications.length === 1 &&
        // !industry.certifications[0].cnpjCert
        !industry?.certifications?.length
      ) {
        _form.certifications = [{ ...CERTIFICATION_INITIAL_STATE }]
      }

      const _photos = []
      industry?.photos?.forEach(photo => {
        const { file, id } = photo as any
        _photos.push({ ...file, photoId: id })
      })
      _form.photos = _photos

      if (_form.lat === null) {
        _form.lat = ''
      }

      if (_form.lng === null) {
        _form.lng = ''
      }

      setErrors({
        ...ERRORS_INITIAL_STATE,
        contacts: _form?.contacts?.map(_contact => CONTACT_INITIAL_STATE)
      })

      setForm({ ..._form })
    }
  }, [industry, groupProfile])

  const permissionEdit = () => {
    const {
      user: { role }
    } = UserStorageService.getUserData()

    return role === 'admind'
  }

  return (
    <S.Wrapper container>
      <S.FullGrid item xs={12}>
        <S.Fieldset>
          <S.GridHeader>
            <S.GridTitle item container xs={12}>
              <S.BoxTitle>
                {t('newIndustry:basicInformation:basicInformation')}
              </S.BoxTitle>
            </S.GridTitle>
          </S.GridHeader>

          <BasicInformation
            disabled={permissionEdit()}
            payload={form}
            errors={errors}
            handleInput={handleInput}
            handleSelected={handleSelected}
          />
        </S.Fieldset>

        <S.Fieldset>
          <S.GridHeader>
            <S.GridTitle item container xs={12}>
              <S.BoxTitle>
                {t('newIndustry:typeIndustry:typeIndustry')}
              </S.BoxTitle>
            </S.GridTitle>
          </S.GridHeader>

          <S.GridFields container>
            <S.GridInput item sm={12} md={12} justifyContent="center">
              <InputAutocomplete
                fullWidth
                error={Boolean(errors.companyProfiles)}
                helperText={errors.companyProfiles}
                label={t('newIndustryGroup:groupProfile:groupProfile')}
                options={groupProfile || []}
                optionLabel="label"
                defaultValue={groupsProfileSelected}
                loading={true}
                loadingText="Aguarde..."
                onSelected={val => handleAutocomplete('companyProfiles', val)}
                disabled={permissionEdit()}
              />
            </S.GridInput>
          </S.GridFields>
        </S.Fieldset>

        <S.Fieldset>
          <S.GridHeaderCertification>
            <S.GridTitleCertification item container xs={12}>
              <S.BoxTitleCertification>
                {t('newIndustry:certifications:certifications')}
              </S.BoxTitleCertification>
            </S.GridTitleCertification>
          </S.GridHeaderCertification>

          {form.certifications.map((certification, i) => (
            <CertificationForm
              errors={errors}
              index={i}
              key={i}
              payload={certification}
              onFill={onFillCertifications}
              removeButton={Boolean(form.certifications.length > 1)}
              onRemove={() => onRemoveCert(i)}
            />
          ))}

          <S.GridFields container spacing={2}>
            <S.GridButtonsAdd item sm={12}>
              <Button variant="blue" onClick={onAddCert}>
                <p>{t('newIndustry:certifications:addCertification')}</p>
              </Button>
            </S.GridButtonsAdd>
          </S.GridFields>
        </S.Fieldset>

        <S.DividerHorizontal />

        <S.Fieldset>
          <S.GridHeader>
            <S.GridTitle item container xs={12}>
              <S.BoxTitle>
                {t('newIndustry:localization:localization')}
              </S.BoxTitle>
            </S.GridTitle>
          </S.GridHeader>
          <Location
            // cities={cities}
            errors={errors}
            optionValueCity="title"
            payload={form}
            handleInput={handleInput}
            handleSelected={handleSelected}
            handleSelectedIBGEState={handleSelectedIBGEState}
            handleSelectedIBGECity={handleSelectedIBGECity}
            handleSelectedMicroregion={handleSelectedMicroregion}
            handleSelectedCountry={handleSelectedCountry}
          />
        </S.Fieldset>

        <S.Fieldset>
          <S.GridHeaderContact>
            <S.GridTitleContact item container xs={12}>
              <S.BoxTitleContact>
                {t('newIndustry:contactData:contactData')}
              </S.BoxTitleContact>
            </S.GridTitleContact>
          </S.GridHeaderContact>

          {form.contacts.map((contact, i) => (
            <ContactForm
              errors={errors}
              index={i}
              key={i}
              payload={contact}
              onFill={onFillContacts}
              removeButton={Boolean(form.contacts.length > 1)}
              onRemove={() => onRemoveItem(i)}
            />
          ))}

          <S.GridFields container spacing={2}>
            <S.GridButtonsAdd item sm={12}>
              <Button variant="blue" onClick={onAddItem}>
                <p>{t('newIndustry:contactData:addContact')}</p>
              </Button>
            </S.GridButtonsAdd>
          </S.GridFields>
        </S.Fieldset>

        <S.DividerHorizontal />

        {id && (
          <S.Fieldset>
            <S.GridHeader>
              <S.GridTitle item container xs={12}>
                <S.BoxTitle>Indústria relacionada ao(s) grupo(s)</S.BoxTitle>
              </S.GridTitle>
            </S.GridHeader>

            <IndustryGroup payload={form.groups} />
          </S.Fieldset>
        )}

        <S.Fieldset>
          <S.GridHeader>
            <S.GridTitle item container xs={12}>
              <S.BoxTitle>
                {t('newIndustry:responsibleTechnician:responsibleTechnician')}
              </S.BoxTitle>
            </S.GridTitle>
          </S.GridHeader>

          <Technician
            payload={form}
            errors={errors}
            handleInput={handleInputObject}
            onLoadImage={onLoadImage({ name: 'technician', field: 'photo' })}
            onRemoveImage={() =>
              onRemoveImage({ name: 'technician', field: 'photo' })
            }
          />
        </S.Fieldset>

        <S.Fieldset>
          <S.GridHeader>
            <S.GridTitle item container xs={12}>
              <S.BoxTitle>
                {t('newIndustry:responsibleManager:responsibleManager')}
              </S.BoxTitle>
            </S.GridTitle>
          </S.GridHeader>

          <Manager
            payload={form}
            errors={errors}
            handleInput={handleInputObject}
            onLoadImage={onLoadImage({ name: 'manager', field: 'photo' })}
            onRemoveImage={() =>
              onRemoveImage({ name: 'manager', field: 'photo' })
            }
          />
        </S.Fieldset>

        <S.Fieldset>
          <S.GridHeader container>
            <S.GridTitle item xs={12}>
              <S.BoxTitle>{t('newIndustry:industryPhoto')}</S.BoxTitle>
            </S.GridTitle>
          </S.GridHeader>

          <S.GridFieldsPhoto>
            <IndustryPhotos
              industryPhotos={form.photos}
              handleSelectImages={handleSelectImages}
              handleRemoveItemImage={handleRemoveItemImage}
            />
          </S.GridFieldsPhoto>
        </S.Fieldset>
      </S.FullGrid>
      <S.GridButtons item xs={12}>
        <Button variant="gray" onClick={onExit} size="medium">
          <p>{t('common:Cancel')}</p>
        </Button>
        <Button variant="green" onClick={submit} size="medium">
          <p>{t('common:Save')}</p>
        </Button>
      </S.GridButtons>
    </S.Wrapper>
  )
}
