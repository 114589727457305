import { AccordionDetails } from 'components'
import styled from 'styled-components'

export const Wrapper = styled.div`
  border-radius: 1.2rem;
  margin: 0 0 20px;
  width: 100%;
`

export const TotalizersContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(33rem, 1fr));
  width: 100%;
  gap: 2rem;
  margin-bottom: 5.6rem;

  @media (max-width: 1570px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 875px) {
    grid-template-columns: 1fr;
    margin-top: 0rem;
  }
`

export const ChartsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
  width: 100%;
  gap: 2rem;
  margin-bottom: 5.6rem;
`

export const SummaryWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
  margin-bottom: 3rem;
`

export const SummaryContainer = styled.div`
  width: 100%;
  height: 10rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(40rem, 1fr));
  align-items: baseline;
  gap: 2rem;

  margin-top: 5rem;

  @media (max-width: 875px) {
    margin-bottom: 3rem;
  }

  @media (max-width: 986px) {
    :nth-last-child(even) {
      margin-bottom: 12rem;
      gap: 4rem;
    }
  }
`

export const SummaryDetails = styled.div`
  width: 100%;
  max-height: 50rem;
  overflow-y: auto;
  display: grid;
  gap: 2rem;
  margin-bottom: 5rem;

  @media (max-width: 875px) {
    margin-top: 5rem;
    grid-template-columns: 1fr;
  }
`
export const AccordionDetailsContainer = styled.div``

export const AccordionDetailsComponent = styled(AccordionDetails)``
