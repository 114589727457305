/**
 * Forma o cep
 * @param value value cep que vai ser formatado
 * @returns value do telefone formatado
 */
export const CepMask = (value: string) => {
  if (!value) return value
  let valMask = value.replace(/\D/g, '')

  valMask = valMask.replace(/\D/g, '')
  valMask = valMask.replace(/(\d{2})(\d)/, '$1.$2')
  valMask = valMask.replace(/(\d{3})(\d)/, '$1-$2')
  valMask = valMask.replace(/(\d{3})\d+?$/, '$1')

  return valMask
}
