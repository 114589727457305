import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`

export const Input = styled.input`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  appearance: none;
  width: 1.8rem;
  height: 1.8rem;
  border: 0.1rem solid ${({ theme }) => theme.colors.lightGray};
  border-radius: 0.2rem;
  transition: background border ${({ theme }) => theme.transition.fast};
  position: relative;
  outline: none;

  &:before {
    content: '';
    width: 0.6rem;
    height: 0.9rem;
    border: 0.2rem solid ${({ theme }) => theme.colors.white};
    border-top: 0;
    border-left: 0;
    transform: rotate(45deg);
    position: absolute;
    top: 0.1rem;
    opacity: 0;
    transition: ${({ theme }) => theme.transition.fast};
  }

  &:focus {
    box-shadow: 0 0 0.5rem ${({ theme }) => theme.colors.secondary};
  }

  &:checked {
    border-color: ${({ theme }) => theme.colors.secondary};
    background: ${({ theme }) => theme.colors.secondary};
    &:before {
      opacity: 1;
    }
  }

  &:disabled {
    cursor: not-allowed;
    border-color: ${({ theme }) => theme.colors.lightGray.alpha(0.4)};
  }
`

export const Label = styled.label`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.darkGray};
  padding-left: ${({ theme }) => theme.spacings.xxsmall};
  line-height: 1.8rem;
  font-size: 1.4rem;
`
