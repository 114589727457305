export const types = Object.freeze({
  GET_ALL_LANG: '@language/fetchAll',
  SET_LANG: '@language/setLang'
})

export function getAll() {
  return { type: types.GET_ALL_LANG }
}

export function setLanguage({ languages = [] } = {}) {
  return { type: types.SET_LANG, payload: { languages } }
}
