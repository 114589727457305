import { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import * as Yup from 'yup'
import {
  IndustryGroupProfileService,
  IndustryGroupService,
  IndustryService,
  UserStorageService
} from 'services'

import { toasts } from 'utils/toasts'
import { YupCNPJ } from 'utils/YupCNPJ'
import { submitOnEnter } from 'utils/submitOnEnter'
import { onFilterPaginate } from 'utils/OnFilterPaginate'

import { Input } from 'components/Input'
import { Button } from 'components/Button'
import { Loading } from 'components/Loading'
import { IndustryTable } from './IndustryTable'
import { AddButton } from 'components/AddButton'
import { Pagination } from 'components/Pagination'
import { InputSelect } from 'components/InputSelect'
import { InputCpfCnpj } from 'components/InputCpfCnpj'

import * as S from './styled'

const INITIAL_STATE = {
  cnpj: '',
  name: '',
  group: '',
  fancyName: '',
  ie: '',
  companyProfiles: '',
  limit: 10,
  page: 0
}

const INITIAL_STATE_INDUSTRIES = {
  items: [],
  pagination: {
    limit: 0,
    page: 0,
    totalItems: 0,
    totalPages: 0
  }
}

const schema = Yup.object().shape({
  name: Yup.string(),
  fancyName: Yup.string(),
  cnpj: Yup.lazy(data => {
    if (data) return YupCNPJ.verify()
    return Yup.string()
  }),
  ie: Yup.string(),
  companyProfiles: Yup.string(),
  group: Yup.string()
})

export function IndustryList() {
  const { t } = useTranslation()

  const industriesService = new IndustryService()
  const industryGroupService = new IndustryGroupService()

  const [loading, setLoading] = useState(false)
  const [groupOptions, setGroupOptions] = useState([])
  const [groupProfile, setGroupProfile] = useState(null)
  const [attIndustryList, setAttIndustryList] = useState(false)
  const [errors, setErrors] = useState({ ...INITIAL_STATE })
  const [filter, setFilter] = useState({ ...INITIAL_STATE })
  const [industries, setIndustries] = useState(INITIAL_STATE_INDUSTRIES)

  const fetchAllIndustries = async () => {
    setLoading(true)
    try {
      const { data } = await industriesService.fetchAll(filter)
      setIndustries(data as any)
    } catch (error) {
      console.log('fetchAllIndustries-error', error)
    } finally {
      setLoading(false)
    }
  }

  const fetchIndustryGroup = async () => {
    try {
      const { data } = await industryGroupService.fetchAll(filter)
      if (data.code === 'NOT_FOUND') return
      setGroupOptions(data.items as any)
    } catch (error) {
      console.log('fetchUserRole-ERROR', error)
    }
  }

  useEffect(() => {
    fetchAllIndustries()
    fetchIndustryGroup()
  }, [attIndustryList])

  const fetchGroupProfileOptions = useCallback(async () => {
    const { data, success } = await new IndustryGroupProfileService().fetchAll()
    if (!success) toasts.generalFail()

    const translateGroupOptions = data.map(item => ({
      ...item,
      label: t(`newIndustryGroup:groupProfile:${item.tag}`)
    }))

    translateGroupOptions.unshift({
      id: '',
      label: t('newIndustryGroup:groupProfile:all')
    })

    setGroupProfile(translateGroupOptions)
  }, [])

  const onFilter = async () => {
    setLoading(true)
    try {
      const params = await schema.validate(filter, {
        abortEarly: false,
        stripUnknown: true
      })
      const { data } = await industriesService.fetchAll(params)
      if (data.code === 'NOT_FOUND') {
        return setIndustries(INITIAL_STATE_INDUSTRIES)
      }
      setIndustries(data as any)
    } catch (ex) {
      const pairs = ex.inner.map(({ path, message }) => [
        path,
        message.replace(`${path} `, '')
      ])
      setErrors(Object.fromEntries(pairs) as typeof INITIAL_STATE)
    } finally {
      setLoading(false)
    }
  }

  const onFilterPaginateIndustry = async ({ limit, page }) => {
    const filters = onFilterPaginate(industries.pagination, limit, page)
    const { data } = await industriesService.fetchAll(filters)
    setIndustries(data as any)
  }

  const clearFilter = useCallback(() => {
    setFilter(INITIAL_STATE)
    setErrors({ ...INITIAL_STATE })
    fetchAllIndustries()
  }, [])

  function handleInput(value: string, name: string) {
    setErrors({ ...errors, [name]: '' })
    setFilter({ ...filter, [name]: value })
  }

  function Permission() {
    const {
      user: { permissions, role }
    } = UserStorageService.getUserData()
    if (permissions.industries && role !== 'admind') {
      return (
        <S.ButtonAdd>
          <Link to="/industria/novo">
            <AddButton />
          </Link>
        </S.ButtonAdd>
      )
    } else {
      return null
    }
  }

  useEffect(() => {
    if (!groupProfile) fetchGroupProfileOptions()
  }, [fetchGroupProfileOptions])

  return (
    <S.Wrapper container>
      <Permission />
      <S.FullGrid item xs={12}>
        <S.GridHeader>
          <S.GridTitle item container xs={12}>
            <S.BoxTitle>
              {t('generalIndustriesPage:filter:filterIndustries')}
            </S.BoxTitle>
            <S.BoxLine />
          </S.GridTitle>
        </S.GridHeader>
        <S.GridFilter container>
          <S.GridInput item xs={12} sm={6} md={3}>
            <Input
              fullWidth
              error={Boolean(errors.name)}
              helperText={errors.name}
              label={t('generalIndustriesPage:filter:corporateName')}
              onInput={value => handleInput(value, 'name')}
              onKeyPress={submitOnEnter(onFilter)}
              value={filter.name}
            />
          </S.GridInput>
          <S.GridInput item xs={12} sm={6} md={3}>
            <Input
              error={Boolean(errors.fancyName)}
              fullWidth
              helperText={errors.fancyName}
              label={t('generalIndustriesPage:filter:fantasyName')}
              onInput={value => handleInput(value, 'fancyName')}
              onKeyPress={submitOnEnter(onFilter)}
              value={filter.fancyName}
            />
          </S.GridInput>
          <S.GridInput item xs={12} sm={6} md={3}>
            <InputCpfCnpj
              error={Boolean(errors.cnpj)}
              fullWidth
              helperText={errors.cnpj}
              label={t('generalIndustriesPage:filter:CNPJ')}
              onInput={value => handleInput(value, 'cnpj')}
              onKeyPress={submitOnEnter(onFilter)}
              type="cnpj"
              value={filter.cnpj}
            />
          </S.GridInput>
          <S.GridInput item xs={12} sm={6} md={3}>
            <Input
              error={Boolean(errors.ie)}
              fullWidth
              helperText={errors.ie}
              label={t('generalIndustriesPage:filter:stateRegistration')}
              onInput={value => handleInput(value, 'ie')}
              onKeyPress={submitOnEnter(onFilter)}
              value={filter.ie}
            />
          </S.GridInput>
        </S.GridFilter>
        <S.GridFilter container>
          <S.GridInput item xs={12} sm={6} md={3}>
            <InputSelect
              error={Boolean(errors.companyProfiles)}
              fullWidth
              helperText={errors.companyProfiles}
              label={t('generalIndustriesPage:filter:typeIndustries:type')}
              onSelected={({ id }) => handleInput(id, 'companyProfiles')}
              optionLabel="label"
              options={groupProfile}
              optionValue="id"
              value={filter.companyProfiles}
            />
          </S.GridInput>
          <S.GridInput item xs={12} sm={6} md={3}>
            <InputSelect
              disabled={groupOptions.length < 1}
              error={Boolean(errors.group)}
              fullWidth
              helperText={errors.group}
              label={t('generalIndustriesPage:filter:grupIndustries:group')}
              onSelected={({ id }) => handleInput(id, 'group')}
              optionLabel="name"
              options={groupOptions}
              optionValue="id"
              value={filter.group}
            />
          </S.GridInput>
          <S.GridButtons item xs={12} sm={12} md={12}>
            <Button variant="gray" onClick={clearFilter} size="medium">
              <p>{t('generalIndustriesPage:filter:clean')}</p>
            </Button>
            <Button variant="blue" onClick={onFilter} size="medium">
              <p>{t('generalIndustriesPage:filter:filter')}</p>
            </Button>
          </S.GridButtons>
        </S.GridFilter>
        <S.GridTable>
          {loading ? (
            <Loading height="50rem" />
          ) : (
            <>
              <IndustryTable
                isDeleted={setAttIndustryList}
                rows={industries.items}
              />
              {industries.pagination?.totalItems > 0 && (
                <Pagination
                  limit={industries.pagination.limit}
                  page={industries.pagination.page}
                  totalItems={industries.pagination.totalItems}
                  setFilter={onFilterPaginateIndustry}
                />
              )}
            </>
          )}
        </S.GridTable>
      </S.FullGrid>
    </S.Wrapper>
  )
}
