import { useEffect, useState } from 'react'
import { ReportFileService } from 'services/downloadReport.service'
import { Container, Card, Row } from './styles'
import { Button } from 'components'
import { useTranslation } from 'react-i18next'

export function DownloadReport() {
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()

  const downloadReport = async () => {
    setLoading(true)

    const routeParam = window.location.pathname.split('/')
    const filePath = window.location.pathname.replace('/download-report/', '')
    const fileName = routeParam[routeParam.length - 1]

    const service = new ReportFileService()

    await service.getReportFile(filePath).then(response => {
      const data = Buffer.from(response.data.file)
      const url = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      link.parentNode.removeChild(link)
    })
    setLoading(false)
  }

  useEffect(() => {
    downloadReport()
  }, [])

  return (
    <Container>
      <Card>
        {loading ? (
          <h1>{t('downloadReport:loading')}</h1>
        ) : (
          <>
            <h1>{t('downloadReport:fileReady')}</h1>
            <br />
            <Row>
              <Button onClick={downloadReport}>
                {t('downloadReport:downloadAgain')}
              </Button>
              <Button variant="gray" onClick={window.close}>
                {t('downloadReport:cancel')}
              </Button>
            </Row>
          </>
        )}
      </Card>
    </Container>
  )
}
