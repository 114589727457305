import { get } from 'lodash'

import { types } from './Spinner.actions'

type Spinner = { visible: boolean }

const INITIAL_STATE = { visible: false }

function setState(state: Spinner) {
  return function _handle(payload: Record<string, any>) {
    return { ...state, ...payload }
  }
}

function toggleVisible(state: Spinner) {
  return function _handle() {
    return { ...state, visible: !state.visible }
  }
}

export default function _reducer(state = INITIAL_STATE, { type, payload }) {
  const _handlers = {
    [types.TOGGLE]: toggleVisible(state),
    [types.SET]: setState(state),
    _default: () => state
  }

  return get(_handlers, type, _handlers._default)(payload)
}
