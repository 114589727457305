import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { IconButton } from '@material-ui/core'
import { Dropdown } from '../../../../Dropdown'
import { Icon } from '../../../../Icon'

import * as S from './styles'

type IndustryGroupDropdownProps = {
  id: string
  industryGroupName?: string
  setDeleteIndustryGroup?: () => void
}

export const IndustryGroupDropdown = ({
  id,
  industryGroupName,
  setDeleteIndustryGroup
}: IndustryGroupDropdownProps) => {
  const { t } = useTranslation()
  return (
    <S.Wrapper>
      <Dropdown
        title={
          <IconButton
            id="action"
            color="inherit"
            aria-label="open drawer"
            onClick={() => {}}
            size="medium"
            edge="start"
          >
            <Icon
              icon="itens-interface-actions"
              width={12}
              height={12}
              aria-label="action"
            />
          </IconButton>
        }
      >
        <S.Nav>
          <Link
            to={{
              pathname: `/grupo-industria/gerenciar/${id}`,
              state: { nameBreadcrumbs: `Grupo ${industryGroupName}` }
            }}
          >
            <S.Link>
              <Icon icon="dropdown-config" height={16} width={16}></Icon>
              <span>{t('generalIndustryGroup:actions:manageGroup')}</span>
            </S.Link>
          </Link>
          <Link to={`/grupo-industria/editar/${id}`}>
            <S.Link>
              <Icon icon="dropdown-edit" height={16} width={16}></Icon>
              <span>{t('generalIndustryGroup:actions:edit')}</span>
            </S.Link>
          </Link>
          <S.Link onClick={setDeleteIndustryGroup}>
            <Icon icon="dropdown-delete" height={16} width={16}></Icon>
            <span>{t('generalIndustryGroup:actions:deleteGroup')}</span>
          </S.Link>
        </S.Nav>
      </Dropdown>
    </S.Wrapper>
  )
}
