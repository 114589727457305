export const INITIAL_SERIES_STATE = [
  {
    name: 'Pedido',
    color: '#315ca7',
    data: [],
    dataSorting: {
      enabled: true
    }
  }
]

export const INITIAL_DELIVERED_OPEN_STATE = () => [
  {
    name: 'ordersDelivered',
    color: '#315ca7',
    data: [],
    stack: 'delivered'
  },
  // Comentado porque no momento não temos os dados concreto de volume
  // {
  //   name: t('traceabilityRetailPage:chartsRetail.volumesDelivered'),
  //   color: '#3fab36',
  //   data: [],
  //   stack: 'delivered'
  // },
  {
    name: 'openOrders',
    color: '#70C3F7',
    data: [],
    stack: 'open'
  }
  // Comentado porque no momento não temos os dados concreto de volume
  // {
  //   name: t('traceabilityRetailPage:chartsRetail.openVolumes'),
  //   color: '#8DC979',
  //   data: [],
  //   stack: 'open'
  // }
]

export const INITIAL_DELAYED_SERIES = [
  {
    name: 'orders',
    color: '#315ca7',
    data: [],
    stack: 'pedidos'
  }
  // {
  //   name: 'volumes',
  //   color: '#3fab36',
  //   data: [],
  //   stack: 'volumes'
  // }
]

export const INITIAL_RANKING_STATE = [
  {
    name: '',
    y: 0
  }
]

export const tabOptions = [
  {
    tag: 'providers',
    label: 'providers'
  }
]

export const accordionItems = [
  {
    tag: 'providers',
    icon: 'confeccao',
    title: 'providers',
    hasActionButton: true,
    accordionDetailsButtonText: 'subProviders'
  },
  {
    tag: 'subProviders',
    icon: 'subproviders',
    title: 'subProviders',
    hasActionButton: true,
    accordionDetailsButtonText: 'providers'
  }
]

export const providerRequestPayload = {
  order: {
    startDate: 'orderStartDate',
    endDate: 'orderEndDate'
  },

  invoice: {
    startDate: 'invoiceSupplierStartDate',
    endDate: 'invoiceSupplierEndDate'
  },

  production: {
    startDate: 'productionStartDate',
    endDate: 'productionEndDate'
  },
  prediction: {
    startDate: 'predictionStartDate',
    endDate: 'predictionEndDate'
  }
}
