import styled from 'styled-components'

export const Wrapper = styled.div`
  display: table;
  > div {
    border: 2px solid ${({ theme }) => theme.colors.white};
    background-color: ${({ theme, color }) => {
      if (color) {
        return color
      } else return theme.colors.primary
    }};
    box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.5);
    font-weight: 600;
    pointer-events: auto;

    &.small {
      font-size: 2rem;
      height: 3.5rem;
      width: 3.5rem;
    }

    &.medium {
      font-size: 9rem;
      height: 12.6rem;
      width: 12.6rem;
    }
  }
`
