import { ReactNode } from 'react'
import clsx from 'clsx'
import Toolbar from '@material-ui/core/Toolbar'
import { makeStyles } from '@material-ui/core/styles'

import * as S from './styled'

import { MediaMatch } from 'components/MediaMatch'
import { UserDropdown } from 'components/UserDropdown'
import { LangDropdown } from 'components/LangDropdown'

export type TopbarProps = {
  loading?: boolean
  button?: ReactNode
  setChangePassword?: () => void
}

const useStyles = makeStyles(() => ({
  toolbar: {
    backgroundColor: '#ffffff',
    paddingLeft: 24
  }
}))

export function Topbar({ loading, button, setChangePassword }: TopbarProps) {
  const classes = useStyles()
  return (
    <Toolbar data-testid="topbar" className={clsx(classes.toolbar)}>
      {button}

      {!loading && (
        <>
          <S.MenuGroup>
            <S.Langdropdown>
              <LangDropdown />
            </S.Langdropdown>
            <MediaMatch data-testid="dropdown" greaterThan="medium">
              <UserDropdown setChangePassword={setChangePassword} />
            </MediaMatch>
          </S.MenuGroup>
        </>
      )}
    </Toolbar>
  )
}
