import * as S from './styled'

export type TotalizerProps = {
  primaryText: string
  primaryValue: any
  secondaryText?: string
  secondaryValue?: any
}

export function Totalizer({
  primaryText,
  primaryValue,
  secondaryText,
  secondaryValue
}: TotalizerProps) {
  return (
    <S.Wrapper hasSecondText={!!secondaryText}>
      <S.FieldWrapper>
        <S.Title>{primaryText}</S.Title>
        <span>{primaryValue}</span>
      </S.FieldWrapper>
      {secondaryText && (
        <S.FieldWrapper>
          <S.Title>{secondaryText}</S.Title>
          <span>{secondaryValue}</span>
        </S.FieldWrapper>
      )}
    </S.Wrapper>
  )
}
