import { ReactNode } from 'react'
import { Helmet } from 'react-helmet'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'

import { Breadcrumbs, Footer, GoBack, Sidebar } from 'components'

import { menuRoutes } from 'utils/routes'

interface stateTypes {
  nameBreadcrumbs: string
}

type Props = {
  children: ReactNode
  lang?: string
  title?: string
  name?: string
}

export function Base({
  children,
  lang = 'pt-BR',
  name = 'Ecotrace Solutions',
  title = ''
}: Props) {
  const { pathname, state } = useLocation<stateTypes>()
  const { path, params } = useRouteMatch()
  const { goBack } = useHistory()

  const crumbs = menuRoutes()
    .filter(({ link }) => path.includes(link))
    .map(({ link, ...rest }) => {
      if (Object.keys(params).length) {
        return {
          link: Object.keys(params).reduce(
            (path, param) => path.replace(`:${param}`, params[param]),
            link
          ),
          ...rest
        }
      } else return { link, ...rest }
    })

  if (state && state.nameBreadcrumbs) {
    crumbs[crumbs.length - 1].labelPage = state.nameBreadcrumbs
  }

  return (
    <>
      <main>
        <Helmet
          htmlAttributes={{ lang }}
          title={title}
          titleTemplate={title ? `%s | ${name}` : name}
        />
        <Sidebar pathname={pathname} itens={menuRoutes}>
          {pathname !== '/' && <GoBack goBack={goBack} />}
          <Breadcrumbs crumbs={crumbs} />
          {children}
        </Sidebar>
      </main>
      <footer>
        <Footer />
      </footer>
    </>
  )
}
