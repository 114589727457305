import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { LeadTimeService } from 'services/leadTime.service'

import { onFilterPaginate } from 'utils/OnFilterPaginate'
import { formaDateStringLocale } from 'utils/formaDateStringLocale'

import {
  INITIAL_STATE_ORDER_SUMMARY,
  orderSummary
} from 'pages/LeadTime/leadTime.constants'
import {
  IQueryParameters,
  OrderSummaryTableProps
} from 'pages/LeadTime/leadTime.types'

import { Loading, Pagination, Table } from 'components'

import * as S from '../../styled'

export function OrderSummaryTable({ filters }) {
  const { t } = useTranslation()
  const { id: orderNumber }: IQueryParameters = useParams()

  const [columnSorted, setColumnSorted] = useState('orderDate')
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC'>('DESC')
  const [loading, setLoading] = useState(false)
  const [orderSummaryTable, setOrderSummaryTable] =
    useState<OrderSummaryTableProps>(INITIAL_STATE_ORDER_SUMMARY)

  const getOrderSummaryFromApi = async (page?: number, limit?: number) => {
    setLoading(true)
    try {
      const service = new LeadTimeService()

      await service
        .getOrderSummary({
          ...filters,
          column: columnSorted,
          direction: sortDirection,
          orderNumber,
          page,
          limit
        })
        .then(response => {
          const { data } = response
          setOrderSummaryTable(data as OrderSummaryTableProps)
        })
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const formattedOrderSummaryData = orderSummaryTable.items.map(item => ({
    ...item,
    deliveryForecast: item.deliveryForecast
      ? formaDateStringLocale(item.deliveryForecast)
      : null,
    endDate: item.endDate ? formaDateStringLocale(item.endDate) : null,
    orderDate: item.orderDate ? formaDateStringLocale(item.orderDate) : null,
    leadTime: `${item.leadTime ? item.leadTime : 0} ${t(
      'leadTimePage:tables:generalData:days'
    )}`
  }))

  function handlePaginationFilter({ limit, page }) {
    const filtersPagination = onFilterPaginate(filters, limit, page)

    getOrderSummaryFromApi(filtersPagination.page, filtersPagination.limit)
  }

  useEffect(() => {
    getOrderSummaryFromApi()
  }, [filters, sortDirection])

  function handleSortData(column: string, direction: 'ASC' | 'DESC') {
    setColumnSorted(column)
    setSortDirection(direction)
    filters.column = column
    filters.direction = direction
  }

  function handleSortColumn(column: string) {
    setColumnSorted(column)
    handleSortData(column, sortDirection)
    if (column !== columnSorted) return
    const direction = sortDirection === 'ASC' ? 'DESC' : 'ASC'
    handleSortData(column, direction)
  }

  return (
    <>
      {loading ? (
        <Loading height="4rem" />
      ) : (
        <S.Wrapper>
          <Table
            rows={formattedOrderSummaryData}
            columns={orderSummary()}
            statusBoxColumnName="orderStatus"
            situationBoxColumnName="situation"
            sortColumn={{ column: columnSorted, sort: sortDirection }}
            onSortColumn={handleSortColumn}
          />
          <Pagination
            totalItems={orderSummaryTable?.pagination.totalItems}
            page={orderSummaryTable?.pagination.page}
            limit={orderSummaryTable?.pagination.limit}
            setFilter={handlePaginationFilter}
          />
        </S.Wrapper>
      )}
    </>
  )
}
