import { HTTPB2B } from 'providers'

export class TraceabilityService extends HTTPB2B {
  public async getSummary(filters) {
    return this.get({
      endpoint: '/traceability-summary',
      params: filters
    })
  }

  public async getChartsRanking(filters) {
    return this.get({
      endpoint: '/traceability-charts/ranking',
      params: filters
    })
  }

  public async getChartsOrdersVolumes(filters) {
    return this.get({
      endpoint: '/traceability-charts/orders-volumes',
      params: filters
    })
  }

  public async getTotalizer(filters) {
    return this.get({
      endpoint: '/traceability-totalizer',
      params: filters
    })
  }

  public async getWeavingTable(filters?: Record<string, any>) {
    return this.get({
      endpoint: '/traceability-weaving',
      params: filters
    })
  }

  public async getOrdersTable(filters?: Record<string, any>) {
    return this.get({
      endpoint: '/traceability-order',
      params: filters
    })
  }

  public async exportRetailReportData(filters?: Record<string, any>) {
    return this.post({
      endpoint: '/traceability-order/report',
      payload: filters
    })
  }

  public async getProvidersTable(filters?: Record<string, any>) {
    return this.get({
      endpoint: '/traceability-supplier',
      params: filters
    })
  }

  public async exportSupplierReportData(filters?: Record<string, any>) {
    return this.post({
      endpoint: '/traceability-supplier/report',
      payload: filters
    })
  }

  public async getWiringTable(filters?: Record<string, any>) {
    return this.get({
      endpoint: '/traceability-wiring',
      params: filters
    })
  }

  public async getPropertyTable(filters?: Record<string, any>) {
    return this.get({
      endpoint: '/traceability-proprierty',
      params: filters
    })
  }
}
