import { useEffect, useState } from 'react'
import { Checkbox } from 'components/Checkbox'
import { Icon } from 'components/Icon'

import TextField from '@material-ui/core/TextField'
import Chip from '@material-ui/core/Chip'

import * as S from './styled'
import { useLocation } from 'react-router-dom'

export type InputAutocompleteProps = {
  defaultValue?: any
  error?: boolean
  fullWidth?: boolean
  helperText?: string
  label?: string
  loading?: boolean
  loadingText?: string
  options: unknown[]
  optionLabel: string
  width?: number
  onSelected?: (_value: any) => any
  userRoleSelected?: string
  userRoleLogged?: string
  disabled?: boolean
  companyGroupBlocked?: Record<string, unknown>
}

export function InputAutocomplete({
  defaultValue = [],
  error = false,
  fullWidth = false,
  helperText = '',
  label = '',
  loading,
  loadingText,
  options,
  optionLabel,
  width = 200,
  onSelected,
  userRoleSelected,
  userRoleLogged,
  disabled = false,
  companyGroupBlocked
}: InputAutocompleteProps) {
  const [value, setValue] = useState<any>(defaultValue)
  const { pathname } = useLocation()
  const isUserPage = pathname.includes('usuarios')

  function handleChange(newValue) {
    if (
      userRoleLogged === 'admeco' ||
      userRoleLogged !== userRoleSelected ||
      !disabled
    ) {
      const setObj = new Set()
      const result = newValue.reduce((acc, item) => {
        if (!setObj.has(item[optionLabel])) {
          setObj.add(item[optionLabel])
          acc.push(item)
        }
        return acc
      }, [])
      setValue(result)
      !!onSelected && onSelected(result)
    }
  }

  function handleCheckDelete(val) {
    if (
      userRoleLogged === 'admeco' ||
      !isUserPage ||
      userRoleLogged !== userRoleSelected ||
      (!disabled && val.id !== companyGroupBlocked.id)
    ) {
      const newValue = value.filter(
        each => each[optionLabel] !== val[optionLabel]
      )
      setValue(newValue)
      !!onSelected && onSelected(newValue)
    }
  }

  function checkBlockedGroupAndPopulateSelect() {
    if (!companyGroupBlocked) return
    if (
      (userRoleLogged &&
        userRoleLogged !== 'admeco' &&
        userRoleLogged === userRoleSelected &&
        !value.length) ||
      (userRoleSelected === 'visual' && !value.length)
    ) {
      const _value = Array.from(value)
      _value.unshift(companyGroupBlocked)
      setValue(_value)
    }
  }

  useEffect(() => {
    if (defaultValue) {
      const newValue = defaultValue.map(each => {
        const formated = { ...each }
        return formated
      })
      setValue(newValue)
    }
  }, [defaultValue])

  useEffect(() => {
    if (value.length && companyGroupBlocked) {
      const teste = value.filter(each => each.id === companyGroupBlocked.id)
      if (teste.length === 0) {
        const _value = Array.from(value)
        _value.unshift(companyGroupBlocked)
        setValue(_value)
      }
    }
    checkBlockedGroupAndPopulateSelect()
  }, [value])

  useEffect(() => {
    checkBlockedGroupAndPopulateSelect()
  }, [companyGroupBlocked])

  return (
    <S.InputAutocomplete
      multiple
      disabled={disabled}
      options={options}
      disableCloseOnSelect
      getOptionLabel={option => option[optionLabel]}
      onChange={(event: any, newValue: unknown) => handleChange(newValue)}
      value={value}
      loading={loading}
      loadingText={loadingText}
      getOptionSelected={(option, value) =>
        option[optionLabel] === value[optionLabel]
      }
      popupIcon={<Icon icon="input-chevron-down" width={14} height={14} />}
      closeIcon={<Icon icon="input-exit" width={12} height={12} />}
      renderOption={option => (
        <>
          <Checkbox
            disabled={
              (userRoleLogged === userRoleSelected &&
                option.id === companyGroupBlocked?.id) ||
              disabled
            }
            label=""
            labelFor=""
            style={{ marginRight: 8 }}
            value={option[optionLabel]}
            isChecked={
              value && !!value.find(f => f[optionLabel] === option[optionLabel])
            }
          />
          {option[optionLabel]}
        </>
      )}
      style={{ width: !fullWidth ? width : '100%' }}
      renderInput={params => (
        <>
          <TextField
            {...params}
            variant="outlined"
            label={label}
            error={error}
            helperText={helperText}
          />
          {error && helperText && (
            <S.AlertIcon>
              <Icon icon="itens-interface-alert" height={10} width={10} />
            </S.AlertIcon>
          )}
        </>
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            key={index}
            disabled={disabled}
            variant="outlined"
            label={option[optionLabel]}
            deleteIcon={
              !option.blocked ? (
                <Icon icon="input-exit" width={12} height={12} />
              ) : (
                <></>
              )
            }
            {...getTagProps({ index })}
            onClick={() => handleCheckDelete(option)}
          />
        ))
      }
    />
  )
}
