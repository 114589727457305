import { useState, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'
import { ptBR, enUS, es, zhCN } from 'date-fns/locale'

import {
  INITIAL_DELAYED_SERIES,
  INITIAL_DELIVERED_OPEN_STATE
} from '../../constants'

import { IOrdersVolumes } from 'pages/Retail/types'

import { BarChart } from 'components'

import * as S from '../../styled'
interface ChartsProps {
  chartsOrdersVolumesData: IOrdersVolumes
}

export function Charts({ chartsOrdersVolumesData }: ChartsProps) {
  const { t, i18n } = useTranslation()
  const language = i18n.language === '中文 - ZH - CN' ? 'zh-CN' : i18n.language

  const dateLocale = {
    'pt-BR': ptBR,
    'en-US': enUS,
    es,
    '中文 - ZH - CN': zhCN
  }

  const formatNameAndTranslateDeliveredOpenSeries =
    INITIAL_DELIVERED_OPEN_STATE().map(item => ({
      ...item,
      name: t(`traceabilityProviderPage:constants:${item.name}`)
    }))

  const formatNameAndTranslateDelayesSeries = INITIAL_DELAYED_SERIES.map(
    item => ({
      ...item,
      name: t(`traceabilityProviderPage:constants.${item.name}`)
    })
  )

  const [deliveredOpenSeries, setDeliveredOpenSeries] = useState(
    formatNameAndTranslateDeliveredOpenSeries
  )
  const [delayedSeries, setDelayedSeries] = useState(
    formatNameAndTranslateDelayesSeries
  )

  const [monthsOrdersVolumes, setMonthsOrdersVolumes] = useState([])
  const [monthsDelayed, setMonthsDelayed] = useState([])

  const formatForDeliveredOpenSeries = (data: IOrdersVolumes) => {
    const months = []
    const delayedSeriesNewState = INITIAL_DELIVERED_OPEN_STATE().map(item => ({
      ...item,
      name: t(`traceabilityProviderPage:chartsProvider.${item.name}`),
      data: []
    }))

    setMonthsOrdersVolumes(months)

    const seriesData = data.openedDelivered.map(item => {
      delayedSeriesNewState[0].data.push(
        Number(item.ordersDelivered.toLocaleString(language)) || 0
      )
      // Comentado porque no momento não temos os dados concreto de volume
      // delayedSeriesNewState[1].data.push(item.volumesDelivered || 0)
      delayedSeriesNewState[1].data.push(
        Number(item.ordersOpen.toLocaleString(language)) || 0
      )
      // Comentado porque no momento não temos os dados concreto de volume
      // delayedSeriesNewState[3].data.push(item.volumesOpen || 0)
      months.push(
        format(new Date(`${item.mes}-02`), 'MMM YYY', {
          locale: dateLocale[i18n.language]
        })
      )
      return delayedSeriesNewState
    })[0]

    setMonthsOrdersVolumes(months)
    setDeliveredOpenSeries(seriesData)
  }

  const formatForDelayedSeries = useCallback((data: IOrdersVolumes) => {
    const months = []
    const delayedSeriesNewState = INITIAL_DELAYED_SERIES.map(item => ({
      ...item,
      name: t(`traceabilityProviderPage:constants.${item.name}`),
      data: []
    }))

    setMonthsDelayed(months)

    const seriesData = data.delayed.map(item => {
      delayedSeriesNewState[0].data.push(item.orderDelayed || 0)
      // delayedSeriesNewState[1].data.push(item.volumeDelayed || 0)
      months.push(item.mes)
      return delayedSeriesNewState
    })[0]

    setMonthsDelayed(months)
    setDelayedSeries(seriesData)
  }, [])

  const populateTables = useCallback(() => {
    if (chartsOrdersVolumesData) {
      formatForDeliveredOpenSeries(chartsOrdersVolumesData as IOrdersVolumes)
    }
    if (chartsOrdersVolumesData) {
      formatForDelayedSeries(chartsOrdersVolumesData as IOrdersVolumes)
    }
  }, [chartsOrdersVolumesData, formatForDelayedSeries])

  useEffect(() => {
    const noDeliveredSeriesDate = INITIAL_DELIVERED_OPEN_STATE().every(
      item => !item.data.length
    )
    const noDelayedSeriesDate = INITIAL_DELAYED_SERIES.every(
      item => !item.data.length
    )

    if (noDeliveredSeriesDate && noDelayedSeriesDate) {
      populateTables()
    }
  }, [populateTables])

  return (
    <S.ChartsContainer>
      <BarChart
        type="column"
        title={t(
          'traceabilityProviderPage:chartsProvider:ordersDeliveredXOpen'
        )}
        subTitle=""
        series={deliveredOpenSeries?.map(item => ({
          ...item,
          pointWidth: 140 / monthsOrdersVolumes.length
        }))}
        tooltipSuffix=""
        categories={monthsOrdersVolumes}
        height={485}
        stacked={true}
      />
      <BarChart
        type="column"
        title={t('traceabilityProviderPage:chartsProvider:pendingOrders')}
        categories={monthsDelayed}
        subTitle=""
        series={delayedSeries?.map(item => ({
          ...item,
          pointWidth: 140 / monthsDelayed.length
        }))}
        tooltipSuffix=""
        height={485}
      />
    </S.ChartsContainer>
  )
}
