import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { FabricsService } from 'services/fabrics.service'

import { cnpjMask } from 'utils/CnpjMask'
import { onFilterPaginate } from 'utils/OnFilterPaginate'

import {
  INITIAL_STATE_FABRIC_DETAILS,
  fabricsDetailsTable
} from '../../../fabrics.constants'
import { FabricDetailsRows, IQueryParameters } from '../../../fabrics.types'

import { Pagination, Table } from 'components'

type FabricDetailsProps = {
  productCode: string
  parentHeight?(_value: number): void
}

export function FabricDetailsTable({
  productCode,
  parentHeight
}: FabricDetailsProps) {
  const { id: orderNumber } = useParams<IQueryParameters>()

  const [fabricDetailsData, setFabricDetailsData] = useState<FabricDetailsRows>(
    INITIAL_STATE_FABRIC_DETAILS
  )

  const getFabricDetailsFromApiWithID = useCallback(
    async (page?: number, limit?: number) => {
      const fabricDetailsService = new FabricsService()
      await fabricDetailsService
        .getFabricDetails({
          orderNumber,
          productCode,
          page,
          limit
        })
        .then(response => {
          const { data } = response
          setFabricDetailsData(data as FabricDetailsRows)
        })
    },
    [orderNumber, productCode]
  )

  const formattedData = fabricDetailsData.items.map(item => ({
    ...item,
    cnpjOrigin: item.cnpjOrigin ? cnpjMask(item.cnpjOrigin) : null
  }))

  function handlePaginationFabricDetailsFilter({ limit, page }) {
    const filters = onFilterPaginate(fabricDetailsData.pagination, limit, page)

    getFabricDetailsFromApiWithID(filters.page, filters.limit)
  }

  const calcParentHeight = () => {
    // "+1" pra considerar a linha do header da tabela
    const totalRows = fabricDetailsData.items.length + 1
    // 40px é a altura da linha da tabela
    const rowHeight = 40

    parentHeight(totalRows * rowHeight)
  }

  useEffect(() => {
    if (orderNumber && !fabricDetailsData.items.length) {
      getFabricDetailsFromApiWithID()
    }
    calcParentHeight()
    return () => parentHeight(0)
  }, [getFabricDetailsFromApiWithID, orderNumber, fabricDetailsData])

  return (
    <>
      <Table rows={formattedData} columns={fabricsDetailsTable()} />
      <Pagination
        totalItems={fabricDetailsData.pagination.totalItems}
        limit={fabricDetailsData.pagination.limit}
        page={fabricDetailsData.pagination.page}
        setFilter={handlePaginationFabricDetailsFilter}
      />
    </>
  )
}
