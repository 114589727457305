import { t } from 'i18next'

import { ColumnsProps } from 'components/Table/Table'

import { SubcontractedProductionDetailsProps } from './ProductionDetailsTable.type'

export const INITIAL_STATE_SUBCONTRACTED_PRODUCTION_DETAILS: SubcontractedProductionDetailsProps =
  {
    pagination: {
      totalItems: 0,
      totalPages: 0,
      page: 0,
      limit: 10
    },

    items: []
  }

export const subcontractedProductionDetails = (
  totalCnpj: string
): ColumnsProps[] => [
  {
    align: 'center',
    fixed: true,
    name: 'subcontractorManufacturingName',
    title: `Subcontratados [${totalCnpj}]`
  },
  {
    align: 'center',
    fixed: false,
    name: 'cnpj',
    title: t('providerDetailsPage:subcontractors:CNPJ')
  },
  {
    align: 'center',
    fixed: false,
    name: 'invoiceNumber',
    title: t('providerDetailsPage:subcontractors:invoiceNumber')
  },
  {
    align: 'center',
    fixed: false,
    name: 'invoiceDate',
    title: t('providerDetailsPage:subcontractors:invoiceDate')
  },
  {
    align: 'center',
    fixed: false,
    name: 'requestDate',
    title: t('providerDetailsPage:subcontractors:requestDate')
  },
  {
    align: 'center',
    fixed: false,
    name: 'expectedEndDate',
    title: t('providerDetailsPage:subcontractors:expectedEndDate')
  },
  {
    align: 'center',
    fixed: false,
    name: 'qtyDelivered',
    title: t('providerDetailsPage:subcontractors:quantityDelivered')
  },
  {
    align: 'center',
    fixed: false,
    name: 'unitMeasurement',
    title: t('providerDetailsPage:subcontractors:unitMeasurement')
  },
  {
    align: 'center',
    fixed: false,
    name: 'activity',
    title: t('providerDetailsPage:subcontractors:activity')
  },
  {
    align: 'center',
    fixed: false,
    name: 'orderUpdateDate',
    title: 'Data Atualização Pedido'
  }
]
