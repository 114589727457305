import { createContext, ReactNode, useState } from 'react'

interface ProductiveChainTraceabilityFilterProviderProps {
  children: ReactNode
}

interface IProductiveChainTraceabilityFilter {
  orderNumber: string
  orderID: string
  orderDate: string
  supplierCnpj: string
  supplierName: string
  qtyTotal: number
  supplierOrigin: string | null
  orderStatus: 'A' | 'W'
  orderSituation: string
}

export interface IProductiveChainTraceabilityFilterContext {
  filterFromTraceabilityRetail: IProductiveChainTraceabilityFilter
  setFilterForProductiveChain: (
    _prevState: IProductiveChainTraceabilityFilter
  ) => void
}

const INITIAL_STATE: IProductiveChainTraceabilityFilterContext = {
  filterFromTraceabilityRetail: {
    orderNumber: '',
    orderID: '',
    orderDate: '',
    supplierCnpj: '',
    supplierName: '',
    qtyTotal: 0,
    supplierOrigin: null,
    orderStatus: 'A',
    orderSituation: ''
  },
  setFilterForProductiveChain(_order: IProductiveChainTraceabilityFilter) {}
}

export const ProductiveChainTraceabilityFilterContext =
  createContext<IProductiveChainTraceabilityFilterContext>(INITIAL_STATE)

export default function SingularOrderProvider({
  children
}: ProductiveChainTraceabilityFilterProviderProps) {
  const [filterFromTraceabilityRetail, setFilterForProductiveChain] =
    useState<IProductiveChainTraceabilityFilter>(null)

  return (
    <ProductiveChainTraceabilityFilterContext.Provider
      value={{
        filterFromTraceabilityRetail,
        setFilterForProductiveChain
      }}
    >
      {children}
    </ProductiveChainTraceabilityFilterContext.Provider>
  )
}
