import { Modal } from 'components/Modal'
import { MouseEventHandler } from 'react'

import * as S from '../../styled'
import * as ModalS from './styled'

export type ModalTraceabilityProps = {
  onClickExport: MouseEventHandler
}

export function ReportModal({ onClickExport }: ModalTraceabilityProps) {
  const button = (
    <S.ButtonComponent onClick={onClickExport}>OK</S.ButtonComponent>
  )

  const content = (
    <S.Wrapper>
      <ModalS.Container>
        O processo de exportação está em andamento. Em alguns instantes você
        receberá o email com os dados.
      </ModalS.Container>
    </S.Wrapper>
  )

  return (
    <Modal
      title="Exportando dados"
      button={button}
      content={content}
      isActionsEnabled={true}
      width={60}
      cancelButton={false}
      centerTitle={true}
    />
  )
}
