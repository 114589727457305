import { Accordion, AccordionSummary } from '@material-ui/core'
import { Icon } from 'components/Icon'
import styled, { css } from 'styled-components'

type ContainerProps = {
  hasCounter?: number
  isTraceabilityPage?: boolean
}

type SummaryProps = {
  hasCounter?: number
  expanded?: string | boolean
  isActive?: boolean
  isTraceabilityPage
}

export const Container = styled(Accordion)<ContainerProps>`
  width: 100%;
  position: relative;
  box-shadow: none;
  background-color: transparent;

  &.MuiPaper-root {
    z-index: unset !important;
  }

  &.MuiAccordion-rounded:first-child,
  &.MuiAccordion-rounded:last-child {
    border-radius: 1.2rem;
  }

  &.MuiAccordion-root:before {
    position: absolute;
    top: -2rem;
    left: 2rem;
    width: 9rem;
    height: 7.2rem;
    z-index: 3;
    content: '0';

    border-radius: 1.2rem;
    background: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
    font-size: 4rem;
    font-weight: bold;

    align-items: center;
    justify-content: center;
    opacity: 1;
    display: none;
  }

  ${({ isTraceabilityPage }) =>
    isTraceabilityPage &&
    css`
      &.MuiAccordion-root.Mui-expanded {
        margin: 0 !important;
      }
    `}

  ${({ hasCounter }) =>
    (hasCounter || hasCounter === 0) &&
    css`
      &.MuiAccordion-root:before {
        content: '${hasCounter}';
        display: flex;
      }

      &.MuiAccordion-root
        > .MuiAccordionSummary-content.MuiAccordionSummary-root {
        padding-left: 0 !important;
      }

      &.MuiAccordion-root.Mui-expanded + .MuiAccordion-root:before {
        display: flex;
      }
    `};

  @media (max-width: 875px) {
    ${({ isTraceabilityPage }) =>
      isTraceabilityPage &&
      css`
        &.MuiAccordion-root.Mui-expanded {
          margin-top: 2rem !important;
        }
      `}
  }
`

export const Summary = styled(AccordionSummary)<SummaryProps>`
  font-size: 1.8rem;
  font-weight: 600;
  height: 10rem;
  flex: 1;
  color: ${({ theme, color }) => theme.colors[color]};
  box-shadow: 4px 10px 20px #00000029;
  background-color: white;
  border-radius: 1.2rem;

  > svg {
    fill: ${({ theme }) => theme.colors.primary};
  }

  .MuiAccordionSummary-content {
    display: flex;
    align-items: center;
    margin: 2rem 0;
    border-radius: 1.2rem;
    &.Mui-expanded {
      margin: 2rem 0;
    }
  }

  &.MuiAccordionSummary-root {
    padding: 0 3.2rem;
  }

  ${({ hasCounter }) =>
    (hasCounter || hasCounter === 0) &&
    css`
      &.MuiAccordionSummary-root {
        padding-left: 14rem;
      }
    `};

  ${({ expanded, isActive }) =>
    !expanded &&
    isActive &&
    css`
      box-shadow: 0px 3px 6px #00000029;
    `};

  ${({ expanded, isActive }) =>
    expanded &&
    isActive &&
    css`
      box-shadow: none;
      margin-bottom: -40px;
    `};

  ${({ isTraceabilityPage, isActive }) =>
    isTraceabilityPage &&
    isActive &&
    css`
      &.Mui-expanded {
        box-shadow: 4px 10px 20px #00000029;
      }
    `};

  @media (max-width: 875px) {
    &.Mui-expanded {
      margin-bottom: 2rem !important;
    }
  }
`

export const AccordionIcon = styled(Icon)`
  margin-right: 2.4rem;
  width: 4rem;
  height: 4rem;

  fill: ${({ theme }) => theme.colors.secondary};
`

export const Box = styled.div`
  display: flex;
  flex-direction: column;
`

export const BoxTitle = styled.p``

export const BoxLine = styled.p`
  padding-top: 1rem;
  width: 7.7rem;
  border-bottom: 4px solid ${({ theme }) => theme.colors.primary};
`

export const Details = styled.div<SummaryProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  border-radius: 1.2rem;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 3px 6px #00000029;

  margin-bottom: 5.6rem;
  padding: 2.4rem;
`
