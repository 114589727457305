import { IconButton, InputAdornment } from '@material-ui/core'
import { useState } from 'react'
import * as Yup from 'yup'

import { Button, Icon } from 'components'

import { YupValidator } from 'services/yupValidator.service'

import * as S from './styled'

type Props = { onSubmit: (_payload: Record<string, any>) => void }
type Payload = { confirmPassword: string; password: string }

const INITIAL_STATE = { password: '', confirmPassword: '' }
const validator = new YupValidator(
  Yup.object().shape({
    password: Yup.string().required().min(6).max(32),
    confirmPassword: Yup.string()
      .required()
      .oneOf([Yup.ref('password')], 'deve ser igual a nova senha')
  })
)

function VisibilityToggler({
  state,
  onClick
}: {
  state: boolean
  onClick: () => void
}) {
  return (
    <InputAdornment position="start">
      <IconButton onClick={onClick}>
        <Icon
          icon={state ? 'input-visible' : 'input-visible-not'}
          height={16}
          width={16}
        />
      </IconButton>
    </InputAdornment>
  )
}

export function ResetPwdForm({ onSubmit }: Props) {
  const [errors, setErrors] = useState<Payload>({ ...INITIAL_STATE })
  const [formData, setFormData] = useState<Payload>({ ...INITIAL_STATE })
  const [secretInputVisibility, setInputVisibility] = useState({
    0: false,
    1: false
  })

  function onInput(key: string) {
    return function (val: string) {
      if (val === formData[key]) return
      if (errors[key]) setErrors({ ...errors, [key]: '' })
      setFormData({ ...formData, [key]: val })
    }
  }

  function onInputVisibilityToggle(key: number) {
    return function () {
      setInputVisibility(curr => ({
        ...secretInputVisibility,
        [key]: !curr[key]
      }))
    }
  }

  async function $onSubmit() {
    const [isValid, validationErrors] = await validator.validate(formData)
    if (isValid) return onSubmit(formData)
    setErrors(validationErrors as Payload)
  }

  return (
    <S.Wrapper container>
      <S.FullGrid item xs={12}>
        <S.TitleMargin />
        <S.FormContainer>
          <S.FormInput
            error={Boolean(errors.password)}
            helperText={errors.password}
            iconLeft={<Icon icon="password-lock" height={16} width={16} />}
            iconRight={
              <VisibilityToggler
                state={secretInputVisibility[0]}
                onClick={onInputVisibilityToggle(0)}
              />
            }
            label="Nova senha"
            onInput={val => onInput('password')(val)}
            type={secretInputVisibility[0] ? 'text' : 'password'}
            value={formData.password}
          />
          <S.FormInput
            error={Boolean(errors.confirmPassword)}
            helperText={errors.confirmPassword}
            iconLeft={<Icon icon="password-lock" height={16} width={16} />}
            iconRight={
              <VisibilityToggler
                state={secretInputVisibility[1]}
                onClick={onInputVisibilityToggle(1)}
              />
            }
            label="Confirmar nova senha"
            onInput={val => onInput('confirmPassword')(val)}
            type={secretInputVisibility[1] ? 'text' : 'password'}
            value={formData.confirmPassword}
          />
        </S.FormContainer>
      </S.FullGrid>
      <Button variant="green" onClick={$onSubmit}>
        Salvar alteração de senha
      </Button>
    </S.Wrapper>
  )
}
