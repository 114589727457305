import styled from 'styled-components'
import { Grid } from '@material-ui/core'

export const Container = styled.div`
  padding: 3rem;
  margin-bottom: 10rem;
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 8px;
`

export const ButtonAdd = styled.div`
  position: absolute;
  right: 2.4rem;
  top: 14.6rem;
`

export const Title = styled.div`
  color: #315ca7;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 4rem;
`

export const Line = styled.div`
  margin-top: 1.5rem;
  height: 1px;
  background-color: #315ca7;
  width: 45px;
  height: 4px;
`

export const Wrapper = styled(Grid)`
  margin-bottom: 5rem;
`
