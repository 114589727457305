import { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import * as Yup from 'yup'

import { IndustryGroupService, UserStorageService } from 'services'

import { submitOnEnter } from 'utils/submitOnEnter'
import { onFilterPaginate } from 'utils/OnFilterPaginate'

import { Input } from 'components/Input'
import { Button } from 'components/Button'
import { Loading } from 'components/Loading'
import { AddButton } from 'components/AddButton'
import { Pagination } from 'components/Pagination'
import { IndustryGroupTable } from './IndustryGroupTable'

import * as S from './styled'

const FILTERS_INITIAL_STATE = {
  name: '',
  adminEmail: '',
  limit: 10,
  page: 0
}

const ERRORS_INITIAL_STATE = {
  name: '',
  adminEmail: ''
}

const INITIAL_STATE_INDUSTRY_GROUPS = {
  items: [],
  pagination: {
    limit: 10,
    page: 0,
    totalItems: 1,
    totalPages: 1
  }
}

export function IndustryGroupList() {
  const { t } = useTranslation()

  const industryGroupService = new IndustryGroupService()

  const [filter, setFilter] = useState({ ...FILTERS_INITIAL_STATE })
  const [errors, setErrors] = useState({ ...ERRORS_INITIAL_STATE })
  const [attIndustryGroupList, setAttIndustryGroupList] = useState(false)
  const [loading, setLoading] = useState(false)
  const [industryGroups, setIndustryGroups] = useState({
    ...INITIAL_STATE_INDUSTRY_GROUPS
  })

  async function fetchAllIndustryGroups() {
    setLoading(true)
    try {
      const { data } = await industryGroupService.fetchAll(filter)
      setIndustryGroups(data as any)
    } catch (error) {
      console.log('fetchAllIndustryGroups-error', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchAllIndustryGroups()
  }, [attIndustryGroupList])

  function handleInputData(value: string, name: string) {
    setErrors({ ...errors, [name]: '' })
    setFilter({ ...filter, [name]: value })
  }

  async function onFilter() {
    setLoading(true)
    try {
      const filterPayload = await Yup.object()
        .shape({
          adminEmail: Yup.string().email().label('Email'),
          name: Yup.string()
        })
        .validate(filter, {
          abortEarly: false,
          stripUnknown: true
        })

      const { data } = await industryGroupService.fetchAll(filterPayload)
      if (data.code === 'NOT_FOUND') {
        return setIndustryGroups(INITIAL_STATE_INDUSTRY_GROUPS)
      }
      setIndustryGroups(data as any)
    } catch (ex) {
      const pairs = ex.inner.map(({ path, message }) => [
        path,
        message.replace(`${path} `, '')
      ])
      setErrors(Object.fromEntries(pairs) as typeof FILTERS_INITIAL_STATE)
    } finally {
      setLoading(false)
    }
  }

  async function onFilterPaginateIndustryGroup({ limit, page }) {
    const filters = onFilterPaginate(industryGroups.pagination, limit, page)
    const { data } = await industryGroupService.fetchAll(filters)
    setIndustryGroups(data as any)
  }

  const clearFilter = useCallback(() => {
    setFilter(FILTERS_INITIAL_STATE)
    setErrors({ ...ERRORS_INITIAL_STATE })
    fetchAllIndustryGroups()
  }, [])

  function Permission() {
    const {
      profile: { permissions }
    } = UserStorageService.getUserData()

    if (permissions.industriesGroup) {
      return (
        <S.ButtonAdd>
          <Link to="/grupo-industria/novo">
            <AddButton />
          </Link>
        </S.ButtonAdd>
      )
    } else {
      return null
    }
  }

  return (
    <S.Wrapper container>
      <Permission />
      <S.FullGrid item xs={12}>
        <S.GridHeader>
          <S.GridTitle item container xs={12}>
            <S.BoxTitle>
              {t('generalIndustryGroup:filter:filterGroup')}
            </S.BoxTitle>
            <S.BoxLine />
          </S.GridTitle>
        </S.GridHeader>
        <S.GridFilter container>
          <S.GridInput item sm={12} md={6} lg={4}>
            <Input
              error={Boolean(errors.name)}
              fullWidth
              helperText={errors.name}
              label={t('generalIndustryGroup:filter:groupName')}
              onInput={value => handleInputData(value, 'name')}
              onKeyPress={submitOnEnter(onFilter)}
              value={filter.name}
            />
          </S.GridInput>
          <S.GridInput item sm={12} md={6} lg={4}>
            <Input
              error={Boolean(errors.adminEmail)}
              fullWidth
              helperText={errors.adminEmail}
              label={t('generalIndustryGroup:filter:adminEmail')}
              onKeyPress={submitOnEnter(onFilter)}
              onInput={value => handleInputData(value, 'adminEmail')}
              type="email"
              value={filter.adminEmail}
            />
          </S.GridInput>
          <S.GridButtons item sm={12} md={12} lg={4}>
            <Button variant="gray" onClick={clearFilter} size="medium">
              <p>{t('generalIndustryGroup:filter:clean')}</p>
            </Button>
            <Button variant="blue" onClick={onFilter} size="medium">
              <p>{t('generalIndustryGroup:filter:search')}</p>
            </Button>
          </S.GridButtons>
        </S.GridFilter>
        <S.GridTable>
          {loading ? (
            <Loading height="50rem" />
          ) : (
            <>
              <IndustryGroupTable
                rows={industryGroups.items}
                isDeleted={setAttIndustryGroupList}
              />
              {industryGroups.pagination?.totalItems > 0 && (
                <Pagination
                  limit={industryGroups.pagination.limit}
                  page={industryGroups.pagination.page}
                  totalItems={industryGroups.pagination.totalItems}
                  setFilter={onFilterPaginateIndustryGroup}
                />
              )}
            </>
          )}
        </S.GridTable>
      </S.FullGrid>
    </S.Wrapper>
  )
}
