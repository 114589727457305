import styled, { css } from 'styled-components'

export const Wrapper = styled.div<{ hasSecondText: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 17.6rem;
  padding: 2.4rem;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 1.2rem;
  box-shadow: 4px 10px 20px #00000029;

  span {
    color: ${({ theme }) => theme.colors.primary};
    font-size: 3.2rem;
    font-weight: 600;
  }

  ${({ hasSecondText }) =>
    !hasSecondText &&
    css`
      h3 {
        margin-bottom: 3rem;
      }
      span {
        font-size: 5rem;
      }
    `}
`

export const Title = styled.h3`
  color: ${({ theme }) => theme.colors.gray};
  font-size: 1.6rem;
  font-weight: 600;
  margin-bottom: 0.4rem;
`

export const FieldWrapper = styled.div`
  & + & {
    margin-top: 1.6rem;
  }
`
