import { useTranslation } from 'react-i18next'
import * as S from './styled'

export function TableNotFoundHint({
  to,
  onClick
}: {
  to?: string
  onClick?: (_value: unknown) => void
}) {
  const { t } = useTranslation()
  return (
    <S.Hint>
      {t('tableComponent:NotFound')}
      {Boolean(to) && <S.Link to={to}>{t('tableComponent:WantToAdd')}</S.Link>}
      {Boolean(onClick) && (
        <S.Link to={'#'} onClick={onClick}>
          {t('tableComponent:WantToAdd')}
        </S.Link>
      )}
    </S.Hint>
  )
}
