import { useSelector } from 'react-redux'

import { isVisible } from 'store/Spinner/Spinner.selector'

import * as S from './styled'

export function Spinner() {
  const visible = useSelector(isVisible)

  return (
    <S.Overlay className={`${visible && '--visible'}`}>
      <S.Spinner />
    </S.Overlay>
  )
}
