import { MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'

import TablePagination from '@material-ui/core/TablePagination'
import { makeStyles } from '@material-ui/core/styles'

import * as S from './styled'

const useStyles = makeStyles({
  caption: {
    fontSize: '1.3rem'
  },
  select: {
    fontSize: '1.3rem'
  },
  input: {
    marginTop: '8px'
  }
})

type PaginationType = {
  totalItems: number
  page: number
  limit: number
  filters?: any
  setFilter: (_args: any) => void
}

export function Pagination(pagination: PaginationType) {
  const { t } = useTranslation()
  const classes = useStyles()

  async function handleChangePage(
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) {
    // Aqui foi adicionado o "+ 1" por conta do componente do material ui que começa a contagem do page a partir do 0,
    // mas a API espera que o page comece a partir do 1,
    // ainda por conta disso, foi adicionado o "- 1" na propriedade page do componente do material ui
    pagination.setFilter({
      ...pagination.filters,
      ...pagination,
      page: newPage + 1
    })
  }

  function handleChangeRowsPerPage(e) {
    pagination.setFilter({
      ...pagination.filters,
      ...pagination,
      limit: e.target.value
    })
  }

  return (
    <S.Wrapper>
      <TablePagination
        backIconButtonText={t('common:previousPage')}
        classes={classes}
        component="ul"
        count={pagination.totalItems}
        labelRowsPerPage={t('common:linesPerPage')}
        nextIconButtonText={t('common:nextPage')}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        page={pagination.page >= 1 ? pagination.page - 1 : 0}
        rowsPerPage={pagination.limit || 10}
        rowsPerPageOptions={[2, 5, 10, 25, 50]}
      />
    </S.Wrapper>
  )
}
