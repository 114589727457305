import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { RadioButton } from 'components'

import * as S from './styled'

window.Highcharts = Highcharts

export type Props = {
  color?: string
  series: {
    name?: string
    data: any
    color: string
  }[]
  subTitle?: string
  title: string
  tooltipSuffix?: string
  radioOptions?: any
  yCategories?: any
  xCategories?: any
  categories?: any
  type?: 'column' | 'bar'
  xAxisTitle?: string
  yAxisTitle?: string
  height?: number
  isLoading?: boolean
  radioValue?: string
  stacked?: boolean
  hasLegend?: boolean
  onRadioSelected?: (_value: unknown) => void
}

export function BarChart({
  color = '#315ca7',
  height = 400,
  series,
  subTitle,
  title,
  tooltipSuffix,
  radioOptions,
  categories,
  yCategories,
  xCategories,
  type = 'bar',
  xAxisTitle = '',
  yAxisTitle = '',
  isLoading = false,
  radioValue,
  stacked = false,
  hasLegend = false,
  onRadioSelected
}: Props) {
  const options = {
    chart: {
      type,
      height,
      borderRadius: 12,
      spacing: 23,
      animate: true
    },
    legend: {
      verticalAlign: 'bottom',
      align: type === 'bar' ? 'left' : 'center',
      margin: 15,
      enabled: !hasLegend,
      symbolRadius: 0
    },
    title: { text: title },
    subtitle: { text: subTitle },
    xAxis: {
      categories,
      xCategories,
      type: 'category',
      title: { text: xAxisTitle },
      lineColor: type === 'bar' ? 'transparent' : '#707070',
      labels: {
        animate: true
      }
    },
    yAxis: {
      yCategories: yCategories && yCategories,
      title: { text: yAxisTitle },
      gridLineWidth: type === 'bar' ? 0 : 1,
      gridLineColor: type === 'bar' ? 'transparent' : '#F5F5F5',
      labels: {
        enabled: Boolean(!(type === 'bar')),
        animate: true
      }
    },
    tooltip: {
      pointFormat: `<span style="color:{point.color}">{point.name}</span>: <b>{point.y} ${tooltipSuffix}</b><br/>`
    },
    plotOptions: {
      series: {
        colorByPoint: false,
        color,
        borderWidth: 0,
        pointWidth: type === 'column' ? 110 : 20,
        stacking: stacked ? 'normal' : '',
        dataLabels: {
          enabled: true,
          format: '{point.y:.0f}'
        }
      }
    },
    series,
    credits: { enabled: false },
    width: '100%',
    height: '100%'
  }

  return (
    <S.ChartContainer>
      <HighchartsReact highcharts={Highcharts} options={options} />
      {radioOptions && (
        <S.Radio>
          <RadioButton
            label=""
            options={radioOptions}
            key="dateType"
            value={radioValue}
            onSelected={onRadioSelected}
          />
        </S.Radio>
      )}
      {hasLegend &&
        series.map(item => {
          return (
            <S.Legend key={item.name}>
              <S.ColorLegend color={item.color} />
              {item.name}
            </S.Legend>
          )
        })}
      {isLoading && (
        <S.BarChartLoadingContainer>
          <S.LoadingTextContainer>
            <S.LoadingText>. . .</S.LoadingText>
          </S.LoadingTextContainer>
        </S.BarChartLoadingContainer>
      )}
    </S.ChartContainer>
  )
}
