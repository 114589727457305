import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useTranslation } from 'react-i18next'

import { AccordionDetailsContent } from '../AccordionDetailsContent'

import {
  accordionItems,
  INITIAL_STATE_SUMMARY
} from 'pages/OrderManagementDetails/orderManagementDetails.constants'

import { Accordion } from 'components'

import * as S from './styles'
import { ITotalizers } from '../../orderManagementDetails.type'
import { OrdersManagementTableContext } from 'contexts/ordersTable/ordersTable.context'
import { useLocation, useParams } from 'react-router-dom'
import { OrdersManagementService } from 'services/ordersManagement.service'
import { ItemsDetailsTable } from '../ItemsDetailsTable'
import { TraceabilityRetailFiltersContext } from 'contexts/traceabilityRetailTableFilters/traceabilityRetailTableFilters.context'
import { SupplierFiltersContext } from 'contexts/supplierFilters/supplierFilters.context'

type ParamsProps = {
  id?: string
}

type LocationProps = {
  fromRetail: boolean
  fromSupplier: boolean
}

export function Summary() {
  const { t } = useTranslation()
  const { ordersManagementFilters } = useContext(OrdersManagementTableContext)
  const { traceabilityRetailsFilters } = useContext(
    TraceabilityRetailFiltersContext
  )
  const { supplierFilters } = useContext(SupplierFiltersContext)

  const { state } = useLocation<LocationProps>()
  const { id: orderNumber } = useParams<ParamsProps>()

  const [summary, setSummary] = useState<ITotalizers>(INITIAL_STATE_SUMMARY)
  const [openSummaryData, setOpenSummaryData] = useState(null)

  const [isOpen, setIsOpen] = useState(false)
  const [expandedSummary, setExpandedSummary] = useState(null)
  const [expanded, setExpanded] = useState<string | boolean>(false)

  const handleAccordionChange = useMemo(() => {
    return (panel: string) => (event: ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false)
    }
  }, [])

  const handleSummaryClick = (index, row) => {
    if (expandedSummary === index) {
      setExpandedSummary(null)
    } else {
      setOpenSummaryData(row)
      setExpandedSummary(index)
    }
  }

  const getSummaryFromApi = useCallback(async () => {
    try {
      let _filters
      switch (true) {
        case state?.fromRetail:
          _filters = { ...traceabilityRetailsFilters }
          break
        case state?.fromSupplier:
          _filters = { ...supplierFilters }
          break
        default:
          _filters = { ...ordersManagementFilters }
      }
      delete _filters.startDate
      delete _filters.endDate
      delete _filters.dateType
      delete _filters?.orderStatus
      delete _filters?.searchNameAndCnpjIn

      const service = new OrdersManagementService()

      await service
        .getSummary({ ..._filters, orderNumber, direction: 'DESC' })
        .then(({ data }: any) => {
          setSummary(data)
        })
    } catch (error) {
      console.log(error)
    }
  }, [])

  useEffect(() => {
    getSummaryFromApi()
  }, [])

  return (
    <S.SummaryWrapper>
      <S.SummaryContainer>
        {accordionItems.map((item, index) => (
          <Accordion
            expanded={expanded === item.tag}
            isActive={true}
            key={index}
            title={t(
              `ordersManagementPage:accordions:accordionCard:${item.title}`
            )}
            icon={item.icon}
            counter={summary[item.tag].length}
            onChange={handleAccordionChange(item.tag)}
            isTraceabilityPage
          />
        ))}
      </S.SummaryContainer>
      <S.SummaryDetails>
        {accordionItems.map(item => (
          <>
            {expanded === item.tag &&
              summary[item.tag].map((summaryItem, index) => (
                <>
                  <S.AccordionDetailsComponent
                    icon={item.icon}
                    buttonText={t(
                      'ordersManagementPage:accordions:accordionDetails:orderItems'
                    )}
                    hasActionButton={item.hasActionButton}
                    onClickButton={() => handleSummaryClick(index, summaryItem)}
                    setIsOpen={setIsOpen}
                    counter={summaryItem.totalItems}
                    key={index}
                  >
                    <AccordionDetailsContent
                      data={summaryItem}
                      tag={item.tag}
                    ></AccordionDetailsContent>
                  </S.AccordionDetailsComponent>
                  {isOpen && expandedSummary === index && (
                    <ItemsDetailsTable openSummaryData={openSummaryData} />
                  )}
                </>
              ))}
          </>
        ))}
      </S.SummaryDetails>
    </S.SummaryWrapper>
  )
}
