import { Link } from 'react-router-dom'
import { IconCard } from 'components/IconCard'

import * as S from './styled'
import { useTranslation } from 'react-i18next'
import { UserStorageService } from 'services'

const links = {
  user: '/usuario',
  industry: '/industria',
  industryGroup: '/grupo-industria',
  property: '/propriedade'
}

export function Cards() {
  const { t } = useTranslation()
  const {
    profile: { permissions }
  } = UserStorageService.getUserData()

  return (
    <S.GridContainer data-testid="cards-general" container spacing={4}>
      <S.GridIconCard item xs={12} md={4}>
        <Link to={links.user}>
          <IconCard
            title={t('generalPanelPage:usersPanelCard')}
            description={t('generalPanelPage:usersPanelCardDescription')}
            icon="card-profile"
          />
        </Link>
      </S.GridIconCard>
      {permissions.industries && (
        <S.GridIconCard item xs={12} md={4}>
          <Link to={links.industry}>
            <IconCard
              title={t('generalPanelPage:industryPanelCard')}
              description={t('generalPanelPage:industryPanelCardDescription')}
              icon="card-industry"
            />
          </Link>
        </S.GridIconCard>
      )}
      {permissions.industriesGroup && (
        <S.GridIconCard item xs={12} md={4}>
          <Link to={links.industryGroup}>
            <IconCard
              title={t('generalPanelPage:industryGroupPanelCard')}
              description={t(
                'generalPanelPage:industryGroupPanelCardDescription'
              )}
              icon="card-industry"
            />
          </Link>
        </S.GridIconCard>
      )}
      {/* Comentando essas importações por decisão de negócio que no momento, não será necessário
          Mantendo código para futuras necessidades e revisões */}
      {/* <S.GridIconCard item xs={12} md={4}>
        <Link to={links.property}>
          <IconCard
            title={t('generalPanelPage:propertiesPanelCard')}
            description={t('generalPanelPage:propertiesPanelCardDescription')}
            icon="card-property"
          />
        </Link>
      </S.GridIconCard> */}
    </S.GridContainer>
  )
}
