import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { OrderService } from 'services'

import { formaDateStringLocale } from 'utils/formaDateStringLocale'

import {
  generalOrderData,
  INITIAL_STATE_GENERAL_DETAILS
} from '../../../orderManagementDetails.constants'
import {
  GeneralOrderData,
  IQueryParameters
} from '../../../orderManagementDetails.type'

import { Table } from 'components'

import * as S from './styled'

export function GeneralDataTable() {
  const { t, i18n } = useTranslation()
  const language = i18n.language === '中文 - ZH - CN' ? 'zh-CN' : i18n.language

  const { id: orderNumber } = useParams<IQueryParameters>()
  const [generalOrderDetails, setGeneralOrderDetails] =
    useState<GeneralOrderData>(INITIAL_STATE_GENERAL_DETAILS)

  const getGeneralOrderDetailsFromApiWithID = useCallback(async () => {
    const orderService = new OrderService()
    await orderService.getOrderData({ orderNumber }).then(response => {
      const { data } = response
      setGeneralOrderDetails(data.items[0])
    })
  }, [orderNumber])

  const formattedGeneralOrderDetails = {
    ...generalOrderDetails,
    orderDate: generalOrderDetails.orderDate
      ? formaDateStringLocale(generalOrderDetails.orderDate)
      : null,
    deliveryForecastDate: generalOrderDetails.deliveryForecastDate
      ? formaDateStringLocale(generalOrderDetails.deliveryForecastDate)
      : null,
    qtyTotal: Number(generalOrderDetails.qtyTotal).toLocaleString(language),
    qtyTotalDelivered: Number(
      generalOrderDetails.qtyTotalDelivered
    ).toLocaleString(language)
  }

  useEffect(() => {
    if (
      orderNumber &&
      Object.values(generalOrderDetails).every(value => !value)
    ) {
      getGeneralOrderDetailsFromApiWithID()
    }
  }, [getGeneralOrderDetailsFromApiWithID, orderNumber, generalOrderDetails])

  return (
    <>
      {/* <S.ButtonAdd>
        <Link to="/gestao-pedidos/detalhes-pedidos">
          <AddButton />
        </Link>
      </S.ButtonAdd> */}
      <S.Container>
        <S.Title>
          {t('ordersManagementPage:tables:generalDataTable:generalData')}
          <S.Line />
        </S.Title>
        <S.Wrapper>
          <Table
            rows={[formattedGeneralOrderDetails]}
            columns={generalOrderData()}
            statusBoxColumnName="orderStatus"
            situationBoxColumnName="orderSituation"
          />
        </S.Wrapper>
      </S.Container>
    </>
  )
}
