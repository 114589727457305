import CircularProgress from '@material-ui/core/CircularProgress'
import * as S from './styled'

type LoadingProps = {
  height?: string
}

export function Loading({ height = '100vh' }: LoadingProps) {
  return (
    <S.Wrapper height={height}>
      <CircularProgress />
    </S.Wrapper>
  )
}
