import { useTranslation } from 'react-i18next'
import { cnpjMask } from 'utils/CnpjMask'

export function AccordionDetailsContentInfo({ data }) {
  const { t, i18n } = useTranslation()
  const language = i18n.language === '中文 - ZH - CN' ? 'zh-CN' : i18n.language

  return (
    <>
      <span>
        <strong>{t('accordionDetailsContent:cnpj')}:</strong>
        {data.cnpj ? cnpjMask(data.cnpj) : ''}
      </span>
      <span>
        <strong>{t('accordionDetailsContent:country')}:</strong>
        {data.country}
      </span>
      <span>
        <strong>{t('accordionDetailsContent:order')}:</strong>
        {Number(data.orders).toLocaleString(language)}
      </span>
      <span>
        <strong>{t('accordionDetailsContent:corporateName')}:</strong>
        {data.corporateName}
      </span>
      <span>
        <strong>
          {t('accordionDetailsContent:city')}/
          {t('accordionDetailsContent:stateAbreviation')}:
        </strong>
        {data.city}/{data.state}
      </span>
      <span>
        <strong>{t('accordionDetailsContent:volume')}:</strong>
        {Number(data.volumes).toLocaleString(language)}
      </span>
    </>
  )
}
