import { useEffect, useState } from 'react'
import { FormHelperText } from '@material-ui/core'
import { Icon } from 'components'

import * as S from './styled'

type elements = { title: string; value: string }

export type RadioButtonProps = {
  error?: boolean
  fullWidth?: boolean
  helperText?: string
  label: string
  options: elements[]
  value?: unknown
  onSelected?: (_value: unknown) => void
}

export function RadioButton({
  error = false,
  helperText = '',
  label,
  options,
  value = null,
  onSelected
}: RadioButtonProps) {
  const [selectedValue, setSelectedValue] = useState(value)

  const handleChange = event => {
    const val = event.target.value
    setSelectedValue(val)
    !!onSelected && onSelected(val)
  }

  useEffect(() => {
    if (value !== null) {
      setSelectedValue(value)
    }
  }, [value])

  return (
    <S.Wrapper>
      <S.FLabel component="legend">{label}</S.FLabel>
      {options.map(({ title, value }) => (
        <S.FControlLabel
          key={value}
          value={title}
          control={
            <S.RButton
              checked={selectedValue === value}
              onChange={handleChange}
              value={value}
              color="default"
              name="radio-button"
              inputProps={{ 'aria-label': title }}
            />
          }
          label={title}
        />
      ))}

      {error && helperText && (
        <S.AlertIcon>
          <Icon icon="itens-interface-alert" height={10} width={10} />
          <FormHelperText>{helperText}</FormHelperText>
        </S.AlertIcon>
      )}
    </S.Wrapper>
  )
}
