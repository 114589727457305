import OrdersTableProvider from 'contexts/ordersTable/ordersTable.context'
import RetailTableProvider from 'contexts/retailTable/retailTable.context'

import { Base } from 'layouts'
import { FilterForm } from './components/FilterForm'
import { TabsOrdersComponents } from './components/TabContent'

export function AllOrdersManagement() {
  return (
    <Base>
      <OrdersTableProvider>
        <RetailTableProvider>
          <FilterForm />
          <TabsOrdersComponents />
        </RetailTableProvider>
      </OrdersTableProvider>
    </Base>
  )
}
