import { Grid } from '@material-ui/core'
import styled from 'styled-components'

export const Container = styled(Grid)`
  width: 100%;
  margin-left: 2.4rem;
  font-size: 1.6rem;
  flex-wrap: wrap;
  align-items: center;
  gap: 1.6rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(38rem, 1fr));
  grid-template-rows: 1fr 1fr;
  max-width: 1200px;

  strong {
    margin-right: 0.8rem;
    font-weight: bold;
  }
`
