import { useCallback, useEffect, useState } from 'react'
import i18n from 'i18n'

import { OrderService } from 'services'

import { onFilterPaginate } from 'utils/OnFilterPaginate'
import { formaDateStringLocale } from 'utils/formaDateStringLocale'

import {
  INITIAL_STATE_PRODUCTION_DETAILS,
  supplierProductionDetails
} from './SupplierDetailsTable.constants'
import { SupplierProductionDetailsProps } from './SupplierDetalsTable.types'

import { Pagination, Table } from 'components'

type SupplierProps = {
  orderNumber: string
}

export function SupplierDetailsTable({ orderNumber }: SupplierProps) {
  const language = i18n.language === '中文 - ZH - CN' ? 'zh-CN' : i18n.language

  const [supplierProductionData, setSupplierProductionData] =
    useState<SupplierProductionDetailsProps>({
      ...INITIAL_STATE_PRODUCTION_DETAILS
    })
  const [columnSorted, setColumnSorted] = useState('deliveryDate')
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC'>('DESC')

  const getDetailsFromApiWithID = useCallback(
    async (
      column: string,
      direction: string,
      page?: number,
      limit?: number
    ) => {
      const orderService = new OrderService()
      await orderService
        .getOrderItens({ orderNumber, page, limit, column, direction })
        .then(response => {
          const { data } = response
          setSupplierProductionData(data as SupplierProductionDetailsProps)
        })
    },
    [orderNumber]
  )

  const formattedSupplierProduction = supplierProductionData.items.map(
    item => ({
      ...item,
      previousDeliveryDate: item.previousDeliveryDate
        ? formaDateStringLocale(item.previousDeliveryDate)
        : null,
      deliveryDate: item.deliveryDate
        ? formaDateStringLocale(item.deliveryDate)
        : null,
      qtyTotal: Number(item.qtyTotal).toLocaleString(language)
    })
  )

  function handlePaginationFilter({ limit, page }) {
    const filters = onFilterPaginate(
      supplierProductionData.pagination,
      limit,
      page
    )

    getDetailsFromApiWithID(filters.page, filters.limit)
  }

  function handleSortData(column: string, direction: 'ASC' | 'DESC') {
    setColumnSorted(column)
    setSortDirection(direction)
  }

  function handleSortColumn(column: string) {
    setColumnSorted(column)
    handleSortData(column, sortDirection)
    if (column !== columnSorted) return
    const direction = sortDirection === 'ASC' ? 'DESC' : 'ASC'
    handleSortData(column, direction)
  }

  useEffect(() => {
    if (orderNumber) {
      getDetailsFromApiWithID(columnSorted, sortDirection)
    }
  }, [getDetailsFromApiWithID, orderNumber, columnSorted, sortDirection])

  return (
    <>
      <Table
        rows={formattedSupplierProduction}
        columns={supplierProductionDetails()}
        statusBoxColumnName="orderStatus"
        situationBoxColumnName="orderSituation"
        sortColumn={{ column: columnSorted, sort: sortDirection }}
        onSortColumn={handleSortColumn}
      />
      <Pagination
        totalItems={supplierProductionData.pagination.totalItems}
        limit={supplierProductionData.pagination.limit}
        page={supplierProductionData.pagination.page}
        setFilter={handlePaginationFilter}
      />
    </>
  )
}
