import Autocomplete from '@material-ui/lab/Autocomplete'
import styled from 'styled-components'

export const InputAutocomplete = styled(Autocomplete).attrs(() => {})`
  padding: 0.8rem;
  .MuiChip-root {
    background-color: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.lightGray};
    border-radius: 0.8rem;
    color: ${({ theme }) => theme.colors.primary};
    font-size: 1.4rem;
    font-weight: 600;
    height: 3.2rem;
    margin: 0.8rem 0.8rem;
    padding: 1.6rem 0.6rem;
    text-transform: uppercase;
    width: fit-content;
  }

  .MuiChip-deleteIcon {
    margin-left: 0.6rem;
  }

  .MuiAutocomplete-inputRoot {
    background-color: ${({ theme }) => theme.colors.backgroundAutocomplete};
  }

  .MuiInputLabel-root {
    font-size: 1.4rem;
    transform: translate(${({ theme }) => theme.spacings.xsmall}, 1.9rem)
      scale(1);
  }

  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -6px) scale(0.71428);
  }

  .MuiOutlinedInput-input {
    font-size: 1.4rem;
    padding: ${({ padding }) => (padding === 'large' ? '1.57rem' : '1.17rem')} 0;

    &.MuiOutlinedInput-inputMultiline {
      padding: ${({ padding }) => (padding === 'large' ? '1.57rem' : '1.17rem')}
        0;
    }
  }

  label.Mui-focused {
    color: ${({ theme }) => theme.colors.focusForm};
  }

  .MuiOutlinedInput-root {
    fieldset {
      legend {
        font-size: 1.05rem;
      }
      border-color: ${({ border, theme }) =>
        border === 'dark' ? theme.colors.darkGray : theme.colors.Gray};
      opacity: 1;
    }

    &:hover fieldset {
      border-color: ${({ theme }) => theme.colors.hoverForm};
    }

    &.Mui-focused fieldset {
      border-color: ${({ theme }) => theme.colors.focusForm};
    }
  }

  .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.colors.error};
  }

  .MuiFormLabel-root.Mui-error {
    color: ${({ theme }) => theme.colors.error};
  }

  .MuiFormHelperText-root.Mui-error {
    color: ${({ theme }) => theme.colors.error};
    font-size: 1rem;
    font-weight: 600;
    padding-left: ${({ theme }) => theme.spacings.xxxsmall};
  }
`

export const AlertIcon = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.error};

  position: relative;
  bottom: 1.4rem;
  height: 0;
  img {
    height: 1rem;
  }
`
