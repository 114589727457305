import { IconButton } from '@material-ui/core'
import { Icon } from 'components/Icon'
import * as S from './styled'

type SquarePictureType = {
  images: { src: string; filename: string }[]
  handleRemoveItemImage: any
  handleSelectImages: any
}

export function SquarePictures({
  images,
  handleRemoveItemImage,
  handleSelectImages
}: SquarePictureType) {
  return (
    <S.WraperImages>
      <S.WraperImagesContainer>
        {images.map((img, index) => {
          return (
            <div key={img.filename}>
              <S.DivRemove>
                <S.RemoveImage>
                  <IconButton
                    style={{ backgroundColor: '#ffffff' }}
                    aria-label="delete picture"
                    component="span"
                    onClick={() => handleRemoveItemImage(index)}
                  >
                    <Icon
                      icon="delete-image"
                      height={16}
                      width={16}
                      fill="red"
                    />
                  </IconButton>
                </S.RemoveImage>
              </S.DivRemove>
              <img key={img.filename} src={img ? img.src : ''} />
            </div>
          )
        })}

        {images.length < 4 && (
          <S.WraperImagesContainerLabel htmlFor="image[]" className="new-image">
            <S.Button className="new-image">
              <p>+</p>
            </S.Button>
          </S.WraperImagesContainerLabel>
        )}
        <S.WraperImagesInput
          multiple
          onChange={handleSelectImages}
          type="file"
          id="image[]"
        />
      </S.WraperImagesContainer>
    </S.WraperImages>
  )
}
