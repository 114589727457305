import * as S from './styles'
import { useSelector } from 'react-redux'
import GitInfo from 'react-git-info/macro'
import { versionApi } from 'store/Version/Version.selector'

export const Footer = () => {
  const year = new Date().getFullYear()
  const appVersionApi = useSelector(versionApi)
  const appEnvVersion = process.env.REACT_APP_VERSION
  let appVersion = appEnvVersion

  if (process.env.NODE_ENV === 'development') {
    const { branch } = GitInfo()
    appVersion = branch
  }

  return (
    <S.Wrapper data-testid="footer">
      <S.Title>
        © {year} Ecotrace Solutions | FRONT {appVersion} - API{' '}
        {appVersionApi.versionApi}
      </S.Title>
    </S.Wrapper>
  )
}
