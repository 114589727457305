import { HTTP } from 'providers'

export class AuthService extends HTTP {
  private endpoint = '/sign-in'

  public login(payload) {
    return this.post({
      endpoint: this.endpoint,
      payload
    })
  }
}
