// import { Grid } from '@material-ui/core'
// import { InputSelect } from 'components/InputSelect'
// import { selectOptions as visibilityOptions } from 'constants/industryGroup.constants'

// CODIGO COMENTADO POR REGRA DE NEGÓCIO - LINK TASK JIRA: https://ecotrace-solutions.atlassian.net/browse/PDTCT00522-535
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'

import * as Yup from 'yup'

import {
  IndustryGroupRoleService,
  IndustryGroupProfileService,
  IndustryGroupService
} from 'services'

import { YupValidator } from 'services/yupValidator.service'

import { toasts } from 'utils/toasts'
import validaCNPJ from 'utils/validaCNPJ'

import { Input } from 'components/Input'
import { InputCpfCnpj } from 'components/InputCpfCnpj'
import { InputAutocomplete } from 'components/InputAutocomplete'
import { Button } from 'components/Button'

import * as S from './styled'

const IndustryGroupFormSchema = Yup.object().shape({
  name: Yup.string().required().label('Nome do grupo'),
  adminName: Yup.string().required().label('Nome do administrador'),
  adminEmail: Yup.string().required().email().label('Email do administrador'),
  cnpj: Yup.string()
    .required()
    .test('cnpj', 'CNPJ inválido', value => {
      if (value) {
        const valido = validaCNPJ(value)
        return valido
      }
      return false
    })
    .label('CNPJ')
})

interface ParamTypes {
  id: string
}

const INITIAL_STATE = {
  form: {
    name: '',
    adminName: '',
    adminEmail: '',
    cnpj: '',
    companyGroupProfiles: null
  },
  errors: {
    name: '',
    adminName: '',
    adminEmail: '',
    cnpj: '',
    companyGroupProfiles: ''
  }
}

export function IndustryGroupForm() {
  const { t } = useTranslation()
  const { id } = useParams<ParamTypes>()

  const { goBack } = useHistory()

  const [groupProfile, setGroupProfile] = useState(null)
  const [groupRoleOptions, setGroupRoleOptions] = useState(null)
  const [groupsProfileSelected, setGroupsProfileSelected] = useState([])
  const [form, setForm] = useState({ ...INITIAL_STATE.form })
  const [errors, setErrors] = useState({ ...INITIAL_STATE.errors })
  // const [formattedVisibilityOptions, setFormattedVisibilityOptions] =
  //   useState(null)

  const fetchGroupProfileOptions = async () => {
    const { data, success } = await new IndustryGroupProfileService().fetchAll()
    if (!success) toasts.generalFail()

    const translateGroupOptions = data.map(item => ({
      ...item,
      label: t(`newIndustryGroup:groupProfile:${item.tag}`)
    }))

    setGroupProfile(translateGroupOptions)
  }

  const getGroupFromApi = useCallback(async () => {
    const industryGroup = new IndustryGroupService()

    const { data, success } = await industryGroup.fetchOne(id)

    if (!success) toasts.generalFail()
    setForm({
      name: data.name,
      adminName: data.adminName,
      adminEmail: data.adminEmail,
      cnpj: data.cnpj,
      companyGroupProfiles: data.companyGroupProfiles
    })

    const getPreSelectedProfiles = () => {
      const profilesListPreSelected = []
      data.companyGroupProfiles?.forEach(companyProfile => {
        const profilesMatched = groupProfile?.find(
          profile => companyProfile.id === profile.id
        )
        profilesListPreSelected.push(profilesMatched)
      })
      setGroupsProfileSelected(profilesListPreSelected)
    }

    getPreSelectedProfiles()
  }, [groupProfile])

  // useEffect(() => {
  //   const translateLabelVisibilityOptions = visibilityOptions.map(item => ({
  //     ...item,
  //     label: t(`newIndustryGroup:visualizationType:${item.label}`)
  //   }))

  //   setFormattedVisibilityOptions(translateLabelVisibilityOptions)
  // }, [])

  useEffect(() => {
    const formIsEmpty = Object.values(form)
      .map(item => !item)
      .every(each => each)

    if (formIsEmpty && id && groupProfile) getGroupFromApi()
  }, [groupProfile])

  useEffect(() => {
    if (!groupProfile) fetchGroupProfileOptions()
  }, [groupProfile])

  const fetchGroupRoleOptions = useCallback(async () => {
    const { data, success } = await new IndustryGroupRoleService().fetchAll()
    if (!success) toasts.generalFail()

    const translateGroupRoleOptions = data.map(item => ({
      ...item,
      label: t(`newIndustryGroup:groupFunction:${item.tag}`)
    }))

    setGroupRoleOptions(translateGroupRoleOptions)
  }, [])

  useEffect(() => {
    if (!groupRoleOptions) fetchGroupRoleOptions()
  }, [fetchGroupRoleOptions, groupRoleOptions])

  function handleInput(value: string, name: string) {
    setErrors({ ...errors, [name]: '' })
    setForm({ ...form, [name]: value })
  }

  // function handleSelected(name: string, value: any) {
  //   setErrors({ ...errors, [name]: '' })
  //   setForm({ ...form, [name]: value })
  // }

  async function submit() {
    setErrors({ ...INITIAL_STATE.errors })

    const validator = new YupValidator(IndustryGroupFormSchema)
    const [isValid, validationError] = await validator.validate(form)
    if (!isValid) {
      return setErrors(validationError as typeof INITIAL_STATE.errors)
    }
    const industryGroup = new IndustryGroupService()
    id
      ? await industryGroup.edit({ ...form, id })
      : await industryGroup.create(form)
    toasts.edited()
    onExit()
  }

  function onExit() {
    goBack()
    setGroupsProfileSelected(() => [])
  }

  function handleAutocomplete(field: string, value: any) {
    setErrors({ ...errors, [field]: '' })
    setForm({ ...form, [field]: value.map(item => ({ id: item.id })) })
    setGroupsProfileSelected(value)
  }

  return (
    <S.Wrapper container>
      <S.FullGrid item xs={12}>
        <div style={{ marginBottom: 16 }}>
          <S.GridHeader>
            <S.GridTitle item container xs={12}>
              <S.BoxTitle>
                {t('newIndustryGroup:basicInformation:basicInformation')}
              </S.BoxTitle>
            </S.GridTitle>
          </S.GridHeader>
          <S.GridFilter container spacing={2}>
            <S.GridInput item sm={12} md={6}>
              <Input
                fullWidth
                label={t('newIndustryGroup:basicInformation:industryGroupName')}
                value={form.name}
                error={!!errors.name}
                helperText={errors.name}
                onInput={value => handleInput(value, 'name')}
              />
            </S.GridInput>
            <S.GridInput item sm={12} md={6}>
              <Input
                fullWidth
                label={t('newIndustryGroup:basicInformation:adminName')}
                value={form.adminName}
                error={!!errors.adminName}
                helperText={errors.adminName}
                onInput={value => handleInput(value, 'adminName')}
              />
            </S.GridInput>
            <S.GridInput item sm={12} md={6}>
              <Input
                fullWidth
                label={t('newIndustryGroup:basicInformation:adminEmail')}
                value={form.adminEmail}
                error={!!errors.adminEmail}
                helperText={errors.adminEmail}
                onInput={value => handleInput(value, 'adminEmail')}
              />
            </S.GridInput>
            <S.GridInput item sm={12} md={6}>
              <InputCpfCnpj
                fullWidth
                value={form.cnpj}
                error={!!errors.cnpj}
                helperText={errors.cnpj}
                label={t('newIndustryGroup:basicInformation:CNPJ')}
                type="cnpj"
                onInput={value => handleInput(value, 'cnpj')}
              />
            </S.GridInput>
            <S.GridInput item xs={12}>
              <InputAutocomplete
                fullWidth
                error={Boolean(errors.companyGroupProfiles)}
                helperText={errors.companyGroupProfiles}
                label={t('newIndustryGroup:groupProfile:groupProfile')}
                options={groupProfile || []}
                optionLabel="label"
                defaultValue={groupsProfileSelected}
                loading={true}
                loadingText="Aguarde..."
                onSelected={val =>
                  handleAutocomplete('companyGroupProfiles', val)
                }
              />
            </S.GridInput>
          </S.GridFilter>
        </div>
        {/* <Grid container spacing={3} style={{ marginBottom: 16 }}>
          <Grid item xs={12} sm={6} lg={6}>
            <S.GridHeader>
              <S.GridTitle item container lg={12}>
                <S.BoxTitle>
                  {t('newIndustryGroup:visualizationType:visualizationType')}
                </S.BoxTitle>
              </S.GridTitle>
            </S.GridHeader>
            <S.GridFilter container spacing={2}>
              <S.GridInput item xs={12}>
                <InputSelect
                  error={Boolean(errors.visible)}
                  fullWidth
                  helperText={errors.visible}
                  label={t('newIndustryGroup:visualizationType:visualization')}
                  onSelected={({ value }) => handleSelected('visible', value)}
                  optionLabel="label"
                  optionValue="value"
                  options={formattedVisibilityOptions}
                  value={form.visible}
                />
              </S.GridInput>
            </S.GridFilter>
          </Grid>
          <Grid item xs={12} sm={6} lg={6}>
            <S.GridHeader>
              <S.GridTitle item container lg={12}>
                <S.BoxTitle>
                  {t('newIndustryGroup:groupFunction:groupFunction')}
                </S.BoxTitle>
              </S.GridTitle>
            </S.GridHeader>
            <S.GridFilter container spacing={2}>
              <S.GridInput item xs={12}>
                <InputSelect
                  error={Boolean(errors.companyGroupRoleId)}
                  fullWidth
                  helperText={errors.companyGroupRoleId}
                  label={t('newIndustryGroup:groupFunction:group')}
                  onSelected={({ id }) =>
                    handleSelected('companyGroupRoleId', id)
                  }
                  optionLabel="label"
                  optionValue="id"
                  options={groupRoleOptions || []}
                  value={form.companyGroupRoleId}
                  loading={true}
                  loadingText="Aguarde..."
                />
              </S.GridInput>
            </S.GridFilter>
          </Grid>
        </Grid> */}
      </S.FullGrid>
      <S.GridButtons item xs={12}>
        <Button variant="gray" onClick={onExit} size="medium">
          <p>{t('common:Cancel')}</p>
        </Button>
        <Button variant="green" onClick={submit} size="medium">
          <p>{t('common:Save')}</p>
        </Button>
      </S.GridButtons>
    </S.Wrapper>
  )
}
