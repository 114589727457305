import { Grid } from '@material-ui/core'
import styled from 'styled-components'

export const GridFields = styled(Grid)`
  display: flex;
  margin: -16px;
  width: calc(100% + 24px);
  flex-direction: row;
  .Mui-disabled {
    color: ${({ theme }) => theme.colors.gray.brighten()};
    border-color: ${({ theme }) => theme.colors.gray.brighten()};
    :hover {
      cursor: not-allowed !important;
    }
  }
`

export const GridInput = styled(Grid)``
