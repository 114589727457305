import { ChangeEvent, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { accordionItems } from 'pages/Provider/constants'
import { ITotalizers } from 'pages/Provider/types'

import { Accordion } from 'components'

import * as S from '../../styled'
import { AccordionDetailsContent } from '../AccordionDetailContent'

interface SummaryProps {
  summaryData: ITotalizers
  children: any
}

export function Summary({ summaryData, children }: SummaryProps) {
  const { t } = useTranslation()
  const [expandedTag, setExpandedTag] = useState<string | boolean>(false)
  const [expanded, setExpanded] = useState(false)

  const handleAccordionChange = useMemo(() => {
    return () => (event: ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(!!isExpanded)
    }
  }, [])

  const handleAccordionChangeTag = useMemo(() => {
    return (panel: string) => (event: ChangeEvent<{}>, isExpanded: boolean) => {
      setExpandedTag(isExpanded ? panel : false)
    }
  }, [])

  return (
    <S.SummaryWrapper>
      <Accordion
        isActive={true}
        expanded={expanded}
        title={t('traceabilityProviderPage:constants:dashboard')}
        icon={'grafico'}
        onChange={handleAccordionChange()}
        color={'primary'}
      >
        <S.SummaryContainer>
          {accordionItems.map((item, index) => (
            <Accordion
              isActive={true}
              key={index}
              expanded={expandedTag === item.tag}
              title={t(
                `traceabilityProviderPage:accordionDetails:${item.title}`
              )}
              icon={item.icon}
              counter={summaryData[item.tag].length}
              onChange={handleAccordionChangeTag(item.tag)}
              isTraceabilityPage
            />
          ))}
        </S.SummaryContainer>
        <S.SummaryDetails>
          {accordionItems.map(item => (
            <>
              {expandedTag === item.tag &&
                summaryData[item.tag].map((summary, index) => (
                  <S.AccordionDetailsComponent key={index} icon={item.icon}>
                    <AccordionDetailsContent data={summary} tag={item.tag} />
                  </S.AccordionDetailsComponent>
                ))}
            </>
          ))}
        </S.SummaryDetails>
        {children}
      </Accordion>
    </S.SummaryWrapper>
  )
}
