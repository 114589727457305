import { useCallback, useContext, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import i18n from 'i18n'

import { OrdersManagementTableContext } from 'contexts/ordersTable/ordersTable.context'
import { SupplierFiltersContext } from 'contexts/supplierFilters/supplierFilters.context'
import { TraceabilityRetailFiltersContext } from 'contexts/traceabilityRetailTableFilters/traceabilityRetailTableFilters.context'

import { OrdersManagementService } from 'services/ordersManagement.service'

import { onFilterPaginate } from 'utils/OnFilterPaginate'
import { formaDateStringLocale } from 'utils/formaDateStringLocale'

import {
  INITIAL_STATE_LAUNCH_PRODUCTION,
  launchProduction
} from 'pages/OrderManagementDetails/orderManagementDetails.constants'
import { LaunchProductionProps } from 'pages/OrderManagementDetails/orderManagementDetails.type'

import { Pagination } from 'components'

import * as S from '../../styles'

type ParamsProps = {
  id?: string
}

type LocationProps = {
  fromRetail: boolean
  fromSupplier: boolean
}

export function InvoiceTable({ getDetailsTableData }) {
  const { state } = useLocation<LocationProps>()
  const { id: orderNumber } = useParams<ParamsProps>()

  const language = i18n.language === '中文 - ZH - CN' ? 'zh-CN' : i18n.language

  const { ordersManagementFilters } = useContext(OrdersManagementTableContext)
  const { traceabilityRetailsFilters } = useContext(
    TraceabilityRetailFiltersContext
  )
  const { supplierFilters } = useContext(SupplierFiltersContext)

  const [invoicesTables, setInvoicesTable] = useState<LaunchProductionProps>(
    INITIAL_STATE_LAUNCH_PRODUCTION
  )

  const getInvoicesFromApi = useCallback(
    async (page?: number, limit?: number) => {
      try {
        const service = new OrdersManagementService()
        let _filters
        switch (true) {
          case state?.fromRetail:
            _filters = { ...traceabilityRetailsFilters }
            break
          case state?.fromSupplier:
            _filters = { ...supplierFilters }
            break
          default:
            _filters = { ...ordersManagementFilters }
        }
        delete _filters.startDate
        delete _filters.endDate
        delete _filters.dateType
        delete _filters.expireIn

        await service
          .getInvoices({
            ..._filters,
            orderNumber,
            supplierAndOutsourcedCnpj:
              getDetailsTableData.supplierAndOutsourcedCnpj,
            supplierAndOutsourcedName:
              getDetailsTableData.supplierAndOutsourcedName,
            itemCode: getDetailsTableData.itemCode,
            direction: 'DESC',
            page,
            limit
          })
          .then(({ data }: any) => {
            setInvoicesTable(data)
          })
      } catch (error) {
        console.log(error)
      }
    },
    []
  )

  const formattedInvoiceTables = invoicesTables.items.map(item => ({
    ...item,
    requestDate: formaDateStringLocale(item.requestDate),
    qtyDelivered: Number(item.qtyDelivered).toLocaleString(language)
  }))

  function handlePaginationFilter({ limit, page }) {
    const filters = onFilterPaginate(ordersManagementFilters, limit, page)

    getInvoicesFromApi(filters.page, filters.limit)
  }

  useEffect(() => {
    getInvoicesFromApi()
  }, [ordersManagementFilters])

  return (
    <>
      <S.TableComponent
        rows={formattedInvoiceTables}
        columns={launchProduction()}
      />
      <Pagination
        totalItems={invoicesTables?.pagination.totalItems}
        page={invoicesTables?.pagination.page}
        limit={invoicesTables?.pagination.limit}
        setFilter={handlePaginationFilter}
      />
    </>
  )
}
