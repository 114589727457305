export const CONTACT_INITIAL_STATE = { email: '', name: '', phone: '' }

export const CERTIFICATION_INITIAL_STATE = {
  name: '',
  acronym: '',
  number: '',
  issuanceDate: null,
  expirationDate: null,
  cnpjCert: '',
  corporateName: '',
  certifierEmail: '',
  certifierPhone: ''
}

export const INITIAL_STATE = {
  id: '',
  address: '',
  cep: '',
  city: null,
  cnpj: '',
  contacts: [{ ...CONTACT_INITIAL_STATE }],
  country: '76', // 76 é o código do Brasil na API do IBGE
  certifications: [{ ...CERTIFICATION_INITIAL_STATE }],
  fancyName: '',
  groups: [],
  ie: '',
  inspectionNum: '',
  inspectionType: '',
  lat: null,
  lng: null,
  name: '',
  manager: {
    bio: '',
    doc: '',
    name: '',
    photo: null
  },
  microregion: '',
  photos: [],
  companyProfiles: [],
  employees: [],
  sifPhoto: null,
  state: null,
  status: true,
  technician: {
    bio: '',
    doc: '',
    name: '',
    photo: null
  }
}

export const ERRORS_INITIAL_STATE = {
  address: '',
  cep: '',
  city: null,
  cnpj: '',
  contacts: [{ ...CONTACT_INITIAL_STATE }],
  country: null,
  certifications: [{ ...CERTIFICATION_INITIAL_STATE }],
  fancyName: '',
  ie: '',
  inspectionNum: '',
  inspectionType: '',
  lat: '',
  lng: '',
  name: '',
  manager: {
    bio: '',
    doc: '',
    name: ''
  },
  microregion: '',
  companyProfiles: '',
  sifPhoto: null,
  state: null,
  status: null,
  technician: {
    bio: '',
    doc: '',
    name: ''
  }
}

export const GROUP_FILTER_INITIAL_STATE = {
  name: '',
  fancyName: '',
  cnpj: ''
}
