import Avatar from '@material-ui/core/Avatar'
import * as S from './styled'

export type AvatarProps = {
  color?: string
  name: string
  imgUrl?: string
  variant?: 'small' | 'medium'
}

export function AvatarUI({ color, name, imgUrl, variant }: AvatarProps) {
  return (
    <S.Wrapper data-testid="avatar" color={color}>
      <Avatar className={variant} alt={name} src={imgUrl} />
    </S.Wrapper>
  )
}
