import { useTranslation } from 'react-i18next'
import { UserStorageService } from 'services'

const Translate = ({ text }: { text: string }) => {
  const { t } = useTranslation()

  return <>{t(text)}</>
}

const menuRoutesList = [
  {
    icon: 'panel-home',
    id: 'inicio',
    labelMenu: <Translate text="breadcrumb:home" />,
    labelPage: <Translate text="breadcrumb:home" />,
    link: '/',
    sidebar: true
  },
  {
    icon: 'panel-general',
    id: 'painelGeral',
    labelMenu: <Translate text="breadcrumb:generalPanel" />,
    labelPage: <Translate text="breadcrumb:generalPanel" />,
    link: '/geral',
    sidebar: true
  },
  {
    icon: 'breadcrumbs-traceability',
    id: 'painelRastreabilidade',
    labelMenu: <Translate text="breadcrumb:traceabilityPanel" />,
    labelPage: <Translate text="breadcrumb:traceabilityPanel" />,
    link: '/rastreabilidade',
    sidebar: true
  },
  {
    icon: 'breadcrumbs-user',
    id: 'profile',
    labelPage: <Translate text="breadcrumb:users" />,
    link: '/usuario',
    sidebar: false
  },
  {
    icon: 'breadcrumbs-user',
    id: 'userRegister',
    labelPage: <Translate text="breadcrumb:userRegistration" />,
    link: '/usuario/novo',
    sidebar: false
  },
  {
    icon: 'breadcrumbs-user',
    id: 'userEdit',
    labelPage: 'Editar Usuário',
    link: '/usuario/editar/:id',
    sidebar: false
  },
  {
    icon: 'breadcrumbs-industry',
    id: 'indutry',
    labelPage: <Translate text="breadcrumb:industries" />,
    link: '/industria',
    sidebar: false
  },
  {
    icon: 'breadcrumbs-industry',
    id: 'indutryRegistration',
    labelPage: <Translate text="breadcrumb:industryRegistration" />,
    link: '/industria/novo',
    sidebar: false
  },
  {
    icon: 'breadcrumbs-industry',
    id: 'indutryGroup',
    labelPage: <Translate text="breadcrumb:industryGroup" />,
    link: '/grupo-industria',
    sidebar: false
  },
  {
    icon: 'breadcrumbs-industry',
    id: 'indutryGroupRegistration',
    labelPage: <Translate text="breadcrumb:industryGroupRegistration" />,
    link: '/grupo-industria/novo',
    sidebar: false
  },
  {
    icon: 'breadcrumbs-spinning',
    id: 'painelConsultaFiacao',
    labelMenu: <Translate text="breadcrumb:wiring" />,
    labelPage: <Translate text="breadcrumb:wiring" />,
    link: '/rastreabilidade/fiacao',
    sidebar: false
  },
  {
    icon: 'breadcrumbs-weaving',
    id: 'painelConsultaTecelagem',
    labelMenu: <Translate text="breadcrumb:weaving" />,
    labelPage: <Translate text="breadcrumb:weaving" />,
    link: '/rastreabilidade/tecelagem',
    sidebar: false
  },
  {
    icon: 'breadcrumbs-fabrics',
    id: 'painelConsultaTecido',
    labelMenu: <Translate text="breadcrumb:fabrics" />,
    labelPage: <Translate text="breadcrumb:fabrics" />,
    link: '/rastreabilidade/tecidos/:id',
    sidebar: false
  },
  {
    icon: 'breadcrumbs-confecction',
    id: 'painelConsultaConfeccao',
    labelMenu: <Translate text="breadcrumb:provider" />,
    labelPage: <Translate text="breadcrumb:provider" />,
    link: '/rastreabilidade/fornecedor',
    sidebar: false
  },
  {
    icon: 'breadcrumbs-retail',
    id: 'painelConsultaVarejo',
    labelMenu: <Translate text="breadcrumb:retail" />,
    labelPage: <Translate text="breadcrumb:retail" />,
    link: '/rastreabilidade/varejo',
    sidebar: false
  },
  {
    icon: 'breadcrumbs-user',
    id: 'user',
    labelPage: 'Perfil',
    link: '/perfil',
    sidebar: false
  },
  {
    icon: 'card-api',
    id: 'orderManagementOrders',
    labelPage: <Translate text="breadcrumb:orders" />,
    link: '/gestao-pedidos/pedidos',
    sidebar: false
  },
  {
    icon: 'card-api',
    id: 'orderManagementOrdersDetails',
    labelPage: <Translate text="breadcrumb:orderDetails" />,
    link: '/gestao-pedidos/detalhes-pedido/:id',
    sidebar: false
  },
  {
    icon: 'hourglass',
    id: 'leadTime',
    labelMenu: <Translate text="breadcrumb:leadTime" />,
    labelPage: <Translate text="breadcrumb:leadTime" />,
    link: '/gestao-pedidos/lead-time/:id',
    sidebar: false
  }
  // {
  //   icon: 'hourglass',
  //   id: 'leadTime',
  //   labelMenu: <Translate text="breadcrumb:leadTime" />,
  //   labelPage: <Translate text="breadcrumb:leadTime" />,
  //   link: '/gestao-pedidos/lead-time',
  //   sidebar: false
  // }
]

export function menuRoutes() {
  let _menuRoutes = Array.from(menuRoutesList)
  if (UserStorageService.isAuthenticated()) {
    const {
      profile: { permissions },
      user
    } = UserStorageService.getUserData()

    if (user.role === 'visual') {
      _menuRoutes = menuRoutesList.filter(route => route.id !== 'painelGeral')
    }

    if (permissions.ordersManagement || permissions.leadTime) {
      _menuRoutes.splice(3, 0, {
        icon: 'breadcrumbs-order-summary',
        id: 'orderManagement',
        labelMenu: <Translate text="breadcrumb:orderManagement" />,
        labelPage: <Translate text="breadcrumb:orderManagement" />,
        link: '/gestao-pedidos',
        sidebar: true
      })
    }
  }

  return _menuRoutes
}
