import { useTranslation } from 'react-i18next'

import {
  ERRORS_INITIAL_STATE,
  INITIAL_STATE
} from 'constants/industry.constants'

import { Input } from 'components/Input'
import { InputSelect } from 'components/InputSelect'
import { InputCpfCnpj } from 'components/InputCpfCnpj'

import * as S from './styled'

type BasicInformationType = {
  errors: typeof ERRORS_INITIAL_STATE
  payload: typeof INITIAL_STATE
  disabled: boolean
  handleInput: (_value, _name) => void
  handleSelected: (_name: string) => (_value: any) => void
}

export function BasicInformation({
  errors,
  payload,
  handleInput,
  handleSelected,
  disabled
}: BasicInformationType) {
  const { t } = useTranslation()

  const status = [
    {
      label: t('newIndustry:basicInformation:statusIndustry:active'),
      value: true
    },
    {
      label: t('newIndustry:basicInformation:statusIndustry:inactive'),
      value: false
    }
  ]

  return (
    <S.GridFields container spacing={2}>
      <S.GridInput item sm={12} md={4}>
        <Input
          fullWidth
          label={t('newIndustry:basicInformation:companyName')}
          value={payload.name}
          error={Boolean(errors.name)}
          helperText={errors.name}
          onInput={value => handleInput(value, 'name')}
          disabled={disabled}
        />
      </S.GridInput>
      <S.GridInput item sm={12} md={4}>
        <Input
          fullWidth
          label={t('newIndustry:basicInformation:fantasyName')}
          value={payload.fancyName}
          error={Boolean(errors.fancyName)}
          helperText={errors.fancyName}
          onInput={value => handleInput(value, 'fancyName')}
          disabled={disabled}
        />
      </S.GridInput>
      <S.GridInput item sm={12} md={4}>
        <InputCpfCnpj
          fullWidth
          label={t('newIndustry:basicInformation:CNPJ')}
          value={payload.cnpj}
          error={Boolean(errors.cnpj)}
          helperText={errors.cnpj}
          type="cnpj"
          onInput={value => handleInput(value, 'cnpj')}
          disabled={disabled}
        />
      </S.GridInput>
      <S.GridInput item sm={12} md={4}>
        <Input
          fullWidth
          label={t('newIndustry:basicInformation:stateRegistration')}
          value={payload.ie}
          error={Boolean(errors.ie)}
          helperText={errors.ie}
          onInput={value => handleInput(value, 'ie')}
          disabled={disabled}
        />
      </S.GridInput>
      <S.GridInput item sm={12} md={4}>
        <InputSelect
          error={Boolean(errors.status)}
          fullWidth
          helperText={errors.status}
          label={t('newIndustry:basicInformation:statusIndustry:status')}
          onSelected={handleSelected('status')}
          optionLabel="label"
          optionValue="value"
          options={status}
          value={payload.status}
          disabled={disabled}
        />
      </S.GridInput>
    </S.GridFields>
  )
}
