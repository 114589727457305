import { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { OrderService } from 'services'

import { TraceabilityRetailFiltersContext } from 'contexts/traceabilityRetailTableFilters/traceabilityRetailTableFilters.context'

import { cnpjMask } from 'utils/CnpjMask'
import { formaDateStringLocale } from 'utils/formaDateStringLocale'
import { calcOffsetForPagination } from 'utils/CalcOffsetForPagination'

import { generalOrderDetails } from './ordersDetails.constants'
import { GeneralOrderDetail, OrderDetails } from './orderDetails.types'

import { Pagination } from 'components'
import { TableAccordion } from 'components/TableAccordion'
import { ProductionDetailsTable } from 'pages/Provider/components/Tables/ProductionDetailsTable/ProductionDetailsTable'

export function GeneralOrderDetails({ id, parentHeight }: any) {
  const { t, i18n } = useTranslation()
  const language = i18n.language === '中文 - ZH - CN' ? 'zh-CN' : i18n.language

  const { traceabilityRetailsFilters } = useContext(
    TraceabilityRetailFiltersContext
  )

  const [columnSorted, setColumnSorted] = useState('invoiceDeliveryDate')
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC'>('DESC')

  const [orderNumber, setOrderNumber] = useState(null)
  const [tableHeight, setTableHeight] = useState(0)

  const [generalOrderDetail, setGeneralOrderDetail] =
    useState<GeneralOrderDetail>({
      items: [],
      pagination: {
        page: 0,
        totalItems: 0,
        limit: 0,
        totalPages: 0
      }
    })

  const [totalCnpj, setTotalCnpj] = useState('0')

  const getDetailsFromApiWithID = useCallback(
    async (page?: number, limit?: number) => {
      const orderService = new OrderService()
      await orderService
        .getOrderData({
          id,
          page: page || generalOrderDetail.pagination.page,
          limit: limit || generalOrderDetail.pagination.limit,
          column: columnSorted,
          direction: sortDirection
        })
        .then(response => {
          const { data } = response
          setGeneralOrderDetail(data as GeneralOrderDetail)
        })
    },
    [id, columnSorted, sortDirection]
  )

  const calcParentHeight = (childHeightValue: number) => {
    // "+1" pra considerar a linha do header da tabela
    const totalRows = generalOrderDetail.items.length + 1
    // 40px é a altura da linha da tabela
    const rowHeight = 40
    // 180px é a margem do accordion
    const marginBorder = 180
    const result = totalRows * rowHeight + childHeightValue
    const resultChild = childHeightValue + marginBorder
    setTableHeight(resultChild)
    // Verificando se o filtro está aberto, se estiver, adiciona a margem do accordion
    const addBorderIfChildrenIsMounted = childHeightValue > 0 ? marginBorder : 0
    parentHeight(result + marginBorder + addBorderIfChildrenIsMounted)
  }

  const getTotalCnpj = () => {
    generalOrderDetail.items.map(item => {
      return setTotalCnpj(item.totalCnpj)
    })
  }

  function handleGetIdRow(orderItemParam?: OrderDetails) {
    setOrderNumber(orderItemParam.orderNumber)
  }

  function handlePaginationFilter({ limit, page }) {
    const newFilter = {
      ...generalOrderDetail.pagination
    }

    newFilter.page = calcOffsetForPagination(
      generalOrderDetail.pagination.limit,
      generalOrderDetail.pagination.page,
      limit,
      page
    )
    newFilter.limit = limit
    setGeneralOrderDetail({ ...generalOrderDetail, pagination: newFilter })
    getDetailsFromApiWithID(newFilter.page, newFilter.limit)
  }

  const formattedGeneralOrderDetail = generalOrderDetail.items.map(item => ({
    ...item,
    supplierCnpj: item.supplierCnpj ? cnpjMask(item.supplierCnpj) : null,
    invoiceIssueDate: item.invoiceIssueDate
      ? formaDateStringLocale(item.invoiceIssueDate)
      : null,
    invoiceDeliveryDate: item.invoiceDeliveryDate
      ? formaDateStringLocale(item.invoiceDeliveryDate)
      : null,
    orderDateUpdated: item.orderDateUpdated
      ? formaDateStringLocale(item.orderDateUpdated)
      : null,
    qtyTotalDelivered: Number(item.qtyTotalDelivered).toLocaleString(language),
    quantityItems: Number(item.quantityItems).toLocaleString(language)
  }))

  function handleSortData(column: string, direction: 'ASC' | 'DESC') {
    setColumnSorted(column)
    setSortDirection(direction)
  }

  function handleSortColumn(column: string) {
    setColumnSorted(column)
    handleSortData(column, sortDirection)
    if (column !== columnSorted) return
    const direction = sortDirection === 'ASC' ? 'DESC' : 'ASC'
    handleSortData(column, direction)
  }

  useEffect(() => {
    if (id && !generalOrderDetail.items.length) {
      getDetailsFromApiWithID()
    }
    getTotalCnpj()
    calcParentHeight(0)
    return () => parentHeight(0)
  }, [id, generalOrderDetail, getDetailsFromApiWithID])

  useEffect(() => {
    getDetailsFromApiWithID()
  }, [columnSorted, sortDirection])

  return (
    <>
      <TableAccordion
        rows={formattedGeneralOrderDetail}
        columns={generalOrderDetails(totalCnpj, t)}
        handleGetId={handleGetIdRow}
        titleTable={'Produção'}
        height={tableHeight}
        sortColumn={{ column: columnSorted, sort: sortDirection }}
        onSortColumn={handleSortColumn}
      >
        <ProductionDetailsTable
          orderNumber={orderNumber}
          outsourcedName={traceabilityRetailsFilters.outsourcedName}
          outsourcedCnpj={traceabilityRetailsFilters.outsourcedCnpj}
          parentHeight={calcParentHeight}
        />
      </TableAccordion>
      {tableHeight < 220 && (
        <Pagination
          totalItems={generalOrderDetail.pagination.totalItems}
          limit={generalOrderDetail.pagination.limit}
          page={generalOrderDetail.pagination.page}
          setFilter={handlePaginationFilter}
        />
      )}
    </>
  )
}
