import { Button } from 'components/Button'
import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
  margin-bottom: 3.2rem;
`

export const ButtonComponent = styled(Button)`
  display: flex;
  align-items: center;
  gap: 1.6rem;

  svg {
    fill: ${({ theme }) => theme.colors.white};
  }
`
