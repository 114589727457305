import { Link } from 'react-router-dom'

import { AvatarUI } from 'components/Avatar'
import { Dropdown } from 'components/Dropdown'
import { Icon } from 'components/Icon'

import * as S from './styles'
import { useSelector } from 'react-redux'
import { userDropdownData } from 'store/UserProfileDropdown/UserProfileDropdown.selector'
import { UserStorageService } from 'services'

export type UserDropdownProps = {
  setChangePassword?: () => void
}

export const UserDropdown = ({ setChangePassword }: UserDropdownProps) => {
  const userLogged = UserStorageService.getUserData()
  const { avatar, userName } = useSelector(userDropdownData)

  return (
    <S.Wrapper>
      <Dropdown
        title={
          <>
            <AvatarUI
              name={userName || userLogged.profile.name}
              imgUrl={avatar || userLogged.profile.avatar}
              variant="small"
            />
            <S.Username>{userName || userLogged.profile.name}</S.Username>
            <Icon icon="user-profine-arrow-down" height={16} width={16}></Icon>
          </>
        }
      >
        <S.Nav>
          <Link to={'#'} onClick={setChangePassword}>
            <S.Link>
              <Icon icon="dropdown-lock" height={16} width={16}></Icon>
              <span>Alterar Senha</span>
            </S.Link>
          </Link>
          <Link
            to={{
              pathname: '/perfil',
              state: { nameBreadcrumbs: 'Perfil do Usuário' }
            }}
          >
            <S.Link>
              <Icon icon="dropdown-edit" height={16} width={16}></Icon>
              <span>Editar</span>
            </S.Link>
          </Link>
          <Link
            to={{
              pathname: '/login'
            }}
          >
            <S.Link>
              <Icon icon="logout" height={16} width={16}></Icon>
              <span>Sair</span>
            </S.Link>
          </Link>
        </S.Nav>
      </Dropdown>
    </S.Wrapper>
  )
}
