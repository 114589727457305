/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import * as Yup from 'yup'
import { addDays, format, isAfter, subDays } from 'date-fns'

import { OrdersManagementTableContext } from 'contexts/ordersTable/ordersTable.context'

import { toasts } from 'utils/toasts'
import { YupCNPJ } from 'utils/YupCNPJ'
import { submitOnEnter } from 'utils/submitOnEnter'

import {
  INITIAL_STATE,
  ERRORS_INITIAL_STATE,
  INITIAL_STATE_CONSTANT_ORDER_SITUATION,
  ordersFilterRequestPayload
} from '../../allOrdersManagement.constants'

import {
  InputDate,
  RadioButton,
  Button,
  InputCpfCnpj,
  Input,
  InputSelect
} from 'components'

import * as S from './styled'

Yup.addMethod(Yup.object, 'atLeastOneOf', function (list) {
  return this.test({
    name: 'atLeastOneOf',
    message: 'Preencha o formulário corretamente',
    exclusive: true,
    params: { keys: list.join(', ') },
    test: value => value == null || list.some(f => !!value[f])
  })
})

const schema = Yup.object()
  .shape({
    startDate: Yup.date(),
    endDate: Yup.date(),
    dateType: Yup.string(),
    orderNumber: Yup.string(),
    orderStatus: Yup.string(),
    orderSituation: Yup.string(),
    supplierAndOutsourcedCnpj: Yup.lazy(data => {
      if (data) return YupCNPJ.verify()
      return Yup.string()
    }),
    supplierAndOutsourcedName: Yup.string()
  })
  .atLeastOneOf([
    'startDate',
    'endDate',
    'orderNumber',
    'supplierAndOutsourcedCnpj',
    'supplierAndOutsourcedName'
  ])

// type FilterProps = {
//   onSubmit: (_form: any) => void
// }

export function FilterForm() {
  const { t } = useTranslation()

  const {
    ordersManagementFilters,
    setOrdersManagementFilters,
    clearOrdersManagementFilters
  } = useContext(OrdersManagementTableContext)

  function parseFilterOptions() {
    const startDateKey =
      ordersFilterRequestPayload[ordersManagementFilters.dateType].startDate
    const endDateKey =
      ordersFilterRequestPayload[ordersManagementFilters.dateType].endDate
    return {
      startDate: ordersManagementFilters[startDateKey],
      endDate: ordersManagementFilters[endDateKey]
    }
  }

  const _INITIAL_STATE = {
    ...INITIAL_STATE,
    ...ordersManagementFilters,
    ...parseFilterOptions()
  }

  const [form, setForm] = useState({
    ..._INITIAL_STATE
  })
  const [errors, setErrors] = useState({ ...ERRORS_INITIAL_STATE })

  const [orderSituation] = useState(INITIAL_STATE_CONSTANT_ORDER_SITUATION)
  const [orderSituationSelected, setOrderSituationSelected] = useState([])

  const orderStatusOptions = () => [
    {
      id: '0',
      label: t('ordersManagementPage:constants.orderStatusDropdown.T'),
      tag: 'T'
    },
    {
      id: '1',
      label: t('ordersManagementPage:constants.orderStatusDropdown.A'),
      tag: 'A'
    },
    {
      id: '2',
      label: t('ordersManagementPage:constants.orderStatusDropdown.P'),
      tag: 'P'
    },
    {
      id: '3',
      label: t('ordersManagementPage:constants.orderStatusDropdown.F'),
      tag: 'F'
    }
  ]

  const [orderStatusSelected, setOrderStatusSelected] = useState(() => ({
    id: '0',
    label: t('traceabilityRetailPage:constants.orderStatusDropdown.T'),
    tag: 'T'
  }))

  async function onFilter(isFirstLoadPage?: boolean) {
    try {
      const filter = await schema.validate(form, {
        abortEarly: false,
        stripUnknown: true
      })

      const params = {
        ...filter,
        orderNumber: filter.orderNumber,
        [ordersFilterRequestPayload[form.dateType].startDate]:
          filter.startDate && format(new Date(filter.startDate), 'yyyy-MM-dd'),
        [ordersFilterRequestPayload[form.dateType].endDate]:
          filter.endDate && format(new Date(filter.endDate), 'yyyy-MM-dd'),
        supplierAndOutsourcedCnpj: filter.supplierAndOutsourcedCnpj.replace(
          /[./-]/g,
          ''
        ),
        supplierAndOutsourcedName: filter.supplierAndOutsourcedName
      }

      const _params = { ...params }
      delete _params.startDate
      delete _params.endDate
      delete _params.dateType

      if (!isFirstLoadPage) setOrdersManagementFilters(params)
    } catch (ex) {
      console.log(ex.inner)

      if (ex.inner && ex.inner.length) {
        const pairs = ex.inner?.map(({ path, message }) => [
          path,
          message.replace(`${path} `, '')
        ])
        toasts.invalidForm()
        setErrors(Object.fromEntries(pairs) as typeof ERRORS_INITIAL_STATE)
      }
    }
  }

  function clearFilter() {
    clearOrdersManagementFilters()
    setForm({ ...INITIAL_STATE })
    setErrors({ ...ERRORS_INITIAL_STATE })
    onFilter(true)
  }

  function handleInput(value: string, name: string) {
    setErrors({ ...errors, [name]: '' })
    setForm({ ...form, [name]: value })
  }

  function handleInputData(value: string | Date, name: string) {
    setForm({ ...form, [name]: value })
  }

  function handleSelect(value: any, name: string) {
    if (value.value) {
      setForm({ ...form, [name]: value.value })
    } else {
      setForm({ ...form, [name]: value })
    }
  }

  function handleSelectOrderStatus(field: string, value: any) {
    setErrors({ ...errors, [field]: '' })
    setForm({ ...form, [field]: value.tag })
    setOrderStatusSelected(value)
  }

  function handleSelectOrderSituation(field: string, value: any) {
    setErrors({ ...errors, [field]: '' })
    setForm({ ...form, [field]: value.id })
    setOrderSituationSelected(value)
  }

  const dateType = [
    {
      title: t('ordersManagementPage:filtersOrders:order'),
      value: 'order'
    },
    {
      title: t('ordersManagementPage:filtersOrders:deliveryForecast'),
      value: 'prediction'
    }
  ]

  useEffect(() => {
    onFilter(true)

    if (isAfter(ordersManagementFilters.expireIn, new Date())) {
      clearOrdersManagementFilters()
    }
  }, [])

  return (
    <S.Wrapper container>
      <S.FullGrid item xs={12}>
        <S.GridHeader>
          <S.GridTitle item container xs={12}>
            <S.BoxTitle>
              {t('ordersManagementPage:filtersOrders:filterData')}
            </S.BoxTitle>
            <S.BoxLine />
          </S.GridTitle>
        </S.GridHeader>
        <S.GridFilter container>
          <S.GridInput item xs={12} sm={6} md={3}>
            <InputDate
              fullWidth
              label={t('ordersManagementPage:filtersOrders:initialDate')}
              value={form.startDate}
              error={Boolean(errors.startDate)}
              helperText={errors.startDate}
              onInput={value => handleInputData(value, 'startDate')}
              maxDate={form.endDate && subDays(form.endDate, 1)}
            />
          </S.GridInput>
          <S.GridInput item xs={12} sm={6} md={3}>
            <InputDate
              fullWidth
              label={t('ordersManagementPage:filtersOrders:finalDate')}
              value={form.endDate}
              error={Boolean(errors.endDate)}
              helperText={errors.endDate}
              onInput={value => handleInputData(value, 'endDate')}
              minDate={form.startDate && addDays(form.startDate, 1)}
            />
          </S.GridInput>
          <S.GridInput item xs={12} sm={12} md={6}>
            <RadioButton
              label=""
              options={dateType}
              key="dateType"
              value={form.dateType}
              onSelected={value => handleSelect(value, 'dateType')}
            />
          </S.GridInput>
        </S.GridFilter>

        <S.GridFilter container>
          <S.GridInput item xs={12} sm={6} md={4}>
            <Input
              error={Boolean(errors.orderNumber)}
              fullWidth
              helperText={errors.orderNumber}
              label={t('ordersManagementPage:filtersOrders:orderNumber')}
              onInput={value => handleInput(value, 'orderNumber')}
              onKeyPress={submitOnEnter(onFilter)}
              value={form.orderNumber}
            />
          </S.GridInput>

          <S.GridInput item xs={12} sm={6} md={4}>
            <InputSelect
              error={Boolean(errors.orderStatus)}
              fullWidth
              helperText={errors.orderStatus}
              defaultValue={orderStatusSelected}
              label={t('ordersManagementPage:filtersOrders:orderStatus')}
              onSelected={val => handleSelectOrderStatus('orderStatus', val)}
              optionLabel="label"
              optionValue="tag"
              options={orderStatusOptions() || []}
              value={form.orderStatus}
              loadingText="Aguarde..."
            />
          </S.GridInput>

          <S.GridInput item xs={12} sm={6} md={4}>
            <InputSelect
              error={Boolean(errors.orderSituation)}
              fullWidth
              helperText={errors.orderSituation}
              defaultValue={orderSituationSelected}
              label={t('ordersManagementPage:filtersOrders:ordersSituation')}
              onSelected={val =>
                handleSelectOrderSituation('orderSituation', val)
              }
              optionLabel="label"
              optionValue="id"
              options={orderSituation || []}
              value={form.orderSituation}
              loadingText="Aguarde..."
            />
          </S.GridInput>
        </S.GridFilter>

        <S.GridFilter container>
          <S.GridInput item xs={12} sm={6} md={4}>
            <InputCpfCnpj
              error={Boolean(errors.supplierAndOutsourcedCnpj)}
              fullWidth
              helperText={errors.supplierAndOutsourcedCnpj}
              label={t(
                'ordersManagementPage:filtersOrders:nationalRegistryOfLegalEntities'
              )}
              onInput={value => handleInput(value, 'supplierAndOutsourcedCnpj')}
              onKeyPress={submitOnEnter(onFilter)}
              type="cnpj"
              value={form.supplierAndOutsourcedCnpj}
            />
          </S.GridInput>
          <S.GridInput item xs={12} sm={6} md={4}>
            <Input
              error={Boolean(errors.supplierAndOutsourcedName)}
              fullWidth
              helperText={errors.supplierAndOutsourcedName}
              label={t('ordersManagementPage:filtersOrders:corporateName')}
              onInput={value => handleInput(value, 'supplierAndOutsourcedName')}
              onKeyPress={submitOnEnter(onFilter)}
              value={form.supplierAndOutsourcedName}
            />
          </S.GridInput>
        </S.GridFilter>

        <S.GridFilter>
          <S.GridButtons item xs={12} sm={12} md={12}>
            <Button variant="gray" onClick={clearFilter} size="medium">
              <p>{t('ordersManagementPage:filtersOrders:clean')}</p>
            </Button>
            <Button variant="blue" onClick={() => onFilter()} size="medium">
              <p>{t('ordersManagementPage:filtersOrders:filter')}</p>
            </Button>
          </S.GridButtons>
        </S.GridFilter>
      </S.FullGrid>
    </S.Wrapper>
  )
}
