/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import * as S from '../../styled'

import { BarChart } from 'components'
import { useState, useCallback, useEffect } from 'react'
import { format } from 'date-fns'
import { ptBR, enUS, es, zhCN } from 'date-fns/locale'

import {
  INITIAL_DELAYED_SERIES,
  INITIAL_DELIVERED_OPEN_STATE,
  INITIAL_RANKING_STATE,
  INITIAL_SERIES_STATE
} from '../../constants'

import { IChartRanking, IOrdersVolumes } from 'pages/Retail/types'
import { useTranslation } from 'react-i18next'

interface ChartsProps {
  chartsOrdersVolumesData: IOrdersVolumes
  chartsRankingData: {
    providerRanking: any
    originProductsRanking: any
    weavingRanking: any
    wiringRanking: any
  }
}

export function Charts({
  chartsOrdersVolumesData,
  chartsRankingData
}: ChartsProps) {
  const { t, i18n } = useTranslation()
  const language = i18n.language === '中文 - ZH - CN' ? 'zh-CN' : i18n.language

  const dateLocale = {
    'pt-BR': ptBR,
    'en-US': enUS,
    es,
    '中文 - ZH - CN': zhCN
  }

  useEffect(() => {
    formatForDeliveredOpenSeries(chartsOrdersVolumesData)
    formatForDelayedSeries(chartsOrdersVolumesData)
  }, [i18n.language])

  const formatNameAndTranslateDeliveredOpenSeries =
    INITIAL_DELIVERED_OPEN_STATE().map(item => ({
      ...item,
      name: t(`traceabilityRetailPage:constants:${item.name}`)
    }))

  const formatNameAndTranslateDelayesSeries = INITIAL_DELAYED_SERIES.map(
    item => ({
      ...item,
      name: t(`traceabilityRetailPage:constants:${item.name}`)
    })
  )

  const [deliveredOpenSeries, setDeliveredOpenSeries] = useState(
    formatNameAndTranslateDeliveredOpenSeries
  )
  const [delayedSeries, setDelayedSeries] = useState(
    formatNameAndTranslateDelayesSeries
  )

  const [monthsOrdersVolumes, setMonthsOrdersVolumes] = useState([])
  const [monthsDelayed, setMonthsDelayed] = useState([])

  const [rankingProvidersOrders, setRankingProvidersOrders] = useState(
    INITIAL_RANKING_STATE
  )
  const [rankingProvidersVolume, setRankingProvidersVolume] = useState(
    INITIAL_RANKING_STATE
  )
  const [rankingOriginOrders, setRankingOriginOrders] = useState(
    INITIAL_RANKING_STATE
  )
  const [rankingOriginVolume, setRankingOriginVolume] = useState(
    INITIAL_RANKING_STATE
  )
  const [rankingWeavingOrders, setRankingWeavingOrders] = useState(
    INITIAL_RANKING_STATE
  )
  const [rankingWeavingVolume, setRankingtWeavingVolume] = useState(
    INITIAL_RANKING_STATE
  )
  const [rankingWiringOrders, setRankingWiringOrders] = useState(
    INITIAL_RANKING_STATE
  )
  const [rankingWiringVolume, setRankingWiringVolume] = useState(
    INITIAL_RANKING_STATE
  )

  const [originRankingSeries, setOriginRankingSeries] =
    useState(INITIAL_SERIES_STATE)
  const [providerRankingSeries, setProviderRankingSeries] =
    useState(INITIAL_SERIES_STATE)
  const [weavingRankingSeries, setWeavingRankingSeries] =
    useState(INITIAL_SERIES_STATE)
  const [wiringRankingSeries, setWiringRankingSeries] =
    useState(INITIAL_SERIES_STATE)

  // Comentado porque no momento não temos os dados concreto de volume
  // const radioOptions = [
  //   { title: t('traceabilityRetailPage:chartsRetail:order'), value: 'order' },
  //   { title: t('traceabilityRetailPage:chartsRetail:volume'), value: 'volume' }
  // ]

  const formatForDeliveredOpenSeries = (data: IOrdersVolumes) => {
    const months = []
    const delayedSeriesNewState = INITIAL_DELIVERED_OPEN_STATE().map(item => ({
      ...item,
      name: t(`traceabilityRetailPage:chartsRetail.${item.name}`),
      data: []
    }))

    setMonthsOrdersVolumes(months)

    const seriesData = data.openedDelivered.map(item => {
      delayedSeriesNewState[0].data.push(
        Number(item.ordersDelivered.toLocaleString(language)) || 0
      )
      // Comentado porque no momento não temos os dados concreto de volume
      // delayedSeriesNewState[1].data.push(item.volumesDelivered || 0)
      delayedSeriesNewState[1].data.push(
        Number(item.ordersOpen.toLocaleString(language)) || 0
      )
      // Comentado porque no momento não temos os dados concreto de volume
      // delayedSeriesNewState[3].data.push(item.volumesOpen || 0)
      months.push(
        format(new Date(`${item.mes}-02`), 'MMM YYY', {
          locale: dateLocale[i18n.language]
        })
      )
      return delayedSeriesNewState
    })[0]

    setMonthsOrdersVolumes(months)
    setDeliveredOpenSeries(seriesData)
  }

  const formatForDelayedSeries = useCallback((data: IOrdersVolumes) => {
    const months = []
    const delayedSeriesNewState = INITIAL_DELAYED_SERIES.map(item => ({
      ...item,
      name: t(`traceabilityRetailPage:constants:${item.name}`),
      data: []
    }))

    setMonthsDelayed(months)

    const seriesData = data.delayed.map(item => {
      delayedSeriesNewState[0].data.push(
        Number(item.orderDelayed.toLocaleString(language)) || 0
      )
      // Comentado porque no momento não temos os dados concreto de volume
      // delayedSeriesNewState[1].data.push(item.volumeDelayed || 0)

      months.push(
        format(new Date(`${item.mes}-02`), 'MMM YYY', {
          locale: dateLocale[i18n.language]
        })
      )
      return delayedSeriesNewState
    })[0]

    setMonthsDelayed(months)
    setDelayedSeries(seriesData)
  }, [])

  const handleFormateChartSeries = useCallback(
    (chartData: Record<string, any>[], isOrder: boolean) => {
      return [
        {
          name: isOrder
            ? t('traceabilityRetailPage:chartsRetail:order')
            : t('traceabilityRetailPage:chartsRetail:volume'),
          color: isOrder ? '#315ca7' : '#3fab36',
          data: JSON.parse(JSON.stringify(chartData)),
          dataSorting: {
            enabled: true
          }
        }
      ]
    },
    []
  )

  const setRankingSeriesState = useCallback(
    (
      chartData: Record<string, any>[],
      type: 'origin' | 'provider' | 'weaving' | 'wiring',
      isOrder = true
    ) => {
      if (!chartData.length) return
      const option = {
        origin: () =>
          setOriginRankingSeries(handleFormateChartSeries(chartData, isOrder)),
        provider: () =>
          setProviderRankingSeries(
            handleFormateChartSeries(chartData, isOrder)
          ),
        weaving: () =>
          setWeavingRankingSeries(handleFormateChartSeries(chartData, isOrder)),
        wiring: () =>
          setWiringRankingSeries(handleFormateChartSeries(chartData, isOrder)),
        default: null
      }
      if (!type) return
      return option[type]()
    },
    []
  )

  const formatForRankingChart = (items: IChartRanking[], isOrders: boolean) => {
    return items.map(item => ({
      name: item.name || item.country,
      y: isOrders
        ? Number(Number(item.orders).toLocaleString(language))
        : Number(Number(item.volumes).toLocaleString(language))
    }))
  }

  const formatForOriginProductsChart = useCallback(
    (originProductRanking: IChartRanking[]) => {
      const formatedOrders = formatForRankingChart(originProductRanking, true)
      setRankingOriginOrders(formatedOrders)
      setRankingSeriesState(formatedOrders, 'origin')
      setRankingOriginVolume(formatForRankingChart(originProductRanking, false))
    },
    [setRankingSeriesState]
  )

  const formatForProviderChart = useCallback(
    (providerRanking: IChartRanking[]) => {
      const formatedOrders = formatForRankingChart(providerRanking, true)
      setRankingProvidersOrders(formatedOrders)
      setRankingProvidersVolume(formatForRankingChart(providerRanking, false))
      setRankingSeriesState(formatedOrders, 'provider')
    },
    [setRankingSeriesState]
  )

  const formatForWeavingChart = useCallback(
    (weavingRanking: IChartRanking[]) => {
      const formatedOrders = formatForRankingChart(weavingRanking, true)
      setRankingWeavingOrders(formatedOrders)
      setRankingtWeavingVolume(formatForRankingChart(weavingRanking, false))
      setRankingSeriesState(formatedOrders, 'weaving')
    },
    [setRankingSeriesState]
  )

  const formatForWiringChart = useCallback(
    (wiringRanking: IChartRanking[]) => {
      const formatedOrders = formatForRankingChart(wiringRanking, true)
      setRankingWiringOrders(formatedOrders)
      setRankingWiringVolume(formatForRankingChart(wiringRanking, false))
      setRankingSeriesState(formatedOrders, 'wiring')
    },
    [setRankingSeriesState]
  )

  function handleSelectOrderOrigin(value: 'order' | 'volume') {
    if (value === 'order') setRankingSeriesState(rankingOriginOrders, 'origin')
    else setRankingSeriesState(rankingOriginVolume, 'origin', false)
  }

  function handleSelectProvider(value: 'order' | 'volume') {
    if (value === 'order') {
      setRankingSeriesState(rankingProvidersOrders, 'provider')
    } else setRankingSeriesState(rankingProvidersVolume, 'provider', false)
  }

  function handleSelectWeaving(value: 'order' | 'volume') {
    if (value === 'order') {
      setRankingSeriesState(rankingWeavingOrders, 'weaving')
    } else setRankingSeriesState(rankingWeavingVolume, 'weaving', false)
  }

  function handleSelectWiring(value: 'order' | 'volume') {
    if (value === 'order') setRankingSeriesState(rankingWiringOrders, 'wiring')
    else setRankingSeriesState(rankingWiringVolume, 'wiring', false)
  }

  const populateCharts = useCallback(() => {
    if (chartsOrdersVolumesData) {
      formatForDeliveredOpenSeries(chartsOrdersVolumesData as IOrdersVolumes)
    }
    if (chartsOrdersVolumesData) {
      formatForDelayedSeries(chartsOrdersVolumesData as IOrdersVolumes)
    }
    if (chartsRankingData) {
      formatForProviderChart(chartsRankingData.providerRanking)
      formatForOriginProductsChart(chartsRankingData.originProductsRanking)
      formatForWeavingChart(chartsRankingData.weavingRanking)
      formatForWiringChart(chartsRankingData.wiringRanking)
    }
  }, [
    formatForOriginProductsChart,
    formatForProviderChart,
    formatForWeavingChart,
    formatForWiringChart,
    chartsOrdersVolumesData,
    chartsRankingData,
    formatForDelayedSeries
  ])

  useEffect(() => {
    const noDeliveredSeriesDate = INITIAL_DELIVERED_OPEN_STATE().every(
      item => !item.data.length
    )
    const noDelayedSeriesDate = INITIAL_DELAYED_SERIES.every(
      item => !item.data.length
    )

    if (noDeliveredSeriesDate && noDelayedSeriesDate) {
      populateCharts()
    }
  }, [populateCharts])

  return (
    <>
      <S.ChartsContainer>
        <BarChart
          type="column"
          title={t('traceabilityRetailPage:chartsRetail:ordersDeliveredXOpen')}
          subTitle=""
          series={deliveredOpenSeries?.map(item => ({
            ...item,
            pointWidth: 140 / monthsOrdersVolumes.length
          }))}
          tooltipSuffix=""
          categories={monthsOrdersVolumes}
          height={485}
          stacked={true}
        />
        <BarChart
          type="column"
          title={t('traceabilityRetailPage:chartsRetail:pendingOrders')}
          categories={monthsDelayed}
          subTitle=""
          series={delayedSeries?.map(item => ({
            ...item,
            pointWidth: 140 / monthsDelayed.length
          }))}
          tooltipSuffix=""
          height={485}
        />
      </S.ChartsContainer>
      <S.ChartsContainer>
        <BarChart
          type="bar"
          title={t('traceabilityRetailPage:chartsRetail:originOfProducts')}
          subTitle=""
          series={originRankingSeries}
          tooltipSuffix=""
          height={485}
          // Comentado porque no momento não temos os dados concreto de volume
          // radioOptions={radioOptions}
          onRadioSelected={handleSelectOrderOrigin}
          hasLegend={true}
        />
        <BarChart
          type="bar"
          title={t('traceabilityRetailPage:chartsRetail:rankingSuppliers')}
          subTitle=""
          series={providerRankingSeries}
          tooltipSuffix=""
          height={485}
          // Comentado porque no momento não temos os dados concreto de volume
          // radioOptions={radioOptions}
          onRadioSelected={handleSelectProvider}
          hasLegend={true}
        />
      </S.ChartsContainer>
      <S.ChartsContainer>
        <BarChart
          type="bar"
          title={t('traceabilityRetailPage:chartsRetail:weavingRanking')}
          subTitle=""
          series={weavingRankingSeries}
          tooltipSuffix=""
          height={485}
          // Comentado porque no momento não temos os dados concreto de volume
          // radioOptions={radioOptions}
          onRadioSelected={handleSelectWeaving}
          hasLegend={true}
        />
        <BarChart
          type="bar"
          title={t('traceabilityRetailPage:chartsRetail:wiringRanking')}
          subTitle=""
          series={wiringRankingSeries}
          tooltipSuffix=""
          height={485}
          // Comentado porque no momento não temos os dados concreto de volume
          // radioOptions={radioOptions}
          onRadioSelected={handleSelectWiring}
          hasLegend={true}
        />
      </S.ChartsContainer>
    </>
  )
}
