import { ReactNode, useState } from 'react'

import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import { TableNotFoundHint } from 'components/TableNotFoundHint'
import { useTranslation } from 'react-i18next'

import * as S from './styled'
import { Icon } from 'components/Icon'

export type ColumnsProps = {
  align?: 'right' | 'left' | 'center'
  fixed?: boolean
  name: string
  title?: string
  useStatusBoxComponent?: boolean
}

export type TableProps = {
  columns: ColumnsProps[]
  children: ReactNode
  linkTableNotFound?: string
  rows: any[]
  sortColumn?: {
    column: string
    sort: string
  }
  titleTable?: string
  actionTable?: (_row) => JSX.Element
  handleGetId?: (_row) => void
  changeColumn?: (_props) => JSX.Element
  onSortColumn?: (_column) => void
  actionColumnLabel?: string
  isLoading?: boolean
  checkOrders?: boolean
  selectOrdersKeys?: (_key: string, _selectAll: boolean) => void
  checkedKeys?: string[]
  selectedAll?: boolean
  disableSelectOrder?: boolean
  disableSelectAllOrder?: boolean
  statusBoxColumnName?: string
  statusOrderCustomText?: boolean
  situationBoxColumnName?: string
  situationOrderCustomText?: boolean
  height?: number
  childrenLevel?: number
  setChildrenLevel?: (_level: number) => void
}

export function TableAccordion({
  columns = [],
  children,
  titleTable,
  linkTableNotFound = '',
  rows = [],
  sortColumn,
  actionTable,
  handleGetId,
  changeColumn,
  onSortColumn,
  actionColumnLabel,
  isLoading,
  statusBoxColumnName,
  statusOrderCustomText = false,
  situationBoxColumnName,
  situationOrderCustomText = false,
  height,
  setChildrenLevel,
  childrenLevel = 1
}: TableProps) {
  const { t } = useTranslation()
  const columnFixed = columns.findIndex(f => f.fixed) > -1
  const columnScroll = columns.findIndex(f => !f.fixed) > -1
  const [expandedRows, setExpandedRows] = useState(null)

  const loadingTable = () => {
    if (!rows.length && isLoading) {
      return (
        <S.TableLoadingFistTime>
          Aguarde, carregando dados...
        </S.TableLoadingFistTime>
      )
    }
    if (rows.length && isLoading) {
      return <S.TableLoading>Aguarde, carregando dados...</S.TableLoading>
    }
    if (!rows.length && !isLoading) {
      return <TableNotFoundHint to={linkTableNotFound} />
    }
  }

  function IconHeader({ column = '' }) {
    return (
      <S.ButtonIcon aria-label="Chevron Right">
        {sortColumn.column !== column || sortColumn.sort === 'DESC' ? (
          <ArrowDownwardIcon
            style={{
              color: sortColumn.column === column ? '#757575' : '#b1b1b1'
            }}
          />
        ) : (
          <ArrowUpwardIcon
            style={{
              color: sortColumn.column === column ? '#757575' : '#b1b1b1'
            }}
          />
        )}
      </S.ButtonIcon>
    )
  }

  function renderStatusAndSituationBox(
    column: string,
    rowValue: string,
    text: string
  ) {
    if (column === statusBoxColumnName) {
      // Colors based from status defined on i18n file above
      // A = "Aberto", P = "Parcial", F = "Finalizado"
      // It's only when don't have a custom status text
      return (
        <S.BoxContainer>
          {statusOrderCustomText ? text : t(`common:orderStatus:${rowValue}`)}
        </S.BoxContainer>
      )
    }

    if (column === situationBoxColumnName) {
      // Colors based from status defined on i18n file above
      // A = "Aberto", P = "Parcial", F = "Finalizado"
      // It's only when don't have a custom status text
      return (
        <S.BoxContainer>
          {situationOrderCustomText
            ? text
            : t(`common:orderSituation:${rowValue}`)}
        </S.BoxContainer>
      )
    }
    return rowValue
  }

  function handleChangeColumn(
    column: Record<string, any>,
    row: Record<string, any>
  ) {
    return changeColumn
      ? changeColumn({ column, row })
      : renderStatusAndSituationBox(
          column.name,
          row[column.name],
          row.customText
        )
  }

  const handleRowClick = (index: number, row: string) => {
    if (expandedRows === index) {
      if (setChildrenLevel) setChildrenLevel(childrenLevel - 1)
      setExpandedRows(null)
    } else {
      if (setChildrenLevel) setChildrenLevel(childrenLevel + 1)
      handleGetId(row)
      setExpandedRows(index)
    }
  }

  const SubRowBlank = (index: number) => {
    return (
      expandedRows === index && <S.ChildrenContainerBlanck height={height} />
    )
  }

  const SubRowChildren = (index: number) => {
    return (
      expandedRows === index && (
        <S.ContainerAbsolute>
          <S.ColumnAbsolute colSpan={20}>
            <S.Box>
              <S.BoxTitle>{titleTable}</S.BoxTitle>
              <S.BoxLine />
            </S.Box>
            {children}
          </S.ColumnAbsolute>
        </S.ContainerAbsolute>
      )
    )
  }

  return (
    <S.Wrapper
      data-testid="Table"
      columnFixed={columnFixed}
      columnScroll={columnScroll}
      actionTable={actionTable}
    >
      <S.Container isLoading={isLoading}>
        <S.Table id="table-fixed">
          <tbody>
            <S.Line id="header">
              <S.Header id="details">
                <S.DetailsContainer>
                  {t('tableComponent:Details')} &nbsp;
                </S.DetailsContainer>
              </S.Header>
              {columns.map(
                (column, index) =>
                  column.fixed && (
                    <S.Header
                      key={index}
                      buttonOpacity={
                        sortColumn ? sortColumn.column === column.name : 0
                      }
                      className={column.align}
                      onClick={() =>
                        onSortColumn ? onSortColumn(column.name) : false
                      }
                    >
                      {onSortColumn && column.align === 'right' && (
                        <IconHeader column={column.name} />
                      )}
                      {column.title || column.name}
                      {onSortColumn && column.align !== 'right' && (
                        <IconHeader column={column.name} />
                      )}
                    </S.Header>
                  )
              )}
            </S.Line>
            {rows.map((row, index) => {
              return (
                <>
                  <S.Line id="values" key={index}>
                    <S.Column key={index}>
                      <S.AccordionColumnContainer
                        onClick={() => {
                          handleRowClick(index, row)
                        }}
                      >
                        {expandedRows === index ? (
                          <Icon
                            icon="chevron-up"
                            width={16}
                            height={16}
                            cursorPointer
                          />
                        ) : (
                          <Icon
                            icon="chevron-down"
                            width={16}
                            height={16}
                            cursorPointer
                          />
                        )}
                      </S.AccordionColumnContainer>
                    </S.Column>
                    {columns.map(
                      (column, i) =>
                        column.fixed && (
                          <S.Column key={i} className={column.align}>
                            {row[column.name]
                              ? handleChangeColumn(column, row)
                              : '--'}
                          </S.Column>
                        )
                    )}
                  </S.Line>
                  {SubRowChildren(index)}
                  {SubRowBlank(index)}
                </>
              )
            })}
          </tbody>
        </S.Table>
        {columnScroll && (
          <S.ScrollWrapper expanded={expandedRows !== null}>
            <S.Table>
              <tbody>
                <S.Line id="header">
                  {columns.map(
                    (column, index) =>
                      !column.fixed && (
                        <S.Header
                          key={index}
                          buttonOpacity={
                            sortColumn ? sortColumn.column === column.name : 0
                          }
                          className={column.align}
                          onClick={() =>
                            onSortColumn ? onSortColumn(column.name) : false
                          }
                        >
                          {onSortColumn && column.align === 'right' && (
                            <IconHeader column={column.name} />
                          )}
                          {column.title || column.name}
                          {onSortColumn && column.align !== 'right' && (
                            <IconHeader column={column.name} />
                          )}
                        </S.Header>
                      )
                  )}
                  {actionTable && (
                    <S.Header>{actionColumnLabel || t('Actions')}</S.Header>
                  )}
                </S.Line>

                {rows.map((row, index) => {
                  return (
                    <>
                      <S.Line id="values" key={index}>
                        {columns.map((column, i) => (
                          <>
                            {!column.fixed && (
                              <S.Column key={i} className={column.align}>
                                {row[column.name]
                                  ? handleChangeColumn(column, row)
                                  : '--'}
                              </S.Column>
                            )}
                          </>
                        ))}
                        {actionTable && (
                          <S.Column id="actions-column">
                            {actionTable(row)}
                          </S.Column>
                        )}
                      </S.Line>
                      {SubRowBlank(index)}
                    </>
                  )
                })}
              </tbody>
            </S.Table>
          </S.ScrollWrapper>
        )}
      </S.Container>
      {loadingTable()}
    </S.Wrapper>
  )
}
