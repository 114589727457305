import styled from 'styled-components'

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
`

export const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  min-width: 17.6rem;

  padding-left: 1.5rem;
  a {
    :first-child {
      margin: 0.8rem 0;
      border-top-left-radius: 0.8rem;
      border-top-right-radius: 0.8rem;
    }
    :last-child {
      margin: 0.8rem 0;
      border-bottom-left-radius: 0.8rem;
      border-bottom-right-radius: 0.8rem;
    }
  }
`

export const Username = styled.span`
  color: ${({ theme }) => theme.colors.darkGray};
  padding: 0 1.5rem;
  font-size: 1.6rem;
  font-weight: 500;
`

export const Link = styled.li`
  align-items: center;
  background: white;
  color: ${({ theme }) => theme.colors.gray};
  display: flex;

  &:hover {
    background: rgba(0, 0, 0, 0.04);
    border-radius: 0;
  }

  > div {
    margin: 0.8rem -0.4rem 0.8rem 1.6rem !important;
  }

  > span {
    font-size: 1.6rem;
    margin: 0.8rem 1.6rem !important;
  }
`
