import { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import * as Yup from 'yup'
import {
  CompanyGroupService,
  UserRoleService,
  UserService,
  UserStorageService
} from 'services'

import { submitOnEnter } from 'utils/submitOnEnter'
import { phoneValidator } from 'utils/phoneValidator'
import { onFilterPaginate } from 'utils/OnFilterPaginate'

import { isValid as validateCPF } from 'cpf'

import {
  AddButton,
  Button,
  Input,
  InputCpfCnpj,
  InputPhone,
  InputSelect,
  Loading
} from 'components'

import { Pagination } from 'components/Pagination'
import { UserTable } from './UserTable'

import * as S from './styled'

const INITIAL_STATE_FILTER = {
  companiesGroups: '',
  CPF: '',
  department: '',
  email: '',
  limit: 10,
  name: '',
  page: 0,
  phone: '',
  roleId: ''
}

const INITIAL_STATE_USERS = {
  items: [],
  pagination: {
    limit: 0,
    page: 0,
    totalItems: 0,
    totalPages: 0
  }
}

const INITIAL_STATE_USER_ROLE = [
  {
    id: '',
    label: '',
    tag: ''
  }
]

const schema = Yup.object().shape({
  company: Yup.string().label('Indústrias'),
  companiesGroups: Yup.string(),
  CPF: Yup.lazy(data => {
    if (data) {
      return Yup.string()
        .test('cpf', 'CPF inválido', value => {
          if (value) return validateCPF(value)
          return false
        })
        .label('CPF')
    }
    return Yup.string()
  }),
  department: Yup.string().label('Departamento'),
  email: Yup.string().email().label('Email'),
  name: Yup.string().label('Nome'),
  phone: Yup.lazy(data => {
    if (!data) return Yup.string()
    return phoneValidator.label('Celular')
  }),
  retail: Yup.string().label('Clientes'),
  roleId: Yup.string()
})

export function UserList() {
  const { t } = useTranslation()

  const userService = new UserService()
  const userRoleService = new UserRoleService()
  const companyGroupService = new CompanyGroupService()

  const [loading, setLoading] = useState(false)
  const [attUserList, setAttUserList] = useState(false)
  const [errors, setErrors] = useState({ ...INITIAL_STATE_FILTER })
  const [filter, setFilter] = useState({ ...INITIAL_STATE_FILTER })
  const [users, setUsers] = useState({ ...INITIAL_STATE_USERS })
  const [userRole, setUserRole] = useState(INITIAL_STATE_USER_ROLE)
  const [companyGroupList, setCompanyGroupList] = useState(null)

  const fetchAllUsers = async () => {
    setLoading(true)
    try {
      const { data } = await userService.fetchAll(filter)
      setUsers(data as any)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const fetchUserRole = async () => {
    try {
      const { data } = await userRoleService.fetchAll()
      setUserRole(data as any)
    } catch (error) {
      console.log('fetchUserRole-ERROR', error)
    }
  }

  useEffect(() => {
    fetchAllUsers()
    fetchUserRole()
  }, [attUserList])

  const formatedLabelUserRole = userRole.map(item => ({
    ...item,
    label: t(`generalUserPage:filters:userType:${item.tag}`)
  }))

  const fecthAllCompanyGroup = async () => {
    const { data, success } = await companyGroupService.fetchAllCompanyGroup()

    if (success) setCompanyGroupList(data.items)
  }

  useEffect(() => {
    if (!companyGroupList) fecthAllCompanyGroup()
  }, [companyGroupList])

  const onFilter = async () => {
    setLoading(true)
    try {
      const params = await schema.validate(filter, {
        abortEarly: false,
        stripUnknown: true
      })

      const { data } = await userService.fetchAll(params)
      if (data.code === 'NOT_FOUND') return setUsers(INITIAL_STATE_USERS)
      setUsers(data as any)
    } catch (ex) {
      const pairs = ex.inner.map(({ path, message }) => [
        path,
        message.replace(`${path} `, '')
      ])
      setErrors(Object.fromEntries(pairs) as typeof INITIAL_STATE_FILTER)
    } finally {
      setLoading(false)
    }
  }

  const onFilterPaginateUser = async ({ limit, page }) => {
    const filters = onFilterPaginate(users.pagination, limit, page)
    const { data } = await userService.fetchAll(filters)
    setUsers(data as any)
  }

  const clearFilter = useCallback(() => {
    setFilter(INITIAL_STATE_FILTER)
    setErrors({ ...INITIAL_STATE_FILTER })
    fetchAllUsers()
  }, [])

  function handleInput(value: string, name: string) {
    setErrors({ ...errors, [name]: '' })
    setFilter({ ...filter, [name]: value })
  }

  function handleSelect(field: string, value: any) {
    setErrors({ ...errors, [field]: '' })
    setFilter({ ...filter, [field]: value })
  }

  function Permission() {
    const {
      profile: { permissions }
    } = UserStorageService.getUserData()

    if (permissions.users) {
      return (
        <S.ButtonAdd>
          <Link to="/usuario/novo">
            <AddButton />
          </Link>
        </S.ButtonAdd>
      )
    } else {
      return null
    }
  }

  return (
    <S.Wrapper container>
      <Permission />
      <S.FullGrid item xs={12}>
        <S.GridHeader>
          <S.GridTitle item container xs={12}>
            <S.BoxTitle>{t('generalUserPage:filters:filterUsers')}</S.BoxTitle>
            <S.BoxLine />
          </S.GridTitle>
        </S.GridHeader>
        <S.GridFilter container>
          <S.GridInput item xs={12} sm={6} md={3}>
            <Input
              error={Boolean(errors.name)}
              helperText={errors.name}
              fullWidth
              label={t('generalUserPage:filters:name')}
              value={filter.name}
              onKeyPress={submitOnEnter(onFilter)}
              onInput={value => handleInput(value, 'name')}
            />
          </S.GridInput>
          <S.GridInput item xs={12} sm={6} md={3}>
            <InputPhone
              error={Boolean(errors.phone)}
              helperText={errors.phone}
              fullWidth
              label={t('generalUserPage:filters:phoneNumber')}
              value={filter.phone}
              onInput={value => handleInput(value, 'phone')}
            />
          </S.GridInput>
          <S.GridInput item xs={12} sm={6} md={3}>
            <Input
              error={Boolean(errors.email)}
              helperText={errors.email}
              fullWidth
              label={t('generalUserPage:filters:email')}
              value={filter.email}
              onKeyPress={submitOnEnter(onFilter)}
              onInput={value => handleInput(value, 'email')}
            />
          </S.GridInput>
          <S.GridInput item xs={12} sm={6} md={3}>
            <InputCpfCnpj
              error={Boolean(errors.CPF)}
              helperText={errors.CPF}
              fullWidth
              label={t('generalUserPage:filters:documentNumber')}
              type="cpf"
              value={filter.CPF}
              onKeyPress={submitOnEnter(onFilter)}
              onInput={value => handleInput(value, 'CPF')}
            />
          </S.GridInput>
        </S.GridFilter>
        <S.GridFilter container>
          <S.GridInput item xs={12} sm={6} md={4} lg={3}>
            <Input
              error={Boolean(errors.department)}
              helperText={errors.department}
              fullWidth
              label={t('generalUserPage:filters:departament')}
              value={filter.department}
              onKeyPress={submitOnEnter(onFilter)}
              onInput={value => handleInput(value, 'department')}
            />
          </S.GridInput>
          <S.GridInput item xs={12} sm={6} md={4} lg={3}>
            {userRole.length ? (
              <InputSelect
                error={Boolean(errors.roleId)}
                fullWidth
                helperText={errors.roleId}
                label={t('generalUserPage:filters:userType:userType')}
                onSelected={({ id }) => handleSelect('roleId', id)}
                optionLabel="label"
                options={formatedLabelUserRole}
                optionValue="id"
                value={filter.roleId}
              />
            ) : null}
          </S.GridInput>
          <S.GridInput item xs={12} sm={6} md={4} lg={3}>
            <InputSelect
              disabled={!companyGroupList}
              error={Boolean(errors.companiesGroups)}
              fullWidth
              helperText={errors.companiesGroups}
              label={t('filters:IndustryGroup')}
              onSelected={({ id }) => handleInput(id, 'companiesGroups')}
              optionLabel="name"
              options={companyGroupList || []}
              optionValue="id"
              value={filter.companiesGroups}
            />
          </S.GridInput>
          <S.GridButtons item xs={12} sm={12} md={12} lg={12}>
            <Button variant="gray" onClick={clearFilter} size="medium">
              <p>{t('common:Clear')}</p>
            </Button>
            <Button variant="blue" onClick={onFilter} size="medium">
              <p>{t('common:Search')}</p>
            </Button>
          </S.GridButtons>
        </S.GridFilter>
        <S.GridTable>
          {loading ? (
            <Loading height="42rem" />
          ) : (
            <>
              <UserTable isDeleted={setAttUserList} rows={users.items} />
              {users.pagination?.totalItems > 0 && (
                <Pagination
                  limit={users.pagination.limit}
                  page={users.pagination.page}
                  totalItems={users.pagination.totalItems}
                  setFilter={onFilterPaginateUser}
                />
              )}
            </>
          )}
        </S.GridTable>
      </S.FullGrid>
    </S.Wrapper>
  )
}
