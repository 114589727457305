import { HTTPB2B } from 'providers'

export class FabricsService extends HTTPB2B {
  private endpoint = '/fabrics'

  public async getOrderData(filters) {
    return this.get({
      endpoint: `${this.endpoint}`,
      params: filters
    })
  }

  public async getFabrics(filters) {
    return this.get({
      endpoint: `${this.endpoint}/all-fabrics`,
      params: filters
    })
  }

  public async getFabricDetails(filters) {
    return this.get({
      endpoint: `${this.endpoint}/details`,
      params: filters
    })
  }
}
