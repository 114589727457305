import { HTTP } from './http.provider'
// import { API as config, app as App } from 'config'

export class HTTPB2B extends HTTP {
  constructor() {
    // super(config, App)
    console.log()
    super()
  }
}
