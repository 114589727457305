import { Grid } from '@material-ui/core'
import styled from 'styled-components'

export const ButtonAdd = styled.div`
  position: absolute;
  right: 2.4rem;
  top: 14.6rem;
`

export const Wrapper = styled(Grid)`
  margin-bottom: 7.4rem;
`
export const GridContainer = styled(Grid)``

export const PageSectionTitle = styled(Grid)`
  margin: 0 0 -1.2rem 1.2rem;
  background-color: #315ca6;
  color: white;
  font-size: 1.4rem;
  padding: 0.6rem 3rem;
  border-radius: 1rem 1rem 0 0;
  font-weight: bold;
`
