import { MouseEventHandler, ReactNode } from 'react'
import { debounce } from 'lodash'

import * as S from './styled'

export type ButtonProps = {
  children: ReactNode
  className?: string
  disabled?: boolean
  minWidth?: number
  size?: 'large' | 'medium' | 'small' | 'xsmall'
  variant?: 'blue' | 'gray' | 'green'
  onClick: MouseEventHandler
  counter?: number | string
  hasCounter?: boolean
}

export function Button({
  children,
  className = '',
  counter = 0,
  disabled = false,
  hasCounter = false,
  minWidth = 6,
  size = 'medium',
  variant = 'blue',
  onClick
}: ButtonProps) {
  return (
    <S.Button
      className={`${variant} ${size} ${className}`}
      minWidth={minWidth}
      onClick={debounce(onClick, 600)}
      counter={counter}
      hasCounter={hasCounter}
      disabled={disabled}
    >
      {children}
    </S.Button>
  )
}
