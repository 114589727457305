import styled, { css } from 'styled-components'

type ContainerProps = {
  loading: boolean
}

export const Container = styled.div<ContainerProps>`
  background-color: white;
  padding: 3rem;

  box-shadow: 0px 3px 6px #00000029;
  border-radius: 1.2rem;

  ${({ loading }) =>
    loading &&
    css`
      opacity: 0;
    `}
`

export const Box = styled.div`
  display: flex;
  flex-direction: column;
`

export const BoxTitle = styled.p`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 600;
  font-size: 1.6rem;
`

export const BoxLine = styled.p`
  padding-top: 1rem;
  width: 5.3rem;
  border-bottom: 4px solid ${({ theme }) => theme.colors.primary};
`
