import { HTTP } from 'providers'
// import { IndustryGroupType } from 'types/IndustryGroup.types'

export class IndustryGroupRoleService extends HTTP {
  private endpoint = '/company-group-role'

  public fetchAll(params = {}) {
    return this.get({ endpoint: this.endpoint, params })
  }
}
