import { Grid } from '@material-ui/core'
import styled from 'styled-components'

export const GridContainer = styled(Grid)`
  background-color: ${({ theme }) => theme.colors.background};
  display: flex;
  flex-direction: row;
  font-size: 1.2vw;
  height: 100vh;
  min-height: 100vh;
  width: 100%;
`

export const SideBar = styled(Grid)`
  display: flex;
  height: 100vh;
`

export const Row = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
`

export const SideBarColumn = styled(Grid)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.secondary};
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
`

export const SideBarTitle = styled.h1`
  color: ${({ theme }) => theme.colors.white};
  font-size: 2em;
  font-weight: 600;
  margin-bottom: 4rem;
  z-index: 1;
`

export const SideBarDescription = styled.p`
  color: ${({ theme }) => theme.colors.white};
  font-size: 1.6rem;
  line-height: 2rem;
  margin-bottom: 6rem;
  opacity: 1;
  padding: 0 3em;
  text-align: center;
  z-index: 1;
`

export const GridRight = styled(Grid)`
  background-color: ${({ theme }) => theme.colors.white2};
  display: flex;
  flex: 1;
  flex-direction: column;
`

export const Footer = styled.div`
  padding: 0 2.4rem;
`

export const Divider = styled.hr`
  border: 1px solid ${({ theme }) => theme.colors.lightGray};
`

export const Copyright = styled.p`
  color: ${({ theme }) => theme.colors.darkGray};
  display: flex;
  font-size: 1.2rem;
  font-weight: 600;
  justify-content: flex-end;
  margin-bottom: 1.4rem;
  margin-right: 1rem;
  opacity: 1;
  text-align: center;
`

export const Langdropdown = styled.div`
  align-items: center;
  text-align: center;
  display: flex;
  justify-content: flex-end;
  margin-top: 3rem;
  margin-right: 5rem;
  position: relative;
`
