import { get } from 'lodash'

import { types } from './Breadcrumbs.actions'

import { BreadcrumbsStore } from './Breadcrumbs.types'

const INITIAL_STATE = { suffix: [] }

function getExample(state: BreadcrumbsStore) {
  return function _handle(payload: BreadcrumbsStore) {
    return { ...state, ...payload }
  }
}

export default function _reducer(state = INITIAL_STATE, { type, payload }) {
  const _handlers = {
    [types.setSuffix]: getExample(state),
    _default: () => state
  }

  return get(_handlers, type, _handlers._default)(payload)
}
