import { omit, pick } from 'lodash'
import { ChangeEvent, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import { Button } from 'components/Button'
import { UserService, UserStorageService } from 'services'
import { toasts } from 'utils/toasts'

import { BasicInformation } from './BasicInformation/BasicInformation'
import { ProfileImage } from './ProfileImage/ProfileImage'

import { userDropdownActions } from 'store/UserProfileDropdown'

import * as S from './styled'

export const INITIAL_STATE = {
  CPF: '',
  department: '',
  email: '',
  emailRecovery: '',
  firstName: '',
  lang: '',
  lastName: '',
  groups: [],
  roleId: '',
  role: '',
  createdAt: '',
  lastSignIn: ''
}

export const EDITABLE_INITIAL_STATE = {
  avatar: null,
  lang: 'Português (brasileiro)'
}

export function ProfileForm() {
  const [updating, setUpdating] = useState(false)
  const [form, setForm] = useState({ ...INITIAL_STATE })
  const [toUploadImage, setImageToUpload] = useState<File>(null)
  const [editableForm, setEditableForm] = useState({
    ...EDITABLE_INITIAL_STATE
  })

  const dispatch = useDispatch()
  const { goBack } = useHistory()

  function onLoadImage() {
    return function handleSelectImage(e: ChangeEvent<HTMLInputElement>) {
      if (!e.target.files) return
      const [img] = Array.from(e.target.files)
      setImageToUpload(img)
      setEditableForm(state => ({
        ...state,
        avatar: { src: URL.createObjectURL(img), filename: img.name }
      }))
    }
  }

  function onRemoveImage() {
    setImageToUpload(null)
    setEditableForm(state => ({ ...state, avatar: null }))
  }

  function handleInputChange(value: string, name: string) {
    setForm({ ...form, [name]: value })
  }

  async function submit() {
    setUpdating(true)
    const $user = new UserService()
    const loggedInUser = UserStorageService.getUserData()

    const promises = [
      $user.updateProfile(loggedInUser.id, omit(form, 'avatar'))
    ]
    if (toUploadImage) {
      const uploadPromise = $user.uploadAvatar(loggedInUser.id, toUploadImage)
      promises.push(uploadPromise)
    }

    const [_, avatarUpdated] = await Promise.all(promises)

    const rawUserData = UserStorageService.getRawUserData()
    rawUserData.profile.name = `${form.firstName} ${form.lastName}`
    const userProfileDropdown = {
      userName: `${form.firstName} ${form.lastName}`
    }

    if (editableForm.avatar.src && avatarUpdated?.success) {
      rawUserData.avatar = avatarUpdated.data
      Object.assign(userProfileDropdown, {
        avatar: avatarUpdated.data
      })
    }
    UserStorageService.signIn(JSON.stringify({ ...rawUserData }))

    dispatch(userDropdownActions.setUserProfileDropdown(userProfileDropdown))

    setForm(prev => ({ ...prev, ...editableForm }))
    toast.success('Perfil atualizado com sucesso!')
    setUpdating(false)
  }

  useEffect(() => {
    const fetch = async () => {
      if (!Object.keys(form).includes('id')) {
        const loggedInUser = UserStorageService.getUserData()
        const { data, success } = await new UserService().fetchOne(
          loggedInUser.id
        )
        if (!success) toasts.generalFail()
        else {
          setEditableForm(pick(data, 'avatar', 'lang'))
          setForm(prev => ({
            ...prev,
            ...data
          }))
        }
      }
    }

    fetch()
  })

  return (
    <S.Wrapper container>
      <S.FullGrid item xs={12}>
        <S.AvatarGrid item xs={12} md={4}>
          <S.AvatarFlex>
            <S.GridAvatarPhoto>
              <ProfileImage
                payload={editableForm.avatar || {}}
                onLoadImage={onLoadImage()}
                onRemoveImage={() => onRemoveImage()}
              />
            </S.GridAvatarPhoto>
            <S.GridAvatarInfo>
              <span className="Name">
                {form.firstName} {form.lastName}
              </span>
              <span className="Department">{form.department}</span>
            </S.GridAvatarInfo>
            <S.GridAvatarInfo>
              <span className="Separator"></span>
              <S.GridAvatarGroupDate>
                <S.GridAvatarDate>
                  <div className="createdAt">
                    <span>Data cadastro</span>
                    <span className="Date">{form.createdAt}</span>
                  </div>
                </S.GridAvatarDate>
                <S.GridAvatarDate>
                  <div className="lastAccess">
                    <span>Data último acesso</span>
                    <span className="Date">{form.lastSignIn}</span>
                  </div>
                </S.GridAvatarDate>
              </S.GridAvatarGroupDate>
            </S.GridAvatarInfo>
          </S.AvatarFlex>
        </S.AvatarGrid>
        <S.InfoGrid item xs={12} md={8}>
          <S.InfoFlex>
            <S.GridHeader>
              <S.GridTitle item container xs={12}>
                <S.BoxTitle>Informações básicas</S.BoxTitle>
              </S.GridTitle>
            </S.GridHeader>
            <BasicInformation
              form={form}
              editableForm={editableForm}
              onChange={handleInputChange}
            />
          </S.InfoFlex>
        </S.InfoGrid>
      </S.FullGrid>
      <S.GridButtons item xs={12}>
        <Button
          variant="gray"
          onClick={() => !updating && goBack()}
          size="medium"
          disabled={updating}
        >
          <p>Cancelar</p>
        </Button>
        <Button
          variant="green"
          onClick={() => !updating && submit()}
          size="medium"
          disabled={updating}
        >
          <p>Salvar</p>
        </Button>
      </S.GridButtons>
    </S.Wrapper>
  )
}
