import * as S from '../../styled'

import { Totalizer } from 'components'
import { useTranslation } from 'react-i18next'

interface TotalizersProps {
  totalizersData: any[]
}

export function Totalizers({ totalizersData }: TotalizersProps) {
  const { t, i18n } = useTranslation()
  const language = i18n.language === '中文 - ZH - CN' ? 'zh-CN' : i18n.language

  return (
    <S.TotalizersContainer>
      <Totalizer
        primaryText={t('traceabilityRetailPage:totalizersRetail:orders')}
        primaryValue={
          totalizersData[0]?.totalOrders.toLocaleString(language) || 0
        }
        secondaryText={t('traceabilityRetailPage:totalizersRetail:volumes')}
        secondaryValue={
          totalizersData[0]?.totalVolumes?.toLocaleString(language) || 0
        }
      />
      <Totalizer
        primaryText={t(
          'traceabilityRetailPage:totalizersRetail:ordersDelivered'
        )}
        primaryValue={
          totalizersData[0]?.ordersDeliveried.toLocaleString(language) || 0
        }
        secondaryText={t(
          'traceabilityRetailPage:totalizersRetail:volumesDelivered'
        )}
        secondaryValue={
          totalizersData[0]?.volumesDeliveried?.toLocaleString(language) || 0
        }
      />
      <Totalizer
        primaryText={t('traceabilityRetailPage:totalizersRetail:openOrders')}
        primaryValue={
          totalizersData[0]?.ordersOpen.toLocaleString(language) || 0
        }
        secondaryText={t('traceabilityRetailPage:totalizersRetail:openVolumes')}
        secondaryValue={
          totalizersData[0]?.volumesOpen?.toLocaleString(language) || 0
        }
      />
      <Totalizer
        primaryText={t('traceabilityRetailPage:totalizersRetail:lateOrders')}
        primaryValue={
          totalizersData[0]?.orderDelayed.toLocaleString(language) || 0
        }
        secondaryText={t('traceabilityRetailPage:totalizersRetail:lateVolumes')}
        secondaryValue={
          totalizersData[0]?.volumeDelayed?.toLocaleString(language) || 0
        }
      />
      {/* OCULTADO POR HORA ----- NÃO REMOVER */}
      {/* <Totalizer
        primaryText={t('traceabilityRetailPage:totalizersRetail:ordersAmABR')}
        primaryValue={totalizersData[0].ordersAbrCertified || 0}
        secondaryText={t(
          'traceabilityRetailPage:totalizersRetail:volumesAmABR'
        )}
        secondaryValue={totalizersData[0].volumesAbrCertified || 0}
      /> */}
    </S.TotalizersContainer>
  )
}
