import { Button } from 'components/Button'
import { Icon } from 'components/Icon'
import styled from 'styled-components'

export const Container = styled.div`
  height: 100%;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 1.2rem;
  background-color: ${({ theme }) => theme.colors.white};
  margin-bottom: 2.6rem;
  width: 100%;
  padding: 2.4rem;

  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 80%;
`

export const AccordionDetailsIcon = styled(Icon)`
  fill: ${({ theme }) => theme.colors.primary};
`

export const ButtonComponent = styled(Button)`
  width: fit-content;
`
