import { Accordion, IconButton } from '@material-ui/core'
import styled, { keyframes, css } from 'styled-components'

type ChildrenContainerBlanckProps = {
  height: number
}

type ScrollProps = {
  expanded: boolean
}

export const Wrapper = styled.div.attrs(() => {})`
  background-color: ${({ theme }) => theme.colors.white};
  padding-bottom: 0.05rem;
  display: flow-root;
  width: 100%;

  table {
    width: ${({ columnScroll }) => (columnScroll ? 'auto' : '100%')};
  }

  th {
    padding: 0.2rem 1.1rem;
    cursor: pointer;
  }

  #table-fixed {
    td:last-child,
    th:last-child {
      border-right: ${({ actionTable, columnScroll }) =>
        columnScroll ? 1 : actionTable ? 0 : 1};
    }
  }

  #table-scrolled {
    td,
    tr,
    td:first-child,
    th:first-child {
      border-left: ${({ columnFixed }) => (columnFixed ? 0 : 1)};
    }

    td:last-child,
    th:last-child {
      border-right: ${({ actionTable, columnFixed }) =>
        columnFixed && !actionTable ? 1 : !columnFixed && !actionTable ? 1 : 0};
    }
  }

  #table-actions {
    width: auto;
  }
`

export const Container = styled.div<{ isLoading?: boolean }>`
  display: flex;
  width: 100%;
  position: relative;

  ${props =>
    props.isLoading &&
    css`
      & #values {
        background: #fff;
        animation: 1.5s ${fade} alternate infinite;
      }

      #table-actions {
        cursor: none;
        pointer-events: none;
      }
    `}
`

export const ScrollWrapper = styled.div<ScrollProps>`
  display: grid;
  width: 20000rem;
  overflow-x: scroll;

  ${({ expanded }) =>
    expanded &&
    css`
      overflow-x: hidden;
    `}

  ::-webkit-scrollbar {
    height: 1rem;
    width: 1.2rem;
  }
  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.background};
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.gray.luminance(0.5)};
    border-radius: 2rem;
    &:hover {
      background-color: ${({ theme }) => theme.colors.gray};
    }
  }
`

export const Table = styled.table`
  border-collapse: collapse;
  font-family: Arial, Helvetica, sans-serif;
  table-layout: auto;
  margin-bottom: auto;

  width: 100% !important;
`

export const Header = styled.th.attrs(() => {})`
  border: 1px solid #ddd;
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: 1.4rem;
  font-weight: 600;
  padding: 1.1rem 1.6rem;
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  line-height: 1.6rem;

  &.center {
    text-align: center;
  }

  &.right {
    text-align: right;
  }

  &.left {
    text-align: left;
  }

  button {
    transition: 0.3s all ease-in-out;
    opacity: ${({ buttonOpacity }) => (buttonOpacity ? 1 : 0)};
  }

  &:hover {
    button {
      opacity: 1;
    }
  }

  &#details {
    width: 10%;
  }
`

export const HeaderAction = styled.th`
  border: 1px solid #ddd;
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: 1.4rem;
  font-weight: 600;
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  line-height: 1.6rem;
`

export const DetailsContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  height: 18px;
  justify-content: center;
`

export const Line = styled.tr`
  height: 4.1rem;
  :nth-child(even) {
    background-color: ${({ theme }) => theme.colors.rowTable};
  }
`

export const Column = styled.td<{ position?: string }>`
  border: 1px solid #ddd;
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: 1.4rem;
  padding: 0 1.6rem;
  white-space: nowrap;
  text-align: ${props => props.position || 'left'};
  vertical-align: middle;
  line-height: 1.6rem;

  &.center {
    text-align: center;
  }

  &.right {
    text-align: right;
  }

  &#actions-column {
    div {
      margin: auto;
    }
    button {
      display: block;
      margin: auto;
    }
  }
`

export const CheckboxColumnContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const ButtonIcon = styled(IconButton)`
  background-color: #ffffff;
  margin: 0 0.8rem;
  &:hover {
    background-color: #efefef;
  }
`

const fade = keyframes`
  from {
    background: #fff;
    opacity: 0.5;
  }

  to {
    background: #fff;
    opacity: 0.1;
  }
`

export const TableLoadingFistTime = styled.div`
  width: 100%;
  height: 120px;
  font-size: 2rem;
  font-weight: 900;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-top: -57px;
  padding-bottom: 25px;
  animation: 1.5s ${fade} alternate infinite;
`

export const TableLoading = styled.div`
  width: 100%;
  height: 100%;
  font-size: 2rem;
  font-weight: 900;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -55px;
  padding-bottom: 25px;
`

export const BoxContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const AccordionColumnContainer = styled(Accordion)`
  display: flex;
  justify-content: center;
  width: 100%;

  background-color: transparent;

  box-shadow: none;
`

export const Box = styled.div``

export const BoxTitle = styled.p`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 600;
  font-size: 1.6rem;
`

export const BoxLine = styled.p`
  padding-top: 1rem;
  width: 6.3rem;
  border-bottom: 4px solid ${({ theme }) => theme.colors.primary};
  margin-bottom: 2rem;
`

export const ChildrenContainerBlanck = styled.div<ChildrenContainerBlanckProps>`
  height: ${({ height }) => `${height}px`};
`

export const ContainerAbsolute = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
`

export const ColumnAbsolute = styled.td`
  margin: 2rem 0;
  position: absolute;
  width: 100%;
  padding: 2rem 5rem;
`
