import { useState } from 'react'

import { TableNotFoundHint } from 'components/TableNotFoundHint'

import { IndustryGroupType } from '../../../../types/IndustryGroup.types'
import { ModalDeleteGroupIndustry } from '../../ModalDeleteGroupIndustry/ModalDeleteGroupIndustry'
import { IndustryGroupDropdown } from './IndustryGroupDropdown'

import * as S from './styled'
import { useTranslation } from 'react-i18next'
import { IndustryGroupService } from 'services'
import { toasts } from 'utils/toasts'

export type IndustryGroupTableProps = {
  rows: IndustryGroupType[]
  isDeleted: (_) => void
}

export function IndustryGroupTable({
  rows,
  isDeleted
}: IndustryGroupTableProps) {
  const { t } = useTranslation()
  const industryGroupService = new IndustryGroupService()

  const [_deleteIndustryGroup, _setDeleteIndustryGroup] = useState(
    {} as IndustryGroupType
  )

  function onExit() {
    _setDeleteIndustryGroup({} as IndustryGroupType)
  }

  async function onRemoveIndustryGroup() {
    try {
      const { success } = await industryGroupService.deleteIndustryGroup({
        id: _deleteIndustryGroup.id
      })

      if (success) {
        isDeleted(true)
        toasts.deleted()
        onExit()
      } else {
        toasts.generalFail()
      }
    } catch (error) {
      console.log('DeleteIndustry-error', error)
    } finally {
      isDeleted(false)
    }
  }

  return (
    <S.Wrapper data-testid="IndustryGroupTable">
      {Object.keys(_deleteIndustryGroup).length ? (
        <ModalDeleteGroupIndustry
          groupName={_deleteIndustryGroup.name}
          companies={_deleteIndustryGroup.total}
          onExit={onExit}
          onRemove={onRemoveIndustryGroup}
        />
      ) : null}
      <S.Table>
        <tbody>
          <S.Line id="header">
            <S.Header>{t('generalIndustryGroup:table:groupName')}</S.Header>
            <S.Header className="right">
              {t('generalIndustryGroup:table:industries')}
            </S.Header>
            <S.Header>{t('generalIndustryGroup:table:adminUser')}</S.Header>
            {/* Comentado por decisão de produto, tarefa: https://ecotrace-solutions.atlassian.net/browse/PDTCT00522-535
            <S.Header>
              {t('generalIndustryGroup:table:visualizationType')}
            </S.Header> */}
            <S.Header>{t('generalIndustryGroup:table:actions')}</S.Header>
          </S.Line>
          {rows.map(each => (
            <S.Line id="values" key={each.id}>
              <S.Column>{each.name}</S.Column>
              <S.Column className="right">{each.total}</S.Column>
              <S.Column>{each.adminEmail}</S.Column>
              {/* Tarefa: https://ecotrace-solutions.atlassian.net/browse/PDTCT00522-535
              <S.Column>
                {each.visible
                  ? t('generalIndustryGroup:table:rows:released')
                  : t('generalIndustryGroup:table:rows:notReleased')}
              </S.Column> */}
              <S.Column id="actions-column">
                <IndustryGroupDropdown
                  id={each.id}
                  industryGroupName={each.name}
                  setDeleteIndustryGroup={() => _setDeleteIndustryGroup(each)}
                />
              </S.Column>
            </S.Line>
          ))}
        </tbody>
      </S.Table>
      {!rows.length && <TableNotFoundHint to="/grupo-industria/novo" />}
    </S.Wrapper>
  )
}
