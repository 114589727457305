import { CardsHome } from 'components'
import { Base } from 'layouts'

export function Home() {
  return (
    <Base>
      <CardsHome />
    </Base>
  )
}
