import { get } from 'lodash'

import { DrawerOpen } from '../../types/Sidebar.types'
import { types } from './Sidebar.actions'

const INITIAL_STATE = {
  drawerOpen: false,
  persistent: false
}

function setDrawer(state: DrawerOpen) {
  return function _handle(payload: Record<string, any>) {
    return { ...state, ...payload }
  }
}

export default function _reducer(state = INITIAL_STATE, { type, payload }) {
  const _handlers = {
    [types.SET_DRAWER]: setDrawer(state),
    [types.SET_PERSISTENT]: setDrawer(state),
    _default: () => state
  }

  return get(_handlers, type, _handlers._default)(payload)
}
