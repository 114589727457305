import { useEffect, useState } from 'react'
import {
  MapContainer,
  TileLayer,
  GeoJSON,
  Marker,
  ZoomControl
} from 'react-leaflet'
import L, { MapOptions } from 'leaflet'
import { v4 } from 'uuid'

import icon2x from '../../assets/map/images/marker-icon-2x.png'
import icon from '../../assets/map/images/marker-icon.png'

import * as S from './styled'
import MarkerClusterGroup from 'react-leaflet-cluster'
import { biomesGeoJson } from './biomeGeoJson'
import { Legend } from './MapLegend'
import './map.css'

const DefaultIcon = L.icon({
  iconRetinaUrl: icon2x,
  iconUrl: icon,
  iconAnchor: [10, 41],
  popupAnchor: [2, -40]
})

L.Marker.prototype.options.icon = DefaultIcon

export type MapProps = {
  data?: any
  biomeSelected?: Record<string, any>
} & MapOptions

const DEFAULT_CENTER = { lat: -17.8, lng: -55 }
const DEFAULT_ZOOM = 5

export function Map({ data, ...props }: MapProps) {
  const [map, setMap] = useState(null)
  const [center, setCenter] = useState(props.center || DEFAULT_CENTER)
  const [zoom, setZoom] = useState(props.zoom || DEFAULT_ZOOM)

  useEffect(() => {
    setZoom(props.zoom)
  }, [props.zoom])

  useEffect(() => {
    setCenter(props.center)
  }, [props.center])

  const onEachBiome = (biome, layer) => {
    layer.options.fillColor = biome.color
    layer.options.weight = 0.2
    layer.options.color = 'grey'
  }

  const customMarkerIcon = item => {
    if (item.blockStatus) {
      return new L.Icon({
        iconUrl: 'images/marker_map_red.svg',
        iconSize: [18, 30],
        iconAnchor: [18, 30],
        className: 'marker-icon'
      })
    }
    return new L.Icon({
      iconUrl: 'images/marker_map_green.svg',
      iconSize: [18, 30],
      iconAnchor: [18, 30],
      className: 'marker-icon'
    })
  }

  return (
    <S.Wrapper data-testid="map">
      <S.LeafletContainerMap>
        <MapContainer
          style={{ width: '100%', height: '100%' }}
          zoomControl={true}
          center={center}
          zoom={zoom}
          preferCanvas
          scrollWheelZoom={false}
          whenCreated={setMap}
          {...props}
        >
          <TileLayer
            maxZoom={18}
            url={`https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/256/{z}/{x}/{y}@2x?access_token=${process.env.REACT_APP_TOKEN_MAPBOX}`}
          />
          <MarkerClusterGroup chunkedLoading>
            {data &&
              data.map((item, key) => (
                <Marker
                  key={key}
                  position={[item.latitude, item.longitude]}
                  icon={customMarkerIcon(item)}
                />
              ))}
          </MarkerClusterGroup>
          <Legend map={map} />
          <ZoomControl position="bottomright" />
          <GeoJSON
            key={v4()}
            data={
              props.biomeSelected
                ? biomesGeoJson.features.find(
                    item =>
                      item.properties.cod_bioma === props.biomeSelected.tag
                  )
                : (biomesGeoJson as any)
            }
            onEachFeature={onEachBiome}
          />
        </MapContainer>
      </S.LeafletContainerMap>
    </S.Wrapper>
  )
}
