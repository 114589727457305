import { Grid } from '@material-ui/core'
import styled from 'styled-components'
import { Input } from 'components/Input'

export const GridFields = styled(Grid)`
  display: flex;
  margin: -16px;
  width: calc(100% + 24px);
  flex-direction: row;
`

export const GridFieldCep = styled(Grid)`
  display: flex;
  margin: 1.6rem -16px;
  width: calc(100% + 24px);
  flex-direction: row;
`

export const GridInput = styled(Grid)`
  padding: 0 !important;
`

export const GridButtonsMap = styled(Grid)`
  align-self: baseline;
  text-align: right;
  > button {
    width: 22rem;
  }
`

export const GridFilter = styled(Grid)`
  display: flex;
  margin: 0 -0.8rem;
  width: calc(100% + 16px);
  flex-direction: row;
`

export const InputCoordinates = styled(Input)`
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

export const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.colors.error};
  font-size: 1.4rem;
  font-weight: 600;
  padding-right: ${({ theme }) => theme.spacings.xxsmall};
`
