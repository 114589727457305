import { MapContainer, TileLayer, Marker, ZoomControl } from 'react-leaflet'
import L from 'leaflet'

import { Modal } from 'components/Modal'

import icon2x from '../../assets/map/images/marker-icon-2x.png'
import icon from '../../assets/map/images/marker-icon.png'
import iconShadow from '../../assets/map/images/marker-shadow.png'

import * as S from './styled'

const DefaultIcon = L.icon({
  iconRetinaUrl: icon2x,
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconAnchor: [10, 41],
  popupAnchor: [2, -40]
})

L.Marker.prototype.options.icon = DefaultIcon

export type ModalMapMarkerProps = {
  latLng: any
  onExit?: () => void
  icon?: string
}

export function ModalMapMarker({ latLng, onExit, icon }: ModalMapMarkerProps) {
  const defaultLocation = {
    lat: 0,
    lng: 0
  }
  const content = (
    <S.Wrapper>
      <S.WrapperMap>
        <MapContainer
          style={{ width: '100%', height: '100%' }}
          scrollWheelZoom={true}
          center={latLng.lat && latLng.lng ? latLng : defaultLocation}
          zoom={latLng.lat && latLng.lng ? 15 : 1}
          zoomControl={false}
          preferCanvas
        >
          <ZoomControl position="bottomright" />
          <TileLayer
            maxZoom={18}
            url={`https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/256/{z}/{x}/{y}@2x?access_token=${process.env.REACT_APP_TOKEN_MAPBOX}`}
          />

          {latLng.lat && latLng.lng && <Marker position={latLng}></Marker>}
        </MapContainer>
      </S.WrapperMap>
    </S.Wrapper>
  )

  return (
    <Modal
      content={content}
      title="Ver no mapa"
      titleIcon={icon}
      isActionsEnabled={false}
      width={60}
      onExit={onExit}
    />
  )
}
