import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import * as Yup from 'yup'

import { IconButton, InputAdornment } from '@material-ui/core'

import { YupValidator } from 'services/yupValidator.service'
import { AuthService, UserService, UserStorageService } from 'services'

import { Icon, Signin } from 'components'

import * as S from './styled'

const LoginSchema = Yup.object().shape({
  email: Yup.string().required().email().label('E-mail'),
  password: Yup.string().required().min(6).max(32).label('Senha')
})
interface UserResponse {
  success: boolean
  data: {
    token?: string
    avatar?: string
    message?: string
    profile?: string
  }
}

export const INITIAL_STATE = {
  email: '',
  password: ''
}

export function Login() {
  const { t } = useTranslation()
  const [validationErrorRequest, setValidationErrorRequest] = useState('')
  const [loading, setLoading] = useState(false)
  const [form, setForm] = useState({ ...INITIAL_STATE })
  const [errors, setErrors] = useState({ ...INITIAL_STATE })
  const [passwordVisible, setPasswordVisible] = useState('password')

  const auth = new AuthService()
  const history = useHistory()

  function changePasswordView() {
    setPasswordVisible(oldValue => (oldValue === 'text' ? 'password' : 'text'))
  }

  useEffect(() => {
    UserStorageService.signOut()
  }, [loading])

  async function handleLogin() {
    setLoading(true)
    setErrors({ ...INITIAL_STATE })
    setValidationErrorRequest('')

    const [isValid, validationError] = await new YupValidator(
      LoginSchema
    ).validate(form)

    if (!isValid) {
      setLoading(false)
      return setErrors(validationError as typeof INITIAL_STATE)
    }

    const { success, data }: UserResponse = await auth.login({
      ...form
    })

    if (success) {
      UserStorageService.signIn(JSON.stringify(data))
      const lang = localStorage.getItem('i18nextLng')
      const $user = new UserService()
      const loggedInUser = UserStorageService.getUserData()
      await $user.updateProfile(loggedInUser.id, { lang })
      // const qs = new URLSearchParams(location.search)
      // if (qs.get('goto')) history.push(qs.get('goto'))
      // else history.push('/')
      history.push('/')
    } else {
      setValidationErrorRequest('Dados inválidos! ')
    }
    setLoading(false)
  }

  function shouldInvoke() {
    if (!loading) return handleLogin()
  }

  function handleInput(value: string, name: string) {
    setErrors({ ...errors, [name]: '' })
    setForm({ ...form, [name]: value })
  }

  return (
    <Signin>
      <S.SideBarCorner>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="138.11"
          height="395.5"
          viewBox="0 0 138.11 395.5"
          className="decoration"
        >
          <path
            fill="#3fab36"
            className="decorator_form"
            d="M138.11,212.143c0-56.625-22.787-78.15-36.5-107.314C92.441,85.312,93.41,0,93.41,0H0V395.5H93.41s1.552-61.884,8.206-76.042C115.324,290.293,138.11,268.769,138.11,212.143Z"
          />
        </svg>
        <p className="chevron">›</p>
      </S.SideBarCorner>
      <S.LoginContainer>
        <S.Logo />
        <S.Title>{t('loginPage:loginTitle')}</S.Title>

        <S.FormInput
          error={Boolean(errors.email)}
          helperText={errors.email}
          iconLeft={
            <Icon icon="envelope" height={16} width={16} fill="primary" />
          }
          label={t('Email')}
          onInput={value => handleInput(value, 'email')}
          type="email"
          value={form.email}
        />

        <S.FormInput
          error={Boolean(errors.password)}
          helperText={errors.password}
          iconLeft={
            <Icon icon="input-padlock" height={16} width={16} fill="primary" />
          }
          iconRight={
            <InputAdornment position="start">
              <IconButton onClick={changePasswordView}>
                <Icon
                  icon={
                    passwordVisible === 'text'
                      ? 'input-visible'
                      : 'input-visible-not'
                  }
                  height={16}
                  width={16}
                  fill="primary"
                />
              </IconButton>
            </InputAdornment>
          }
          label={t('Password')}
          onInput={value => handleInput(value, 'password')}
          type={passwordVisible}
          value={form.password}
        />
        {validationErrorRequest && (
          <S.InputError>
            <S.InputErrorIcon
              icon="itens-interface-alert"
              width={12}
              opacity={validationErrorRequest}
            />
            <S.InputErrorText>{validationErrorRequest}</S.InputErrorText>
          </S.InputError>
        )}

        <S.ForgetPasswordContainer>
          <S.ForgetPassword to="/esqueci-minha-senha">
            {t('loginPage:forgotPassword')}
          </S.ForgetPassword>
        </S.ForgetPasswordContainer>

        <S.FormButton size="large" variant="green" onClick={shouldInvoke}>
          {t('loginPage:loginButton')}
        </S.FormButton>

        {/* <S.DontHaveAccess>
          {t('loginPage:notAccess')}&nbsp;
          <S.HowToAccess href="https://suporte-ecotrace.zendesk.com/hc/pt-br/sections/4520404266779-ECO-B2B-Algod%C3%A3o">
            {t('loginPage:knowToAccess')}
          </S.HowToAccess>
        </S.DontHaveAccess> */}
      </S.LoginContainer>
    </Signin>
  )
}
