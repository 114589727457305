import { useTranslation } from 'react-i18next'
import { ITotalizer } from 'pages/OrderManagementDetails/orderManagementDetails.type'
import { cnpjMask } from 'utils/CnpjMask'

type SupplierContentProps = {
  data: ITotalizer
}

export function OwnProductionContent({ data }: SupplierContentProps) {
  const { t, i18n } = useTranslation()
  const language = i18n.language === '中文 - ZH - CN' ? 'zh-CN' : i18n.language

  return (
    <>
      <span>
        <strong>
          {t('ordersManagementPage:accordions:accordionDetails:accordionName')}:
        </strong>
        {data.supplierAndOutsourcedName || ''}
      </span>
      <span>
        <strong>
          {t(
            'ordersManagementPage:accordions:accordionDetails:accordionRequestQuantity'
          )}
          :
        </strong>
        {data.quantityTotal
          ? Number(data.quantityTotal).toLocaleString(language)
          : 0}
      </span>
      <span>
        <strong>
          {t(
            'ordersManagementPage:accordions:accordionDetails:accordionActivityQuantity'
          )}
          :
        </strong>
        {data.activityQuantity
          ? Number(data.activityQuantity).toLocaleString(language)
          : 0}
      </span>
      <span>
        <strong>
          {t('ordersManagementPage:accordions:accordionDetails:accordionCNPJ')}:
        </strong>
        {data.supplierAndOutsourcedCnpj
          ? cnpjMask(data.supplierAndOutsourcedCnpj)
          : null || ''}
      </span>
      <span>
        <strong>
          {t(
            'ordersManagementPage:accordions:accordionDetails:accordionProducedQuantity'
          )}
          :
        </strong>
        {data.quantityProduced
          ? Number(data.quantityProduced).toLocaleString(language)
          : 0}
      </span>
      {/* OCULTADO POR NÃO TER O APP AINDA */}
      {/* <span>
        <strong>
          {t(
            'ordersManagementPage:accordions:accordionDetails:accordionAllocationStatus'
          )}
          :
        </strong>
        {data.allocationStatus || ''}
      </span> */}
    </>
  )
}
