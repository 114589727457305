import { OwnProductionContent } from './OwnProductionContent'
import { SubContractorsContent } from './SubContractorsContent'

import * as S from './styled'

type AccordionDetailsContentProps = {
  tag: string
  data: any
}

export function AccordionDetailsContent({
  tag,
  data
}: AccordionDetailsContentProps) {
  function contentVariation() {
    const AccordionDetails = {
      providers: () => <OwnProductionContent data={data} />,
      subProviders: () => <SubContractorsContent data={data} />,
      default: () => null
    }
    const Component = AccordionDetails[tag]
    return <Component />
  }

  return <S.Container>{contentVariation()}</S.Container>
}
