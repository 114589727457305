import { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'

import { IndustryGroupService } from 'services'

import { toasts } from 'utils/toasts'

import { Icon } from 'components/Icon'
import { ModalDeleteGroupIndustry } from 'components/IndustryGroup/ModalDeleteGroupIndustry/ModalDeleteGroupIndustry'

// CODIGO COMENTADO POR REGRA DE NEGOCIO -> ATIVIDADE JIRA: https://ecotrace-solutions.atlassian.net/browse/PDTCT00522-548

// import { StatusBox } from 'components/StatusBox'
// import {
//   labels,
//   statusBadgeClassnames
// } from 'constants/industryGroup.constants'

import * as S from './styled'

export function IndustryGroupInfo({ industry }) {
  const industryGroupService = new IndustryGroupService()

  const history = useHistory()
  const { goBack } = useHistory()

  const [_deleteIndustryGroup, _setDeleteIndustryGroup] = useState(false)
  const [loading, setLoading] = useState(false)
  const {
    adminEmail,
    adminName,
    companies,
    id,
    name,
    // visible,
    companyGroupProfiles
  } = industry

  useEffect(() => {
    history.replace({
      state: { nameBreadcrumbs: `Grupo ${name}` }
    })
  }, [history, name, id])

  function onExit() {
    _setDeleteIndustryGroup(false)
  }

  async function onRemoveIndustryGroup() {
    setLoading(true)
    try {
      const { success } = await industryGroupService.deleteIndustryGroup({ id })

      if (success) {
        toasts.deleted()
        onExit()
        goBack()
      } else {
        toasts.generalFail()
      }
    } catch (error) {
      console.log('onRemoveIndustryGroup-error', error)
    } finally {
      setLoading(false)
    }
  }

  function onDeleteIndustryGroup() {
    _setDeleteIndustryGroup(true)
  }

  return (
    <S.GridCard>
      {!loading && (
        <>
          <S.GridHeader>
            <S.GridTitle item container xs={12} md={6}>
              <S.BoxTitle>Grupo de Indústria</S.BoxTitle>
              <S.BoxLine />
            </S.GridTitle>
          </S.GridHeader>

          <S.Row marginBotton="3rem">
            <S.Card item container xs={12} md={6}>
              <div>
                <S.GroupTitle>Responsável</S.GroupTitle>
                <S.GroupTitleName>{adminName}</S.GroupTitleName>
              </div>
            </S.Card>

            <S.Card item container xs={12} md={6}>
              <div>
                <S.GroupTitle>E-mail administrador</S.GroupTitle>
                <S.GroupTitleEmail>{adminEmail}</S.GroupTitleEmail>
              </div>
            </S.Card>

            {/* <S.Card item container xs={12} md={3}>
              <div>
                <S.GroupTitle>Função grupo</S.GroupTitle>
                <S.GroupTitleName>Configuração de filtro</S.GroupTitleName>
              </div>
            </S.Card>

            <S.Card item container xs={12} md={3}>
              <div>
                <S.GroupTitle>Visualização</S.GroupTitle>
                <S.GridStatus item container xs={12} md={6}>
                  <StatusBox variant={statusBadgeClassnames[String(visible)]}>
                    {labels[String(visible)]}
                  </StatusBox>
                </S.GridStatus>
              </div>
            </S.Card> */}
          </S.Row>

          <S.Row>
            <S.Card item container xs={12} md={12}>
              <div>
                <S.GroupTitle>Perfil grupo</S.GroupTitle>
                <S.ProfileGroupContainer>
                  {companyGroupProfiles?.map(profile => (
                    <S.ProfileGroup key={profile.id}>
                      {profile.description.toUpperCase()}
                    </S.ProfileGroup>
                  ))}
                </S.ProfileGroupContainer>
              </div>
            </S.Card>
          </S.Row>

          <S.GridLinks>
            <Link to={`/grupo-industria/editar/${id}`}>
              <S.Link>
                <Icon icon="group-profile-rename" height={16} width={16}></Icon>
                <span>Editar grupo</span>
              </S.Link>
            </Link>
            <S.Link onClick={onDeleteIndustryGroup}>
              <Icon icon="group-profile-delete" height={16} width={16}></Icon>
              <span>Excluir grupo</span>
            </S.Link>
          </S.GridLinks>

          {_deleteIndustryGroup && (
            <ModalDeleteGroupIndustry
              groupName={name}
              companies={companies.length}
              onExit={onExit}
              onRemove={onRemoveIndustryGroup}
            />
          )}
        </>
      )}
    </S.GridCard>
  )
}
