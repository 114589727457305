import { useContext, useEffect, useState } from 'react'
import { OrdersManagementTableContext } from 'contexts/ordersTable/ordersTable.context'
import { OrdersManagementService } from 'services/ordersManagement.service'

import { Loading } from 'components'
import { Table } from '../Tables'

import * as S from './styled'

export function TabsOrdersComponents() {
  const { ordersManagementFilters } = useContext(OrdersManagementTableContext)
  const [loading, setLoading] = useState(false)
  const [ordersRows, setOrdersRows] = useState({
    items: [],
    pagination: {
      page: 0,
      totalItems: 0,
      limit: 0,
      totalPages: 0
    }
  })

  const getOrdersTableFromApi = async () => {
    setLoading(true)
    try {
      const service = new OrdersManagementService()
      const _filters = { ...ordersManagementFilters }
      delete _filters.startDate
      delete _filters.endDate
      delete _filters.dateType
      delete _filters.searchNameAndCnpjIn
      delete _filters.cnpj
      delete _filters.name
      delete _filters.expireIn

      await service
        .getOrdersManagementTable({ ..._filters })
        .then(({ data }: any) => {
          setOrdersRows(data)
        })
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getOrdersTableFromApi()
  }, [ordersManagementFilters])

  return (
    <S.Container>
      {loading ? (
        <Loading height="40rem" />
      ) : (
        <>
          <S.Box>
            <S.BoxTitle>{'Pedidos'}</S.BoxTitle>
            <S.BoxLine />
          </S.Box>
          <Table rows={ordersRows} hasActions={false} variation={'orders'} />
        </>
      )}

      {/* Recurso de exportação de dados removido temporariamente */}
      {/* <ReportButton onClick={onExit} />
      {isOpen && <ReportModal onClickExport={() => {}} />} */}
    </S.Container>
  )
}
