import { Button } from 'components'
import styled from 'styled-components'
export const Wrapper = styled.div`
  align-items: center;
  display: flex;
`

export const Nav = styled.nav`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
`

export const Link = styled.li`
  align-items: center;
  cursor: pointer;
  background: transparent;
  color: ${({ theme }) => theme.colors.gray};
  display: flex;
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const ButtonComponent = styled(Button)`
  width: fit-content;
`
