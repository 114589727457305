import { ReactNode } from 'react'
import { LangDropdown } from 'components/LangDropdown'

import * as S from './styled'
import { useTranslation } from 'react-i18next'
import { Footer } from 'components/Footer'

type SigninProps = {
  children: ReactNode
  requestInfo?: boolean
}

export function Signin({ children }: SigninProps) {
  const { t } = useTranslation()

  return (
    <S.GridContainer container>
      <S.SideBar item xs={12} sm={5} md={4}>
        <S.Row>
          <S.SideBarColumn>
            <S.SideBarTitle>{t('loginPage:welcomeTitle')}</S.SideBarTitle>
            <S.SideBarDescription>
              {t('loginPage:welcomeText')}
            </S.SideBarDescription>
          </S.SideBarColumn>
        </S.Row>
      </S.SideBar>

      <S.GridRight item xs={12} sm={7} md={8}>
        <S.Langdropdown>
          <LangDropdown />
        </S.Langdropdown>

        <S.Row>{children}</S.Row>
        <Footer />
      </S.GridRight>
    </S.GridContainer>
  )
}
