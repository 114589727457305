export const retailRequestPayload = {
  retail: {
    cnpj: 'retailCnpj',
    name: 'retailName'
  },
  provider: {
    cnpj: 'providerCnpj',
    name: 'providerName'
  },
  weaving: {
    cnpj: 'weavingCnpj',
    name: 'weavingName'
  },
  wiring: {
    cnpj: 'wiringCnpj',
    name: 'wiringName'
  },
  property: {
    cnpj: 'propertyCar',
    name: 'propertyName'
  },
  outsourced: {
    cnpj: 'outsourcedCnpj',
    name: 'outsourcedName'
  },
  order: {
    startDate: 'orderStartDate',
    endDate: 'orderEndDate'
  },
  invoice: {
    startDate: 'invoiceStartDate',
    endDate: 'invoiceEndDate'
  },
  invoiceDelivery: {
    startDate: 'invoiceDeliveryStartDate',
    endDate: 'invoiceDeliveryEndDate'
  },
  production: {
    startDate: 'productionStartDate',
    endDate: 'productionEndDate'
  },
  prediction: {
    startDate: 'predictionStartDate',
    endDate: 'predictionEndDate'
  }
}

export const INITIAL_LOCATION_STATE = {
  providerCountry: '', // 76 é o código do Brasil na api do IBGE
  providerMicroregion: '',
  providerState: '',
  providerCity: ''
}

export const ERRORS_LOCATION_STATE = {
  providerCountry: '',
  providerMicroregion: '',
  providerState: '',
  providerCity: ''
}
