/* eslint-disable no-unused-vars */
import { ReactNode, useState } from 'react'
import { useTranslation } from 'react-i18next'

import * as S from './styled'

type AccordionDetailsProps = {
  icon?: string
  marginBottom?: number
  children?: ReactNode
  onClickButton?: () => void
  buttonText?: string
  hasActionButton?: boolean
  counter?: number
  setIsOpen?: (func: any) => void
}

export function AccordionDetails({
  icon,
  buttonText,
  hasActionButton = false,
  children,
  counter,
  setIsOpen,
  onClickButton
}: AccordionDetailsProps) {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)

  return (
    <S.Container>
      <S.Wrapper>
        <S.AccordionDetailsIcon icon={icon} />
        {children}
      </S.Wrapper>

      {hasActionButton && (
        <S.ButtonComponent
          variant="green"
          onClick={() => {
            setOpen(!open)
            setIsOpen(!open)
            onClickButton()
          }}
          hasCounter
          counter={counter}
          disabled={counter <= 0}
        >
          {!open
            ? t('traceabilityRetailPage:accordionDetails:show')
            : t('traceabilityRetailPage:accordionDetails:hide')}{' '}
          {buttonText}
        </S.ButtonComponent>
      )}
    </S.Container>
  )
}
