import common from './pt-br/common.json'
import loginPage from './pt-br/loginPage.json'
import recoveryPassPage from './pt-br/recoveryPassPage.json'
import generalIndustriesPage from './pt-br/generalIndustriesPage.json'
import generalPanelPage from './pt-br/generalPanelPage.json'
import breadcrumb from './pt-br/breadcrumb.json'
import generalUserPage from './pt-br/generalUserPage.json'
import filters from './pt-br/filters.json'
import traceabilityRetailPage from './pt-br/traceabilityRetailPage.json'
import traceabilityProviderPage from './pt-br/traceabilityProviderPage.json'
import traceabilityWeavingPage from './pt-br/traceabilityWeavingPage.json'
import traceabilityWiringPage from './pt-br/traceabilityWiringPage.json'
import propertiesPage from './pt-br/propertiesPage.json'
import tableComponent from './pt-br/tableComponent.json'
import biomesSelectComponent from './pt-br/biomesSelectComponent.json'
import productionChainPage from './pt-br/productionChainPage.json'
import ordersManagementPage from './pt-br/ordersManagementPage.json'
import traceabilityPanelPage from './pt-br/traceabilityPanelPage.json'
import newIndustry from './pt-br/newIndustry.json'
import generalIndustryGroup from './pt-br/generalIndustryGroup.json'
import newIndustryGroup from './pt-br/newIndustryGroup.json'
import newUser from './pt-br/newUser.json'
import orderDetails from './pt-br/orderDetails.json'
import providerDetailsPage from './pt-br/providerDetailsPage.json'
import notFoundPage from './pt-br/notFoundPage.json'
import accordionDetailsContent from './pt-br/accordionDetailsContent.json'
import leadTimePage from './pt-br/leadTimePage.json'
import fabricsPage from './pt-br/fabricsPage.json'
import downloadReport from './pt-br/downloadReport.json'

export default {
  biomesSelectComponent,
  breadcrumb,
  common,
  filters,
  generalIndustriesPage,
  generalIndustryGroup,
  generalPanelPage,
  loginPage,
  newIndustry,
  newIndustryGroup,
  newUser,
  productionChainPage,
  ordersManagementPage,
  propertiesPage,
  providerDetailsPage,
  recoveryPassPage,
  tableComponent,
  traceabilityPanelPage,
  traceabilityProviderPage,
  traceabilityRetailPage,
  traceabilityWeavingPage,
  traceabilityWiringPage,
  generalUserPage,
  orderDetails,
  notFoundPage,
  accordionDetailsContent,
  leadTimePage,
  fabricsPage,
  downloadReport
}
