import chroma from 'chroma-js'

export const standard = {
  colors: {
    background: chroma('#F4F8F7'),
    backgroundAutocomplete: chroma('#F7F9FD'),
    backgroundInactive: chroma('#F99746'),
    backgroundMessage: chroma('#EBEBEB'),
    black: chroma('#000000'),
    borderActive: chroma('#128C49'),
    borderInactive: chroma('#F99746'),
    darkGray: chroma('#464A53'),
    error: chroma('#de4f4f'),
    focusForm: chroma('#408ae4'),
    footer: chroma('#e4e4e4'),
    gray: chroma('#707070'),
    hoverForm: chroma('#0f0f0f'),
    lightGray: chroma('#abafb3'),
    primary: chroma('#315ca7'),
    red: chroma('#DE4F4F'),
    lightRed: chroma('#DE4F4F54'),
    darkRed: chroma('#650D0D'),
    rowTable: chroma('#F4F8F7'),
    secondary: chroma('#3fab36'),
    success: chroma('#3FAB36'),
    textActive: chroma('#0B592F'),
    textInactive: chroma('#944B0F'),
    warn: chroma('#F39C12'),
    white: chroma('#ffffff'),
    borderColor: chroma('#F2F3F7'),
    disabled: chroma('#e4e4e5'),
    lightBlue: chroma('#bbc9e2'),
    darkBlue: chroma('#315CA7'),
    darkGrayRGBA: chroma('#5757571c')
  },
  spacings: {
    xxxsmall: '0.4rem',
    xxsmall: '0.8rem',
    xsmall: '1.6rem',
    small: '2.4rem',
    medium: '3.2rem',
    large: '4.0rem',
    xlarge: '4.8rem',
    xxlarge: '5.6rem'
  },
  transition: {
    default: '0.3s ease-in-out',
    fast: '0.1s ease-in-out'
  }
}
