import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
  StoreEnhancer
} from 'redux'
import createSagaMiddleware from 'redux-saga'
import { all, takeLatest } from 'redux-saga/effects'

import { tron } from 'config'

import { breadcrumbs } from './Breadcrumbs'
import { sidebar } from './Sidebar'
import { spinner } from './Spinner'
import { language, LanguageActions, LanguageSaga } from './Language'
import { userDropdownData } from './UserProfileDropdown'
import { versionApi } from './Version'

export type RootState = {
  breadcrumbs: ReturnType<typeof breadcrumbs>
  sidebar: ReturnType<typeof sidebar>
  spinner: ReturnType<typeof spinner>
  language: ReturnType<typeof language>
  userDropdownData: ReturnType<typeof userDropdownData>
  versionApi: ReturnType<typeof versionApi>
}

const saga = createSagaMiddleware()

function* rootSaga() {
  yield all([
    takeLatest(LanguageActions.types.GET_ALL_LANG, LanguageSaga.getAllLanguages)
  ])
}

const rootReducer = combineReducers({
  breadcrumbs,
  sidebar,
  spinner,
  language,
  userDropdownData,
  versionApi
})

const rootEnhancer: StoreEnhancer = compose(
  ...[applyMiddleware(saga), tron.createEnhancer()].filter(e => e)
)

export const store = createStore(rootReducer, rootEnhancer)
saga.run(rootSaga)
