import { MouseEventHandler } from 'react'

import * as S from './styled'
import { Icon } from 'components/Icon'
import { useTranslation } from 'react-i18next'

type ReportButtonProps = {
  onClick: MouseEventHandler<HTMLButtonElement>
}

export function ReportButton({ onClick }: ReportButtonProps) {
  const { t } = useTranslation()
  return (
    <S.Wrapper>
      <S.ButtonComponent onClick={onClick}>
        <Icon icon="download" height={16} width={24} /> {t('common:reports')}
      </S.ButtonComponent>
    </S.Wrapper>
  )
}
