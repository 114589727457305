import * as S from './styled'

import { Icon } from '../Icon'

export type IconCardProps = {
  description: string
  icon: string
  title: string
}

export function IconCard({
  description,
  icon = 'card-panel',
  title
}: IconCardProps) {
  return (
    <S.IconCard>
      <S.Title>{title}</S.Title>
      <S.Subtitle>
        <Icon icon={icon} fill="success" height={32} width={32} />
        <S.Description>{description}</S.Description>
      </S.Subtitle>
    </S.IconCard>
  )
}
