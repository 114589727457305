import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { UserService } from 'services'

import { toasts } from 'utils/toasts'
import { UserType } from 'types/User.types'

import { UserDropdown } from './UserDropdown'
import { TableNotFoundHint } from 'components/TableNotFoundHint'
import { ModalDeleteUser } from 'components/User/ModalDeleteUser/ModalDeleteUser'

import * as S from './styled'

export type UserTableProps = {
  rows: UserType[]
  isDeleted: (_) => void
}

export function UserTable({ rows, isDeleted }: UserTableProps) {
  const { t } = useTranslation()
  const userService = new UserService()

  const [_deleteUser, _setDeleteUser] = useState({} as UserType)

  const onExit = () => {
    _setDeleteUser({} as UserType)
  }

  const onRemoveUser = async () => {
    try {
      const { success } = await userService.deleteUser({
        id: _deleteUser.id
      })

      if (success) {
        isDeleted(true)
        toasts.deleted()
      } else {
        toasts.generalFail()
      }
      _setDeleteUser({} as UserType)
    } catch (error) {
      console.log(error)
    } finally {
      isDeleted(false)
    }
  }

  const onEdit = async id => {
    return userService.fetchOne(id)
  }

  return (
    <S.Wrapper data-testid="UserTable">
      {Object.keys(_deleteUser).length > 0 && (
        <ModalDeleteUser
          name={_deleteUser.firstName}
          onExit={onExit}
          onRemove={onRemoveUser}
        />
      )}

      <S.Container>
        <S.Table id="table-fixed">
          <tbody>
            <S.Line id="header">
              <S.Header>{t('generalUserPage:table:name')}</S.Header>
            </S.Line>
            {rows.map((each, key) => (
              <S.Line id="values" key={key}>
                <S.Column>{each.name}</S.Column>
              </S.Line>
            ))}
          </tbody>
        </S.Table>
        <S.ScrollWrapper>
          <S.Table id="table-scrolled">
            <tbody>
              <S.Line id="header">
                <S.Header className="right">
                  {t('generalUserPage:table:phoneNumber')}
                </S.Header>
                <S.Header className="right">
                  {t('generalUserPage:table:documentNumber')}
                </S.Header>
                <S.Header>{t('generalUserPage:table:email')}</S.Header>
                <S.Header>
                  {t('generalUserPage:filters:userType:userType')}
                </S.Header>
                <S.Header>{t('generalUserPage:table:departament')}</S.Header>
              </S.Line>
              {rows.map((each, key) => (
                <S.Line id="values" key={key}>
                  <S.Column className="right">
                    {each.phone ? each.phone : each.phoneRecovery}{' '}
                  </S.Column>
                  <S.Column className="right">{each.CPF}</S.Column>
                  <S.Column>{each.email}</S.Column>
                  <S.Column>{each.role}</S.Column>
                  <S.Column>{each.department}</S.Column>
                </S.Line>
              ))}
            </tbody>
          </S.Table>
        </S.ScrollWrapper>
        <S.Table id="table-fixed">
          <tbody>
            <S.Line id="header">
              <S.Header>{t('generalUserPage:table:actions')}</S.Header>
            </S.Line>
            {rows.map((each, key) => (
              <S.Line key={key}>
                <S.Column id="actions-column">
                  <UserDropdown
                    id={each.id}
                    onEdit={onEdit}
                    setDeleteUser={() => _setDeleteUser(each)}
                  ></UserDropdown>
                </S.Column>
              </S.Line>
            ))}
          </tbody>
        </S.Table>
      </S.Container>

      {!rows.length && <TableNotFoundHint to="/usuario/novo" />}
    </S.Wrapper>
  )
}
