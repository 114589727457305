import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { FabricsService } from 'services/fabrics.service'

import { onFilterPaginate } from 'utils/OnFilterPaginate'

import { fabricsTable } from '../../../fabrics.constants'
import { FabricsRows, IQueryParameters } from '../../../fabrics.types'

import { Pagination } from 'components'
import { TableAccordion } from 'components/TableAccordion'
import { FabricDetailsTable } from '../FabricDetailsTable/FabricDetailsTable'

import * as S from '../GeneralDataTable/styled'

export function FabricsTable() {
  const { t } = useTranslation()
  const { id: orderNumber } = useParams<IQueryParameters>()

  const [productCode, setProductCode] = useState('')
  const [tableHeight, setTableHeight] = useState(0)
  const [rows, setRows] = useState({
    items: [],
    pagination: {
      page: 0,
      totalItems: 0,
      limit: 0,
      totalPages: 0
    }
  })

  function handleGetIdRow(param?: any) {
    setProductCode(param.productCode)
  }

  function handleTableHeight(height: number) {
    // 180px é a margem do accordion
    const marginBorder = 180
    setTableHeight(height + marginBorder)
  }

  const getFabricsTableFromApi = useCallback(
    async (page?: number, limit?: number) => {
      const fabricService = new FabricsService()
      await fabricService
        .getFabrics({
          orderNumber,
          page,
          limit
        })
        .then(response => {
          const { data } = response
          setRows(data as FabricsRows)
        })
    },
    [orderNumber]
  )

  function handlePaginationFilter({ limit, page }) {
    const filters = onFilterPaginate(rows.pagination, limit, page)

    getFabricsTableFromApi(filters.page, filters.limit)
  }

  useEffect(() => {
    if (orderNumber && !rows.items.length) {
      getFabricsTableFromApi()
    }
  }, [getFabricsTableFromApi, orderNumber, rows])

  console.log(tableHeight)

  return (
    <S.Container>
      <S.Title>
        {t('fabricsPage:tables:fabrics:fabrics')}
        <S.Line />
      </S.Title>
      <S.Wrapper>
        <TableAccordion
          rows={rows.items}
          columns={fabricsTable()}
          handleGetId={handleGetIdRow}
          titleTable={t('fabricsPage:tables:fabricDetails:fabricDetails')}
          height={tableHeight}
        >
          <FabricDetailsTable
            productCode={productCode}
            parentHeight={handleTableHeight}
          />
        </TableAccordion>
      </S.Wrapper>
      <Pagination
        totalItems={rows.pagination.totalItems}
        page={rows.pagination.page}
        limit={rows.pagination.limit}
        setFilter={handlePaginationFilter}
      />
    </S.Container>
  )
}
