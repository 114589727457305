import { AxiosRequestConfig } from 'axios'
import { HTTP } from 'providers'
import { IndustryGroupType } from 'types/IndustryGroup.types'

export class IndustryGroupService extends HTTP {
  private endpoint = '/company-group'
  private config: AxiosRequestConfig

  public fetchAll(filters) {
    return this.get({ endpoint: this.endpoint, params: filters })
  }

  public fetchAllWithAccessRelease() {
    return this.get({
      endpoint: `${this.endpoint}/accessRelease`
    })
  }

  public fetchOne(id) {
    return this.get({
      endpoint: `${this.endpoint}/${id}`
    })
  }

  public async getUnrelatedIndustries(id) {
    return this.get({
      endpoint: `${this.endpoint}/unrelatedIndustries/${id}`
    })
  }

  public async create(industryGroup: IndustryGroupType) {
    return this.post({
      endpoint: this.endpoint,
      payload: industryGroup,
      config: this.config
    })
  }

  public async edit(industryGroup: IndustryGroupType) {
    const { id } = industryGroup
    return this.put({
      endpoint: `${this.endpoint}/${id}`,
      payload: industryGroup,
      config: this.config
    })
  }

  public async linkWithGroup({ id, companyIds }) {
    return this.patch({
      endpoint: `${this.endpoint}/link-with-companies/${id}`,
      payload: { companyIds },
      config: this.config
    })
  }

  public unlinkWithGroup({ id, companyIds }) {
    return this.patch({
      endpoint: `${this.endpoint}/unlink-with-companies/${id}`,
      payload: { companyIds },
      config: this.config
    })
  }

  public async deleteIndustryGroup({ id }) {
    return this.delete({
      endpoint: `${this.endpoint}/${id}`
    })
  }

  public async options() {
    return this.get({ endpoint: `${this.endpoint}/options` })
  }
}
