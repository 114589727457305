import { ReactNode, createContext, useState } from 'react'
import { subMonths } from 'date-fns'
import { retailRequestPayload } from 'constants/retailFilterForm.constants'

interface SupplierProviderProps {
  children: ReactNode
}

export interface ISupplierFiltersContext {
  startDate: Date
  endDate: Date
  dateType: string
  orderEndDate?: Date | string
  orderStartDate?: Date | string
  invoiceStartDate?: Date | string
  invoiceEndDate?: Date | string
  productionStartDate?: Date | string
  productionEndDate?: Date | string
  predictionStartDate?: Date | string
  predictionEndDate?: Date | string
  orderNumber: string
  invoiceNumber: string
  productCode?: string
  orderStatus: string
  providerCnpj?: string
  providerName?: string
  outsourcedCnpj?: string
  outsourcedName?: string
  column: 'ASC' | 'DESC'
  direction: string
}

interface ISupplierFiltersProps {
  supplierFilters?: Record<string, any>
  setSupplierFilters?: (_filters: Record<string, any>) => void
  clearSupplierFilters?: () => void
}

export const SupplierFiltersContext =
  createContext<ISupplierFiltersProps>(undefined)

export default function SupplierFiltersProvider({
  children
}: SupplierProviderProps) {
  const localStorageFilters = localStorage.getItem('supplierFilters')
  const localStorageFiltersParsed = localStorageFilters
    ? JSON.parse(localStorageFilters)
    : null

  const getDateLastMonth = subMonths(new Date(), 1)
  const formattedDate = new Date(
    getDateLastMonth.getFullYear(),
    getDateLastMonth.getMonth(),
    1,
    getDateLastMonth.getHours(),
    getDateLastMonth.getMinutes(),
    getDateLastMonth.getSeconds()
  )

  function parseFilterOptions() {
    if (localStorageFiltersParsed) {
      const startDateKey =
        retailRequestPayload[localStorageFiltersParsed.dateType].startDate
      const endDateKey =
        retailRequestPayload[localStorageFiltersParsed.dateType].endDate
      return {
        [startDateKey]: localStorageFiltersParsed[startDateKey],
        [endDateKey]: localStorageFiltersParsed[endDateKey]
      }
    }

    return {
      orderStartDate: formattedDate,
      orderEndDate: new Date()
    }
  }

  const INITIAL_FILTERS_STATE = {
    ...parseFilterOptions(),
    isSupplierPage: true,
    startDate: localStorageFiltersParsed?.orderStartDate || formattedDate,
    endDate: localStorageFiltersParsed?.orderEndDate || new Date(),
    dateType: localStorageFiltersParsed?.dateType || 'order',
    orderNumber: localStorageFiltersParsed?.orderNumber || '',
    invoiceNumber: localStorageFiltersParsed?.invoiceNumber || '',
    productCode: localStorageFiltersParsed?.productCode || '',
    orderStatus: localStorageFiltersParsed?.orderStatus || '',
    providerCnpj: localStorageFiltersParsed?.providerCnpj || '',
    providerName: localStorageFiltersParsed?.providerName || '',
    outsourcedCnpj: localStorageFiltersParsed?.outsourcedCnpj || '',
    outsourcedName: localStorageFiltersParsed?.outsourcedName || '',
    column: localStorageFiltersParsed?.column || 'orderDate',
    direction: localStorageFiltersParsed?.direction || 'DESC'
  }

  const [supplierFilters, setContextState] = useState<ISupplierFiltersContext>(
    INITIAL_FILTERS_STATE
  )

  function clearSupplierFilters() {
    setContextState(INITIAL_FILTERS_STATE)
    localStorage.removeItem('supplierFilters')
  }

  function setSupplierFilters(filters: ISupplierFiltersContext) {
    setContextState(filters)
    localStorage.setItem('supplierFilters', JSON.stringify(filters))
  }

  return (
    <SupplierFiltersContext.Provider
      value={{
        supplierFilters,
        setSupplierFilters,
        clearSupplierFilters
      }}
    >
      {children}
    </SupplierFiltersContext.Provider>
  )
}
