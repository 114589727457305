import { t } from 'i18next'
import { ColumnsProps } from 'components/Table/Table'

// CONSTANTES FILTROS VVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV

export const INITIAL_STATE = {
  supplierAndOutsourcedName: '',
  activity: '',
  orderStatus: 'T',
  orderSituation: ''
}

export const ERRORS_INITIAL_STATE = {
  supplierAndOutsourcedName: '',
  activity: '',
  orderStatus: '',
  orderSituation: ''
}

export const INITIAL_STATE_CONSTANT_STATUS_ORDER = [
  {
    id: 'T',
    label: t('leadTimePage:constants:orderStatus:all'),
    tag: 'T'
  },
  {
    id: 'P',
    label: t('leadTimePage:constants:orderStatus:progress'),
    tag: 'P'
  },
  {
    id: 'F',
    label: t('leadTimePage:constants:orderStatus:finished'),
    tag: 'F'
  }
]

export const INITIAL_STATE_CONSTANT_ORDER_SITUATION = [
  {
    id: 'P',
    label: t('leadTimePage:constants:orderSituation:onTime'),
    tag: 'P'
  },
  {
    id: 'A',
    label: t('leadTimePage:constants:orderSituation:overdue'),
    tag: 'A'
  }
]

export const INITIAL_STATE_GENERAL_DATA = {
  items: [],
  pagination: {
    totalPages: 0,
    totalItems: 0,
    limit: 10,
    page: 0
  }
}

export const INITIAL_STATE_ORDER_SUMMARY = {
  items: [],
  pagination: {
    totalPages: 0,
    totalItems: 0,
    limit: 10,
    page: 0
  }
}

// COLUNAS TABELAS VVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV

export const generalData = (): ColumnsProps[] => [
  {
    fixed: true,
    align: 'center',
    name: 'orderNumber',
    title: t('leadTimePage:tables:generalData:orderNumber')
  },
  {
    fixed: false,
    align: 'center',
    name: 'orderStatus',
    title: t('leadTimePage:tables:generalData:orderStatus')
  },

  {
    fixed: false,
    align: 'center',
    name: 'orderSituation',
    title: t('leadTimePage:tables:generalData:orderSituation')
  },
  {
    fixed: false,
    align: 'center',
    name: 'orderDate',
    title: t('leadTimePage:tables:generalData:orderDate')
  },
  {
    fixed: false,
    align: 'center',
    name: 'deliveryForecast',
    title: t('leadTimePage:tables:generalData:deliveryForecastDate')
  },
  {
    fixed: false,
    align: 'center',
    name: 'endDate',
    title: t('leadTimePage:tables:generalData:endDate')
  },
  {
    fixed: false,
    align: 'center',
    name: 'leadTime',
    title: t('leadTimePage:tables:generalData:leadTime')
  },
  {
    fixed: false,
    align: 'center',
    name: 'supplierAndOutsourcedCnpj',
    title: t('leadTimePage:tables:generalData:supplierCnpj')
  },
  {
    fixed: false,
    align: 'center',
    name: 'supplierAndOutsourcedName',
    title: t('leadTimePage:tables:generalData:supplierName')
  }
]

export const orderSummary = (): ColumnsProps[] => [
  {
    fixed: true,
    align: 'center',
    name: 'supplierAndOutsourcedName',
    title: t('leadTimePage:tables:orderSummary:production')
  },
  {
    fixed: false,
    align: 'center',
    name: 'activity',
    title: t('leadTimePage:tables:orderSummary:activity')
  },
  {
    fixed: false,
    align: 'center',
    name: 'orderDate',
    title: t('leadTimePage:tables:orderSummary:orderDate')
  },
  {
    fixed: false,
    align: 'center',
    name: 'deliveryForecast',
    title: t('leadTimePage:tables:orderSummary:deliveryForecastDate')
  },
  {
    fixed: false,
    align: 'center',
    name: 'endDate',
    title: t('leadTimePage:tables:orderSummary:endDate')
  },
  {
    fixed: false,
    align: 'center',
    name: 'leadTime',
    title: t('leadTimePage:tables:orderSummary:leadTime')
  },
  {
    fixed: false,
    align: 'center',
    name: 'orderStatus',
    title: t('leadTimePage:tables:orderSummary:orderStatus')
  },
  {
    fixed: false,
    align: 'center',
    name: 'situation',
    title: t('leadTimePage:tables:orderSummary:orderSituation')
  }
]
