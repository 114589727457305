import { useContext, useState } from 'react'
import { t } from 'i18next'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { formaDateStringLocale } from 'utils/formaDateStringLocale'

import { Icon } from 'components'
import { ColumnsProps } from 'components/Table/Table'
import { TooltipComponent } from 'components/Tooltip'
import { TableAccordion } from 'components/TableAccordion'
import { ModalTable } from 'components/ModalOpenTable/ModalTable'
import { SupplierDetailsTable } from '../SupplierDetailsTable/SupplierDetailsTable'
import { ProductionDetailsTable } from '../ProductionDetailsTable/ProductionDetailsTable'

import * as S from '../styled'

import { SupplierFiltersContext } from 'contexts/supplierFilters/supplierFilters.context'

type ProvidersRows = {
  confectionId: string
  orderNumber: string
  orderDate: Date
  supplierName: string
  invoiceSale: string
  invoiceSaleDate: Date
  orderStatus: string
  orderSituation: string
  productionOrder: string
  batch: string | null
  deliveryForecast: Date
  productionDate: Date
  quantityItemsOrdered: number
  lastUpdateOrder: Date
  haveFabricData: 'N' | 'Y'
}

export type ProviderTableProps = {
  rows: ProvidersRows[]
}

const columns = (): ColumnsProps[] => [
  {
    fixed: true,
    align: 'center',
    name: 'orderNumber',
    title: t('traceabilityRetailPage:tables:provider:orderNumber'),
    useStatusBoxComponent: true
  },
  {
    fixed: true,
    align: 'center',
    name: 'orderDate',
    title: t('traceabilityProviderPage:tables:orders:orderDate')
  },
  {
    fixed: true,
    align: 'center',
    name: 'supplierName',
    title: t('traceabilityProviderPage:tables:orders:supplierName')
  },
  {
    fixed: false,
    align: 'center',
    name: 'invoiceSale',
    title: t('traceabilityRetailPage:tables:provider:salesInvoiceNumber')
  },
  {
    fixed: false,
    align: 'center',
    name: 'invoiceSaleDate',
    title: t('traceabilityRetailPage:tables:provider:issueInvoice')
  },
  {
    fixed: false,
    align: 'center',
    name: 'orderStatus',
    title: t('traceabilityRetailPage:tables:provider:orderStatus')
  },
  {
    fixed: false,
    align: 'center',
    name: 'orderSituation',
    title: t('traceabilityRetailPage:tables:provider:orderSituation')
  },
  {
    fixed: false,
    align: 'center',
    name: 'productionOrder',
    title: t('traceabilityRetailPage:tables:provider:productionOrderNumber')
  },
  {
    fixed: false,
    align: 'center',
    name: 'batch',
    title: t('traceabilityRetailPage:tables:provider:lotNumber')
  },
  {
    fixed: false,
    align: 'center',
    name: 'deliveryForecast',
    title: t('traceabilityRetailPage:tables:provider:deliveryForecastDate')
  },
  {
    fixed: false,
    align: 'center',
    name: 'productionDate',
    title: t('traceabilityRetailPage:tables:provider:productionDate')
  },
  {
    fixed: false,
    align: 'center',
    name: 'quantityItemsOrdered',
    title: t('traceabilityRetailPage:tables:orders:quantityItemsOrdered')
  },
  {
    fixed: false,
    align: 'center',
    name: 'quantityDelivered',
    title: t('traceabilityRetailPage:tables:orders:totalDelivered')
  },
  {
    fixed: false,
    align: 'center',
    name: 'lastUpdateOrder',
    title: t('traceabilityRetailPage:tables:provider:orderUpdateDate')
  },
  {
    fixed: false,
    align: 'center',
    name: 'orderProgress',
    title: t('traceabilityRetailPage:tables:orders:progress')
  }
]

export function ProvidersTable({ rows }: ProviderTableProps) {
  const { supplierFilters, setSupplierFilters } = useContext(
    SupplierFiltersContext
  )
  const { t, i18n } = useTranslation()

  const language = i18n.language === '中文 - ZH - CN' ? 'zh-CN' : i18n.language

  const [columnSorted, setColumnSorted] = useState(supplierFilters.column)
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC'>(
    supplierFilters.direction
  )

  const [orderNumber, setOrderNumber] = useState('')
  const [openModal, setOpenModal] = useState(false)
  const [tableHeight, setTableHeight] = useState(0)

  function actionTable(orderItemParam?: ProvidersRows) {
    return (
      <S.Nav>
        <TooltipComponent
          text={t('traceabilityProviderPage:tables:orders:ordersDetails')}
        >
          <S.Link
            onClick={() => {
              setOrderNumber(orderItemParam.orderNumber)
              setOpenModal(true)
            }}
          >
            <Icon
              icon="details"
              height={24}
              width={24}
              fill="success"
              cursorPointer
            />
          </S.Link>
        </TooltipComponent>
        <Link
          to={{
            pathname: `/gestao-pedidos/detalhes-pedido/${orderItemParam.orderNumber}`,
            state: { fromSupplier: true }
          }}
        >
          <TooltipComponent
            text={t(
              'ordersManagementPage:tables:ordersTable:actions:manageOrder'
            )}
          >
            <S.Link>
              <Icon
                icon="breadcrumbs-order-summary"
                height={24}
                width={24}
                fill="success"
                cursorPointer
              />
            </S.Link>
          </TooltipComponent>
        </Link>
        <Link to={`/gestao-pedidos/lead-time/${orderItemParam.orderNumber}`}>
          <TooltipComponent
            text={t('ordersManagementPage:tables:ordersTable:actions:leadTime')}
          >
            <S.Link>
              <Icon
                icon="hourglass"
                height={24}
                width={24}
                fill="success"
                cursorPointer
              />
            </S.Link>
          </TooltipComponent>
        </Link>
        {orderItemParam.haveFabricData !== 'N' ? (
          <Link to={`/rastreabilidade/tecidos/${orderItemParam.orderNumber}`}>
            <TooltipComponent text={t('fabricsPage:tables:fabrics:fabrics')}>
              <S.Link>
                <Icon
                  icon="fabrics"
                  height={24}
                  width={24}
                  fill="success"
                  cursorPointer
                />
              </S.Link>
            </TooltipComponent>
          </Link>
        ) : (
          <TooltipComponent text={t('fabricsPage:tables:fabrics:fabrics')}>
            <S.Link>
              <Icon
                icon="fabricsDesabilited"
                height={24}
                width={24}
                fill="primary"
              />
            </S.Link>
          </TooltipComponent>
        )}

        {/*
          Inativando esse link por decisão de negócio que no momento, não será necessário usar a tela de cadeia produtiva
          Mantendo código para futuras necessidades e revisões
          <Link
            to={'/cadeia-produtiva'}
            onClick={() => setFilterForProductiveChain(orderItemParam)}
          >
            <S.Link>
              <Icon
                icon="productive-chain-action"
                height={16}
                width={16}
              ></Icon>
              <span>
                {t('traceabilityRetailPage:tables:orders:productiveChain')}
              </span>
            </S.Link>
          </Link> */}
      </S.Nav>
    )
  }

  const formattedRows = rows.map(item => ({
    ...item,
    orderDate: item.orderDate ? formaDateStringLocale(item.orderDate) : null,
    deliveryForecast: item.deliveryForecast
      ? formaDateStringLocale(item.deliveryForecast)
      : null,
    invoiceSaleDate: item.invoiceSaleDate
      ? formaDateStringLocale(item.invoiceSaleDate)
      : null,
    lastUpdateOrder: item.lastUpdateOrder
      ? formaDateStringLocale(item.lastUpdateOrder)
      : null,
    productionDate: item.productionDate
      ? formaDateStringLocale(item.productionDate)
      : null,
    quantityItemsOrdered: Number(item.quantityItemsOrdered).toLocaleString(
      language
    ),
    key: JSON.stringify({ number: item.orderNumber })
  }))

  function handleGetIdRow(orderItemParam?: ProvidersRows) {
    setOrderNumber(orderItemParam.orderNumber)
  }

  function handleTableHeight(height: number) {
    // 180px é a margem do accordion
    const marginBorder = 180
    setTableHeight(height + marginBorder)
  }

  function handleSortData(column: string, direction: 'ASC' | 'DESC') {
    setColumnSorted(column)
    setSortDirection(direction)
    setSupplierFilters({
      ...supplierFilters,
      column,
      direction
    })
  }

  function handleSortColumn(column: string) {
    setColumnSorted(column)
    handleSortData(column, sortDirection)
    if (column !== columnSorted) return
    const direction = sortDirection === 'ASC' ? 'DESC' : 'ASC'
    handleSortData(column, direction)
  }

  return (
    <>
      {openModal ? (
        <ModalTable
          content={<SupplierDetailsTable orderNumber={orderNumber} />}
          titleTable={'Itens do Pedido'}
          onExit={() => setOpenModal(false)}
        />
      ) : null}
      <TableAccordion
        rows={formattedRows}
        columns={columns()}
        sortColumn={{ column: columnSorted, sort: sortDirection }}
        onSortColumn={handleSortColumn}
        actionTable={actionTable}
        handleGetId={handleGetIdRow}
        titleTable="Produção"
        height={tableHeight}
        statusBoxColumnName="orderStatus"
        situationBoxColumnName="orderSituation"
      >
        <ProductionDetailsTable
          orderNumber={orderNumber}
          parentHeight={handleTableHeight}
        />
      </TableAccordion>
    </>
  )
}
