import styled from 'styled-components'

export const Wrapper = styled.div`
  padding: 0 2rem;
`

export const TitleWrapper = styled.text`
  margin-bottom: 2rem;

  font-weight: bold;
  font-size: large;
`

export const ContentWrapper = styled.div``

export const Box = styled.div`
  display: flex;
  flex-direction: column;
`

export const BoxTitle = styled.p`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 600;
  font-size: 1.6rem;
`

export const BoxLine = styled.p`
  padding-top: 1rem;
  width: 6.3rem;
  border-bottom: 4px solid ${({ theme }) => theme.colors.primary};
  margin-bottom: 2rem;
`

export const GridTable = styled.div`
  margin-top: 1.6rem;
  max-height: 55rem;
  overflow-y: auto;

  ::-webkit-scrollbar {
    height: 1.2rem;
    width: 0.4rem;
  }
  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.background};
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.gray.luminance(0.5)};
    border-radius: 2rem;
  }

  @media screen and (max-height: 750px) {
    max-height: 15rem;
  }
`
