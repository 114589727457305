import { Button } from 'components/Button'
import { Icon } from 'components/Icon'
import { Modal } from 'components/Modal'

import * as S from './styled'

export type ModalDeleteUserProps = {
  name: string
  onExit?: () => void
  onRemove?: () => void
}

export function ModalDeleteUser({
  name,
  onExit,
  onRemove
}: ModalDeleteUserProps) {
  const button = (
    <Button variant="green" onClick={onRemove} size="medium">
      <p>Sim</p>
    </Button>
  )

  const content = (
    <S.Wrapper>
      <S.Icon>
        <Icon icon="dropdown-delete" width={16} height={16} />
      </S.Icon>
      <S.Title>Excluir usuário</S.Title>
      <S.Description>
        Tem certeza que deseja excluir o usuário <span>{name}</span> ?
      </S.Description>
    </S.Wrapper>
  )

  return (
    <Modal
      button={button}
      content={content}
      isActionsEnabled={true}
      width={60}
      onExit={onExit}
    />
  )
}
