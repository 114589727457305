import styled from 'styled-components'

interface ButtonProps {
  minWidth: number
}

// CODIGO COMENTADO POR ALTERAÇOES NO PROJETO.
// NÃO REMOVER, CASO PRECISE NOVAMENTE JÁ ESTA PRONTO.

export const Button = styled.div<ButtonProps>`
  /* border: none;
  border-radius: 2.4rem;
  cursor: default;
  font-size: 1.2rem;
  font-weight: 600;
  outline: none;
  padding: 0.4rem 0.8rem;
  text-transform: uppercase;
  transform-origin: center;
  min-width: ${({ minWidth }) => minWidth}rem;

  &.active {
    background-color: ${({ theme }) => theme.colors.secondary.luminance(0.8)};
    border: 1px solid ${({ theme }) => theme.colors.borderActive};
    color: ${({ theme }) => theme.colors.textActive};
  }

  &.inactive {
    background-color: ${({ theme }) =>
    theme.colors.backgroundInactive.luminance(0.8)};
    border: 1px solid ${({ theme }) => theme.colors.borderInactive};
    color: ${({ theme }) => theme.colors.textInactive};
  }

  &.neutral {
    background-color: ${({ theme }) => theme.colors.lightBlue};
    border: 1px solid ${({ theme }) => theme.colors.darkBlue};
    color: ${({ theme }) => theme.colors.darkBlue};
  }

  &.negative {
    background-color: ${({ theme }) => theme.colors.lightRed};
    border: 1px solid ${({ theme }) => theme.colors.red};
    color: ${({ theme }) => theme.colors.darkRed};
  } */
`
