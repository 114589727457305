import { ChangeEvent } from 'react'

import * as S from './styled'
import { SquarePictures } from './SquarePictures/SquarePictures'

type IndustryPhotoType = {
  industryPhotos: { src: string; filename: string }[]
  handleRemoveItemImage: (_index) => void
  handleSelectImages: (_e: ChangeEvent<HTMLInputElement>) => void
}

export function IndustryPhotos({
  industryPhotos,
  handleRemoveItemImage,
  handleSelectImages
}: IndustryPhotoType) {
  return (
    <S.GridGalery container item sm={12} md={8} spacing={2}>
      <S.CardPhotos>
        <S.ScrollWrapper>
          <SquarePictures
            images={industryPhotos}
            handleRemoveItemImage={handleRemoveItemImage}
            handleSelectImages={handleSelectImages}
          />
        </S.ScrollWrapper>
      </S.CardPhotos>
    </S.GridGalery>
  )
}
