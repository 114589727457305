import { useEffect, useState } from 'react'

import { IndustryGroupService } from 'services'

import { IndustryType } from 'types/Industry.types'

import { Checkbox, Icon } from 'components'
import { ModalDelete } from './ModalDelete/ModalDelete'
import { TableNotFoundHint } from 'components/TableNotFoundHint'
import { IndustryGroupFilterDropDown } from './IndustryGroupFilterDropDown'

import * as S from './styled'
import { toasts } from 'utils/toasts'

export type IndustryGroupFilterTableProps = {
  groupId: string
  groupName: string
  items: IndustryType[]
  onNewClick?: (_value: unknown) => void
  isDeleted: (_) => void
}

type DeleteSelected = string[]

export function IndustryGroupFilterTable({
  groupId,
  groupName,
  items = [],
  onNewClick,
  isDeleted
}: IndustryGroupFilterTableProps) {
  const industryGroupService = new IndustryGroupService()

  const [deleteIndustry, setDeleteIndustry] = useState({} as IndustryType)
  const [deleteSelected, setDeleteSelected] = useState([] as DeleteSelected)
  const [allCheckboxes, setAllCheckboxes] = useState(false)
  const [checkboxes, setCheckboxes] = useState([])

  useEffect(() => {
    if (items.length) {
      if (checkboxes.length === items.length) return setAllCheckboxes(true)
      return setAllCheckboxes(false)
    }
  }, [checkboxes, items])

  function cleanSelectedIndustries() {
    setCheckboxes([])
  }

  function changeAll() {
    setAllCheckboxes(!allCheckboxes)
    if (!allCheckboxes) return setCheckboxes(items.map(({ id }) => id))
    return cleanSelectedIndustries()
  }

  function handleCheckbox(id: string) {
    return function handle(val) {
      if (val) return setCheckboxes([...checkboxes, id])

      const newCheckboxes = checkboxes.filter(checkbox => checkbox !== id)
      return setCheckboxes(newCheckboxes)
    }
  }

  function onExit() {
    setDeleteIndustry({} as IndustryType)
    setDeleteSelected([] as DeleteSelected)
  }

  async function onRemoveOne() {
    try {
      const { success } = await industryGroupService.unlinkWithGroup({
        id: groupId,
        companyIds: [deleteIndustry.id]
      })

      if (success) {
        isDeleted(true)
        toasts.deleted()
        setDeleteIndustry({} as IndustryType)
        cleanSelectedIndustries()
        setAllCheckboxes(false)
      } else {
        toasts.generalFail()
      }
    } catch (error) {
      console.log(error)
    } finally {
      isDeleted(false)
    }
  }

  async function onRemoveSelected() {
    try {
      const { success } = await industryGroupService.unlinkWithGroup({
        id: groupId,
        companyIds: deleteSelected
      })

      if (success) {
        isDeleted(true)
        toasts.deleted()
        setDeleteSelected([] as DeleteSelected)
        cleanSelectedIndustries()
        setAllCheckboxes(false)
      } else {
        toasts.generalFail()
      }
    } catch (error) {
      console.log(error)
    } finally {
      isDeleted(false)
    }
  }

  function descriptionIndustrySelect() {
    const plural = checkboxes.length ? 's' : ''
    return `${checkboxes.length} indústria${plural} selecionada${plural}`
  }

  function actionIndustrySelect() {
    const plural = checkboxes.length ? 's' : ''
    return `Remover indústria${plural}`
  }

  return (
    <S.Wrapper data-testid="IndustryGroupFilterTable">
      {Object.keys(deleteIndustry).length ? (
        <ModalDelete
          cnpj={deleteIndustry.cnpj}
          groupName={deleteIndustry.name}
          onExit={onExit}
          onRemove={onRemoveOne}
        />
      ) : null}
      {deleteSelected.length ? (
        <ModalDelete
          cnpj={deleteSelected.map(
            id => items.find(({ id: eachId }) => eachId === id).cnpj
          )}
          groupName={groupName}
          onExit={onExit}
          onRemove={onRemoveSelected}
        />
      ) : null}
      {items && checkboxes.length ? (
        <S.CheckboxInfoGuide>
          <S.SelectedCheckboxInfo>
            <S.RemoveIndustryIcon
              title="Limpar"
              onClick={cleanSelectedIndustries}
            >
              <Icon icon="input-exit" height={12} width={12} />
            </S.RemoveIndustryIcon>
            <span>{descriptionIndustrySelect()}</span>
            <S.RemoveIndustry onClick={() => setDeleteSelected(checkboxes)}>
              {actionIndustrySelect()}
            </S.RemoveIndustry>
          </S.SelectedCheckboxInfo>
        </S.CheckboxInfoGuide>
      ) : null}
      <S.TableGroup>
        <S.ScrollWrapper>
          <S.Table id="table-scrolled">
            <tbody>
              <S.Line id="header">
                <S.Header>
                  <S.CheckboxLine>
                    <Checkbox
                      labelFor=""
                      label=""
                      value="all"
                      checked={allCheckboxes}
                      onCheck={changeAll}
                    />
                    Razão Social
                  </S.CheckboxLine>
                </S.Header>
                <S.Header>Nome Fantasia</S.Header>
                <S.Header>CNPJ</S.Header>
              </S.Line>
              {items.map((each, key) => (
                <S.Line id="values" key={key}>
                  <S.Column>
                    <S.CheckboxLine>
                      <Checkbox
                        labelFor=""
                        label=""
                        value={each.id}
                        isChecked={
                          !!checkboxes.filter(f => f.includes(each.id)).length
                        }
                        onCheck={handleCheckbox(each.id)}
                      />
                      {each.name}
                    </S.CheckboxLine>
                  </S.Column>
                  <S.Column>{each.fancyName}</S.Column>
                  <S.Column>{each.cnpj}</S.Column>
                </S.Line>
              ))}
            </tbody>
          </S.Table>
        </S.ScrollWrapper>
        <S.Table id="table-fixed">
          <tbody>
            <S.Line id="header">
              <S.Header>Ações</S.Header>
            </S.Line>
            {items.map((each, key) => (
              <S.Line key={key}>
                <S.Column id="actions-column">
                  <IndustryGroupFilterDropDown
                    setDeleteIndustry={() => setDeleteIndustry(each)}
                  />
                </S.Column>
              </S.Line>
            ))}
          </tbody>
        </S.Table>
      </S.TableGroup>
      {!items.length && <TableNotFoundHint onClick={onNewClick} />}
    </S.Wrapper>
  )
}
