import { Base } from 'layouts'
import { UserForm } from 'components'

import * as S from './styled'

export function UserRegister() {
  return (
    <Base>
      <S.GridContainer container>
        <UserForm />
      </S.GridContainer>
    </Base>
  )
}
