import styled from 'styled-components'

export const Wrapper = styled.div`
  background-color: #ededed;
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100%;
  justify-content: center;
  width: 100%;
`

export const Logo = styled.img.attrs({
  alt: 'Lupa com uma folha com Ecotrace escrito ao lado',
  src: '/images/logo.png'
})`
  height: auto;
  object-fit: contain;
  object-position: center;
  margin: 0 0 2.4rem 0;
  width: 16rem;
`

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 2.4rem;
  font-weight: 500;
  text-align: center;
`
