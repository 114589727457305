import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import OrdersTableProvider from 'contexts/ordersTable/ordersTable.context'

import { Base } from 'layouts'

import { FilterForm } from './components/FilterForm'
import { GeneralDataTable } from './components/GeneralDataTable'
import { OrderSummaryTable } from './components/OrderSummaryTable'

import * as S from './styled'

export function LeadTime() {
  const { t } = useTranslation()
  const [filters, setFilters] = useState(null)
  return (
    <Base>
      <OrdersTableProvider>
        <GeneralDataTable />
        <S.Container>
          <S.Title>
            {t('leadTimePage:tables:orderSummary:orderSummary')}
            <S.Line />
          </S.Title>
          <FilterForm onSubmit={setFilters} />
          <OrderSummaryTable filters={filters} />
        </S.Container>
      </OrdersTableProvider>
    </Base>
  )
}
