import { useCallback } from 'react'

import * as S from './styled'

function sanitizeEmail(rawEmail: string): string {
  const [address, domain] = rawEmail.split('@')
  const account = [
    address.charAt(0),
    Array.from({ length: address.length - 2 })
      .fill('*')
      .join(''),
    address.charAt(address.length - 1)
  ].join('')
  return `${account}@${domain}`
}

export function SuccessRequestMessage({ email }: { email: string }) {
  const $sanitizeEmail = useCallback(sanitizeEmail, [])

  return (
    <S.Wrapper container>
      <S.FullGrid item xs={12}>
        <S.Description item sm={12}>
          Dentro de alguns instantes você deve receber o e-mail, <br />
          caso não receba, verifique a sua caixa de spam.
        </S.Description>

        <S.DescriptionBox item sm={12}>
          <S.DescriptionText>
            E-mail de alteração de senha enviado para <br />
            <S.BoldText>{$sanitizeEmail(email)}</S.BoldText>
          </S.DescriptionText>
        </S.DescriptionBox>
      </S.FullGrid>
    </S.Wrapper>
  )
}
