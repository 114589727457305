import styled from 'styled-components'

export const Wrapper = styled.div.attrs(() => {})`
  .highcharts-node {
    cursor: pointer;
    fill-opacity: 0.9;
    shape-rendering: auto;
  }

  .highcharts-node:nth-child(odd) {
    fill: #fafafa;
    stroke: rgb(204, 204, 204);
  }

  .highcharts-node:nth-child(even) {
    fill: #d9ecf7;
    stroke: rgb(204, 204, 204);
  }

  .highcharts-data-labels span {
    color: #000 !important;
    cursor: pointer;
    width: 146px;
    text-align: center;
    word-break: break-word !important;
    white-space: normal !important;
  }
`

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 2.4rem;
  font-weight: 500;
  text-align: center;
`
