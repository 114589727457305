import { HTTPB2B } from 'providers'

export class OrdersManagementService extends HTTPB2B {
  private endpoint = '/ordersManagement'

  public async getOrdersManagementTable(filters?: Record<string, any>) {
    return this.get({
      endpoint: this.endpoint,
      params: filters
    })
  }

  public async getSummary(filters) {
    return this.get({
      endpoint: `${this.endpoint}/summary`,
      params: filters
    })
  }

  public async getHeaderDetails(filters) {
    return this.get({
      endpoint: `${this.endpoint}/header-details`,
      params: filters
    })
  }

  public async getItemsDetails(filters) {
    return this.get({
      endpoint: `${this.endpoint}/details`,
      params: filters
    })
  }

  public async getInvoices(filters) {
    return this.get({
      endpoint: `${this.endpoint}/invoices`,
      params: filters
    })
  }
}
