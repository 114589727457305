import { useState } from 'react'
import * as Yup from 'yup'

import { submitOnEnter } from 'utils/submitOnEnter'
import { YupCNPJ } from 'utils/YupCNPJ'

import * as S from './styled'
import { InputDate, RadioButton, Button, Input, InputSelect } from 'components'

import { toasts } from 'utils/toasts'
import { addDays, format, subDays, subMonths } from 'date-fns'
import { retailRequestPayload } from 'constants/retailFilterForm.constants'
import { useTranslation } from 'react-i18next'

const INITIAL_STATE = {
  startDate: subMonths(new Date(), 1),
  endDate: new Date(),
  dateType: 'order',
  cnpj: '',
  name: '',
  batchNumber: '',
  orderNumber: '',
  invoiceNumber: '',
  orderNumberProduction: '',
  certificationABR: null
}

const ERRORS_INITIAL_STATE = {
  startDate: null,
  endDate: null,
  dateType: 'order',
  cnpj: '',
  name: '',
  batchNumber: '',
  orderNumber: '',
  invoiceNumber: '',
  orderNumberProduction: '',
  certificationABR: null
}

Yup.addMethod(Yup.object, 'atLeastOneOf', function (list) {
  return this.test({
    name: 'atLeastOneOf',
    message: 'Preencha o formulário corretamente',
    exclusive: true,
    params: { keys: list.join(', ') },
    test: value => value == null || list.some(f => !!value[f])
  })
})

const schema = Yup.object()
  .shape({
    startDate: Yup.date().nullable(),
    endDate: Yup.date().nullable(),
    cnpj: Yup.lazy(data => {
      if (data) return YupCNPJ.verify()
      return Yup.string()
    }),
    name: Yup.string(),
    orderNumber: Yup.string(),
    invoiceNumber: Yup.string(),
    batchNumber: Yup.string(),
    orderNumberProduction: Yup.string()
  })
  .atLeastOneOf([
    'startDate',
    'endDate',
    'cnpj',
    'name',
    'orderNumber',
    'invoiceNumber',
    'batchNumber',
    'orderNumberProduction'
  ])

type FilterProps = {
  onSubmit: (_form: any) => void
}

export function FilterForm({ onSubmit }: FilterProps) {
  const { t } = useTranslation()
  const [errors, setErrors] = useState({ ...ERRORS_INITIAL_STATE })
  const [form, setForm] = useState({ ...INITIAL_STATE })

  async function onFilter() {
    try {
      const filter = await schema.validate(form, {
        abortEarly: false,
        stripUnknown: true
      })

      const params = {
        orderNumber: filter.orderNumber,
        invoiceNumber: filter.invoiceNumber,
        batchNumber: filter.batchNumber,
        orderNumberProduction: filter.orderNumberProduction,
        cnpj: filter.cnpj,
        name: filter.name,
        [retailRequestPayload[form.dateType].startDate]:
          filter.startDate && format(new Date(filter.startDate), 'yyyy-MM-dd'),
        [retailRequestPayload[form.dateType].endDate]:
          filter.endDate && format(new Date(filter.endDate), 'yyyy-MM-dd')
      }

      onSubmit(params)
    } catch (ex) {
      console.log(ex.inner)
      const pairs = ex.inner.map(({ path, message }) => [
        path,
        message.replace(`${path} `, '')
      ])
      toasts.invalidForm()
      setErrors(Object.fromEntries(pairs) as typeof ERRORS_INITIAL_STATE)
    }
  }

  function clearFilter() {
    setForm({ ...INITIAL_STATE })
    setErrors({ ...ERRORS_INITIAL_STATE })
  }

  function handleInput(value: string, name: string) {
    setErrors({ ...errors, [name]: '' })
    setForm({ ...form, [name]: value })
  }

  function handleInputData(value: string | Date, name: string) {
    setForm({ ...form, [name]: value })
  }

  function handleSelect(value: any, name: string) {
    if (value.value) {
      setForm({ ...form, [name]: value.value })
    } else {
      setForm({ ...form, [name]: value })
    }
  }

  const dateType = [
    {
      title: t('traceabilityWeavingPage:filtersWeaving:order'),
      value: 'order'
    },
    {
      title: t('traceabilityWeavingPage:filtersWeaving:invoice'),
      value: 'invoice'
    },
    {
      title: t('traceabilityWeavingPage:filtersWeaving:production'),
      value: 'production'
    }
  ]

  return (
    <S.Wrapper container>
      <S.FullGrid item xs={12}>
        <S.GridHeader>
          <S.GridTitle item container xs={12}>
            <S.BoxTitle>
              {t('traceabilityWeavingPage:filtersWeaving:filterData')}
            </S.BoxTitle>
            <S.BoxLine />
          </S.GridTitle>
        </S.GridHeader>
        <S.GridFilter container>
          <S.GridInput item xs={12} sm={6} md={2}>
            <InputDate
              fullWidth
              label={t('traceabilityWeavingPage:filtersWeaving:initialDate')}
              value={form.startDate}
              error={Boolean(errors.startDate)}
              helperText={errors.startDate}
              onInput={value => handleInputData(value, 'startDate')}
              maxDate={form.endDate && subDays(form.endDate, 1)}
            />
          </S.GridInput>
          <S.GridInput item xs={12} sm={6} md={2}>
            <InputDate
              fullWidth
              label={t('traceabilityWeavingPage:filtersWeaving:finalDate')}
              value={form.endDate}
              error={Boolean(errors.endDate)}
              helperText={errors.endDate}
              onInput={value => handleInputData(value, 'endDate')}
              minDate={form.startDate && addDays(form.startDate, 1)}
            />
          </S.GridInput>
          <S.GridInput item xs={12} sm={12} md={8}>
            <RadioButton
              label=""
              options={dateType}
              key="dateType"
              value={form.dateType}
              onSelected={value => handleSelect(value, 'dateType')}
            />
          </S.GridInput>
        </S.GridFilter>
        <S.GridFilter container>
          <S.GridInput item xs={12} sm={6} md={4}>
            <Input
              error={Boolean(errors.orderNumber)}
              fullWidth
              helperText={errors.orderNumber}
              label={t('traceabilityWeavingPage:filtersWeaving:orderNumber')}
              onInput={value => handleInput(value, 'orderNumber')}
              onKeyPress={submitOnEnter(onFilter)}
              value={form.orderNumber}
            />
          </S.GridInput>
          <S.GridInput item xs={12} sm={6} md={4}>
            <Input
              error={Boolean(errors.invoiceNumber)}
              fullWidth
              helperText={errors.invoiceNumber}
              label={t('traceabilityWeavingPage:filtersWeaving:salesInvoice')}
              onInput={value => handleInput(value, 'invoiceNumber')}
              onKeyPress={submitOnEnter(onFilter)}
              value={form.invoiceNumber}
            />
          </S.GridInput>

          <S.GridInput item xs={12} sm={6} md={4}>
            <Input
              error={Boolean(errors.batchNumber)}
              fullWidth
              helperText={errors.batchNumber}
              label={t('traceabilityWeavingPage:filtersWeaving:lotNumber')}
              onInput={value => handleInput(value, 'batchNumber')}
              onKeyPress={submitOnEnter(onFilter)}
              value={form.batchNumber}
            />
          </S.GridInput>

          <S.GridInput item xs={12} sm={6} md={4}>
            <Input
              error={Boolean(errors.orderNumberProduction)}
              fullWidth
              helperText={errors.orderNumberProduction}
              label={t(
                'traceabilityWeavingPage:filtersWeaving:productionOrderNumber'
              )}
              onInput={value => handleInput(value, 'orderNumberProduction')}
              onKeyPress={submitOnEnter(onFilter)}
              value={form.orderNumberProduction}
            />
          </S.GridInput>
          <S.GridInput item xs={12} sm={6} md={4}>
            <Input
              error={Boolean(errors.cnpj)}
              fullWidth
              helperText={errors.cnpj}
              label={t('traceabilityWeavingPage:filtersWeaving:CNPJweaving')}
              onInput={value => handleInput(value, 'cnpj')}
              onKeyPress={submitOnEnter(onFilter)}
              value={form.cnpj}
            />
          </S.GridInput>
          <S.GridInput item xs={12} sm={6} md={4}>
            <Input
              error={Boolean(errors.name)}
              fullWidth
              helperText={errors.name}
              label={t(
                'traceabilityWeavingPage:filtersWeaving:weavingCorporateName'
              )}
              onInput={value => handleInput(value, 'name')}
              onKeyPress={submitOnEnter(onFilter)}
              value={form.name}
            />
          </S.GridInput>
          <S.GridInput item xs={12} sm={6} md={4}>
            <InputSelect
              error={Boolean(errors.certificationABR)}
              fullWidth
              helperText={errors.certificationABR}
              label={t(
                'traceabilityWeavingPage:filtersWeaving:certificationABR'
              )}
              onSelected={() => {}}
              optionLabel="nome"
              optionValue="id.M49"
              options={[]}
              loadingText="Aguarde..."
            />
          </S.GridInput>
        </S.GridFilter>

        <S.GridFilter>
          <S.GridButtons item xs={12} sm={12} md={12}>
            <Button variant="blue" onClick={clearFilter} size="medium">
              <p>{t('traceabilityWeavingPage:filtersWeaving:clean')}</p>
            </Button>
            <Button variant="blue" onClick={onFilter} size="medium">
              <p>{t('traceabilityWeavingPage:filtersWeaving:filter')}</p>
            </Button>
          </S.GridButtons>
        </S.GridFilter>
      </S.FullGrid>
    </S.Wrapper>
  )
}
