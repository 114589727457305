import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { IndustryGroupService } from 'services'

import { IndustryGroupFilter } from './IndustryGroupFilter'
import { IndustryGroupInfo } from './IndustryGroupInfo'
import { ModalAddIndustry } from './ModalAddIndustry/ModalAddIndustry'

import * as S from './styled'
interface ParamTypes {
  id: string
}

const INITIAL_STATE_INDUSTRY_GROUP = {
  adminEmail: '',
  adminName: '',
  cnpj: '',
  companies: [],
  companiesPagination: {},
  companyGroupProfiles: [],
  id: '',
  name: '',
  visible: null
}

export function ManageIndustryGroup() {
  const { id } = useParams<ParamTypes>()

  const industryGroupService = new IndustryGroupService()
  const [industryGroup, setIndustryGroup] = useState({
    ...INITIAL_STATE_INDUSTRY_GROUP
  })

  const [modalAdd, setModalAdd] = useState(false)
  const [attTable, setAttTable] = useState(false)

  async function fetchOneIndustryGroups() {
    const { data } = await industryGroupService.fetchOne(id)
    setIndustryGroup(data as any)
  }

  useEffect(() => {
    if (id) fetchOneIndustryGroups()
  }, [id])

  function onExit() {
    setModalAdd(false)
  }

  return (
    <S.Wrapper container spacing={3}>
      {modalAdd && (
        <ModalAddIndustry attTable={setAttTable} id={id} onExit={onExit} />
      )}
      <S.GridContainer item xs={12} md={12} lg={12}>
        <IndustryGroupInfo industry={industryGroup} />
      </S.GridContainer>
      <S.GridContainer item xs={12} md={12} lg={12}>
        <IndustryGroupFilter
          attFilterTable={attTable}
          onNewClick={() => setModalAdd(true)}
        />
      </S.GridContainer>
    </S.Wrapper>
  )
}
