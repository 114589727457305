import { useCallback, useEffect, useState } from 'react'
import i18n from 'i18n'

import { OrderService } from 'services'

import { formaDateStringLocale } from 'utils/formaDateStringLocale'
import { calcOffsetForPagination } from 'utils/CalcOffsetForPagination'

import { itemOrderDetails } from './ordersDetails.constants'
import { OrderItemsDetails } from './orderDetails.types'

import { Pagination, Table } from 'components'

export function ItemOrderDetails({ id }: any) {
  const [columnSorted, setColumnSorted] = useState('qtyTotal')
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC'>('DESC')
  const language = i18n.language === '中文 - ZH - CN' ? 'zh-CN' : i18n.language

  const [itemOrders, setItemOrders] = useState<OrderItemsDetails>({
    items: null,
    pagination: {
      limit: 0,
      page: 0,
      totalItems: 0,
      totalPages: 0
    }
  })

  const getItemOrdersFromApiWithID = useCallback(
    async (
      column: string,
      direction: string,
      page?: number,
      limit?: number
    ) => {
      const orderService = new OrderService()
      await orderService
        .getOrderItens({
          id,
          page: page || itemOrders.pagination.page,
          limit: limit || itemOrders.pagination.limit,
          column,
          direction
        })
        .then(response => {
          const { data } = response
          setItemOrders(data as OrderItemsDetails)
        })
    },
    []
  )

  const formattedItemOrders = itemOrders.items?.map(item => ({
    ...item,
    previousDeliveryDate: item.previousDeliveryDate
      ? formaDateStringLocale(item.previousDeliveryDate)
      : null,
    deliveryDate: item.deliveryDate
      ? formaDateStringLocale(item.deliveryDate)
      : null,
    qtyTotal: Number(item.qtyTotal).toLocaleString(language),
    invoiceQty: Number(item.invoiceQty).toLocaleString(language)
  }))

  useEffect(() => {
    if (id) {
      getItemOrdersFromApiWithID(columnSorted, sortDirection)
    }
  }, [sortDirection, columnSorted])

  function handlePaginationFilter({ limit, page }) {
    const newFilter = {
      ...itemOrders.pagination
    }

    newFilter.page = calcOffsetForPagination(
      itemOrders.pagination.limit,
      itemOrders.pagination.page,
      limit,
      page
    )
    newFilter.limit = limit
    setItemOrders({ ...itemOrders, pagination: newFilter })

    getItemOrdersFromApiWithID(
      columnSorted,
      sortDirection,
      newFilter.page,
      newFilter.limit
    )
  }

  function handleSortData(column: string, direction: 'ASC' | 'DESC') {
    setColumnSorted(column)
    setSortDirection(direction)
  }

  function handleSortColumn(column: string) {
    setColumnSorted(column)
    handleSortData(column, sortDirection)
    if (column !== columnSorted) return
    const direction = sortDirection === 'ASC' ? 'DESC' : 'ASC'
    handleSortData(column, direction)
  }

  return (
    <>
      <Table
        rows={formattedItemOrders}
        columns={itemOrderDetails()}
        statusBoxColumnName="orderStatus"
        situationBoxColumnName="orderSituation"
        sortColumn={{ column: columnSorted, sort: sortDirection }}
        onSortColumn={handleSortColumn}
      />
      <Pagination
        totalItems={itemOrders.pagination.totalItems}
        limit={itemOrders.pagination.limit}
        page={itemOrders.pagination.page}
        setFilter={handlePaginationFilter}
      />
    </>
  )
}
