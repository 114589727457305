import { useSelector } from 'react-redux'
import { useEffect, useMemo, useRef, useState } from 'react'
import * as Highcharts from 'highcharts'
import HighchartsSankey from 'highcharts/modules/sankey'
import HighchartsReact from 'highcharts-react-official'

import { all } from 'store/Sidebar/Sidebar.selector'

import * as S from './styled'

HighchartsSankey(Highcharts)

export type ChartProps = {
  data: Highcharts.SeriesSankeyPointOptionsObject[] | any[]
  nodes: Highcharts.SeriesSankeyNodesOptionsObject[] | any[]
  height?: number
  margin?: number
  resize: number
  handleMarkerMap: (
    _node: Highcharts.SeriesSankeyNodesOptionsObject | any
  ) => void
}

const colors: any = [
  {
    linearGradient: { x1: 1, x2: 0, y1: 0, y2: 1 },
    stops: [
      [0, '#b9dcf0'],
      [1, '#1883ad']
    ]
  },
  {
    linearGradient: { x1: 1, x2: 0, y1: 0, y2: 1 },
    stops: [
      [0, '#61ac36'],
      [1, '#448891']
    ]
  },
  {
    linearGradient: { x1: 1, x2: 0, y1: 0, y2: 1 },
    stops: [
      [0, '#080857'],
      [1, '#aba6f5']
    ]
  },
  {
    linearGradient: { x1: 1, x2: 0, y1: 0, y2: 1 },
    stops: [
      [0, '#cef0e3'],
      [1, '#08a86d']
    ]
  }
]

function linksClick(point, series, handleMarkerMap) {
  if (point.formatPrefix === 'node') {
    let selected = false

    const existNode = []
    const nodeSelected = []
    const selecteds = series.nodes.filter(f => f.selected)

    series.nodes.forEach(f => {
      selecteds.filter(selected => {
        const from = f.linksFrom.findIndex(
          from =>
            from.to === point.id &&
            f.selected &&
            point.column !== selected.column
        )
        const to = f.linksTo.findIndex(
          to =>
            to.from === point.id &&
            f.selected &&
            point.column !== selected.column
        )
        if (from !== -1 || to !== -1) {
          existNode.push(selected)
          return selected
        }
        return false
      })
    })

    series.nodes.forEach(f => {
      if (
        (f.id === point.id && !f.selected) ||
        existNode.filter(exist => exist.id === f.id && exist.id !== point.id)
          .length
      ) {
        f.selected = true
        selected = true
        nodeSelected.push({
          id: f.id,
          latlng: f.latlng || null,
          polygon: f.polygon || null,
          geo: f.geo || null
        })
        f.graphic.css({ fill: '#accaff' })
      } else {
        f.graphic.css({ fill: undefined })
        f.selected = false
      }
    })

    const updateSelecteds = series.nodes.filter(f => f.selected)

    series.data.forEach(elemento => {
      if (selected) {
        let linksFrom = false
        let linksTo = false
        let from = false
        let to = true

        const _from =
          updateSelecteds.findIndex(selected => selected.id === elemento.from) >
          -1
        const _to =
          updateSelecteds.findIndex(selected => selected.id === elemento.to) >
          -1

        if (updateSelecteds.length > 1) {
          to = _from && _to
          from = _from && _to

          linksFrom =
            updateSelecteds.findIndex(selected => {
              const existTo =
                selected.linksFrom.findIndex(
                  sFrom =>
                    updateSelecteds.findIndex(upSel => upSel.id === sFrom.to) >
                    -1
                ) > -1
              if (selected.id === elemento.from && !existTo) {
                return true
              }

              return false
            }) > -1

          linksTo =
            updateSelecteds.findIndex(selected => {
              const existFrom =
                selected.linksTo.findIndex(
                  sTo =>
                    updateSelecteds.findIndex(upSel => upSel.id === sTo.from) >
                    -1
                ) > -1

              if (selected.id === elemento.to && !existFrom) {
                return true
              }
              return false
            }) > -1
        } else {
          linksFrom =
            updateSelecteds.findIndex(
              selected =>
                selected.linksFrom.findIndex(f => f.to === elemento.from) > -1
            ) > -1

          linksTo =
            updateSelecteds.findIndex(
              selected =>
                selected.linksTo.findIndex(f => f.from === elemento.to) > -1
            ) > -1
          to = _to
          from = _from
        }

        if (from || to || linksFrom || linksTo) {
          elemento.graphic.css({ opacity: 1, display: 'block' })
        } else elemento.graphic.css({ opacity: 0, display: 'none' })
      } else elemento.graphic.css({ opacity: 1, display: 'block' })
    })

    handleMarkerMap(nodeSelected)
  }
}

function nodeFormatter(node) {
  const value =
    node.linksFrom.length > 0
      ? node.sum
      : node.linksTo.reduce((val, linkTo) => {
          return (val += parseFloat(linkTo.value))
        }, 0)

  let info = ''

  if (node.column === 0) {
    info = String.raw`
    <div
      style="
            margin-top: 12px;
            color: #6A707E;
            font-size: 16px;"
      >Quantidade de venda</div>

    <div style="margin-top: 2px;">${Math.trunc(value)} animais</div>`
  }

  if (node.column === 1) {
    const sale = node.linksFrom.reduce(
      (val, linkTo) => (val += parseFloat(linkTo.value)),
      0
    )

    info = String.raw`
    <div
      style="
            margin-top: 12px;
            color: #6A707E;
            font-size: 16px;"
      >Quantidade de compra</div>

    <div style="margin-top: 2px;">${Math.trunc(value)} animais</div>


    <div
      style="
      margin-top: 12px;
            color: #6A707E;
            font-size: 16px;"
      >Volume de venda</div>

    <div style="margin-top: 2px;">${sale.toFixed(2)} kg</div>`
  }

  if (node.column === 2) {
    info = String.raw`
    <div
      style="
            margin-top: 12px;
            color: #6A707E;
            font-size: 16px;"
      >Volume de compra</div>

    <div style="margin-top: 2px;">${value} kg</div>`
  }

  return String.raw`
    <div
      style="
        color: #315CA7;
        font-size: 18px;
        font-weight: 600">
        <div>${node.name}</div>

        <div style="margin-top: 6px">${info}</div>
    </div>`
}

function pointFormatter(point) {
  let info = ''
  if (point.fromNode.column === 0) {
    info = String.raw`
      <div style="margin-top: 10px;display: flex;flex-direction:column">
      <span
        style="
              color: #6A707E;
              font-size: 16px;
              margin-bottom: 5px;"
        >Quantidade de venda
      </span>

      <div style="display: flex;justify-content: space-between">
        <span style="color: #393D46; font-size: 20px;">${point.value} animais</span>
      </div>
      </div>
      `
  }

  if (point.fromNode.column === 1) {
    info = String.raw`
      <div style="margin-top: 10px;display: flex;flex-direction:column">
      <span
        style="
              color: #6A707E;
              font-size: 16px;
              margin-bottom: 5px;"
        >Volume de venda
      </span>

      <div style="display: flex;justify-content: space-between">
        <span style="color: #393D46; font-size: 20px;">${point.value} kg</span>
      </div>
      </div>
      `
  }

  return String.raw`
    <div
      style="
            padding:5px;
            color: #315CA7;
            font-size: 18px;
            font-weight: 600"
    >
      <div
        style="
              display: flex;
              flex-direction: row;
              padding: 5px;
              border-bottom: 1px solid #F2F3F7;
              align-items:center">
        <div style="margin-right:5px">${point.from}</div>→<div style="margin-left:5px">${point.to}</div>
      </div>

      ${info}
    </div>
  `
}

const options = ({
  data,
  nodes,
  height,
  margin,
  setNodeSelected
}): Highcharts.Options => {
  return {
    plotOptions: {
      sankey: {
        tooltip: {
          followPointer: true,
          headerFormat: null,
          nodeFormatter: function () {
            return nodeFormatter(this)
          },

          pointFormatter: function () {
            return pointFormatter(this)
          }
        },
        linkOpacity: 1,
        stickyTracking: false,
        point: {
          events: {
            click: function () {
              linksClick(this, this.series, setNodeSelected)
            }
          }
        }
      }
    },
    title: { text: null },
    credits: { enabled: false },
    chart: { height, margin },
    colors,
    series: [
      {
        allowPointSelect: false,
        clip: true,
        curveFactor: 0.1,
        data,
        dataLabels: { useHTML: true },
        enableMouseTracking: true,
        keys: ['from', 'to', 'weight', 'value'],
        minLinkWidth: 1,
        name: 'Tracecotton',
        nodePadding: 0,
        nodes,
        nodeWidth: 170,
        opacity: 1,
        stickyTracking: true,
        type: 'sankey'
      }
    ],
    tooltip: {
      outside: true,
      useHTML: true,
      backgroundColor: '#fafafa',
      borderWidth: 1
    }
  }
}

export function SankeyDiagramChart({
  data,
  height = 600,
  margin = 8,
  nodes,
  resize,
  handleMarkerMap
}: ChartProps) {
  const chartRef = useRef(null)
  const { drawerOpen } = useSelector(all)
  const [nodeSelected, setNodeSelected] = useState(null)

  useEffect(() => {
    handleMarkerMap(nodeSelected)
  }, [nodeSelected, handleMarkerMap])

  useEffect(() => {
    if (chartRef && chartRef.current) {
      setTimeout(() => chartRef.current?.chart.reflow(), 500)
    }
  }, [drawerOpen, resize])

  const chart = useMemo(
    () => (
      <HighchartsReact
        highcharts={Highcharts}
        options={options({
          data,
          height,
          margin,
          nodes,
          setNodeSelected
        })}
        ref={chartRef}
      />
    ),
    [data, nodes, height, margin]
  )

  return <S.Wrapper data-testid="chart">{chart}</S.Wrapper>
}
