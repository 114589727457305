import styled from 'styled-components'

type WrapperProps = {
  height: string
}

export const Wrapper = styled.div<WrapperProps>`
  align-items: center;
  display: flex;
  height: ${({ height }) => height};
  justify-content: center;
  width: 100%;

  padding: 10rem 0;
`
