import { IndustryGroupType } from 'types/IndustryGroup.types'
import { TableNotFoundHint } from 'components/TableNotFoundHint'

import * as S from './styled'

export type IndustryGroupProps = {
  payload: IndustryGroupType[]
}

export function IndustryGroup({ payload }: IndustryGroupProps) {
  return (
    <S.Wrapper data-testid="IndustryGroup">
      <S.TableGroup>
        <S.ScrollWrapper>
          <S.Table id="table-scrolled">
            <tbody>
              <S.Line id="header">
                <S.Header>Nome do grupo</S.Header>
                <S.Header>Administrador do grupo</S.Header>
                <S.Header>E-mail administrador</S.Header>
                <S.Header>Visualização</S.Header>
              </S.Line>
              {payload.map((each, key) => (
                <S.Line id="values" key={key}>
                  <S.Column>{each.name}</S.Column>
                  <S.Column>{each.adminName}</S.Column>
                  <S.Column>{each.adminEmail}</S.Column>
                  <S.Column>
                    {each.visible ? 'Liberado' : 'Não Liberado'}
                  </S.Column>
                </S.Line>
              ))}
            </tbody>
          </S.Table>
        </S.ScrollWrapper>
      </S.TableGroup>
      {!payload.length && <TableNotFoundHint to="/grupo-industria" />}
    </S.Wrapper>
  )
}
