import styled, { css } from 'styled-components'

interface ButtonProps {
  minWidth: number
  hasCounter: boolean
  counter: number | string
  disabled: boolean
}

export const Button = styled.button<ButtonProps>`
  outline: none;
  border-radius: 4rem;
  border: none;
  font-size: 1.4rem;
  font-weight: 600;
  transform-origin: center;
  transition: background-color 400ms, color 400ms, transform 400ms;
  will-change: background-color, color, transform;
  margin: 0.8rem;
  width: max-content;
  min-width: ${({ minWidth }) => minWidth}rem;
  position: relative;

  ${({ hasCounter, counter, disabled }) =>
    hasCounter &&
    !disabled &&
    css`
      &:before {
        display: flex;
        align-items: center;
        justify-content: center;
        content: '${counter}';
        position: absolute;
        left: -1rem;
        top: 50%;
        transform: translateY(-50%);
        border: 2px solid ${({ theme }) => theme.colors.white};

        width: 2.8rem;
        height: 2.8rem;
        border-radius: 100%;
        background-color: ${({ theme }) => theme.colors.borderColor};
        color: ${({ theme }) => theme.colors.darkGray};
      }
    `}

  &.blue {
    background-color: ${({ theme }) => theme.colors.primary};
    color: white;
    &:hover {
      background-color: ${({ theme }) => theme.colors.primary.darken()};
    }
  }

  &.green {
    background-color: ${({ theme }) => theme.colors.secondary};
    color: white;
    &:hover {
      background-color: ${({ theme }) => theme.colors.secondary.darken()};
    }
  }

  &.gray {
    background-color: ${({ theme }) => theme.colors.gray};
    color: white;
    &:hover {
      background-color: ${({ theme }) => theme.colors.gray.darken()};
    }
  }

  &:active {
    transform: scale(0.88);
  }

  &.large {
    padding: 1.4rem 10rem;
    font-size: 1.6rem;
  }

  &.medium {
    padding: 1.1rem 3.2rem;
  }

  &.small {
    padding: 0.7rem 2.8rem;
  }

  &.xsmall {
    box-shadow: 0px 3px 6px ${({ theme }) => theme.colors.background.darken()};
    font-weight: 400;
    padding: 0.1rem 1.1rem;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${({ theme }) => theme.colors.disabled} !important;
      color: ${({ theme }) => theme.colors.lightGray} !important;
    `}
`
