import { Base } from 'layouts'

import OrdersTableProvider from 'contexts/ordersTable/ordersTable.context'

import { Summary } from './Components/Summary'
import { GeneralDataTable } from './Components/Tables/GeneralDataTable'
import TraceabilityRetailFiltersProvider from 'contexts/traceabilityRetailTableFilters/traceabilityRetailTableFilters.context'
import SupplierFiltersProvider from 'contexts/supplierFilters/supplierFilters.context'

export function OrderManagementDetails() {
  return (
    <TraceabilityRetailFiltersProvider>
      <SupplierFiltersProvider>
        <OrdersTableProvider>
          <Base>
            <GeneralDataTable />
            <Summary />
          </Base>
        </OrdersTableProvider>
      </SupplierFiltersProvider>
    </TraceabilityRetailFiltersProvider>
  )
}
