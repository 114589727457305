import { AccordionDetails, Tabs } from 'components'
import styled from 'styled-components'

export const Wrapper = styled.div`
  border-radius: 1.2rem;
  margin: 0 0 20px;
  width: 100%;
`

export const TotalizersContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(33rem, 1fr));
  width: 100%;
  gap: 2rem;
  margin-bottom: 5.6rem;
`

export const ChartsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
  width: 100%;
  gap: 2rem;
  margin-bottom: 5.6rem;
`

export const SummaryWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
  margin-bottom: 3rem;

  @media screen and (min-width: 768px) {
    margin-top: -3rem;
  }
`

export const SummaryContainer = styled.div`
  width: 100%;
  height: fit-content;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
  align-items: baseline;
  gap: 2rem;
  margin-bottom: 2.5rem;
`

export const SummaryDetails = styled.div``

export const AccordionDetailsContainer = styled.div``

export const AccordionDetailsComponent = styled(AccordionDetails)`
  height: 10rem;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 1.2rem;
  background-color: ${({ theme }) => theme.colors.white};
  margin-bottom: 2.4rem !important;
  width: 100%;
  left: 0;
  right: 0;
`

export const TabsComponent = styled(Tabs)`
  margin-top: 5.6rem !important;
`
