import { useCallback, useState } from 'react'

import { TraceabilityService } from 'services'

import RetailTableProvider from 'contexts/retailTable/retailTable.context'
import SupplierFiltersProvider from 'contexts/supplierFilters/supplierFilters.context'

import { Base } from 'layouts'

import { ISumaryCounter, ITotalizers } from './types'

import { Loading } from 'components'
import { Charts } from './components/Charts'
import { Summary } from './components/Summary'
import { Totalizers } from './components/Totalizers'
import { FilterForm } from './components/FilterForm'
import { ProvidersTabContent } from './components/TabContent'

import * as S from './styled'

export function Provider() {
  const [loading, setLoading] = useState(false)
  const [summary, setSummary] = useState<ITotalizers>(null)
  const [chartsOrdersVolumesData, setChartsOrdersVolumesData] = useState(null)
  const [totalizer, setTotalizer] = useState<ISumaryCounter[]>(null)

  const getFromApi = useCallback(async (filter: Record<string, any>) => {
    setLoading(true)
    try {
      const { data: totalizersData } =
        await new TraceabilityService().getSummary(filter)
      setTotalizer(totalizersData as ISumaryCounter[])

      const { data: chartsOrdersVolumesData } =
        await new TraceabilityService().getChartsOrdersVolumes(filter)
      setChartsOrdersVolumesData(chartsOrdersVolumesData as any)

      const { data: summaryData } =
        await new TraceabilityService().getTotalizer(filter)
      setSummary(summaryData as ITotalizers)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }, [])

  return (
    <Base>
      <SupplierFiltersProvider>
        <RetailTableProvider>
          <FilterForm onSubmit={getFromApi} />
          <S.Wrapper>
            {loading && <Loading height="5rem" />}
            <>
              {!loading && summary && (
                <Summary summaryData={summary}>
                  {totalizer && <Totalizers totalizersData={totalizer[0]} />}
                  {chartsOrdersVolumesData && (
                    <Charts chartsOrdersVolumesData={chartsOrdersVolumesData} />
                  )}
                </Summary>
              )}
              {!loading && <ProvidersTabContent loading={loading} />}
            </>
          </S.Wrapper>
        </RetailTableProvider>
      </SupplierFiltersProvider>
    </Base>
  )
}
