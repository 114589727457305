import styled from 'styled-components'

export const Overlay = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.background.alpha(0.64)};
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  opacity: 0;
  transition: opacity 400ms, visibility 400ms;
  visibility: hidden;
  width: 100%;
  will-change: opacity, visibility;
  z-index: 999;

  &.--visible {
    opacity: 1;
    visibility: visible;
  }
`

export const Spinner = styled.div`
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }

  animation: spin infinite linear 1s;
  border: 1.6rem solid ${({ theme }) => theme.colors.primary.alpha(0.16)};
  border-bottom-color: ${({ theme }) => theme.colors.primary};
  border-radius: 50%;
  height: 10rem;
  width: 10rem;
`
