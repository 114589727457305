import { Input } from 'components/Input'
import { Picture } from 'components/Picture'

import {
  ERRORS_INITIAL_STATE,
  INITIAL_STATE
} from 'constants/industry.constants'
import { useTranslation } from 'react-i18next'

import * as S from './styled'

type TechnicianType = {
  errors: typeof ERRORS_INITIAL_STATE
  payload: typeof INITIAL_STATE
  handleInput: (_value, _name, _field) => void
  onLoadImage: (_key) => void
  onRemoveImage: (_key) => void
}

export function Technician({
  errors,
  payload,
  handleInput,
  onLoadImage,
  onRemoveImage
}: TechnicianType) {
  const { t } = useTranslation()
  function err(name: string) {
    const { technician } = errors
    return technician ? technician[name] : errors[`technician.${name}`]
  }

  return (
    <S.GridFieldsPhoto container>
      <S.GridFields container item sm={12} md={8} spacing={2}>
        <S.GridInput item sm={12} md={6}>
          <Input
            fullWidth
            label={t('newIndustry:responsibleTechnician:nameTechnician')}
            value={payload.technician?.name}
            error={Boolean(err('name'))}
            helperText={err('name')}
            onInput={value => handleInput(value, 'technician', 'name')}
          />
        </S.GridInput>
        <S.GridInput item sm={12} md={6}>
          <Input
            fullWidth
            label={t(
              'newIndustry:responsibleTechnician:professionalIdentificationNumber'
            )}
            value={payload.technician?.doc}
            error={Boolean(err('doc'))}
            helperText={err('doc')}
            onInput={value => handleInput(value, 'technician', 'doc')}
          />
        </S.GridInput>
        <S.GridInput item sm={12} md={12}>
          <Input
            fullWidth
            label={t('newIndustry:responsibleTechnician:about')}
            value={payload.technician?.bio}
            error={Boolean(err('bio'))}
            helperText={err('bio')}
            multiline
            rows={3}
            onInput={value => handleInput(value, 'technician', 'bio')}
          />
        </S.GridInput>
      </S.GridFields>
      <S.GridPhoto item sm={12} md={4}>
        <S.DividerVertical orientation="vertical" />
        <S.CardPhoto>
          <Picture
            name="photo"
            imgUrl={
              payload.technician?.photo ? payload.technician.photo.src : ''
            }
            onLoadImage={onLoadImage}
            onRemoveImage={onRemoveImage}
          />
        </S.CardPhoto>
      </S.GridPhoto>
    </S.GridFieldsPhoto>
  )
}
