import { Grid } from '@material-ui/core'
import styled from 'styled-components'

export const Container = styled(Grid)`
  width: 100%;
  margin-left: 2.4rem;
  font-size: 1.6rem;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 1.6rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;

  strong {
    margin-right: 0.8rem;
    font-weight: bold;
  }
`
