import { t } from 'i18next'
import { FabricDetailsRows, GeneralData } from './fabrics.types'

import { ColumnsProps } from 'components/Table/Table'

export const INITIAL_STATE_GENERAL_DATA: GeneralData = {
  orderNumber: null,
  supplierName: null,
  supplierCnpj: null,
  orderDate: null,
  deliveryDate: null,
  orderStatus: null,
  orderSituation: null,
  deliveryForecastDate: null
}

export const INITIAL_STATE_FABRIC_DETAILS: FabricDetailsRows = {
  items: [],
  pagination: {
    limit: 0,
    page: 0,
    totalItems: 0,
    totalPages: 0
  }
}

export const generalDataTable = (): ColumnsProps[] => [
  {
    fixed: true,
    align: 'center',
    name: 'orderNumber',
    title: t('fabricsPage:tables:generalData:orderNumber')
  },
  {
    fixed: false,
    align: 'center',
    name: 'supplierName',
    title: t('fabricsPage:tables:generalData:companyName')
  },

  {
    fixed: false,
    align: 'center',
    name: 'supplierCnpj',
    title: t('fabricsPage:tables:generalData:numberCompany')
  },
  {
    fixed: false,
    align: 'center',
    name: 'orderDate',
    title: t('fabricsPage:tables:generalData:orderDate')
  },
  {
    fixed: false,
    align: 'center',
    name: 'deliveryDate',
    title: t('fabricsPage:tables:generalData:deliveryDate')
  },
  {
    fixed: false,
    align: 'center',
    name: 'orderStatus',
    title: t('fabricsPage:tables:generalData:orderStatus')
  },
  {
    fixed: false,
    align: 'center',
    name: 'orderSituation',
    title: t('fabricsPage:tables:generalData:orderSituation')
  },
  {
    fixed: false,
    align: 'center',
    name: 'deliveryForecastDate',
    title: t('fabricsPage:tables:generalData:deliveryForecastDate')
  }
]

export const fabricsTable = (): ColumnsProps[] => [
  {
    fixed: true,
    align: 'left',
    name: 'productCode',
    title: t('fabricsPage:tables:fabrics:numberCode')
  }
]

export const fabricsDetailsTable = (): ColumnsProps[] => [
  {
    fixed: true,
    align: 'center',
    name: 'cnpjOrigin',
    title: t('fabricsPage:tables:fabricDetails:originNumber')
  },
  {
    fixed: false,
    align: 'center',
    name: 'invoice',
    title: t('fabricsPage:tables:fabricDetails:invoice')
  },

  {
    fixed: false,
    align: 'center',
    name: 'skuFabric',
    title: t('fabricsPage:tables:fabricDetails:skuFabric')
  },
  {
    fixed: false,
    align: 'center',
    name: 'barCode',
    title: t('fabricsPage:tables:fabricDetails:barCode')
  },

  {
    fixed: false,
    align: 'center',
    name: 'quantity',
    title: t('fabricsPage:tables:fabricDetails:quantity')
  },
  {
    fixed: false,
    align: 'center',
    name: 'unity',
    title: t('fabricsPage:tables:fabricDetails:unity')
  }
]
