import jwt from 'jwt-decode'
import { GoTo } from 'utils/goto'

type PermissionsType = {
  retail: boolean
  provider: boolean
  weaving: boolean
  wiring: boolean
  productiveChain: boolean
  traceabilityProperty: boolean
  blockchainHistory: boolean
  ordersManagement: boolean
  leadTime: boolean
  users: boolean
  industries: boolean
  industriesGroup: boolean
  propertyRegister: boolean
  productionLaunchApp: boolean
  productionAllocation: boolean
}

type UserData = {
  id: string
  user: Record<string, any>
  profile: { avatar: string; name: string; permissions: PermissionsType }
}

export const UserStorageService = {
  getRawUserData: () => {
    try {
      return JSON.parse(localStorage.getItem('@user'))
    } catch (_) {
      return null
    }
  },
  isAuthenticated: () => !!localStorage.getItem('@user'),
  signIn: user => localStorage.setItem('@user', user),
  signOut: () => {
    localStorage.removeItem('@user')
    localStorage.removeItem('supplierFilters')
    localStorage.removeItem('traceabilityFilters')
    localStorage.removeItem('ordersManagementFilters')
    localStorage.removeItem('orderKeySelected')
  },
  getToken: () => localStorage.getItem('@user'),
  getUserData: (): UserData => {
    try {
      const user = JSON.parse(localStorage.getItem('@user'))
      const userJWT: Object = jwt(localStorage.getItem('@user'))
      const profile = {
        name: user.profile.name,
        avatar: user.avatar,
        permissions: user.permissions
      }
      return { ...userJWT, user, profile } as UserData
    } catch (_) {
      GoTo.login()
    }
  }
}
