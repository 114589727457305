import dayjs from 'dayjs'
import dayjsUtc from 'dayjs/plugin/utc'
import dayjsTimezone from 'dayjs/plugin/timezone'
import i18n from 'i18n'

dayjs.extend(dayjsUtc)
dayjs.extend(dayjsTimezone)

export function formaDateStringLocale(date: Date | string) {
  const dateLocaleFormat = {
    'pt-BR': 'DD/MM/YYYY',
    'en-US': 'YYYY-MM-DD',
    es: 'DD-MM-YYYY',
    '中文 - ZH - CN': 'YYYY-MM-DD'
  }

  const timezone = 'Zulu' // GMT +0
  const format = dateLocaleFormat[i18n.language]

  return dayjs(new Date(date)).tz(timezone).format(format)
}
