import { useCallback, useEffect, useState } from 'react'

import { IndustryGroupService, IndustryService } from 'services'

import { onFilterPaginate } from 'utils/OnFilterPaginate'

import { Modal } from 'components/Modal'
import { Input } from 'components/Input'
import { Button } from 'components/Button'
import { Pagination } from 'components/Pagination'
import { IndustryTableAdd } from './IndustryTableAdd'
import { InputCpfCnpj } from 'components/InputCpfCnpj'

import * as S from './styled'

const INITIAL_STATE_UNRELATED_INDUSTRIES = {
  items: [],
  pagination: {
    totalItems: 0,
    totalPages: 0,
    page: 0,
    limit: 10
  }
}

const INITIAL_FILTER = { cnpj: '', name: '', limit: 10, page: 0 }

export type ModalAddIndustryProps = {
  id: string
  onExit?: () => void
  attTable: (_) => void
}
export function ModalAddIndustry({
  id,
  onExit,
  attTable
}: ModalAddIndustryProps) {
  const industryService = new IndustryService()
  const industryGroupService = new IndustryGroupService()

  const [loading, setLoading] = useState(false)
  const [industryIds, setIndustryIds] = useState([])
  const [filter, setFilter] = useState(INITIAL_FILTER)
  const [unrelatedIndustries, setUnrelatedIndustries] = useState({
    ...INITIAL_STATE_UNRELATED_INDUSTRIES
  })

  async function fetchUnrelatedIndustries() {
    setLoading(true)
    try {
      const { data } = await industryService.fetchUnrelatedWithGroup({
        groupId: id
      })
      if (data.code === 'NOT_FOUND') return
      setUnrelatedIndustries(data as any)
    } catch (error) {
      console.log('fetchUnrelatedIndustries-error', error)
    } finally {
      setLoading(false)
    }
  }

  async function onAdd() {
    setLoading(true)
    try {
      if (industryIds.length) {
        await industryGroupService.linkWithGroup({
          id,
          companyIds: industryIds
        })
        attTable(true)
        onExit()
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
      attTable(false)
    }
  }

  async function onFilter() {
    setLoading(true)
    try {
      const { data } = await industryService.fetchUnrelatedWithGroup({
        groupId: id,
        params: {
          ...filter,
          limit: unrelatedIndustries.pagination.limit,
          page: unrelatedIndustries.pagination.page
        }
      })
      setUnrelatedIndustries(data as any)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  async function onFilterPaginateUnrelatedIndustries({ limit, page }) {
    const filters = onFilterPaginate(
      unrelatedIndustries.pagination,
      limit,
      page
    )
    const { data } = await industryService.fetchUnrelatedWithGroup({
      groupId: id,
      params: filters
    })
    setUnrelatedIndustries(data as any)
  }

  const clearFilter = useCallback(() => {
    setFilter(INITIAL_FILTER)
    fetchUnrelatedIndustries()
  }, [])

  function handleFilter(value: string, name: string) {
    setFilter({ ...filter, [name]: value })
  }

  useEffect(() => {
    fetchUnrelatedIndustries()
  }, [id])

  const button = (
    <Button variant="green" onClick={onAdd} size="medium">
      <p>Adicionar</p>
    </Button>
  )

  const content = (
    <S.Wrapper>
      <S.GridHeader>
        <S.GridTitle item container xs={12}>
          <S.BoxTitle>Adicionar indústria ao Grupo {name}</S.BoxTitle>
        </S.GridTitle>
      </S.GridHeader>

      <S.Line />

      <S.Title>Lista de indústria</S.Title>

      <S.GridFilter container>
        <S.GridInput item md={12} lg={6}>
          <Input
            fullWidth
            label="Nome"
            value={filter.name}
            onInput={value => handleFilter(value, 'name')}
          />
        </S.GridInput>
        <S.GridInput item md={12} lg={6}>
          <InputCpfCnpj
            fullWidth
            type="cnpj"
            value={filter.cnpj}
            onInput={value => handleFilter(value, 'cnpj')}
          />
        </S.GridInput>
        <S.GridButtons item xs={12}>
          <Button variant="gray" onClick={clearFilter} size="medium">
            <p>Limpar</p>
          </Button>
          <Button variant="blue" onClick={onFilter} size="medium">
            <p>Buscar</p>
          </Button>
        </S.GridButtons>
      </S.GridFilter>

      <S.GridTable>
        <IndustryTableAdd
          industries={unrelatedIndustries.items}
          loading={loading}
          setIndustries={setIndustryIds}
        />
      </S.GridTable>
      {unrelatedIndustries.pagination?.totalItems > 0 && (
        <Pagination
          limit={unrelatedIndustries.pagination.limit}
          page={unrelatedIndustries.pagination.page}
          totalItems={unrelatedIndustries.pagination.totalItems}
          setFilter={onFilterPaginateUnrelatedIndustries}
        />
      )}
    </S.Wrapper>
  )

  return (
    <Modal
      button={button}
      content={content}
      isActionsEnabled={true}
      width={72}
      onExit={onExit}
    />
  )
}
