import L from 'leaflet'
import { useEffect } from 'react'
// import reactElementToJSXString from 'react-element-to-jsx-string'
import './legend.css'

export function Legend({ map }) {
  const LegendContainer = () => {
    return `
      <div class="legend">
        <div class="biome-row">
          <div class="color-block-amazonia"></div>
          Amazônia
        </div>
        <div class="biome-row">
          <div class="color-block-cerrado"></div>
          Cerrado
        </div>
        <div class="biome-row">
          <div class="color-block-caatinga"></div>
          Caatinga
        </div>
        <div class="biome-row">
          <div class="color-block-mata-atlantica"></div>
          Mata Atlântica
        </div>
        <div class="biome-row">
          <div class="color-block-pampa"></div>
          Pampa
        </div>
        <div class="biome-row">
          <div class="color-block-pantanal"></div>
          Pantanal
        </div>
      </div>
    `
  }

  useEffect(() => {
    if (map) {
      const control = new L.Control()
      const legend = control.setPosition('bottomleft')

      legend.onAdd = () => {
        const div = L.DomUtil.create('div', 'legend')
        div.innerHTML = LegendContainer()
        return div
      }

      legend.addTo(map)
    }
  }, [map])
  return null
}

export default Legend
