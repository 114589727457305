import { useContext } from 'react'

import ProductiveChainTraceabilityFilter from 'contexts/productiveChain/productiveChainTraceabilityFilter.context'
import { TraceabilityRetailFiltersContext } from 'contexts/traceabilityRetailTableFilters/traceabilityRetailTableFilters.context'

import { onFilterPaginate } from 'utils/OnFilterPaginate'

import { OrdersTable } from './OrdersTable/OrdersTable'

import { Pagination } from 'components'

import * as S from './styled'

export type TableProps = {
  rows: {
    items: any[]
    pagination: {
      page: number
      totalItems: number
      limit: number
      totalPages: number
    }
  }
  variation: string
  hasActions?: boolean
}

export function Table({ rows, variation }: TableProps) {
  const { traceabilityRetailsFilters, setTraceabilityRetailsFilters } =
    useContext(TraceabilityRetailFiltersContext)

  function handleTableBody() {
    const fixedTable = {
      orders: () => <OrdersTable rows={rows.items || []} />,
      default: () => null
    }
    const Component = fixedTable[variation] || fixedTable.default
    return <Component />
  }

  function handlePaginationFilter({ limit, page }) {
    setTraceabilityRetailsFilters({
      ...traceabilityRetailsFilters,
      ...onFilterPaginate(traceabilityRetailsFilters, limit, page)
    })
  }

  return (
    <>
      <ProductiveChainTraceabilityFilter>
        <S.Wrapper>{handleTableBody()}</S.Wrapper>
        <Pagination
          totalItems={rows.pagination.totalItems}
          page={rows.pagination.page}
          limit={rows.pagination.limit}
          setFilter={handlePaginationFilter}
        />
      </ProductiveChainTraceabilityFilter>
    </>
  )
}
