import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'

import { Dropdown } from 'components/Dropdown'
import { Icon } from 'components/Icon'
import { useTranslation } from 'react-i18next'

import * as S from './styles'
import { UserService, UserStorageService } from 'services'
import { LanguageActions } from 'store/Language'
import { useDispatch, useSelector } from 'react-redux'
import { all as allLanguages } from 'store/Language/language.selector'

export const LangDropdown = () => {
  const langLocalStorage = localStorage.getItem('i18nextLng')
  const dispatch = useDispatch()

  const { i18n } = useTranslation()
  const { languages } = useSelector(allLanguages)
  const [activeLang, setActiveLang] = useState('')
  const [flag, setFlag] = useState('')

  async function setChangeLang(
    tag: string,
    label: string,
    flag: string,
    id: string
  ) {
    const $user = new UserService()
    const loggedInUser = UserStorageService.getUserData()
    $user.updateProfile(loggedInUser.id, { languageId: id })
    i18n.changeLanguage(tag)
    setActiveLang(label)
    setFlag(flag)
  }

  useEffect(() => {
    dispatch(LanguageActions.getAll())
  }, [dispatch])

  useEffect(() => {
    if (languages.length) {
      setFlag(languages.find(item => item.tag === langLocalStorage).flag)
      setActiveLang(languages.find(item => item.tag === langLocalStorage).label)
    }
  }, [langLocalStorage, languages])

  return (
    <S.Wrapper>
      <Dropdown
        title={
          <>
            <Icon icon={`flag-${flag}`} height={24} width={24}></Icon>
            <S.Langname>{activeLang}</S.Langname>
            <Icon icon="user-profine-arrow-down" height={16} width={16} />
          </>
        }
      >
        <S.Nav>
          {languages.map(lang => (
            <Link
              key={lang.id}
              to={'#'}
              onClick={() =>
                setChangeLang(lang.tag, lang.label, lang.flag, lang.id)
              }
            >
              <S.Link>
                <Icon icon={`flag-${lang.flag}`} height={16} width={16}></Icon>
                <span>{lang.label}</span>
              </S.Link>
            </Link>
          ))}
        </S.Nav>
      </Dropdown>
    </S.Wrapper>
  )
}
