import { useState, useCallback } from 'react'

import RetailTableProvider from 'contexts/retailTable/retailTable.context'
import TraceabilityRetailFiltersProvider from 'contexts/traceabilityRetailTableFilters/traceabilityRetailTableFilters.context'

import { TraceabilityService } from 'services'

import { ISumaryCounter, ITotalizers } from './types'

import { Base } from 'layouts'

import { Loading } from 'components'
import { Charts } from './components/Charts'
import { Summary } from './components/Summary'
import { Totalizers } from './components/Totalizers'
import { FilterForm } from './components/FilterForm'
import { TabOrdersComponent } from './components/TabContent'

import * as S from './styled'

export function Retail() {
  const [totalizer, setTotalizer] = useState<ISumaryCounter[]>(null)
  const [chartsRankingData, setChartsRankingData] = useState<any>(null)
  const [chartsOrdersVolumesData, setChartsOrdersVolumesData] =
    useState<any>(null)
  const [summary, setSummary] = useState<ITotalizers>(null)
  const [loading, setLoading] = useState(false)

  const getFromApi = useCallback(async (filter: Record<string, any>) => {
    setLoading(true)
    try {
      const { data: totalizersData } =
        await new TraceabilityService().getSummary(filter)
      setTotalizer(totalizersData as ISumaryCounter[])
      const { data: chartsRankingData } =
        await new TraceabilityService().getChartsRanking(filter)
      setChartsRankingData(chartsRankingData)
      const { data: chartsOrdersVolumesData } =
        await new TraceabilityService().getChartsOrdersVolumes(filter)
      setChartsOrdersVolumesData(chartsOrdersVolumesData)
      const { data: summaryData } =
        await new TraceabilityService().getTotalizer(filter)
      setSummary(summaryData as ITotalizers)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }, [])

  return (
    <Base>
      <TraceabilityRetailFiltersProvider>
        <RetailTableProvider>
          <FilterForm onSubmit={getFromApi} />
          <S.Wrapper>
            {loading && <Loading height="5rem" />}
            <>
              {!loading && summary && (
                <Summary summaryData={summary}>
                  {totalizer && <Totalizers totalizersData={totalizer} />}
                  {chartsOrdersVolumesData && chartsRankingData && (
                    <Charts
                      chartsOrdersVolumesData={chartsOrdersVolumesData}
                      chartsRankingData={chartsRankingData}
                    />
                  )}
                </Summary>
              )}
              {!loading && <TabOrdersComponent loading={loading} />}
            </>
          </S.Wrapper>
        </RetailTableProvider>
      </TraceabilityRetailFiltersProvider>
    </Base>
  )
}
