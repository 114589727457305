import { useMemo, useState } from 'react'
import * as Yup from 'yup'

import { IconButton, InputAdornment } from '@material-ui/core'

import { UserService, UserStorageService } from 'services'
import { YupValidator } from 'services/yupValidator.service'

import { toasts } from 'utils/toasts'

import { ChangePasswordType, LoogedUserType } from 'types/User.types'
import { PASSWORD_INITIAL_STATE } from 'constants/user.constants'

import { Button } from 'components/Button'
import { Icon } from 'components/Icon'
import { Input } from 'components/Input'
import { Modal } from 'components/Modal'

import * as S from './styled'

const ChangePasswordFormSchema = Yup.object().shape({
  confirmPassword: Yup.string()
    .required()
    .oneOf(
      [Yup.ref('password'), null],
      'A confirmação deve ser igual a nova senha'
    )
    .label('Confirmação de senha'),
  currentPassword: Yup.string().required().min(6).max(32).label('Senha atual'),
  password: Yup.string().required().min(6).max(32).label('Nova senha')
})

export type ModalDeleteUserProps = {
  onExit?: () => void
}

export function ModalChangePassword({ onExit }: ModalDeleteUserProps) {
  const userService = new UserService()

  const [form, setForm] = useState({ ...PASSWORD_INITIAL_STATE })
  const [errors, setErrors] = useState({ ...PASSWORD_INITIAL_STATE })
  const [currentPasswordShow, setCurrentPasswordShow] = useState('password')
  const [newPasswordShow, setNewPasswordShow] = useState('password')
  const [passwordConfirmShow, setPasswordConfirmShow] = useState('password')

  function changeCurrentPasswordView() {
    setCurrentPasswordShow(oldValue =>
      oldValue === 'text' ? 'password' : 'text'
    )
  }

  function changeNewPasswordView() {
    setNewPasswordShow(oldValue => (oldValue === 'text' ? 'password' : 'text'))
  }

  function changePasswordConfirmView() {
    setPasswordConfirmShow(oldValue =>
      oldValue === 'text' ? 'password' : 'text'
    )
  }

  const userLogged: LoogedUserType = useMemo(
    () => UserStorageService.getUserData(),
    []
  )

  function handleInput(value: string, name: string) {
    setErrors({ ...errors, [name]: '' })
    setForm({ ...form, [name]: value })
  }

  async function submit() {
    setErrors({ ...PASSWORD_INITIAL_STATE })

    const [isValid, validationError] = await new YupValidator(
      ChangePasswordFormSchema
    ).validate(form)

    if (!isValid) {
      return setErrors(validationError as typeof PASSWORD_INITIAL_STATE)
    }

    const payload: ChangePasswordType = {
      id: userLogged.id,
      currentPassword: form.currentPassword,
      password: form.password,
      confirmPassword: form.confirmPassword
    }

    const { success } = await userService.changePassword({ payload })

    if (success) {
      toasts.updatePwd()
      onExit()
    } else {
      toasts.generalFail()
    }
  }

  const button = (
    <Button variant="green" onClick={submit} size="medium">
      <p>Alterar</p>
    </Button>
  )

  const content = (
    <S.Wrapper>
      <S.DivTitle>
        <S.GridHeader>
          <S.GridTitle item container xs={12}>
            <IconButton
              id="action"
              aria-label="mapButton"
              onClick={() => {}}
              size="medium"
              edge="start"
              style={{ backgroundColor: '#F2F3F7', padding: '1.4rem' }}
            >
              <Icon
                icon="password-lock"
                width={14}
                height={14}
                aria-label="action"
              />
            </IconButton>
            <S.BoxTitle>Alterar senha</S.BoxTitle>
          </S.GridTitle>
        </S.GridHeader>
        <S.Line />
      </S.DivTitle>

      <S.GridFields container spacing={2}>
        <S.GridInput item sm={12}>
          <S.GridDescription>
            A senha deve ter no mínimo 6 caracteres.
          </S.GridDescription>
        </S.GridInput>
        <S.GridInput item sm={12}>
          <Input
            error={Boolean(errors.currentPassword)}
            fullWidth
            helperText={errors.currentPassword}
            iconRight={
              <InputAdornment position="start">
                <IconButton onClick={changeCurrentPasswordView}>
                  <Icon
                    icon={
                      currentPasswordShow === 'text'
                        ? 'input-visible'
                        : 'input-visible-not'
                    }
                    height={16}
                    width={16}
                  />
                </IconButton>
              </InputAdornment>
            }
            label="Senha atual"
            onInput={value => handleInput(value, 'currentPassword')}
            type={currentPasswordShow}
            value={form.currentPassword}
          />
        </S.GridInput>
        <S.GridInput item sm={12}>
          <Input
            error={Boolean(errors.password)}
            fullWidth
            helperText={errors.password}
            iconRight={
              <InputAdornment position="start">
                <IconButton onClick={changeNewPasswordView}>
                  <Icon
                    icon={
                      newPasswordShow === 'text'
                        ? 'input-visible'
                        : 'input-visible-not'
                    }
                    height={16}
                    width={16}
                  />
                </IconButton>
              </InputAdornment>
            }
            label="Nova senha"
            onInput={value => handleInput(value, 'password')}
            type={newPasswordShow}
            value={form.password}
          />
        </S.GridInput>
        <S.GridInput item sm={12}>
          <Input
            error={Boolean(errors.confirmPassword)}
            fullWidth
            helperText={errors.confirmPassword}
            iconRight={
              <InputAdornment position="start">
                <IconButton onClick={changePasswordConfirmView}>
                  <Icon
                    icon={
                      passwordConfirmShow === 'text'
                        ? 'input-visible'
                        : 'input-visible-not'
                    }
                    height={16}
                    width={16}
                  />
                </IconButton>
              </InputAdornment>
            }
            label="Confirmação de senha"
            onInput={value => handleInput(value, 'confirmPassword')}
            type={passwordConfirmShow}
            value={form.confirmPassword}
          />
        </S.GridInput>
      </S.GridFields>
    </S.Wrapper>
  )

  return (
    <Modal
      button={button}
      content={content}
      isActionsEnabled={true}
      width={60}
      onExit={onExit}
    />
  )
}
