import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import { UserStorageService } from 'services'

// Comentando essas importações por decisão de negócio que no momento, não será necessário
// Mantendo código para futuras necessidades e revisões
import {
  General,
  Home,
  Industry,
  IndustryEdit,
  IndustryGroup,
  IndustryGroupEdit,
  IndustryGroupRegister,
  IndustryRegister,
  Login,
  ManageIndustryGroup,
  SearchPanel,
  PageNotFound,
  Profile,
  // Property,
  // PropertyEdit,
  // PropertyRegister,
  RecoveryPwd,
  Retail,
  User,
  UserEdit,
  UserRegister,
  // ProductionChain,
  // Weaving,
  // Wiring
  Provider,
  LeadTime,
  OrderManagement,
  OrderManagementDetails,
  AllOrdersManagement,
  Fabrics
} from 'pages'
import { DownloadReport } from 'pages/DownloadReport'

function PrivateRoute({ component: Component, ...rest }: any) {
  return (
    <Route
      {...rest}
      render={props => {
        if (UserStorageService.isAuthenticated()) {
          return <Component props={props} />
        }

        return (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        )
      }}
    />
  )
}

// Comentando essas rotas e componentes por decisão de negócio que no momento, não será necessário
// Mantendo código para futuras necessidades e revisões
export function Router() {
  return (
    <BrowserRouter>
      <Switch>
        {/* <PrivateRoute
          path="/cadeia-produtiva"
          component={ProductionChain}
          exact
        /> */}
        {/* <PrivateRoute
          path="/rastreabilidade/tecelagem"
          component={Weaving}
          exact
        /> */}
        {/* <PrivateRoute path="/rastreabilidade/fiacao" component={Wiring} exact /> */}

        {/* <PrivateRoute path="/fiacao/detalhes/:id" component={DetailsWiring} /> */}

        <PrivateRoute path="/" component={Home} exact />
        <PrivateRoute path="/geral" component={General} exact />

        <PrivateRoute path="/usuario" component={User} exact />
        <PrivateRoute path="/usuario/novo" component={UserRegister} />
        <PrivateRoute path="/usuario/editar/:id" component={UserEdit} />

        <PrivateRoute path="/rastreabilidade" component={SearchPanel} exact />
        <PrivateRoute
          path="/rastreabilidade/fornecedor"
          component={Provider}
          exact
        />
        <PrivateRoute path="/rastreabilidade/varejo" component={Retail} exact />
        <PrivateRoute
          path="/rastreabilidade/tecidos/:id"
          component={Fabrics}
          exact
        />

        <PrivateRoute path="/industria" component={Industry} exact />
        <PrivateRoute path="/industria/novo" component={IndustryRegister} />
        <PrivateRoute path="/industria/editar/:id" component={IndustryEdit} />

        <PrivateRoute path="/perfil" component={Profile} />

        <PrivateRoute path="/grupo-industria" component={IndustryGroup} exact />
        <PrivateRoute
          path="/grupo-industria/novo"
          component={IndustryGroupRegister}
        />
        <PrivateRoute
          path="/grupo-industria/editar/:id"
          component={IndustryGroupEdit}
        />
        <PrivateRoute
          path="/grupo-industria/gerenciar/:id"
          component={ManageIndustryGroup}
        />

        {/* <PrivateRoute path="/propriedade" component={Property} exact />
        <PrivateRoute path="/propriedade/novo" component={PropertyRegister} />
        <PrivateRoute path="/propriedade/editar/:id" component={PropertyEdit} /> */}

        <PrivateRoute
          path="/gestao-pedidos"
          component={OrderManagement}
          exact
        />
        <PrivateRoute
          path="/gestao-pedidos/pedidos"
          component={AllOrdersManagement}
          exact
        />
        <PrivateRoute
          path="/gestao-pedidos/detalhes-pedido/:id"
          component={OrderManagementDetails}
        />

        <PrivateRoute
          path="/gestao-pedidos/lead-time/:id"
          component={LeadTime}
          exact
        />

        <Route path="/login" component={Login} exact />
        <Route path="/esqueci-minha-senha" component={RecoveryPwd} />
        <Route path="/404" component={PageNotFound} />

        <Route path="/download-report/:reportFile" component={DownloadReport} />
        {/* <Route path="/download-report/:reportFile" component={DownloadReport} /> */}

        <Redirect from="*" to="/404" />
      </Switch>
    </BrowserRouter>
  )
}
